// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AssetsManager$FrontendWebsite from "../../../view/assets/AssetsManager.bs.js";
import * as TripStatusUtils$FrontendWebsite from "../../track-live-order/entity/TripStatusUtils.bs.js";
import * as TrackLiveTripInfo$FrontendWebsite from "../../track-live-order/entity/TrackLiveTripInfo.bs.js";

function VM(Content) {
  var two_wheeler_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("two_wheeler.png");
  var four_wheeler_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("four_wheeler.png");
  var getVehicleType = function (orderDetail) {
    return orderDetail.vehicle.vehicle_type + (
            orderDetail.isRental ? " Rental" : ""
          );
  };
  var showSensitiveInfo = function (orderDetail) {
    var endTime = orderDetail.trip_ended_time;
    if (endTime !== undefined) {
      return ((new Date().getTime() / 1000.0 | 0) - endTime | 0) <= 3600;
    } else {
      return true;
    }
  };
  var getVehicleUrlFromType = function (partnerDetail) {
    if (partnerDetail !== undefined) {
      return partnerDetail.vehicleType;
    } else {
      return "";
    }
  };
  var getVM = function (orderDetail) {
    var partner = orderDetail.partner;
    var tmp;
    if (partner !== undefined) {
      var match = orderDetail.status;
      var match$1 = orderDetail.status;
      var tmp$1;
      var exit = 0;
      switch (match$1) {
        case /* Accepted */2 :
        case /* Live */3 :
        case /* Completed */7 :
            exit = 1;
            break;
        case /* Open */0 :
        case /* Allocated */1 :
        case /* Loading */4 :
        case /* Unloading */5 :
        case /* Ended */6 :
        case /* Cancelled */8 :
        case /* Rescheduled */9 :
            tmp$1 = "";
            break;
        
      }
      if (exit === 1) {
        tmp$1 = showSensitiveInfo(orderDetail) ? partner.mobile : "";
      }
      var match$2 = orderDetail.status;
      var tmp$2;
      var exit$1 = 0;
      switch (match$2) {
        case /* Accepted */2 :
        case /* Live */3 :
        case /* Completed */7 :
            exit$1 = 1;
            break;
        case /* Open */0 :
        case /* Allocated */1 :
        case /* Loading */4 :
        case /* Unloading */5 :
        case /* Ended */6 :
        case /* Cancelled */8 :
        case /* Rescheduled */9 :
            tmp$2 = "";
            break;
        
      }
      if (exit$1 === 1) {
        tmp$2 = showSensitiveInfo(orderDetail) ? partner.vehicleType : "";
      }
      var match$3 = orderDetail.status;
      var tmp$3;
      var exit$2 = 0;
      switch (match$3) {
        case /* Accepted */2 :
        case /* Live */3 :
        case /* Completed */7 :
            exit$2 = 1;
            break;
        case /* Open */0 :
        case /* Allocated */1 :
        case /* Loading */4 :
        case /* Unloading */5 :
        case /* Ended */6 :
        case /* Cancelled */8 :
        case /* Rescheduled */9 :
            tmp$3 = "";
            break;
        
      }
      if (exit$2 === 1) {
        tmp$3 = showSensitiveInfo(orderDetail) ? "• " + partner.vehicleNumber : "";
      }
      tmp = {
        name: match >= 2 ? partner.name : Content.allocated_driver_text,
        mobile: tmp$1,
        vehicleType: tmp$2,
        vehicleNumber: tmp$3
      };
    } else {
      tmp = undefined;
    }
    var match$4 = orderDetail.status;
    var partner$1 = orderDetail.partner;
    var tmp$4;
    if (partner$1 !== undefined) {
      var match$5 = TrackLiveTripInfo$FrontendWebsite.getVehicleTypeFromString(partner$1.vehicleType);
      tmp$4 = match$5 ? Belt_Option.mapWithDefault(orderDetail.vehicle.icons, four_wheeler_icon, (function (icons) {
                return icons.selected;
              })) : Belt_Option.mapWithDefault(orderDetail.vehicle.icons, two_wheeler_icon, (function (icons) {
                return icons.selected;
              }));
    } else {
      tmp$4 = four_wheeler_icon;
    }
    return {
            partnerInfo: tmp,
            noPartner: match$4 !== 8 ? Content.allocated_driver_text : Content.cancelled_driver_text,
            tripStatus: TripStatusUtils$FrontendWebsite.getDisplayString(orderDetail.status, Content.status),
            tripStatusIcon: TripStatusUtils$FrontendWebsite.getDisplayColor(orderDetail.status),
            isHelper: orderDetail.isHelper,
            crn: orderDetail.crn,
            vehicle: {
              vehicle_url: tmp$4,
              vehicle_type: getVehicleType(orderDetail)
            },
            trip_ended_time: orderDetail.trip_ended_time
          };
  };
  return {
          two_wheeler_icon: two_wheeler_icon,
          four_wheeler_icon: four_wheeler_icon,
          getVehicleType: getVehicleType,
          showSensitiveInfo: showSensitiveInfo,
          getVehicleUrlFromType: getVehicleUrlFromType,
          getVM: getVM
        };
}

export {
  VM ,
  
}
/* No side effect */
