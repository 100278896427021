// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ApiUtils$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as ErrorUtils$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";
import * as RescriptReactRouter from "../../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as Config$FrontendWebsite from "../../../../Config.bs.js";
import * as ReactGoogleRecaptchaV3 from "react-google-recaptcha-v3";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../../../utils/helper/CommonHelper.bs.js";
import * as EventContext$FrontendWebsite from "../../../../../utils/context/events-manager/EventContext.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../../../utils/NetworkUtils.bs.js";
import * as OfferValidCmp$FrontendWebsite from "../OfferValidCmp.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../../website/view/components/CircularLoader.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";
import * as HomeRepoManager$FrontendWebsite from "../../../../home/repo/HomeRepoManager.bs.js";
import * as QueryParamsConstructor$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/helper-modules/query-params/QueryParamsConstructor.bs.js";
import * as FareEstimateResultHelper$FrontendWebsite from "../../FareEstimateResultHelper.bs.js";
import * as FareEstimateResultVMMapper$FrontendWebsite from "../../../view-model/FareEstimateResultVMMapper.bs.js";
import * as FareEstimateResultRedirectCmp$FrontendWebsite from "../../FareEstimateResultRedirectCmp.bs.js";
import * as FareEstimateResultRequirement$FrontendWebsite from "../../FareEstimateResultRequirement.bs.js";
import * as FareEstimateResultStateManager$FrontendWebsite from "../../FareEstimateResultStateManager.bs.js";
import * as FareEstimateResultMobileFormCmp$FrontendWebsite from "./FareEstimateResultMobileFormCmp.bs.js";
import * as FareEstimateResultMobileFooterCmp$FrontendWebsite from "./FareEstimateResultMobileFooterCmp.bs.js";
import * as FareEstimateResultMobileHeaderCmp$FrontendWebsite from "./FareEstimateResultMobileHeaderCmp.bs.js";
import * as FareEstimateResultMobileVehiclesListCmp$FrontendWebsite from "./FareEstimateResultMobileVehiclesListCmp.bs.js";

function FareEstimateResultMobileCmp(Props) {
  var form_data = Props.form_data;
  var cmsVm = Props.cmsVm;
  var vehicleSection = cmsVm.vehicleSection;
  var editSection = cmsVm.editSection;
  var match = React.useState(function () {
        return Belt_Option.mapWithDefault(form_data, undefined, (function (x) {
                      return x.city;
                    }));
      });
  var setCity = match[1];
  var city = match[0];
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match$1 = AppContext$FrontendWebsite.useAppContext(undefined);
  var language = match$1.language;
  ContentManager$FrontendWebsite.getContent(language);
  var RepoInstance = HomeRepoManager$FrontendWebsite.getRepo(undefined);
  var initialState_fareEstimateResult = {
    TAG: /* Loading */0,
    _0: undefined
  };
  var initialState = {
    fareEstimateFormData: form_data,
    fareEstimateResult: initialState_fareEstimateResult,
    showEditForm: false,
    cityBounds: /* Init */0,
    showFormError: false
  };
  var match$2 = React.useReducer(FareEstimateResultStateManager$FrontendWebsite.reducer, initialState);
  var dispatch = match$2[1];
  var state = match$2[0];
  var recaptcha_execute = ReactGoogleRecaptchaV3.useGoogleReCaptcha();
  var userEvents = EventContext$FrontendWebsite.useEventContext(undefined);
  var getGeoRegionInfo = function (city) {
    return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._3(RepoInstance.getGeoregionBounds, FareEstimateResultHelper$FrontendWebsite.getCityId(city, country), country, language);
                }), (function (data) {
                  return Curry._1(dispatch, {
                              TAG: /* SetCityBounds */3,
                              _0: data
                            });
                }), (function (error) {
                  console.log("error", error);
                  
                }));
  };
  var getFareEstimateResult = function (form_data) {
    var web_tracking_id = Config$FrontendWebsite.Env.getWebTrackingId(undefined);
    return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._5(RepoInstance.getFareEstimateResult, form_data, true, country, language, web_tracking_id);
                }), (function (data) {
                  Curry._1(dispatch, {
                        TAG: /* SetFareEstimateResult */1,
                        _0: FareEstimateResultVMMapper$FrontendWebsite.getVM(data)
                      });
                  Curry._1(dispatch, {
                        TAG: /* SetFormError */4,
                        _0: false
                      });
                  Curry._1(dispatch, {
                        TAG: /* SetEditForm */2,
                        _0: false
                      });
                  var urlParams = FareEstimateResultHelper$FrontendWebsite.getFareEstimateResultParams({
                        name: form_data.name,
                        mobile: form_data.mobile,
                        city: String(FareEstimateResultHelper$FrontendWebsite.getCityId(form_data.city, country)),
                        requirement: form_data.requirement,
                        pickup_address: form_data.pickup_address,
                        drop_address: form_data.drop_address,
                        fare_estimate_token: form_data.fare_estimate_token,
                        consent_for_callback: form_data.consent_for_callback
                      }, undefined, undefined, country, undefined);
                  return RescriptReactRouter.replace("/fare_estimate_mob?" + Belt_Option.getWithDefault(QueryParamsConstructor$Catalyst.create(urlParams), ""));
                }), (function (_error) {
                  return Curry._1(dispatch, {
                              TAG: /* SetFormError */4,
                              _0: true
                            });
                }));
  };
  var toggleFormHandler = function (param) {
    return Curry._1(dispatch, {
                TAG: /* SetEditForm */2,
                _0: !state.showEditForm
              });
  };
  React.useEffect((function () {
          var data = state.fareEstimateFormData;
          if (data !== undefined) {
            getFareEstimateResult(data);
          }
          
        }), []);
  React.useEffect((function () {
          if (city !== undefined) {
            getGeoRegionInfo(city);
          }
          
        }), [city]);
  var updateFareEstimateForm = function (form_data) {
    var estimate_form_geo_region = String(FareEstimateResultHelper$FrontendWebsite.getCityId(form_data.city.toLocaleLowerCase(), country));
    var estimate_form_pickup_address = form_data.pickup_address;
    var estimate_form_drop_address = form_data.drop_address;
    var estimate_form_name = form_data.name;
    var estimate_form_mobile = form_data.mobile;
    var estimate_form_requirement = FareEstimateResultRequirement$FrontendWebsite.toString(form_data.requirement);
    var estimate_form = {
      geo_region: estimate_form_geo_region,
      pickup_address: estimate_form_pickup_address,
      drop_address: estimate_form_drop_address,
      name: estimate_form_name,
      mobile: estimate_form_mobile,
      requirement: estimate_form_requirement
    };
    var recaptcha_val = recaptcha_execute.executeRecaptcha("homepage");
    if (recaptcha_val !== undefined) {
      Caml_option.valFromOption(recaptcha_val).then(function (token) {
              CommonHelper$FrontendWebsite.publishReCaptchaGaEvents(userEvents.category, true, country, language);
              return Promise.resolve(ApiUtils$Catalyst.handleAPIRequest((function (param) {
                                return Curry._4(RepoInstance.getTripEstimate, estimate_form, token, country, language);
                              }), (function (data) {
                                var updated_form_data_name = form_data.name;
                                var updated_form_data_mobile = form_data.mobile;
                                var updated_form_data_city = form_data.city;
                                var updated_form_data_requirement = form_data.requirement;
                                var updated_form_data_pickup_address = form_data.pickup_address;
                                var updated_form_data_drop_address = form_data.drop_address;
                                var updated_form_data_fare_estimate_token = data.fare_estimate_token;
                                var updated_form_data_consent_for_callback = form_data.consent_for_callback;
                                var updated_form_data = {
                                  name: updated_form_data_name,
                                  mobile: updated_form_data_mobile,
                                  city: updated_form_data_city,
                                  requirement: updated_form_data_requirement,
                                  pickup_address: updated_form_data_pickup_address,
                                  drop_address: updated_form_data_drop_address,
                                  fare_estimate_token: updated_form_data_fare_estimate_token,
                                  consent_for_callback: updated_form_data_consent_for_callback
                                };
                                Curry._1(dispatch, {
                                      TAG: /* SetFareEstimateFormData */0,
                                      _0: updated_form_data
                                    });
                                return getFareEstimateResult(updated_form_data);
                              }), (function (error) {
                                return NetworkUtils$FrontendWebsite.captureException("FareEstimateResult - FareEstimateHash API Failed - " + ErrorUtils$Catalyst.resolveError(error));
                              })));
            }).catch(function (error) {
            NetworkUtils$FrontendWebsite.captureException("FareEstimateResult - ReCaptcha Failed - " + error.toString());
            return Promise.resolve(undefined);
          });
      return ;
    } else {
      CommonHelper$FrontendWebsite.publishReCaptchaGaEvents(userEvents.category, false, country, language);
      return NetworkUtils$FrontendWebsite.captureException("FareEstimateResult - ReCaptcha Init Failed");
    }
  };
  var match$3 = state.fareEstimateFormData;
  var match$4 = state.fareEstimateResult;
  var tmp;
  var exit = 0;
  if (match$3 !== undefined && typeof match$4 !== "number") {
    switch (match$4.TAG | 0) {
      case /* Loading */0 :
          tmp = React.createElement(CircularLoader$FrontendWebsite.make, {});
          break;
      case /* Live */1 :
          var page_data = match$4._0;
          var match$5 = state.showEditForm;
          var match$6 = state.cityBounds;
          var tmp$1;
          tmp$1 = match$5 && !(typeof match$6 === "number" || match$6.TAG !== /* Live */1) ? React.createElement(FareEstimateResultMobileFormCmp$FrontendWebsite.make, {
                  bounds: match$6._0,
                  form_data: match$3,
                  form_handler: toggleFormHandler,
                  update_fare_estimate_result: updateFareEstimateForm,
                  show_error: state.showFormError,
                  set_city: (function (city) {
                      return Curry._1(setCity, (function (param) {
                                    return city;
                                  }));
                    }),
                  edit_section: editSection
                }) : null;
          tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement(FareEstimateResultMobileHeaderCmp$FrontendWebsite.make, {
                    edit_form_handler: toggleFormHandler,
                    vehicle_Section: vehicleSection,
                    edit_section: editSection
                  }), React.createElement(FareEstimateResultMobileVehiclesListCmp$FrontendWebsite.make, {
                    vehicles: page_data.vehicles,
                    vehicleSection: vehicleSection
                  }), Belt_List.some(page_data.vehicles, (function (value) {
                      return value.discount !== undefined;
                    })) ? React.createElement(OfferValidCmp$FrontendWebsite.make, {
                      title: vehicleSection.offerMessage
                    }) : null, React.createElement(FareEstimateResultMobileFooterCmp$FrontendWebsite.make, {
                    footer: cmsVm.footer
                  }));
          break;
      case /* Error */2 :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(FareEstimateResultRedirectCmp$FrontendWebsite.make, {});
  }
  return React.createElement("div", {
              className: "fare-estimate-result-mobile-container"
            }, tmp);
}

var make = FareEstimateResultMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
