// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";

function ParagraphOrderedViewCmp(Props) {
  var paragraph = Props.paragraph;
  var childrenOpt = Props.children;
  var index = Props.index;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: paragraph.is_bold ? "paragraph-text paragraph-bold paragraph-numbered" : "paragraph-text paragraph-numbered"
                }, React.createElement("div", undefined, index + "."), React.createElement("div", undefined, paragraph.show_html === /* Text */0 ? paragraph.text : React.createElement("div", {
                            dangerouslySetInnerHTML: {
                              __html: paragraph.text
                            }
                          }))), children);
}

var make = ParagraphOrderedViewCmp;

export {
  make ,
  
}
/* react Not a pure module */
