// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Row$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Row.bs.js";
import * as Switch$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/switch/Switch.bs.js";

function CookieCategoryCmp(Props) {
  var title = Props.title;
  var description = Props.description;
  var disabledOpt = Props.disabled;
  var checked = Props.checked;
  var on_change = Props.on_change;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(Row$Catalyst.make, {
              children: null,
              className: "cookie-category"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "cookie-category__tile"
                    }, title), React.createElement("div", {
                      className: "cookie-category__description"
                    }, description)), React.createElement(Switch$Catalyst.make, {
                  checked: checked,
                  on_change: on_change,
                  disabled: disabled,
                  switch_color: /* Primary */1
                }));
}

var make = CookieCategoryCmp;

export {
  make ,
  
}
/* react Not a pure module */
