// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as CircularLoader$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as EventContext$FrontendWebsite from "../../../../utils/context/events-manager/EventContext.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as VehicleCmsVMMapper$FrontendWebsite from "../../view-model/VehicleCmsVMMapper.bs.js";
import * as VehicleSeoContentCmp$FrontendWebsite from "../../../website/view/components/VehicleSeoContentCmp.bs.js";
import * as VehicleInfoDesktopCmp$FrontendWebsite from "./VehicleInfoDesktopCmp.bs.js";
import * as DownloadAppsDesktopCmp$FrontendWebsite from "../../../website/view/components/desktop/DownloadAppsDesktopCmp.bs.js";
import * as OtherVehiclesDesktopCmp$FrontendWebsite from "./OtherVehiclesDesktopCmp.bs.js";
import * as VehicleOffersDesktopCmp$FrontendWebsite from "./VehicleOffersDesktopCmp.bs.js";
import * as EstimateFormWrapperDesktopCmp$FrontendWebsite from "../../../website/view/components/desktop/EstimateFormWrapperDesktopCmp.bs.js";
import * as VehicleServiceableCitiesDesktopCmp$FrontendWebsite from "./VehicleServiceableCitiesDesktopCmp.bs.js";

function VehicleDesktopCmp(Props) {
  var city = Props.city;
  var vehicleCMSData = Props.vehicleCMSData;
  var userEvents = EventContext$FrontendWebsite.useEventContext(undefined);
  React.useEffect((function () {
          Curry._1(userEvents.pageLoadHandler, true);
          
        }), []);
  if (typeof vehicleCMSData === "number") {
    return React.createElement(CircularLoader$Catalyst.make, {});
  }
  switch (vehicleCMSData.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(CircularLoader$Catalyst.make, {});
    case /* Live */1 :
        var vehicleCmsVM = VehicleCmsVMMapper$FrontendWebsite.cmsVM(vehicleCMSData._0);
        return React.createElement("div", {
                    className: "desktop-vehicle-page-container-uae"
                  }, React.createElement(VehicleInfoDesktopCmp$FrontendWebsite.make, {
                        vehicleData: vehicleCmsVM.vehicleData,
                        cityData: vehicleCmsVM.cityData,
                        vehicleServiceData: vehicleCmsVM.vehicleServiceData
                      }), React.createElement(EstimateFormWrapperDesktopCmp$FrontendWebsite.make, {
                        container_class_name: "vehicle-desktop-estimate-container",
                        city: city
                      }), React.createElement(DownloadAppsDesktopCmp$FrontendWebsite.make, {
                        container_class_name: "vehicle-download-apps-container",
                        appDownloadData: vehicleCmsVM.appDownload
                      }), React.createElement(OtherVehiclesDesktopCmp$FrontendWebsite.make, {
                        otherVehiclesData: vehicleCmsVM.otherVehiclesData
                      }), vehicleCmsVM.vehicleServiceData.vehicleContent.length !== 0 ? React.createElement(VehicleSeoContentCmp$FrontendWebsite.make, {
                          seoContent: vehicleCmsVM.vehicleServiceData.vehicleContent,
                          title: vehicleCmsVM.vehicleServiceData.title
                        }) : null, React.createElement(VehicleOffersDesktopCmp$FrontendWebsite.make, {
                        offersData: vehicleCmsVM.porterOffers
                      }), React.createElement(VehicleServiceableCitiesDesktopCmp$FrontendWebsite.make, {
                        serviceableCitiesSectionData: vehicleCmsVM.serviceableCities
                      }));
    case /* Error */2 :
        return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                    error: vehicleCMSData._0
                  });
    
  }
}

var make = VehicleDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
