// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function img_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var path = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "path"), null));
  if (path.TAG === /* Ok */0) {
    var alt_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "alt_text"), null));
    if (alt_text.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                path: path._0,
                alt_text: alt_text._0
              }
            };
    }
    var e = alt_text._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".alt_text" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = path._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".path" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function snippet_details_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var heading_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "heading_text"), null));
  if (heading_text.TAG === /* Ok */0) {
    var content = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
    if (content.TAG === /* Ok */0) {
      var content_2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content_2"), null));
      if (content_2.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  heading_text: heading_text._0,
                  content: content._0,
                  content_2: content_2._0
                }
              };
      }
      var e = content_2._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".content_2" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = content._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".content" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = heading_text._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".heading_text" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function cityCoupon_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var banner_img = img_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner_img"), null));
  if (banner_img.TAG === /* Ok */0) {
    var coupon_info = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "coupon_info"), null));
    if (coupon_info.TAG === /* Ok */0) {
      var coupon_code = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "coupon_code"), null));
      if (coupon_code.TAG === /* Ok */0) {
        var coupon_validity_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "coupon_validity_text"), null));
        if (coupon_validity_text.TAG === /* Ok */0) {
          var details_link = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "details_link"), null));
          if (details_link.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      banner_img: banner_img._0,
                      coupon_info: coupon_info._0,
                      coupon_code: coupon_code._0,
                      coupon_validity_text: coupon_validity_text._0,
                      details_link: details_link._0
                    }
                  };
          }
          var e = details_link._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".details_link" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = coupon_validity_text._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".coupon_validity_text" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = coupon_code._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".coupon_code" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = coupon_info._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".coupon_info" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = banner_img._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".banner_img" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function vehicleOne_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var display_name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "display_name"), null));
  if (display_name.TAG === /* Ok */0) {
    var vehicle_type = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicle_type"), null));
    if (vehicle_type.TAG === /* Ok */0) {
      var capacity_display = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "capacity_display"), null));
      if (capacity_display.TAG === /* Ok */0) {
        var base_fare_display = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "base_fare_display"), null));
        if (base_fare_display.TAG === /* Ok */0) {
          var size_display = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "size_display"), null));
          if (size_display.TAG === /* Ok */0) {
            var image_url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_url"), null));
            if (image_url.TAG === /* Ok */0) {
              var image_alt_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_alt_text"), null));
              if (image_alt_text.TAG === /* Ok */0) {
                var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
                if (description.TAG === /* Ok */0) {
                  var description_2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description_2"), null));
                  if (description_2.TAG === /* Ok */0) {
                    var description_3 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description_3"), null));
                    if (description_3.TAG === /* Ok */0) {
                      var snippet_details = snippet_details_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "snippet_details"), null));
                      if (snippet_details.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  display_name: display_name._0,
                                  vehicle_type: vehicle_type._0,
                                  capacity_display: capacity_display._0,
                                  base_fare_display: base_fare_display._0,
                                  size_display: size_display._0,
                                  image_url: image_url._0,
                                  image_alt_text: image_alt_text._0,
                                  description: description._0,
                                  description_2: description_2._0,
                                  description_3: description_3._0,
                                  snippet_details: snippet_details._0
                                }
                              };
                      }
                      var e = snippet_details._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".snippet_details" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = description_3._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".description_3" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = description_2._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".description_2" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = description._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".description" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = image_alt_text._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".image_alt_text" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = image_url._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".image_url" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = size_display._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".size_display" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = base_fare_display._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".base_fare_display" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = capacity_display._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".capacity_display" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = vehicle_type._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".vehicle_type" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = display_name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".display_name" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var selected_vehicle = vehicleOne_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "selected_vehicle"), null));
  if (selected_vehicle.TAG === /* Ok */0) {
    var remaining_vehicles = Decco.listFromJson(vehicleOne_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "remaining_vehicles"), null));
    if (remaining_vehicles.TAG === /* Ok */0) {
      var offers = Decco.optionFromJson((function (param) {
              return Decco.listFromJson(cityCoupon_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "offers"), null));
      if (offers.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  selected_vehicle: selected_vehicle._0,
                  remaining_vehicles: remaining_vehicles._0,
                  offers: offers._0
                }
              };
      }
      var e = offers._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".offers" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = remaining_vehicles._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".remaining_vehicles" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = selected_vehicle._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".selected_vehicle" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

export {
  img_decode ,
  snippet_details_decode ,
  cityCoupon_decode ,
  vehicleOne_decode ,
  t_decode ,
  
}
/* No side effect */
