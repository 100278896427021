// Generated by ReScript, PLEASE EDIT WITH CARE


var androidLinkUrl = "/android-app";

var iosLinkUrl = "/iphone-app";

var iosWebpageLinkUrl = "https://apps.apple.com/in/app/porter-hire-a-mini-truck/id1109398410";

export {
  androidLinkUrl ,
  iosLinkUrl ,
  iosWebpageLinkUrl ,
  
}
/* No side effect */
