// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as DriverPartnerRepo$FrontendWebsite from "./DriverPartnerRepo.bs.js";
import * as DummyDriverPartnerRepo$FrontendWebsite from "./DummyDriverPartnerRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyDriverPartnerRepo$FrontendWebsite;
  } else {
    return DriverPartnerRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
