// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../app/Config.bs.js";
import * as LanguageConfig$FrontendWebsite from "../languang_and_content/LanguageConfig.bs.js";

function getAboutUsRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "about-us-uae";
        break;
    case /* BD */1 :
        pathUrl = "about-us-bd";
        break;
    case /* TR */2 :
        pathUrl = "about-us-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=overview.banner&populate[1]=commitment.banner&populate[2]=porterStats.yearFounded&populate[3]=porterStats.customers&populate[4]=porterStats.teamCount&populate[5]=joinPorter&populate[6]=banner&" + locale;
}

function getVehicleInventoryRequestUrl(country, language, vehicleSlug, city) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "vehicles-uae";
        break;
    case /* BD */1 :
        pathUrl = "vehicles-bd";
        break;
    case /* TR */2 :
        pathUrl = "vehicles-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  var queryString = "?filters[$and][0][slug][$eq]=" + vehicleSlug + "&filters[$and][1][city][cityName][$eq]=" + city + "&populate[0]=vehicleBanner&populate[1]=city&populate[2]=vehicleInfo.serviceDetailsRow&populate[3]=otherVehicles.vehicles.city.cityIcon&populate[4]=otherVehicles.vehicles.vehicleBanner&populate[5]=serviceableCities.cities.banner&populate[6]=porterOffers.coupons.bannerImg&populate[7]=serviceableCities.cities.cityIcon&populate[8]=city.cityIcon&populate[9]=appDownload.googlePlayStoreBanner&populate[10]=appDownload.appStoreBanner&populate[11]=vehicleInfo.vehicleContent&";
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + queryString + locale;
}

function getHomeRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "home-uae";
        break;
    case /* BD */1 :
        pathUrl = "home-bd";
        break;
    case /* TR */2 :
        pathUrl = "home-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=heroSection.mobileBanners.banner&populate[1]=heroSection.desktopBanners.banner&populate[2]=tripEstimateForm.cityField.label&populate[3]=tripEstimateForm.citiesDropdown&populate[4]=tripEstimateForm.pickAndDropFields&populate[5]=tripEstimateForm.mobileField&populate[6]=tripEstimateForm.nameField&populate[7]=tripEstimateForm.requirementField&populate[8]=tripEstimateForm.requirementsDropdown&populate[9]=features.featureItems&populate[10]=features.orderTrackingSection&populate[11]=features.appDownload.googlePlayStoreBanner&populate[12]=features.appDownload.appStoreBanner&populate[13]=features.phoneCarousel.phoneCarouselBanners&populate[14]=features.phoneCarousel.phoneSkin&populate[15]=offerings.usps.uspImage&populate[16]=porterVehicles.citiesDropdown&populate[17]=porterVehicles.vehicles.homePageVehicleCard.vehicleDetailsRow&populate[18]=porterVehicles.vehicles.vehicleBanner&populate[19]=porterVehicles.vehicles.city&populate[20]=porterVehicles.vehicles.vehicleIcon&populate[21]=serviceableCities.cities.cityIcon&populate[22]=faqs.questions&populate[23]=moreFaqs.icon&populate[24]=growthStats.growthStatItems&" + locale;
}

function getServicesRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "service-uae";
        break;
    case /* BD */1 :
    case /* TR */2 :
        pathUrl = "";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=banner&populate[1]=keywordsList.keywords&populate[2]=appDownload.googlePlayStoreBanner&populate[3]=appDownload.appStoreBanner&" + locale;
}

function getKeywordsRequestUrl(country, language, keyword) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "keywords-uae";
        break;
    case /* BD */1 :
    case /* TR */2 :
        pathUrl = "";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  var queryString = "?filters[slug][$eq]=" + keyword + "&populate[0]=banner&populate[1]=serviceInfo.serviceDetailsRow&populate[2]=serviceFeatures.features.featureIcon&populate[3]=keywordsList.keywords&populate[4]=serviceBookingGuide.bookingSteps&populate[5]=availableCategories.categories.vehicleIcon&populate[6]=faqs.questions&populate[7]=appDownload.googlePlayStoreBanner&populate[8]=appDownload.appStoreBanner&";
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + queryString + locale;
}

function getTermsOfServiceDubaiBranchRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "terms-of-service-uae-dubai-branch";
        break;
    case /* BD */1 :
    case /* TR */2 :
        pathUrl = "";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getTermsOfServiceRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "terms-of-service-uae";
        break;
    case /* BD */1 :
        pathUrl = "terms-and-conditions-bd";
        break;
    case /* TR */2 :
        pathUrl = "terms-and-conditions-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getPrivacyPolicyRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "privacy-policy-uae";
        break;
    case /* BD */1 :
        pathUrl = "privacy-policy-bd";
        break;
    case /* TR */2 :
        pathUrl = "privacy-policy-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getRefundPolicyRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* BD */1 :
        pathUrl = "refund-policy-bd";
        break;
    case /* UAE */0 :
    case /* TR */2 :
        pathUrl = "";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getCookiePolicyRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        pathUrl = "";
        break;
    case /* TR */2 :
        pathUrl = "cookie-policy-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getPrivacyPolicyV1RequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "privacy-policy-v1-uae";
        break;
    case /* BD */1 :
        pathUrl = "privacy-policy-v1-bd";
        break;
    case /* TR */2 :
        pathUrl = "privacy-policy-v1-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=policyItems.description&" + locale;
}

function getSpotFAQRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "spot-fa-qs-uae";
        break;
    case /* BD */1 :
        pathUrl = "spot-fa-qs-bd";
        break;
    case /* TR */2 :
        pathUrl = "spot-fa-qs-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=faqs.questions.question&" + locale;
}

function getSupportRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "support-uae";
        break;
    case /* BD */1 :
        pathUrl = "support-bd";
        break;
    case /* TR */2 :
        pathUrl = "support-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=supportItems.icon&populate[1]=headOffice.officeItems.address&populate[2]=regionalOffices.officeItems.address&" + locale;
}

function getDriverPartnerRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "driver-partner-page";
        break;
    case /* BD */1 :
        pathUrl = "driver-partner-bd";
        break;
    case /* TR */2 :
        pathUrl = "driver-partner-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=heroSection.appDownload.googlePlayStoreBanner&populate[1]=heroSection.image&populate[2]=porterAdvantage.points.description&populate[3]=porterAdvantage.image&populate[4]=lifeEasySection.image&populate[5]=ownVehicleSection.image&populate[6]=lifeEasySection.points.description&populate[7]=ownVehicleSection.points.description&populate[8]=faqs.questions&" + locale;
}

function getFareEstimateRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "fare-estimate-uae";
        break;
    case /* BD */1 :
        pathUrl = "fare-estimate-bd";
        break;
    case /* TR */2 :
        pathUrl = "fare-estimate-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=editSection.closeIcon&populate[1]=vehicleSection.title&populate[2]=appDownloadSection.phoneCarousel.phoneSkin&populate[3]=appDownloadSection.phoneCarousel.phoneCarouselBanners&populate[4]=qrSection.qrImage&populate[5]=footer.title&populate[6]=appDownloadSection.appStoreImage&populate[7]=appDownloadSection.playStoreImage&" + locale;
}

function getCityRequestUrl(country, language, city) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "cities-uae";
        break;
    case /* BD */1 :
        pathUrl = "cities-bd";
        break;
    case /* TR */2 :
        pathUrl = "cities-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  var queryString = "?filters[cityName][$eq]=" + city + "&populate[0]=banner&populate[1]=availableVehicles.vehicles.homePageVehicleCard.vehicleDetailsRow&populate[2]=availableVehicles.vehicles.city.cityName&populate[3]=availableVehicles.vehicles.vehicleBanner&populate[4]=availableVehicles.vehicles.vehicleIcon&populate[5]=porterOffers.coupons.bannerImg&populate[6]=tripEstimateForm.citiesDropdown&populate[7]=tripEstimateForm.pickAndDropFields&populate[8]=tripEstimateForm.cityField&populate[9]=tripEstimateForm.requirementField&populate[10]=tripEstimateForm.requirementsDropdown&populate[11]=tripEstimateForm.mobileField&populate[12]=tripEstimateForm.nameField&populate[13]=appDownload.googlePlayStoreBanner&populate[14]=appDownload.appStoreBanner&populate[15]=serviceableCities.cities.cityIcon&";
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + queryString + locale;
}

function getHeaderFooterRequestUrl(country, language) {
  var pathUrl;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        pathUrl = "header-and-footer-uae";
        break;
    case /* BD */1 :
        pathUrl = "header-and-footer-bd";
        break;
    case /* TR */2 :
        pathUrl = "header-and-footer-tr";
        break;
    
  }
  var locale = "locale=" + LanguageConfig$FrontendWebsite.Locale.getLocaleFromLanguage(language);
  return Config$FrontendWebsite.Env.cms_url + "/api/" + pathUrl + "?populate[0]=footer.iconLinkLabel&populate[1]=footer.logo&populate[2]=footer.socialMediaIcons&populate[3]=footer.cityLinkLabel&populate[4]=footer.cityLinksTitile&populate[5]=footer.companyLinksLabel&populate[6]=footer.companyLinksLabel&populate[7]=footer.supportLinksLabel&populate[8]=footer.serviceLinksLabel&populate[9]=footer.appStoreIcon&populate[10]=footer.playStoreIcon&populate[11]=companyInfo.pinIcon&populate[12]=logoSection.languageSelector&populate[13]=logoSection.partnerSection.icon&populate[14]=logoSection.title&populate[15]=logoSection.logo&populate[16]=navSection.secondaryNavLinks&populate[17]=navSection.primaryNavLinks&populate[18]=companyInfo.companyName&populate[19]=companyInfo.tncLinkLabel&populate[20]=companyInfo.refundLinkLabel.url&populate[21]=logoSection.languageSelector.icon&" + locale;
}

export {
  getAboutUsRequestUrl ,
  getVehicleInventoryRequestUrl ,
  getHomeRequestUrl ,
  getServicesRequestUrl ,
  getKeywordsRequestUrl ,
  getTermsOfServiceDubaiBranchRequestUrl ,
  getTermsOfServiceRequestUrl ,
  getPrivacyPolicyRequestUrl ,
  getRefundPolicyRequestUrl ,
  getCookiePolicyRequestUrl ,
  getPrivacyPolicyV1RequestUrl ,
  getSpotFAQRequestUrl ,
  getSupportRequestUrl ,
  getDriverPartnerRequestUrl ,
  getFareEstimateRequestUrl ,
  getCityRequestUrl ,
  getHeaderFooterRequestUrl ,
  
}
/* Config-FrontendWebsite Not a pure module */
