// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as CityCMSRepo$FrontendWebsite from "./CityCMSRepo.bs.js";
import * as DummyCityCMSRepo$FrontendWebsite from "./DummyCityCMSRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyCityCMSRepo$FrontendWebsite;
  } else {
    return CityCMSRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
