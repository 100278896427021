// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MediaVMMapper$FrontendWebsite from "../../website/cms/view-model/MediaVMMapper.bs.js";

function getEditSection(data) {
  return {
          pickupLabel: data.pickupLabel,
          dropLabel: data.dropLabel,
          editButtonLabel: data.editButtonLabel,
          submitButtonLabel: data.submitButtonLabel,
          selectCityLabel: data.selectCityLabel,
          title: data.title,
          selectCityError: data.selectCityError,
          pickUpError: data.pickUpError,
          dropError: data.dropError,
          closeIcon: MediaVMMapper$FrontendWebsite.getBanner(data.closeIcon.data)
        };
}

function decodVehicleSection(data) {
  return {
          title: data.title,
          subtitle: data.subtitle,
          offLabel: data.offLabel,
          uptoLabel: data.uptoLabel,
          offerMessage: data.offerMessage,
          capacityLabel: data.capacityLabel,
          sizeLabel: data.sizeLabel
        };
}

function getphoneCarousel(data) {
  return {
          phoneSkin: MediaVMMapper$FrontendWebsite.getBanner(data.phoneSkin.data),
          phoneCarouselBanners: MediaVMMapper$FrontendWebsite.getBanners(data.phoneCarouselBanners)
        };
}

function getAppDownloadSection(data) {
  return {
          title: data.title,
          pointOne: data.pointOne,
          point2: data.point2,
          point3: data.point3,
          phoneCarousel: getphoneCarousel(data.phoneCarousel),
          appStoreImage: MediaVMMapper$FrontendWebsite.getBanner(data.appStoreImage.data),
          playStoreImage: MediaVMMapper$FrontendWebsite.getBanner(data.playStoreImage.data)
        };
}

function getQrSection(data) {
  return {
          title: data.title,
          desription: data.desription,
          callToActionPrefix: data.callToActionPrefix,
          callToActionLinkText: data.callToActionLinkText,
          smsLinkDescription: data.smsLinkDescription,
          qrImage: MediaVMMapper$FrontendWebsite.getBanner(data.qrImage.data)
        };
}

function getFooter(data) {
  return {
          title: data.title,
          subtitle: data.subtitle,
          buttonLabel: data.buttonLabel
        };
}

function getVM(data) {
  return {
          editSection: getEditSection(data.editSection),
          vehicleSection: decodVehicleSection(data.vehicleSection),
          appDownloadSection: getAppDownloadSection(data.appDownloadSection),
          qrSection: getQrSection(data.qrSection),
          footer: getFooter(data.footer)
        };
}

export {
  getEditSection ,
  decodVehicleSection ,
  getphoneCarousel ,
  getAppDownloadSection ,
  getQrSection ,
  getFooter ,
  getVM ,
  
}
/* No side effect */
