// Generated by ReScript, PLEASE EDIT WITH CARE


function getContent(data) {
  return data._0;
}

function getContentList(data) {
  if (!data) {
    return /* [] */0;
  }
  var linkText = data.hd;
  if (linkText.TAG !== /* LinkTextWithAction */7) {
    return {
            hd: linkText._0,
            tl: getContentList(data.tl)
          };
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "ContentHelper.res",
          20,
          4
        ],
        Error: new Error()
      };
}

export {
  getContent ,
  getContentList ,
  
}
/* No side effect */
