// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as CampaignsRepo$FrontendWebsite from "./CampaignsRepo.bs.js";
import * as DummyCampaignsRepo$FrontendWebsite from "./DummyCampaignsRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return {
            fetchOffers: DummyCampaignsRepo$FrontendWebsite.fetchOffers
          };
  } else {
    return CampaignsRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
