// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function defaultValue_pageLoadHandler(param) {
  
}

var defaultValue = {
  scrollHeight: 0,
  category: "",
  pageLoadHandler: defaultValue_pageLoadHandler
};

var context = React.createContext(defaultValue);

function useEventContext(param) {
  return React.useContext(context);
}

export {
  defaultValue ,
  context ,
  useEventContext ,
  
}
/* context Not a pure module */
