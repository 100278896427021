// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as SpotFAQsEntityMapper$FrontendWebsite from "../entity-mapper/SpotFAQsEntityMapper.bs.js";

var execute = SpotFAQsEntityMapper$FrontendWebsite.execute;

var SpotFAQsCMSApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var SpotFAQsCMSApiResponseConverter = partial_arg(SpotFAQsCMSApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  SpotFAQsCMSApiConfig ,
  SpotFAQsCMSApiResponseConverter ,
  
}
/* SpotFAQsCMSApiResponseConverter Not a pure module */
