// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_array from "../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UrlUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as RescriptReactRouter from "../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as GAConfig$FrontendWebsite from "../library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../library/ga/GAEvents.bs.js";
import * as OptionTypeUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/OptionTypeUtils.bs.js";
import * as UtmUtils$FrontendWebsite from "../UtmUtils.bs.js";
import * as EventsHelper$FrontendWebsite from "../context/events-manager/EventsHelper.bs.js";
import * as QueryParamsConstructor$Catalyst from "../../../node_modules/@porterin/catalyst/src/helper-modules/query-params/QueryParamsConstructor.bs.js";

function getSelectComponentItems(typeList, conv) {
  return List.map((function (variant) {
                var typeString = Curry._1(conv, variant);
                return {
                        key: typeString.toLowerCase(),
                        value: typeString,
                        text: typeString.toUpperCase()
                      };
              }), typeList);
}

function getDefaultCityBound(param) {
  return {
          ne: {
            lat: 35.513327,
            lng: 97.39535869999999
          },
          sw: {
            lat: 6.4626999,
            lng: 68.1097
          }
        };
}

function hasAtleastOneAlphabet(word) {
  return /\w*[a-zA-Z]\w*/.test(word);
}

function validateNumber(mobile, country) {
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = /^(?:\+971|00971|0)?(?:50|51|52|54|55|56|58)\d{7}$/.test("+971" + mobile);
        break;
    case /* BD */1 :
        tmp = /(^(\+880|00880|0)?(1)[3456789](\d){8})$/.test("+880" + mobile);
        break;
    case /* TR */2 :
        tmp = /(^(\+90|0)?(5)\d{9})$/.test("+90" + mobile);
        break;
    
  }
  if (tmp) {
    return {
            TAG: /* Ok */0,
            _0: mobile
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Invalid Number"
          };
  }
}

function getUtmString(param) {
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  var urlParams = UrlUtils$Catalyst.getURLParams(url.search);
  var utm_source = Belt_Option.mapWithDefault(Js_dict.get(urlParams, "utm_source"), "", (function (x) {
          return x;
        }));
  var utm_campaign = Belt_Option.mapWithDefault(Js_dict.get(urlParams, "utm_campaign"), "", (function (x) {
          return x;
        }));
  return [
          utm_source,
          utm_campaign
        ];
}

function encodeToUrlTripEstimateFormInfo(form_info) {
  return $$Array.fold_left((function (dict, param) {
                dict[param[0]] = window.encodeURI(param[1]);
                return dict;
              }), {}, [
              [
                "geo_region_id",
                form_info.geo_region
              ],
              [
                "from_address_landmark",
                form_info.pickup_address.landmark
              ],
              [
                "to_address_landmark",
                form_info.drop_address.landmark
              ],
              [
                "from_address_lat",
                form_info.pickup_address.location.lat.toString()
              ],
              [
                "from_address_long",
                form_info.pickup_address.location.lng.toString()
              ],
              [
                "to_address_lat",
                form_info.drop_address.location.lat.toString()
              ],
              [
                "to_address_long",
                form_info.drop_address.location.lng.toString()
              ],
              [
                "customer_name",
                form_info.name
              ],
              [
                "customer_mobile",
                form_info.mobile
              ],
              [
                "user_type",
                "/"
              ],
              [
                "brand",
                "porter"
              ],
              [
                "frequency",
                form_info.requirement
              ]
            ]);
}

function scrollToElement(ele) {
  return ele.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
}

function useCustomGeoCodingApiV2PnM(param) {
  return true;
}

function useCustomGeoCodingApiV2TripEstimate(param) {
  return true;
}

function publishReCaptchaGaEvents(categoryOpt, is_initialized, country, language) {
  var category = categoryOpt !== undefined ? categoryOpt : "Home";
  var GAConfigActions;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        GAConfigActions = GAConfig$FrontendWebsite.UAE.Actions;
        break;
    case /* BD */1 :
        GAConfigActions = GAConfig$FrontendWebsite.BD.Actions;
        break;
    case /* TR */2 :
        GAConfigActions = GAConfig$FrontendWebsite.TR.Actions;
        break;
    
  }
  return GAEvents$FrontendWebsite.publishEventParameter(is_initialized ? GAConfigActions.reCaptchaExecuteSucceess : GAConfigActions.reCaptchaExecuteFail, {
              event_category: category,
              nonInteraction: true,
              language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
            });
}

function resetAddress(param) {
  return {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        };
}

function getRequirementId(requirement) {
  switch (requirement) {
    case "business" :
        return "4";
    case "house shifting" :
        return "6";
    case "personal" :
        return "5";
    default:
      return "";
  }
}

function getUTmParamString(param) {
  var default_param_values = [
    "website_fare_estimate_DL",
    "fare_estimate_organic",
    "fare_estimate_organic",
    "fare_estimate_organic",
    "ow"
  ];
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  var utm_obj = UtmUtils$FrontendWebsite.getAllUtmParams(url);
  if ($$Array.exists((function (param) {
            return OptionTypeUtils$Catalyst.getEmptyStringFrmOptionalType(Js_dict.get(utm_obj, param)).length !== 0;
          }), UtmUtils$FrontendWebsite.utm_params_keys)) {
    
  } else {
    $$Array.iteri((function (index, param) {
            utm_obj[param] = Caml_array.get(default_param_values, index);
            
          }), UtmUtils$FrontendWebsite.utm_params_keys);
  }
  return Belt_Option.getWithDefault(QueryParamsConstructor$Catalyst.create(utm_obj), "");
}

export {
  getSelectComponentItems ,
  getDefaultCityBound ,
  hasAtleastOneAlphabet ,
  validateNumber ,
  getUtmString ,
  encodeToUrlTripEstimateFormInfo ,
  scrollToElement ,
  useCustomGeoCodingApiV2PnM ,
  useCustomGeoCodingApiV2TripEstimate ,
  publishReCaptchaGaEvents ,
  resetAddress ,
  getRequirementId ,
  getUTmParamString ,
  
}
/* RescriptReactRouter Not a pure module */
