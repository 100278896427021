// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Api from "@react-google-maps/api";

function MarkerComponentMod(Props) {
  var lib_nameOpt = Props.lib_name;
  var config = Props.config;
  var children = Props.children;
  var lib_name = lib_nameOpt !== undefined ? lib_nameOpt : /* GoogleMap */0;
  if (lib_name) {
    return null;
  } else {
    return React.createElement(Api.Marker, {
                position: config.position,
                clickable: config.clickable,
                visible: config.visible,
                icon: config.icon,
                ref: config.ref,
                children: children
              });
  }
}

var make = MarkerComponentMod;

export {
  make ,
  
}
/* react Not a pure module */
