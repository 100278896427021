// Generated by ReScript, PLEASE EDIT WITH CARE


var English_track_order_heading = "Track Order";

var English_booking_mobile_number_heading = "Booking mobile Number";

var English_track_order_button_title = "Track Order";

var English_allocated_driver_text = "Driver yet to be assigned";

var English_cancelled_driver_text = "No Driver assigned";

var English_status = {
  open_order_status: "Searching for a driver",
  allocated_order_status: "Searching for a driver",
  accepted_order_status: "Driver is on his way to pickup",
  live_order_status: "Driver is on the way to drop",
  loading_order_status: "Awaiting update…",
  unloading_order_status: "Awaiting update…",
  ended_order_status: "Driver has delivered the goods",
  completed_order_status: "Driver has delivered the goods",
  cancelled_order_status: "Trip is cancelled",
  rescheduled_order_status: "Trip is cancelled"
};

var English_show_order_details_text = "SHOW ORDER DETAILS";

var English_hide_order_details_text = "HIDE ORDER DETAILS";

var English_order_placed_by_text = "Order Placed By";

var English_order_details_text = "Order Details";

var English_order_id_text = "Order ID : ";

var English_contact_support_text = "Contact Support";

var English_experiencing_issues_text = "Still experiencing issues?";

var English = {
  track_order_heading: English_track_order_heading,
  booking_mobile_number_heading: English_booking_mobile_number_heading,
  track_order_button_title: English_track_order_button_title,
  allocated_driver_text: English_allocated_driver_text,
  cancelled_driver_text: English_cancelled_driver_text,
  status: English_status,
  show_order_details_text: English_show_order_details_text,
  hide_order_details_text: English_hide_order_details_text,
  order_placed_by_text: English_order_placed_by_text,
  order_details_text: English_order_details_text,
  order_id_text: English_order_id_text,
  contact_support_text: English_contact_support_text,
  experiencing_issues_text: English_experiencing_issues_text
};

var BD_track_order_heading = "অর্ডার ট্র্যাক করুন";

var BD_booking_mobile_number_heading = "বুকিং মোবাইল নম্বর";

var BD_track_order_button_title = "অর্ডার ট্র্যাক করুন";

var BD_allocated_driver_text = "Driver yet to be assigned";

var BD_cancelled_driver_text = "No Driver assigned";

var BD_status = {
  open_order_status: "ড্রাইভার খোঁজা হচ্ছে",
  allocated_order_status: "ড্রাইভার খোঁজা হচ্ছে",
  accepted_order_status: "ড্রাইভার পিকআপ করতে রওনা দিয়েছেন",
  live_order_status: "ড্রাইভার নামিয়ে দিতে যাচ্ছেন",
  loading_order_status: "আপডেটের অপেক্ষায়...",
  unloading_order_status: "আপডেটের অপেক্ষায়...",
  ended_order_status: "ড্রাইভার পণ্য ডেলিভারি করেছেন",
  completed_order_status: "ড্রাইভার পণ্য ডেলিভারি করেছেন",
  cancelled_order_status: "ট্রিপ বাতিল করা হয়েছে",
  rescheduled_order_status: "ট্রিপ বাতিল করা হয়েছে"
};

var BD_show_order_details_text = "SHOW ORDER DETAILS";

var BD_hide_order_details_text = "HIDE ORDER DETAILS";

var BD_order_placed_by_text = "Order Placed By";

var BD_order_details_text = "Order Details";

var BD_order_id_text = "Order ID : ";

var BD_contact_support_text = "Contact Support";

var BD_experiencing_issues_text = "Still experiencing issues?";

var BD = {
  track_order_heading: BD_track_order_heading,
  booking_mobile_number_heading: BD_booking_mobile_number_heading,
  track_order_button_title: BD_track_order_button_title,
  allocated_driver_text: BD_allocated_driver_text,
  cancelled_driver_text: BD_cancelled_driver_text,
  status: BD_status,
  show_order_details_text: BD_show_order_details_text,
  hide_order_details_text: BD_hide_order_details_text,
  order_placed_by_text: BD_order_placed_by_text,
  order_details_text: BD_order_details_text,
  order_id_text: BD_order_id_text,
  contact_support_text: BD_contact_support_text,
  experiencing_issues_text: BD_experiencing_issues_text
};

export {
  English ,
  BD ,
  
}
/* No side effect */
