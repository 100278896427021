// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EventsConfig$FrontendWebsite from "./EventsConfig.bs.js";
import * as EventsHelper$FrontendWebsite from "./EventsHelper.bs.js";

function getEventScrollHeightAndMaxHeight(user_scrolled_height) {
  if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_200_px) {
    return [
            EventsConfig$FrontendWebsite.sh_100_px,
            EventsConfig$FrontendWebsite.sh_200_px - 1 | 0
          ];
  } else if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_500_px) {
    return [
            EventsConfig$FrontendWebsite.sh_200_px,
            EventsConfig$FrontendWebsite.sh_500_px - 1 | 0
          ];
  } else if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_1000_px) {
    return [
            EventsConfig$FrontendWebsite.sh_500_px,
            EventsConfig$FrontendWebsite.sh_1000_px - 1 | 0
          ];
  } else if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_2000_px) {
    return [
            EventsConfig$FrontendWebsite.sh_1000_px,
            EventsConfig$FrontendWebsite.sh_2000_px - 1 | 0
          ];
  } else if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_5000_px) {
    return [
            EventsConfig$FrontendWebsite.sh_2000_px,
            EventsConfig$FrontendWebsite.sh_5000_px - 1 | 0
          ];
  } else if (user_scrolled_height < EventsConfig$FrontendWebsite.sh_10000_px) {
    return [
            EventsConfig$FrontendWebsite.sh_5000_px,
            EventsConfig$FrontendWebsite.sh_10000_px - 1 | 0
          ];
  } else {
    return [
            EventsConfig$FrontendWebsite.sh_10000_px,
            EventsConfig$FrontendWebsite.sh_20000_px
          ];
  }
}

function setScrollHeightEvents(route, user_sh, action, language) {
  return EventsHelper$FrontendWebsite.userEventHandler(EventsHelper$FrontendWebsite.routeToEventCategory(route), action, "Scrolled Height " + (String(user_sh) + "px"), user_sh, language);
}

export {
  getEventScrollHeightAndMaxHeight ,
  setScrollHeightEvents ,
  
}
/* EventsHelper-FrontendWebsite Not a pure module */
