// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "../../website/cms/api-model/MediaApiModel.bs.js";

function editSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var pickupLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pickupLabel"), null));
  if (pickupLabel.TAG === /* Ok */0) {
    var dropLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dropLabel"), null));
    if (dropLabel.TAG === /* Ok */0) {
      var submitButtonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "submitButtonLabel"), null));
      if (submitButtonLabel.TAG === /* Ok */0) {
        var selectCityLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "selectCityLabel"), null));
        if (selectCityLabel.TAG === /* Ok */0) {
          var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
          if (title.TAG === /* Ok */0) {
            var editButtonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "editButtonLabel"), null));
            if (editButtonLabel.TAG === /* Ok */0) {
              var selectCityError = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "selectCityError"), null));
              if (selectCityError.TAG === /* Ok */0) {
                var pickUpError = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pickUpError"), null));
                if (pickUpError.TAG === /* Ok */0) {
                  var dropError = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dropError"), null));
                  if (dropError.TAG === /* Ok */0) {
                    var closeIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "closeIcon"), null));
                    if (closeIcon.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                pickupLabel: pickupLabel._0,
                                dropLabel: dropLabel._0,
                                submitButtonLabel: submitButtonLabel._0,
                                selectCityLabel: selectCityLabel._0,
                                title: title._0,
                                editButtonLabel: editButtonLabel._0,
                                selectCityError: selectCityError._0,
                                pickUpError: pickUpError._0,
                                dropError: dropError._0,
                                closeIcon: closeIcon._0
                              }
                            };
                    }
                    var e = closeIcon._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".closeIcon" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = dropError._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".dropError" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = pickUpError._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".pickUpError" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = selectCityError._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".selectCityError" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = editButtonLabel._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".editButtonLabel" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = title._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".title" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = selectCityLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".selectCityLabel" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = submitButtonLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".submitButtonLabel" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = dropLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dropLabel" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = pickupLabel._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".pickupLabel" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function vehicleSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var subtitle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subtitle"), null));
    if (subtitle.TAG === /* Ok */0) {
      var offLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "offLabel"), null));
      if (offLabel.TAG === /* Ok */0) {
        var uptoLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uptoLabel"), null));
        if (uptoLabel.TAG === /* Ok */0) {
          var offerMessage = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "offerMessage"), null));
          if (offerMessage.TAG === /* Ok */0) {
            var capacityLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "capacityLabel"), null));
            if (capacityLabel.TAG === /* Ok */0) {
              var sizeLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "sizeLabel"), null));
              if (sizeLabel.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          title: title._0,
                          subtitle: subtitle._0,
                          offLabel: offLabel._0,
                          uptoLabel: uptoLabel._0,
                          offerMessage: offerMessage._0,
                          capacityLabel: capacityLabel._0,
                          sizeLabel: sizeLabel._0
                        }
                      };
              }
              var e = sizeLabel._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".sizeLabel" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = capacityLabel._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".capacityLabel" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = offerMessage._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".offerMessage" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = uptoLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".uptoLabel" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = offLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".offLabel" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = subtitle._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subtitle" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function phoneCarousel_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var phoneSkin = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneSkin"), null));
  if (phoneSkin.TAG === /* Ok */0) {
    var phoneCarouselBanners = MediaApiModel$FrontendWebsite.banners_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneCarouselBanners"), null));
    if (phoneCarouselBanners.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                phoneSkin: phoneSkin._0,
                phoneCarouselBanners: phoneCarouselBanners._0
              }
            };
    }
    var e = phoneCarouselBanners._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".phoneCarouselBanners" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = phoneSkin._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".phoneSkin" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function appDownloadSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var pointOne = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pointOne"), null));
    if (pointOne.TAG === /* Ok */0) {
      var point2 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "point2"), null));
      if (point2.TAG === /* Ok */0) {
        var point3 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "point3"), null));
        if (point3.TAG === /* Ok */0) {
          var phoneCarousel = phoneCarousel_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneCarousel"), null));
          if (phoneCarousel.TAG === /* Ok */0) {
            var appStoreImage = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appStoreImage"), null));
            if (appStoreImage.TAG === /* Ok */0) {
              var playStoreImage = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "playStoreImage"), null));
              if (playStoreImage.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          title: title._0,
                          pointOne: pointOne._0,
                          point2: point2._0,
                          point3: point3._0,
                          phoneCarousel: phoneCarousel._0,
                          appStoreImage: appStoreImage._0,
                          playStoreImage: playStoreImage._0
                        }
                      };
              }
              var e = playStoreImage._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".playStoreImage" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = appStoreImage._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".appStoreImage" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = phoneCarousel._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".phoneCarousel" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = point3._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".point3" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = point2._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".point2" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = pointOne._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".pointOne" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function qrSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var desription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "desription"), null));
    if (desription.TAG === /* Ok */0) {
      var callToActionPrefix = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "callToActionPrefix"), null));
      if (callToActionPrefix.TAG === /* Ok */0) {
        var callToActionLinkText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "callToActionLinkText"), null));
        if (callToActionLinkText.TAG === /* Ok */0) {
          var smsLinkDescription = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "smsLinkDescription"), null));
          if (smsLinkDescription.TAG === /* Ok */0) {
            var qrImage = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "qrImage"), null));
            if (qrImage.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        title: title._0,
                        desription: desription._0,
                        callToActionPrefix: callToActionPrefix._0,
                        callToActionLinkText: callToActionLinkText._0,
                        smsLinkDescription: smsLinkDescription._0,
                        qrImage: qrImage._0
                      }
                    };
            }
            var e = qrImage._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".qrImage" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = smsLinkDescription._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".smsLinkDescription" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = callToActionLinkText._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".callToActionLinkText" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = callToActionPrefix._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".callToActionPrefix" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = desription._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".desription" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function footer_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var subtitle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subtitle"), null));
    if (subtitle.TAG === /* Ok */0) {
      var buttonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "buttonLabel"), null));
      if (buttonLabel.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  subtitle: subtitle._0,
                  buttonLabel: buttonLabel._0
                }
              };
      }
      var e = buttonLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".buttonLabel" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = subtitle._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subtitle" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var editSection = editSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "editSection"), null));
  if (editSection.TAG === /* Ok */0) {
    var vehicleSection = vehicleSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleSection"), null));
    if (vehicleSection.TAG === /* Ok */0) {
      var appDownloadSection = appDownloadSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownloadSection"), null));
      if (appDownloadSection.TAG === /* Ok */0) {
        var qrSection = qrSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "qrSection"), null));
        if (qrSection.TAG === /* Ok */0) {
          var footer = footer_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "footer"), null));
          if (footer.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      editSection: editSection._0,
                      vehicleSection: vehicleSection._0,
                      appDownloadSection: appDownloadSection._0,
                      qrSection: qrSection._0,
                      footer: footer._0
                    }
                  };
          }
          var e = footer._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".footer" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = qrSection._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".qrSection" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = appDownloadSection._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".appDownloadSection" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = vehicleSection._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".vehicleSection" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = editSection._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".editSection" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function data_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = data_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  editSection_decode ,
  vehicleSection_decode ,
  phoneCarousel_decode ,
  appDownloadSection_decode ,
  qrSection_decode ,
  footer_decode ,
  attributes_decode ,
  data_decode ,
  t_decode ,
  
}
/* No side effect */
