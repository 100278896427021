// Generated by ReScript, PLEASE EDIT WITH CARE


function machine(state, action) {
  if (typeof state === "number") {
    if (action.TAG === /* FetchDriverPartnerCMSData */0) {
      return {
              TAG: /* Data */0,
              _0: action._0
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: action._0
            };
    }
  } else {
    return state;
  }
}

var StateMachine = {
  machine: machine
};

export {
  StateMachine ,
  
}
/* No side effect */
