// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as CouponConfig$FrontendWebsite from "../view/view-model/CouponConfig.bs.js";
import * as CouponApiModel$FrontendWebsite from "../api-model/CouponApiModel.bs.js";

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(CouponApiModel$FrontendWebsite.t_decode(json), "OfferInfoStateMapper.res");
  return $$Array.map((function (data) {
                return {
                        id: data.scheme_id,
                        title: data.title,
                        coupon_code: Belt_Option.mapWithDefault(data.coupon_code, CouponConfig$FrontendWebsite.default_coupon_code, (function (x) {
                                return x;
                              })),
                        short_desc: Belt_Option.getWithDefault(data.short_description, ""),
                        long_desc: Belt_Option.getWithDefault(data.long_description, ""),
                        validity_text: Belt_Option.getWithDefault(data.validity_text, ""),
                        thumbnail_image_link: Belt_Option.mapWithDefault(data.main_image_link, CouponConfig$FrontendWebsite.default_thumbnail_image_link, (function (x) {
                                return x;
                              })),
                        tnc: Belt_Option.getWithDefault(data.terms_and_conditions, [])
                      };
              }), model.response);
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
