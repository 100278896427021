// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";

function make_props(position, mapPaneNameOpt, boundsOpt, getPixelPositionOffsetOpt, param) {
  var mapPaneName = mapPaneNameOpt !== undefined ? Caml_option.valFromOption(mapPaneNameOpt) : "markerLayer";
  var bounds = boundsOpt !== undefined ? Caml_option.valFromOption(boundsOpt) : undefined;
  var getPixelPositionOffset = getPixelPositionOffsetOpt !== undefined ? Caml_option.valFromOption(getPixelPositionOffsetOpt) : undefined;
  return {
          position: position,
          mapPaneName: mapPaneName,
          bounds: bounds,
          getPixelPositionOffset: getPixelPositionOffset
        };
}

export {
  make_props ,
  
}
/* No side effect */
