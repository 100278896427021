// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Currency$FrontendWebsite from "../../Currency.bs.js";
import * as WebsiteImgConfig$FrontendWebsite from "../../../view/assets/WebsiteImgConfig.bs.js";

function getVehicles(vehicle) {
  var price = vehicle.base_fare_display;
  var data = vehicle.discount;
  return {
          display_name: vehicle.display_name,
          capacity: vehicle.capacity,
          size: vehicle.size,
          icon: {
            path: WebsiteImgConfig$FrontendWebsite.getVehicleImage(vehicle.vehicle_type),
            alt_text: vehicle.icon.alt_text
          },
          base_fare: price !== undefined ? price : Currency$FrontendWebsite.getPriceWithCurrency(Caml_format.caml_int_of_string(vehicle.fare.value), vehicle.fare.currency),
          discount: data !== undefined ? ({
                value: data.value,
                max_discount: data.max_discount.currency + " " + data.max_discount.amount,
                discounted_fare: Belt_Option.getWithDefault(data.discounted_fare, "")
              }) : undefined
        };
}

function getVM(data) {
  return {
          vehicles: List.map(getVehicles, data.vehicles),
          callback_request_id: data.request_id
        };
}

export {
  getVehicles ,
  getVM ,
  
}
/* No side effect */
