// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var ServicesDesktopCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(ServicesDesktopCmpLazyWrapper);

function ServicesDesktopCmpLazyWrapper$1(Props) {
  var servicesCMSData = Props.servicesCMSData;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./ServicesDesktopCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            servicesCMSData: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              servicesCMSData: servicesCMSData
            });
}

var make = ServicesDesktopCmpLazyWrapper$1;

export {
  ServicesDesktopCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
