// Generated by ReScript, PLEASE EDIT WITH CARE


var pickup = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/uae/pickup_1tonne.webp";

var canter = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/uae/canter_3tonne.webp";

var two_wheeler = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/uae/2_wheeler.webp";

var compact = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/uae/compact.webp";

var pickup_bd = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/bd/one_tonne_bd.webp";

var two_wheeler_bd = "https://porter-website-banners.s3.ap-southeast-1.amazonaws.com/prod/home/bd/two_wheeler.webp";

var two_wheeler_tr = "https://int-website-prod-cdn-cms.porter.ae/nmax_125_cc_34_th_view_01_1_a8a3575ff5/nmax_125_cc_34_th_view_01_1_a8a3575ff5.webp";

var pickup_tr = "https://d3qesod9op4re9.cloudfront.net/1_5_tonne_pickup_03_1eb37e75c9.webp";

var minivan_tr = "https://int-website-prod-cdn-cms.porter.ae/doblo_03_1_1554958bd9/doblo_03_1_1554958bd9.webp";

function getVehicleImage(vehicle) {
  switch (vehicle.TAG | 0) {
    case /* UAE */0 :
        switch (vehicle._0) {
          case /* Canter3Tonne */1 :
              return canter;
          case /* Pickup1Tonne */2 :
              return pickup;
          case /* TwoWheeler */0 :
          case /* Courier */3 :
              return two_wheeler;
          case /* Compact */4 :
              return compact;
          
        }
    case /* BD */1 :
        if (vehicle._0) {
          return two_wheeler_bd;
        } else {
          return pickup_bd;
        }
    case /* TR */2 :
        switch (vehicle._0) {
          case /* Pickup */0 :
              return pickup_tr;
          case /* TwoWheeler */1 :
              return two_wheeler_tr;
          case /* Minivan */2 :
              return minivan_tr;
          
        }
    
  }
}

export {
  pickup ,
  canter ,
  two_wheeler ,
  compact ,
  pickup_bd ,
  two_wheeler_bd ,
  two_wheeler_tr ,
  pickup_tr ,
  minivan_tr ,
  getVehicleImage ,
  
}
/* No side effect */
