// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function response_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var fare_estimate_token = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "fare_estimate_token"), null));
  if (fare_estimate_token.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              fare_estimate_token: fare_estimate_token._0
            }
          };
  }
  var e = fare_estimate_token._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".fare_estimate_token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = response_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  response_decode ,
  t_decode ,
  
}
/* No side effect */
