// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LinkCmp$FrontendWebsite from "../../../website/view/components/LinkCmp.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../../website/types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../../utils/helper/ContentHelper.bs.js";
import * as MenuMobileCmp$FrontendWebsite from "./MenuMobileCmp.bs.js";
import * as BDCopyrightCmp$FrontendWebsite from "../BDCopyrightCmp.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as LanguageSelectorCmp$FrontendWebsite from "../../../../utils/languang_and_content/LanguageSelectorCmp.bs.js";

function HeaderMobileCmp(Props) {
  var porter_logo = Props.porter_logo;
  var primary_menu_items = Props.primary_menu_items;
  var secondary_menu_items = Props.secondary_menu_items;
  var partner_link = Props.partner_link;
  var menu_logo = Props.menu_logo;
  var menu_assets = Props.menu_assets;
  var partnerLink = ContentHelper$FrontendWebsite.getContent(partner_link);
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  var tmp;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp = React.createElement(BDCopyrightCmp$FrontendWebsite.make, {});
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp = null;
        break;
    
  }
  var tmp$1;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$1 = React.createElement(LinkCmp$FrontendWebsite.make, {
              href: WebsiteTypes$FrontendWebsite.urltoString(partnerLink.target),
              className: "instruction",
              children: WebsiteTypes$FrontendWebsite.titleToString(partnerLink.text)
            });
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp$1 = React.createElement(LanguageSelectorCmp$FrontendWebsite.make, {});
        break;
    
  }
  var tmp$2;
  var exit = 0;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$2 = null;
        break;
    case /* BD */1 :
    case /* TR */2 :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp$2 = React.createElement(LinkCmp$FrontendWebsite.make, {
          href: WebsiteTypes$FrontendWebsite.urltoString(partnerLink.target),
          className: "instruction",
          children: WebsiteTypes$FrontendWebsite.titleToString(partnerLink.text)
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "mobile-header"
                }, tmp, React.createElement("div", {
                      className: "mobile-header-container"
                    }, React.createElement("div", {
                          className: "left-container"
                        }, React.createElement(MenuMobileCmp$FrontendWebsite.make, {
                              primary_menu_items: primary_menu_items,
                              secondary_menu_items: secondary_menu_items,
                              porter_logo: porter_logo,
                              menu_logo: menu_logo,
                              menu_assets: menu_assets
                            }), React.createElement(LinkCmp$FrontendWebsite.make, {
                              href: "/",
                              className: "logo-container",
                              children: React.createElement("img", {
                                    className: "mobile-company-logo ",
                                    src: porter_logo.url
                                  })
                            })), React.createElement("div", {
                          className: "right-container"
                        }, tmp$1)), tmp$2));
}

var make = HeaderMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
