// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";

function faq_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var question = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "question"), null));
    if (question.TAG === /* Ok */0) {
      var answer = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "answer"), null));
      if (answer.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  question: question._0,
                  answer: answer._0
                }
              };
      }
      var e = answer._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".answer" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = question._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".question" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function faqs_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var questions = Decco.arrayFromJson(faq_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "questions"), null));
    if (questions.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                questions: questions._0
              }
            };
    }
    var e = questions._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".questions" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  faq_decode ,
  faqs_decode ,
  
}
/* No side effect */
