// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";

function bannerAttributesToEntity(bannerAttribute) {
  return {
          alternativeText: bannerAttribute.alternativeText,
          url: bannerAttribute.url,
          ext: bannerAttribute.ext
        };
}

function multipleBannerDataToEntity(bannerData) {
  return $$Array.map((function (data) {
                return {
                        id: data.id,
                        attributes: bannerAttributesToEntity(data.attributes)
                      };
              }), bannerData);
}

function bannerDataToEntity(bannerData) {
  return {
          id: bannerData.id,
          attributes: bannerAttributesToEntity(bannerData.attributes)
        };
}

function bannerToEntity(banner) {
  return {
          data: bannerDataToEntity(banner.data)
        };
}

function optionalBannerToEntity(banner) {
  var value = banner.data;
  if (value !== undefined) {
    return {
            data: bannerDataToEntity(value)
          };
  }
  
}

function bannersToEntity(banners) {
  return {
          data: multipleBannerDataToEntity(banners.data)
        };
}

export {
  bannerAttributesToEntity ,
  multipleBannerDataToEntity ,
  bannerDataToEntity ,
  bannerToEntity ,
  optionalBannerToEntity ,
  bannersToEntity ,
  
}
/* No side effect */
