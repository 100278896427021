// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function CircularLoader(Props) {
  var wrapper_classOpt = Props.wrapper_class;
  var wrapper_class = wrapper_classOpt !== undefined ? wrapper_classOpt : "";
  return React.createElement("div", {
              className: wrapper_class
            }, React.createElement("div", {
                  className: "loader"
                }));
}

var make = CircularLoader;

export {
  make ,
  
}
/* react Not a pure module */
