// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";

function dropdownItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
    if (label.TAG === /* Ok */0) {
      var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  label: label._0,
                  value: value._0
                }
              };
      }
      var e = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function dropdownLabel_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "label"), null));
  if (label.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              label: label._0
            }
          };
  }
  var e = label._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".label" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function nameField_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
  if (label.TAG === /* Ok */0) {
    var placeholder = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "placeholder"), null));
    if (placeholder.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                label: label._0,
                placeholder: placeholder._0
              }
            };
    }
    var e = placeholder._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".placeholder" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = label._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".label" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function mobileField_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
  if (label.TAG === /* Ok */0) {
    var placeholder = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "placeholder"), null));
    if (placeholder.TAG === /* Ok */0) {
      var countryDialingCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "countryDialingCode"), null));
      if (countryDialingCode.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  label: label._0,
                  placeholder: placeholder._0,
                  countryDialingCode: countryDialingCode._0
                }
              };
      }
      var e = countryDialingCode._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".countryDialingCode" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = placeholder._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".placeholder" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = label._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".label" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function pickAndDropFields_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var pickupLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pickupLabel"), null));
  if (pickupLabel.TAG === /* Ok */0) {
    var pickupPlaceholder = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pickupPlaceholder"), null));
    if (pickupPlaceholder.TAG === /* Ok */0) {
      var dropOffLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dropOffLabel"), null));
      if (dropOffLabel.TAG === /* Ok */0) {
        var dropOffPlaceholder = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dropOffPlaceholder"), null));
        if (dropOffPlaceholder.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    pickupLabel: pickupLabel._0,
                    pickupPlaceholder: pickupPlaceholder._0,
                    dropOffLabel: dropOffLabel._0,
                    dropOffPlaceholder: dropOffPlaceholder._0
                  }
                };
        }
        var e = dropOffPlaceholder._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".dropOffPlaceholder" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = dropOffLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dropOffLabel" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = pickupPlaceholder._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".pickupPlaceholder" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = pickupLabel._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".pickupLabel" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var formSubmitButtonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "formSubmitButtonLabel"), null));
  if (formSubmitButtonLabel.TAG === /* Ok */0) {
    var consentLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "consentLabel"), null));
    if (consentLabel.TAG === /* Ok */0) {
      var requirementsDropdown = Decco.arrayFromJson(dropdownItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "requirementsDropdown"), null));
      if (requirementsDropdown.TAG === /* Ok */0) {
        var requirementField = dropdownLabel_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "requirementField"), null));
        if (requirementField.TAG === /* Ok */0) {
          var nameField = nameField_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "nameField"), null));
          if (nameField.TAG === /* Ok */0) {
            var mobileField = mobileField_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "mobileField"), null));
            if (mobileField.TAG === /* Ok */0) {
              var pickAndDropFields = pickAndDropFields_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pickAndDropFields"), null));
              if (pickAndDropFields.TAG === /* Ok */0) {
                var citiesDropdown = Decco.arrayFromJson(dropdownItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "citiesDropdown"), null));
                if (citiesDropdown.TAG === /* Ok */0) {
                  var cityField = dropdownLabel_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityField"), null));
                  if (cityField.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              formSubmitButtonLabel: formSubmitButtonLabel._0,
                              consentLabel: consentLabel._0,
                              requirementsDropdown: requirementsDropdown._0,
                              requirementField: requirementField._0,
                              nameField: nameField._0,
                              mobileField: mobileField._0,
                              pickAndDropFields: pickAndDropFields._0,
                              citiesDropdown: citiesDropdown._0,
                              cityField: cityField._0
                            }
                          };
                  }
                  var e = cityField._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".cityField" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = citiesDropdown._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".citiesDropdown" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = pickAndDropFields._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".pickAndDropFields" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = mobileField._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".mobileField" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = nameField._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".nameField" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = requirementField._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".requirementField" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = requirementsDropdown._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".requirementsDropdown" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = consentLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".consentLabel" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = formSubmitButtonLabel._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".formSubmitButtonLabel" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

export {
  dropdownItem_decode ,
  dropdownLabel_decode ,
  nameField_decode ,
  mobileField_decode ,
  pickAndDropFields_decode ,
  t_decode ,
  
}
/* No side effect */
