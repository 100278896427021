// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Content_BD$FrontendWebsite from "./bd/Content_BD.bs.js";
import * as Content_TR$FrontendWebsite from "./tr/Content_TR.bs.js";
import * as Content_UAE$FrontendWebsite from "./uae/Content_UAE.bs.js";

function getContent(language) {
  switch (language.TAG | 0) {
    case /* UAE */0 :
        return Content_UAE$FrontendWebsite.English;
    case /* BD */1 :
        if (language._0) {
          return Content_BD$FrontendWebsite.BD;
        } else {
          return Content_BD$FrontendWebsite.English;
        }
    case /* TR */2 :
        if (language._0) {
          return Content_TR$FrontendWebsite.TR;
        } else {
          return Content_TR$FrontendWebsite.English;
        }
    
  }
}

export {
  getContent ,
  
}
/* Content_BD-FrontendWebsite Not a pure module */
