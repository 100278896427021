// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as MediaVMMapper$FrontendWebsite from "./MediaVMMapper.bs.js";

function getCitiesData(cities) {
  return $$Array.map((function (city) {
                var attributes = city.attributes;
                return {
                        title: attributes.title,
                        description: attributes.description,
                        cityName: attributes.cityName,
                        cityUrl: attributes.cityUrl,
                        cityIcon: MediaVMMapper$FrontendWebsite.getBanner(attributes.cityIcon.data)
                      };
              }), cities.data);
}

function getServiceableCities(serviceableCities) {
  return {
          title: serviceableCities.title,
          cities: getCitiesData(serviceableCities.cities)
        };
}

export {
  getCitiesData ,
  getServiceableCities ,
  
}
/* No side effect */
