// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as MediaVMMapper$FrontendWebsite from "../../../website/cms/view-model/MediaVMMapper.bs.js";

function getSupportItems(supportItems) {
  return $$Array.map((function (supportItem) {
                return {
                        title: supportItem.title,
                        description: supportItem.description,
                        icon: MediaVMMapper$FrontendWebsite.getBanner(supportItem.icon.data)
                      };
              }), supportItems);
}

function cmsVM(cmsData) {
  var match = cmsData.data.attributes;
  return {
          pageTitle: match.title,
          pageSubText: match.subText,
          ourOfficesTitle: match.ourOfficesTitle,
          supportItems: getSupportItems(match.supportItems),
          headOffice: match.headOffice,
          regionalOffices: match.regionalOffices
        };
}

export {
  getSupportItems ,
  cmsVM ,
  
}
/* No side effect */
