// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import ReactMarkdown from "react-markdown";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as VehicleVMMapper$FrontendWebsite from "../../VehicleVMMapper.bs.js";
import * as NavigationMenuCmp$FrontendWebsite from "../../../website/view/components/NavigationMenuCmp.bs.js";

function VehicleInfoDesktopCmp(Props) {
  var vehicleData = Props.vehicleData;
  var cityData = Props.cityData;
  var vehicleServiceData = Props.vehicleServiceData;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var getServiceInfoTableCmp = function (param) {
    var serviceDetails = vehicleServiceData.serviceDetailsRow;
    $$Array.sort((function (a, b) {
            return a.order - b.order | 0;
          }), serviceDetails);
    return $$Array.map((function (serviceDetailsRow) {
                  return React.createElement("tr", undefined, React.createElement("td", undefined, serviceDetailsRow.label), React.createElement("td", undefined, serviceDetailsRow.value));
                }), serviceDetails);
  };
  var data = vehicleServiceData.description;
  return React.createElement("div", {
              className: "desktop-vehicle-info-container"
            }, React.createElement("div", {
                  className: "heading-menu"
                }, React.createElement(NavigationMenuCmp$FrontendWebsite.make, {
                      menu_list: VehicleVMMapper$FrontendWebsite.getVehicleNavBarLinks(vehicleData.vehicleName, $$String.capitalize_ascii(cityData.cityName), language, vehicleData.slug)
                    })), React.createElement("div", {
                  className: "content"
                }, React.createElement("div", {
                      className: "img-wrapper"
                    }, React.createElement("img", {
                          className: "img",
                          alt: vehicleData.vehicleName,
                          src: vehicleData.vehicleBanner.url
                        })), React.createElement("h1", {
                      className: "title"
                    }, vehicleData.title), React.createElement("div", {
                      className: "title-desc"
                    }, React.createElement(ReactMarkdown, {
                          children: vehicleData.description
                        })), data !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "fare-heading"
                          }, vehicleServiceData.title), React.createElement("div", {
                            className: "fare-desc"
                          }, React.createElement(ReactMarkdown, {
                                children: data
                              }))) : null, React.createElement("div", {
                      className: "table-wrapper"
                    }, React.createElement("table", undefined, React.createElement("tbody", undefined, getServiceInfoTableCmp(undefined))))));
}

var make = VehicleInfoDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
