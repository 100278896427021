// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as GeocodeApiMapper$FrontendWebsite from "./GeocodeApiMapper.bs.js";

function getGeocodeFromPlaceId(place_id, onSuccess, onError) {
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return new (window.google.maps.Geocoder)().geocode({
                                placeId: place_id
                              }).then(function (data) {
                              return Promise.resolve({
                                          TAG: /* Ok */0,
                                          _0: GeocodeApiMapper$FrontendWebsite.execute(data)
                                        });
                            }).catch(function (err) {
                            return Promise.resolve({
                                        TAG: /* Error */1,
                                        _0: err
                                      });
                          });
              }), Curry.__1(onSuccess), Curry.__1(onError));
}

export {
  getGeocodeFromPlaceId ,
  
}
/* GeocodeApiMapper-FrontendWebsite Not a pure module */
