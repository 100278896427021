// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LinkCmp$FrontendWebsite from "../../website/view/components/LinkCmp.bs.js";
import * as GAConfig$FrontendWebsite from "../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../utils/library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../utils/context/events-manager/EventsHelper.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../routing/RoutesConfig.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../website/types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../utils/helper/ContentHelper.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

function PartnerLeadCmp(Props) {
  var data = Props.data;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var DeskTopNavContent = WebsiteConfig$FrontendWebsite.Desktop(Content.Home);
  var driverlogo = ContentHelper$FrontendWebsite.getContent(DeskTopNavContent.driverLogo);
  var publishDriveWithUsGAEvent = function (param) {
    return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.driveWithUsClick, {
                event_category: WebsiteConfig$FrontendWebsite.ga_title_home,
                event_label: "",
                language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
              });
  };
  return React.createElement(LinkCmp$FrontendWebsite.make, {
              href: RoutesConfig$FrontendWebsite.partnersUrl,
              className: "partner-lead-container",
              children: null,
              on_click: publishDriveWithUsGAEvent
            }, React.createElement("img", {
                  className: "driver-logo",
                  alt: WebsiteTypes$FrontendWebsite.titleToString(driverlogo.text),
                  src: data.icon.url
                }), React.createElement("div", {
                  className: "heading"
                }, React.createElement("a", {
                      className: "link",
                      href: RoutesConfig$FrontendWebsite.partnersUrl
                    }, data.title), React.createElement("div", {
                      className: "description"
                    }, data.subTitle)), React.createElement("div", {
                  className: "join-us"
                }, data.tagLine));
}

var make = PartnerLeadCmp;

export {
  make ,
  
}
/* react Not a pure module */
