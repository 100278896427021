// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as DateTime$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DateTime.bs.js";
import * as GAConfig$FrontendWebsite from "../../library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../app-contexts/AppContext.bs.js";
import * as MediaQuery$FrontendWebsite from "../../hooks/MediaQuery.bs.js";
import * as EventsHelper$FrontendWebsite from "./EventsHelper.bs.js";
import * as EventProvider$FrontendWebsite from "./EventProvider.bs.js";
import * as DurationEvents$FrontendWebsite from "./DurationEvents.bs.js";
import * as RescriptReactUtils$FrontendWebsite from "../../RescriptReactUtils.bs.js";
import * as ScrollHeightEvents$FrontendWebsite from "./ScrollHeightEvents.bs.js";
import * as PageViewedPctEvents$FrontendWebsite from "./PageViewedPctEvents.bs.js";

function EventWrapperCmp(Props) {
  var country = Props.country;
  var route = Props.route;
  var scroll_heightOpt = Props.scroll_height;
  var children = Props.children;
  var scroll_height = scroll_heightOpt !== undefined ? scroll_heightOpt : 0;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var match = React.useState(function () {
        return 99;
      });
  var setMaxScrollHeight = match[1];
  var max_scrolled_height = match[0];
  var match$1 = React.useState(function () {
        return 9;
      });
  var setMaxPageViewPct = match$1[1];
  var max_page_viewed_pct = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPageLoad = match$2[1];
  var isPageLoaded = match$2[0];
  var match$3 = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  var tabHiddenTimestamp = React.useRef(0);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var getVisibilityEvents = function (param) {
    var visibility = document.visibilityState;
    var timestamp = DateTime$Catalyst.toSecond(DateTime$Catalyst.now(undefined)) - tabHiddenTimestamp.current | 0;
    if (visibility === "visible") {
      return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.tab_visibility_events_action, {
                  event_category: EventsHelper$FrontendWebsite.routeToEventCategory(route),
                  event_label: visibility,
                  event_value: timestamp,
                  nonInteraction: true,
                  language_selected: language
                });
    }
    EventsHelper$FrontendWebsite.userEventHandler(EventsHelper$FrontendWebsite.routeToEventCategory(route), GAConfigActions.tab_visibility_events_action, visibility, undefined, EventsHelper$FrontendWebsite.getEventLanguage(language));
    tabHiddenTimestamp.current = DateTime$Catalyst.toSecond(DateTime$Catalyst.now(undefined));
    
  };
  var onLoadHandler = function (param) {
    document.removeEventListener("visibilitychange", getVisibilityEvents);
    
  };
  var getViewPct = function (param) {
    var pageHeight = RescriptReactUtils$FrontendWebsite.getScrollHeight("event-wrapper");
    var viewedHeight = window.innerHeight + scroll_height | 0;
    return RescriptReactUtils$FrontendWebsite.getPct(viewedHeight, pageHeight);
  };
  var getViewedEventsOnPageLoad = function (param) {
    return List.iteri((function (index, viewed_perc) {
                  if (getViewPct(undefined) >= viewed_perc) {
                    PageViewedPctEvents$FrontendWebsite.setPageViewEvents(route, viewed_perc, GAConfigActions.page_view_pct_events_action, EventsHelper$FrontendWebsite.getEventLanguage(language));
                    return Curry._1(setMaxPageViewPct, (function (param) {
                                  if (index === (List.length(PageViewedPctEvents$FrontendWebsite.listOfPageViewsPct) - 1 | 0)) {
                                    return List.nth(PageViewedPctEvents$FrontendWebsite.listOfPageViewsPct, index) - 1 | 0;
                                  } else {
                                    return List.nth(PageViewedPctEvents$FrontendWebsite.listOfPageViewsPct, index + 1 | 0) - 1 | 0;
                                  }
                                }));
                  }
                  
                }), PageViewedPctEvents$FrontendWebsite.listOfPageViewsPct);
  };
  React.useEffect((function () {
          document.addEventListener("visibilitychange", getVisibilityEvents);
          window.addEventListener("beforeunload", onLoadHandler);
          return (function (param) {
                    window.removeEventListener("beforeunload", onLoadHandler);
                    
                  });
        }), []);
  React.useEffect((function () {
          if (isPageLoaded) {
            getViewedEventsOnPageLoad(undefined);
            DurationEvents$FrontendWebsite.setDurationEvents(route, GAConfigActions.duration_events_action, EventsHelper$FrontendWebsite.getEventLanguage(language));
          }
          
        }), [isPageLoaded]);
  React.useEffect((function () {
          if (scroll_height > max_scrolled_height) {
            var match = ScrollHeightEvents$FrontendWebsite.getEventScrollHeightAndMaxHeight(scroll_height);
            var max_page_scroll_height = match[1];
            ScrollHeightEvents$FrontendWebsite.setScrollHeightEvents(route, match[0], GAConfigActions.scroll_events_action, EventsHelper$FrontendWebsite.getEventLanguage(language));
            Curry._1(setMaxScrollHeight, (function (param) {
                    return max_page_scroll_height;
                  }));
          }
          if (getViewPct(undefined) > max_page_viewed_pct && isPageLoaded) {
            var match$1 = PageViewedPctEvents$FrontendWebsite.getViewPctAndMaxViewedPct(getViewPct(undefined));
            var max_page_viewed_perc = match$1[1];
            PageViewedPctEvents$FrontendWebsite.setPageViewEvents(route, match$1[0], GAConfigActions.page_view_pct_events_action, EventsHelper$FrontendWebsite.getEventLanguage(language));
            Curry._1(setMaxPageViewPct, (function (param) {
                    return max_page_viewed_perc;
                  }));
          }
          
        }), [scroll_height]);
  var getCountryContainerClass = function (param) {
    switch (country.TAG | 0) {
      case /* UAE */0 :
          return "";
      case /* BD */1 :
          return "container__bd";
      case /* TR */2 :
          return "container__tr";
      
    }
  };
  return React.createElement("div", {
              className: (
                match$3[0] ? "desktop-event-wrapper-container" : "mobile-event-wrapper-container"
              ) + " " + getCountryContainerClass(undefined),
              id: "event-wrapper"
            }, React.createElement(EventProvider$FrontendWebsite.make, EventProvider$FrontendWebsite.makeProps({
                      scrollHeight: scroll_height,
                      category: EventsHelper$FrontendWebsite.routeToEventCategory(route),
                      pageLoadHandler: (function (value) {
                          return Curry._1(setPageLoad, (function (param) {
                                        return value;
                                      }));
                        })
                    }, children, undefined)));
}

var make = EventWrapperCmp;

export {
  make ,
  
}
/* react Not a pure module */
