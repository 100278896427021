// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Column$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Column.bs.js";
import * as TertiaryButton$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/buttons/TertiaryButton.bs.js";
import * as AssetsManager$FrontendWebsite from "../../../../view/assets/AssetsManager.bs.js";

var errorPage = AssetsManager$FrontendWebsite.getCommonImage("ErrorPage.svg");

function SomethingWentWrongCmp(Props) {
  var reload_cbOpt = Props.reload_cb;
  var class_nameOpt = Props.class_name;
  var messageOpt = Props.message;
  var reload_cb = reload_cbOpt !== undefined ? reload_cbOpt : (function (param) {
        
      });
  var class_name = class_nameOpt !== undefined ? class_nameOpt : "";
  var message = messageOpt !== undefined ? messageOpt : "";
  return React.createElement(Column$Catalyst.make, {
              children: null,
              className: "c-error-page " + class_name
            }, React.createElement("img", {
                  className: "error-img",
                  alt: "Error",
                  src: errorPage
                }), React.createElement("div", {
                  className: "error__msg"
                }, message), React.createElement("div", {
                  className: "reload-msg"
                }, "Can you try again? ", React.createElement(TertiaryButton$Catalyst.make, {
                      onSelectCB: (function (param) {
                          return Curry._1(reload_cb, undefined);
                        }),
                      children: "Reload Page"
                    })));
}

var make = SomethingWentWrongCmp;

export {
  errorPage ,
  make ,
  
}
/* errorPage Not a pure module */
