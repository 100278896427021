// Generated by ReScript, PLEASE EDIT WITH CARE


function getTitleDesc(vehicle, content) {
  switch (vehicle.TAG | 0) {
    case /* UAE */0 :
        switch (vehicle._0) {
          case /* TwoWheeler */0 :
              return [
                      content.two_wheeler_title,
                      content.two_wheeler_meta_description
                    ];
          case /* Canter3Tonne */1 :
              return [
                      content.canter_3tonne_title,
                      content.canter_3tonne_meta_description
                    ];
          case /* Pickup1Tonne */2 :
              return [
                      content.pickup_1tonne_title,
                      content.pickup_1tonne_meta_description
                    ];
          case /* Courier */3 :
              return [
                      content.courier_title,
                      content.courier_meta_description
                    ];
          case /* Compact */4 :
              return [
                      content.compact_title,
                      content.compact_meta_description
                    ];
          
        }
    case /* BD */1 :
        if (vehicle._0) {
          return [
                  content.two_wheeler_title,
                  content.two_wheeler_meta_description
                ];
        } else {
          return [
                  content.pickup_1tonne_title,
                  content.pickup_1tonne_meta_description
                ];
        }
    case /* TR */2 :
        switch (vehicle._0) {
          case /* Pickup */0 :
              return [
                      content.pickup_1tonne_title,
                      content.pickup_1tonne_meta_description
                    ];
          case /* TwoWheeler */1 :
              return [
                      content.two_wheeler_title,
                      content.two_wheeler_meta_description
                    ];
          case /* Minivan */2 :
              return [
                      content.minivan_title,
                      content.minivan_meta_description
                    ];
          
        }
    
  }
}

export {
  getTitleDesc ,
  
}
/* No side effect */
