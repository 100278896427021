// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as TrackLiveTripRepoManager$FrontendWebsite from "../../track-live-order/repo/TrackLiveTripRepoManager.bs.js";

function updateTrackingRoute(booking_id, customer_uuid) {
  return RescriptReactRouter.push("/track_live_order?booking_id=" + (booking_id + ("&customer_uuid=" + customer_uuid)));
}

function getOrderInfo(dispatch, booking_id, customer_uuid, country, language) {
  var TrackLiveTripRepo = TrackLiveTripRepoManager$FrontendWebsite.getRepo(undefined);
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return Curry._4(TrackLiveTripRepo.fetchTrackLiveTripInfo, booking_id, customer_uuid, country, language);
              }), (function (orderInfo) {
                var updated_crn = orderInfo.updated_crn;
                if (updated_crn !== undefined) {
                  getOrderInfo(dispatch, updated_crn, customer_uuid, country, language);
                  return updateTrackingRoute(updated_crn, customer_uuid);
                } else {
                  return Curry._1(dispatch, {
                              TAG: /* SetOrderInfo */0,
                              _0: orderInfo
                            });
                }
              }), (function (error) {
                Curry._1(dispatch, {
                      TAG: /* SetOrderInfoError */1,
                      _0: error
                    });
                return Curry._1(dispatch, {
                            TAG: /* SetAutoReload */5,
                            _0: false
                          });
              }));
}

function getMarketingInfo(dispatch, booking_id, customer_uuid, country, language) {
  var TrackLiveTripRepo = TrackLiveTripRepoManager$FrontendWebsite.getRepo(undefined);
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return Curry._4(TrackLiveTripRepo.fetchTrackLiveTripMarketingInfo, booking_id, customer_uuid, country, language);
              }), (function (marketingInfo) {
                return Curry._1(dispatch, {
                            TAG: /* SetMarketingInfo */2,
                            _0: marketingInfo
                          });
              }), (function (error) {
                Curry._1(dispatch, {
                      TAG: /* SetMarketingInfoError */3,
                      _0: error
                    });
                return Curry._1(dispatch, {
                            TAG: /* SetAutoReload */5,
                            _0: false
                          });
              }));
}

export {
  updateTrackingRoute ,
  getOrderInfo ,
  getMarketingInfo ,
  
}
/* RescriptReactRouter Not a pure module */
