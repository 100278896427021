// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";

function fromRouteString(route, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        switch (route) {
          case "bike-parcel-delivery-dubai" :
              return {
                      TAG: /* VehiclePage */1,
                      _0: "dubai",
                      _1: {
                        TAG: /* UAE */0,
                        _0: /* TwoWheeler */0
                      }
                    };
          case "bike-parcel-delivery-sharjah" :
              return {
                      TAG: /* VehiclePage */1,
                      _0: "sharjah",
                      _1: {
                        TAG: /* UAE */0,
                        _0: /* TwoWheeler */0
                      }
                    };
          default:
            return ;
        }
    case /* BD */1 :
        if (route === "bike-parcel-delivery-dhaka") {
          return {
                  TAG: /* VehiclePage */1,
                  _0: "dhaka",
                  _1: {
                    TAG: /* BD */1,
                    _0: /* TwoWheeler */1
                  }
                };
        } else {
          return ;
        }
    case /* TR */2 :
        if (route === "bike-parcel-delivery-istanbul") {
          return {
                  TAG: /* VehiclePage */1,
                  _0: "istanbul",
                  _1: {
                    TAG: /* TR */2,
                    _0: /* TwoWheeler */1
                  }
                };
        } else {
          return ;
        }
    
  }
}

function toRouteString(city) {
  var match = $$String.lowercase_ascii(city);
  switch (match) {
    case "dhaka" :
        return "/bike-parcel-delivery-dhaka";
    case "dubai" :
        return "/bike-parcel-delivery-dubai";
    case "istanbul" :
        return "/bike-parcel-delivery-istanbul";
    case "sharjah" :
        return "/bike-parcel-delivery-sharjah";
    default:
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "TwoWheelerRoutes.res",
              21,
              2
            ],
            Error: new Error()
          };
  }
}

export {
  fromRouteString ,
  toRouteString ,
  
}
/* No side effect */
