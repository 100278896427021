// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function vehicle_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var capacity_display = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "capacity_display"), null));
    if (capacity_display.TAG === /* Ok */0) {
      var display_name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "display_name"), null));
      if (display_name.TAG === /* Ok */0) {
        var vehicle_type = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicle_type"), null));
        if (vehicle_type.TAG === /* Ok */0) {
          var size_display = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "size_display"), null));
          if (size_display.TAG === /* Ok */0) {
            var image_url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_url"), null));
            if (image_url.TAG === /* Ok */0) {
              var image_alt_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_alt_text"), null));
              if (image_alt_text.TAG === /* Ok */0) {
                var url = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
                if (url.TAG === /* Ok */0) {
                  var base_fare_display = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "base_fare_display"), null));
                  if (base_fare_display.TAG === /* Ok */0) {
                    var free_time_distance_text = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "free_time_distance_text"), null));
                    if (free_time_distance_text.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                id: id._0,
                                capacity_display: capacity_display._0,
                                display_name: display_name._0,
                                vehicle_type: vehicle_type._0,
                                size_display: size_display._0,
                                image_url: image_url._0,
                                image_alt_text: image_alt_text._0,
                                url: url._0,
                                base_fare_display: base_fare_display._0,
                                free_time_distance_text: free_time_distance_text._0
                              }
                            };
                    }
                    var e = free_time_distance_text._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".free_time_distance_text" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = base_fare_display._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".base_fare_display" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = url._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".url" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = image_alt_text._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".image_alt_text" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = image_url._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".image_url" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = size_display._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".size_display" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = vehicle_type._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicle_type" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = display_name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".display_name" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = capacity_display._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".capacity_display" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.listFromJson(vehicle_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  vehicle_decode ,
  t_decode ,
  
}
/* No side effect */
