// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EventContext$FrontendWebsite from "./EventContext.bs.js";

var make = EventContext$FrontendWebsite.context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

export {
  make ,
  makeProps ,
  
}
/* make Not a pure module */
