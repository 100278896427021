// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as FareEstimateResultMobileVehicleCardCmp$FrontendWebsite from "./FareEstimateResultMobileVehicleCardCmp.bs.js";

function FareEstimateResultMobileVehiclesListCmp(Props) {
  var vehicles = Props.vehicles;
  var vehicleSection = Props.vehicleSection;
  return React.createElement("div", {
              className: "fare-estimate-result-mobile-vehicle-list-container"
            }, $$Array.of_list(List.map((function (vehicle) {
                        return React.createElement(FareEstimateResultMobileVehicleCardCmp$FrontendWebsite.make, {
                                    vehicle: vehicle,
                                    vehicleSection: vehicleSection
                                  });
                      }), vehicles)));
}

var make = FareEstimateResultMobileVehiclesListCmp;

export {
  make ,
  
}
/* react Not a pure module */
