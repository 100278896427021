// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as TripStatusUtils$FrontendWebsite from "../entity/TripStatusUtils.bs.js";

function locationDecoder(json) {
  return {
          landmark: Json_decode.at({
                  hd: "place",
                  tl: {
                    hd: "landmark",
                    tl: /* [] */0
                  }
                }, Json_decode.string)(json),
          location: {
            lat: Json_decode.at({
                    hd: "place",
                    tl: {
                      hd: "location",
                      tl: {
                        hd: "lat",
                        tl: /* [] */0
                      }
                    }
                  }, Json_decode.$$float)(json),
            lng: Json_decode.at({
                    hd: "place",
                    tl: {
                      hd: "location",
                      tl: {
                        hd: "long",
                        tl: /* [] */0
                      }
                    }
                  }, Json_decode.$$float)(json)
          }
        };
}

function partnerLocationDecoder(json) {
  return {
          lat: Json_decode.field("lat", Json_decode.$$float, json),
          lng: Json_decode.field("long", Json_decode.$$float, json)
        };
}

function vehicleIconDecoder(json) {
  return {
          map_marker: Json_decode.field("map_marker", Json_decode.string, json),
          selected: Json_decode.field("selected", Json_decode.string, json),
          deselected: Json_decode.field("deselected", Json_decode.string, json)
        };
}

function vehicleInfoDecoder(json) {
  return {
          icons: Json_decode.optional((function (param) {
                  return Json_decode.field("icons", vehicleIconDecoder, param);
                }), json),
          vehicle_type: Json_decode.field("vehicle_type", Json_decode.string, json)
        };
}

function customerInfoDecoder(json) {
  return {
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          number: Json_decode.optional((function (param) {
                  return Json_decode.field("customer_mobile", Json_decode.string, param);
                }), json)
        };
}

function partnerInfoDecoder(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          mobile: Json_decode.field("mobile", Json_decode.string, json),
          vehicleType: Json_decode.field("vehicle_type", Json_decode.string, json),
          vehicleNumber: Json_decode.field("vehicle_number", Json_decode.string, json)
        };
}

function execute(json) {
  return {
          status: TripStatusUtils$FrontendWebsite.fromString(Json_decode.at({
                      hd: "order_details",
                      tl: {
                        hd: "status",
                        tl: /* [] */0
                      }
                    }, Json_decode.string)(json)),
          crn: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "order_id",
                    tl: /* [] */0
                  }
                }, Json_decode.string)(json),
          updated_crn: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "reallocated_order_id",
                      tl: /* [] */0
                    }
                  }, Json_decode.string), json),
          geo_region_id: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "geo_region_id",
                    tl: /* [] */0
                  }
                }, Json_decode.$$int)(json),
          partner: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "partner_info",
                      tl: /* [] */0
                    }
                  }, partnerInfoDecoder), json),
          pickupLocation: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "pickup_info",
                    tl: /* [] */0
                  }
                }, locationDecoder)(json),
          dropLocation: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "drop_info",
                      tl: /* [] */0
                    }
                  }, locationDecoder), json),
          waypointLocations: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "waypoints",
                    tl: /* [] */0
                  }
                }, (function (param) {
                    return Json_decode.list(locationDecoder, param);
                  }))(json),
          hasWaypoints: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "has_waypoints",
                    tl: /* [] */0
                  }
                }, Json_decode.bool)(json),
          isRental: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "order_type",
                    tl: /* [] */0
                  }
                }, Json_decode.string)(json) === "rental",
          isHelper: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "is_helper_order",
                    tl: /* [] */0
                  }
                }, Json_decode.bool)(json),
          isOutstation: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "is_outstation_order",
                    tl: /* [] */0
                  }
                }, Json_decode.bool)(json),
          vehicle: Json_decode.at({
                  hd: "order_details",
                  tl: {
                    hd: "vehicle",
                    tl: /* [] */0
                  }
                }, vehicleInfoDecoder)(json),
          partnerLocation: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "partner_location",
                      tl: /* [] */0
                    }
                  }, partnerLocationDecoder), json),
          customerSupportContact: Json_decode.at({
                  hd: "config",
                  tl: {
                    hd: "customer_care_number",
                    tl: /* [] */0
                  }
                }, Json_decode.string)(json),
          customerInfo: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "customer_info",
                      tl: /* [] */0
                    }
                  }, customerInfoDecoder), json),
          trip_ended_time: Json_decode.optional(Json_decode.at({
                    hd: "order_details",
                    tl: {
                      hd: "trip_ended_time",
                      tl: /* [] */0
                    }
                  }, Json_decode.$$int), json)
        };
}

var TrackLiveTripInfo = {
  locationDecoder: locationDecoder,
  partnerLocationDecoder: partnerLocationDecoder,
  vehicleIconDecoder: vehicleIconDecoder,
  vehicleInfoDecoder: vehicleInfoDecoder,
  customerInfoDecoder: customerInfoDecoder,
  partnerInfoDecoder: partnerInfoDecoder,
  execute: execute
};

function referralDiscountDecoder(json) {
  return {
          TAG: /* Referral */0,
          _0: {
            discountAmount: Json_decode.field("discount_amount", Json_decode.$$int, json),
            discountRides: Json_decode.field("n_tickets", Json_decode.$$int, json),
            discountCode: Json_decode.field("discount_code", Json_decode.string, json)
          }
        };
}

function couponDiscountDecoder(json) {
  return {
          TAG: /* Coupon */1,
          _0: {
            discountAmount: Json_decode.field("discount_amount", Json_decode.$$int, json),
            discountRides: Json_decode.field("n_tickets", Json_decode.$$int, json),
            discountCode: Json_decode.field("discount_code", Json_decode.string, json)
          }
        };
}

function execute$1(json) {
  var partial_arg_0 = Json_decode.at({
        hd: "marketing_info",
        tl: {
          hd: "referral_discount_info",
          tl: /* [] */0
        }
      }, referralDiscountDecoder);
  var partial_arg_1 = {
    hd: Json_decode.at({
          hd: "marketing_info",
          tl: {
            hd: "coupon_discount_info",
            tl: /* [] */0
          }
        }, couponDiscountDecoder),
    tl: /* [] */0
  };
  var partial_arg = {
    hd: partial_arg_0,
    tl: partial_arg_1
  };
  return {
          savingAmount: Json_decode.at({
                  hd: "marketing_info",
                  tl: {
                    hd: "savings_amount",
                    tl: /* [] */0
                  }
                }, Json_decode.$$float)(json),
          averageEta: Json_decode.at({
                  hd: "marketing_info",
                  tl: {
                    hd: "average_eta",
                    tl: /* [] */0
                  }
                }, Json_decode.$$int)(json),
          registeredPartners: Json_decode.at({
                  hd: "marketing_info",
                  tl: {
                    hd: "registered_partners",
                    tl: /* [] */0
                  }
                }, Json_decode.$$int)(json),
          averageBookingTime: Json_decode.at({
                  hd: "marketing_info",
                  tl: {
                    hd: "average_booking_time",
                    tl: /* [] */0
                  }
                }, Json_decode.$$int)(json),
          downloadLink: Json_decode.at({
                  hd: "marketing_info",
                  tl: {
                    hd: "download_app_link",
                    tl: /* [] */0
                  }
                }, Json_decode.string)(json),
          discount: Json_decode.optional((function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json)
        };
}

var TrackLiveTripMarketingInfo = {
  referralDiscountDecoder: referralDiscountDecoder,
  couponDiscountDecoder: couponDiscountDecoder,
  execute: execute$1
};

export {
  TrackLiveTripInfo ,
  TrackLiveTripMarketingInfo ,
  
}
/* No side effect */
