// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "../../website/cms/api-model/MediaApiModel.bs.js";
import * as AppDownloadApiModel$FrontendWebsite from "../../website/cms/api-model/AppDownloadApiModel.bs.js";
import * as ServiceableCitiesApiModel$FrontendWebsite from "../../website/cms/api-model/ServiceableCitiesApiModel.bs.js";

function vehiclesAttribute_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var vehicleName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleName"), null));
      if (vehicleName.TAG === /* Ok */0) {
        var city = ServiceableCitiesApiModel$FrontendWebsite.city_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
        if (city.TAG === /* Ok */0) {
          var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
          if (url.TAG === /* Ok */0) {
            var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
            if (vehicleType.TAG === /* Ok */0) {
              var vehicleBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleBanner"), null));
              if (vehicleBanner.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          title: title._0,
                          description: description._0,
                          vehicleName: vehicleName._0,
                          city: city._0,
                          url: url._0,
                          vehicleType: vehicleType._0,
                          vehicleBanner: vehicleBanner._0
                        }
                      };
              }
              var e = vehicleBanner._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".vehicleBanner" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = vehicleType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".vehicleType" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = url._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".url" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = city._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".city" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = vehicleName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicleName" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function otherVehiclesData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = vehiclesAttribute_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function vehicles_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(otherVehiclesData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function otherVehicles_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var vehicles = vehicles_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicles"), null));
    if (vehicles.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                vehicles: vehicles._0
              }
            };
    }
    var e = vehicles._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".vehicles" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function vehicleContent_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                description: description._0
              }
            };
    }
    var e = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function serviceDetailsRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
    if (label.TAG === /* Ok */0) {
      var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  label: label._0,
                  value: value._0
                }
              };
      }
      var e = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function vehicleInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var serviceDetailsRow = Decco.arrayFromJson(serviceDetailsRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceDetailsRow"), null));
      if (serviceDetailsRow.TAG === /* Ok */0) {
        var vehicleContent = Decco.arrayFromJson(vehicleContent_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleContent"), null));
        if (vehicleContent.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    description: description._0,
                    serviceDetailsRow: serviceDetailsRow._0,
                    vehicleContent: vehicleContent._0
                  }
                };
        }
        var e = vehicleContent._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleContent" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = serviceDetailsRow._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".serviceDetailsRow" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function coupon_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var couponInfo = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "couponInfo"), null));
  if (couponInfo.TAG === /* Ok */0) {
    var couponCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "couponCode"), null));
    if (couponCode.TAG === /* Ok */0) {
      var couponValidityText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "couponValidityText"), null));
      if (couponValidityText.TAG === /* Ok */0) {
        var detailsLink = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "detailsLink"), null));
        if (detailsLink.TAG === /* Ok */0) {
          var bannerImg = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "bannerImg"), null));
          if (bannerImg.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      couponInfo: couponInfo._0,
                      couponCode: couponCode._0,
                      couponValidityText: couponValidityText._0,
                      detailsLink: detailsLink._0,
                      bannerImg: bannerImg._0
                    }
                  };
          }
          var e = bannerImg._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".bannerImg" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = detailsLink._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".detailsLink" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = couponValidityText._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".couponValidityText" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = couponCode._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".couponCode" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = couponInfo._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".couponInfo" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function porterOffers_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var coupons = Decco.listFromJson(coupon_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "coupons"), null));
    if (coupons.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                coupons: coupons._0
              }
            };
    }
    var e = coupons._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".coupons" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
    if (slug.TAG === /* Ok */0) {
      var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
      if (description.TAG === /* Ok */0) {
        var vehicleName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleName"), null));
        if (vehicleName.TAG === /* Ok */0) {
          var vehicleBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleBanner"), null));
          if (vehicleBanner.TAG === /* Ok */0) {
            var city = ServiceableCitiesApiModel$FrontendWebsite.city_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
            if (city.TAG === /* Ok */0) {
              var vehicleInfo = vehicleInfo_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleInfo"), null));
              if (vehicleInfo.TAG === /* Ok */0) {
                var otherVehicles = otherVehicles_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "otherVehicles"), null));
                if (otherVehicles.TAG === /* Ok */0) {
                  var serviceableCities = ServiceableCitiesApiModel$FrontendWebsite.serviceableCities_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceableCities"), null));
                  if (serviceableCities.TAG === /* Ok */0) {
                    var porterOffers = porterOffers_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "porterOffers"), null));
                    if (porterOffers.TAG === /* Ok */0) {
                      var estimateButtonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "estimateButtonLabel"), null));
                      if (estimateButtonLabel.TAG === /* Ok */0) {
                        var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
                        if (url.TAG === /* Ok */0) {
                          var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
                          if (vehicleType.TAG === /* Ok */0) {
                            var appDownload = AppDownloadApiModel$FrontendWebsite.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownload"), null));
                            if (appDownload.TAG === /* Ok */0) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: {
                                        title: title._0,
                                        slug: slug._0,
                                        description: description._0,
                                        vehicleName: vehicleName._0,
                                        vehicleBanner: vehicleBanner._0,
                                        city: city._0,
                                        vehicleInfo: vehicleInfo._0,
                                        otherVehicles: otherVehicles._0,
                                        serviceableCities: serviceableCities._0,
                                        porterOffers: porterOffers._0,
                                        estimateButtonLabel: estimateButtonLabel._0,
                                        url: url._0,
                                        vehicleType: vehicleType._0,
                                        appDownload: appDownload._0
                                      }
                                    };
                            }
                            var e = appDownload._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".appDownload" + e.path,
                                      message: e.message,
                                      value: e.value
                                    }
                                  };
                          }
                          var e$1 = vehicleType._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".vehicleType" + e$1.path,
                                    message: e$1.message,
                                    value: e$1.value
                                  }
                                };
                        }
                        var e$2 = url._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".url" + e$2.path,
                                  message: e$2.message,
                                  value: e$2.value
                                }
                              };
                      }
                      var e$3 = estimateButtonLabel._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".estimateButtonLabel" + e$3.path,
                                message: e$3.message,
                                value: e$3.value
                              }
                            };
                    }
                    var e$4 = porterOffers._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".porterOffers" + e$4.path,
                              message: e$4.message,
                              value: e$4.value
                            }
                          };
                  }
                  var e$5 = serviceableCities._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".serviceableCities" + e$5.path,
                            message: e$5.message,
                            value: e$5.value
                          }
                        };
                }
                var e$6 = otherVehicles._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".otherVehicles" + e$6.path,
                          message: e$6.message,
                          value: e$6.value
                        }
                      };
              }
              var e$7 = vehicleInfo._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".vehicleInfo" + e$7.path,
                        message: e$7.message,
                        value: e$7.value
                      }
                    };
            }
            var e$8 = city._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".city" + e$8.path,
                      message: e$8.message,
                      value: e$8.value
                    }
                  };
          }
          var e$9 = vehicleBanner._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".vehicleBanner" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
        }
        var e$10 = vehicleName._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleName" + e$10.path,
                  message: e$10.message,
                  value: e$10.value
                }
              };
      }
      var e$11 = description._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".description" + e$11.path,
                message: e$11.message,
                value: e$11.value
              }
            };
    }
    var e$12 = slug._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".slug" + e$12.path,
              message: e$12.message,
              value: e$12.value
            }
          };
  }
  var e$13 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$13.path,
            message: e$13.message,
            value: e$13.value
          }
        };
}

function vehiclesData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "attributes"), null));
    if (attributes.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                attributes: attributes._0
              }
            };
    }
    var e = attributes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".attributes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(vehiclesData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  vehiclesAttribute_decode ,
  otherVehiclesData_decode ,
  vehicles_decode ,
  otherVehicles_decode ,
  vehicleContent_decode ,
  serviceDetailsRow_decode ,
  vehicleInfo_decode ,
  coupon_decode ,
  porterOffers_decode ,
  attributes_decode ,
  vehiclesData_decode ,
  t_decode ,
  
}
/* No side effect */
