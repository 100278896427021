// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Formality from "../../../../../node_modules/re-formality/src/Formality.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as CheckBox$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/CheckBox.bs.js";
import * as FormInput$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormInput.bs.js";
import * as FormHelper$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/FormHelper.bs.js";
import * as ButtonUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/buttons/ButtonUtils.bs.js";
import * as Config$FrontendWebsite from "../../../Config.bs.js";
import * as FormTextInput$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormTextInput.bs.js";
import * as Formality__ReactUpdate from "../../../../../node_modules/re-formality/src/Formality__ReactUpdate.bs.js";
import * as CircularLoader$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as FormSelectInput$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormSelectInput.bs.js";
import * as GAConfig$FrontendWebsite from "../../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../../utils/library/ga/GAEvents.bs.js";
import * as SelectComponent$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/drop-downs/SelectComponent.bs.js";
import * as UtmUtils$FrontendWebsite from "../../../../utils/UtmUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CityHelper$FrontendWebsite from "../../../city/CityHelper.bs.js";
import * as HomeHelper$FrontendWebsite from "../../../../utils/helper/HomeHelper.bs.js";
import * as JsBindings$FrontendWebsite from "../../../../utils/library/bindings/JsBindings.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../../utils/helper/CommonHelper.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../../utils/context/events-manager/EventsHelper.bs.js";
import * as RoundedPrimaryButton$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/buttons/RoundedPrimaryButton.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../../routing/WebsiteConfig.bs.js";
import * as CityCookieHook$FrontendWebsite from "../../../../utils/hooks/CityCookieHook.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../../../cookie-consent/utils/CookieConsentHelper.bs.js";
import * as TrackLiveTripFormUtils$FrontendWebsite from "../../../../utils/TrackLiveTripFormUtils.bs.js";
import * as GoogleAutoCompleteInputSimplified$FrontendWebsite from "../../../home/components/GoogleAutoCompleteInputSimplified.bs.js";

function getInputClass(base_class_name, input) {
  if (Belt_Option.mapWithDefault(input, false, Belt_Result.isError)) {
    return base_class_name + " trip-estimate__error-box";
  } else {
    return base_class_name;
  }
}

function TripEstimateCmp(Props) {
  var initialCity = Props.initialCity;
  var setCity = Props.setCity;
  var bounds = Props.bounds;
  var get_trip_estimate = Props.get_trip_estimate;
  var event_category = Props.event_category;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var HomeHelper = HomeHelper$FrontendWebsite.Helper(Content.Home);
  var cookieConsent = CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setIsSubmitting = match[1];
  var isSubmitting = match[0];
  var invalidLocation = {
    lat: 1000.0,
    lng: 1000.0
  };
  var citiesSelectComponentList = Curry._2(HomeHelper.getSelectComponentItems, CityCookieHook$FrontendWebsite.cities, (function (value) {
          return [
                  value,
                  value
                ];
        }));
  var requirementList = Curry._2(HomeHelper.getSelectComponentItems, {
        hd: "Personal",
        tl: {
          hd: "Business",
          tl: /* [] */0
        }
      }, (function (value) {
          return [
                  value,
                  Curry._1(Content.Home.Select.getRequirementStrings, value)
                ];
        }));
  var isd_code = Curry._1(HomeHelper.getISDCode, country);
  var autocomp_restrictions = Curry._1(HomeHelper.getAutoCompRestrictions, country);
  var consent_display_text = Content.Home.form_consent_box_title;
  var validators_requirement = {
    strategy: /* OnSubmit */4,
    validate: (function (param) {
        var requirement = param.requirement;
        if (requirement !== undefined) {
          return {
                  TAG: /* Ok */0,
                  _0: requirement.value
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Select requirement type"
                };
        }
      })
  };
  var validators_consent_for_callback = {
    strategy: /* OnSubmit */4,
    validate: (function (param) {
        var consent_for_callback = param.consent_for_callback;
        if (consent_for_callback) {
          return {
                  TAG: /* Ok */0,
                  _0: consent_for_callback
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: ""
                };
        }
      })
  };
  var validators_mobile = {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        var mobile = param.mobile;
        if (mobile.length === 0) {
          return {
                  TAG: /* Error */1,
                  _0: "Enter your mobile number"
                };
        }
        var _error = CommonHelper$FrontendWebsite.validateNumber(mobile, country);
        if (_error.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: _error._0
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Enter valid mobile number"
                };
        }
      })
  };
  var validators_dropoffAddress = {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        var dropoffAddress = param.dropoffAddress;
        if (dropoffAddress.landmark.length !== 0) {
          if (dropoffAddress.location.lat > 0.0 && dropoffAddress.location.lat < 1000.0) {
            if (dropoffAddress.location.lat > bounds.sw.lat && dropoffAddress.location.lng > bounds.sw.lng && dropoffAddress.location.lat < bounds.ne.lat && dropoffAddress.location.lng < bounds.ne.lng) {
              return {
                      TAG: /* Ok */0,
                      _0: dropoffAddress
                    };
            } else {
              return {
                      TAG: /* Error */1,
                      _0: "Location is not within city bounds"
                    };
            }
          } else {
            return {
                    TAG: /* Error */1,
                    _0: "Select valid address"
                  };
          }
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Enter dropoff address"
                };
        }
      })
  };
  var validators_pickupAddress = {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        var pickupAddress = param.pickupAddress;
        if (pickupAddress.landmark.length !== 0) {
          if (pickupAddress.location.lat > 0.0 && pickupAddress.location.lat < 1000.0) {
            if (pickupAddress.location.lat > bounds.sw.lat && pickupAddress.location.lng > bounds.sw.lng && pickupAddress.location.lat < bounds.ne.lat && pickupAddress.location.lng < bounds.ne.lng) {
              return {
                      TAG: /* Ok */0,
                      _0: pickupAddress
                    };
            } else {
              return {
                      TAG: /* Error */1,
                      _0: "Location is not within city bounds"
                    };
            }
          } else {
            return {
                    TAG: /* Error */1,
                    _0: "Select valid address"
                  };
          }
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Enter pickup address"
                };
        }
      })
  };
  var validators_city = {
    strategy: /* OnSubmit */4,
    validate: (function (param) {
        var city = param.city;
        if (city !== undefined) {
          return {
                  TAG: /* Ok */0,
                  _0: city.value
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Please select a city"
                };
        }
      })
  };
  var validators = {
    requirement: validators_requirement,
    consent_for_callback: validators_consent_for_callback,
    mobile: validators_mobile,
    name: undefined,
    dropoffAddress: validators_dropoffAddress,
    pickupAddress: validators_pickupAddress,
    city: validators_city
  };
  var initialFieldsStatuses = function (_input) {
    return {
            requirement: /* Pristine */0,
            consent_for_callback: /* Pristine */0,
            mobile: /* Pristine */0,
            name: /* Pristine */0,
            dropoffAddress: /* Pristine */0,
            pickupAddress: /* Pristine */0,
            city: /* Pristine */0
          };
  };
  var initialState = function (input) {
    return {
            input: input,
            fieldsStatuses: initialFieldsStatuses(input),
            collectionsStatuses: undefined,
            formStatus: /* Editing */0,
            submissionStatus: /* NeverSubmitted */0
          };
  };
  var validateForm = function (input, validators, fieldsStatuses) {
    var match = fieldsStatuses.requirement;
    var match_0 = match ? match._0 : Curry._1(validators.requirement.validate, input);
    var match$1 = fieldsStatuses.consent_for_callback;
    var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.consent_for_callback.validate, input);
    var match$2 = fieldsStatuses.mobile;
    var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.mobile.validate, input);
    var match_0$3 = {
      TAG: /* Ok */0,
      _0: input.name
    };
    var match$3 = fieldsStatuses.dropoffAddress;
    var match_0$4 = match$3 ? match$3._0 : Curry._1(validators.dropoffAddress.validate, input);
    var match$4 = fieldsStatuses.pickupAddress;
    var match_0$5 = match$4 ? match$4._0 : Curry._1(validators.pickupAddress.validate, input);
    var match$5 = fieldsStatuses.city;
    var match_0$6 = match$5 ? match$5._0 : Curry._1(validators.city.validate, input);
    var requirementResult = match_0;
    var requirementResult$1;
    if (requirementResult.TAG === /* Ok */0) {
      var consent_for_callbackResult = match_0$1;
      if (consent_for_callbackResult.TAG === /* Ok */0) {
        var mobileResult = match_0$2;
        if (mobileResult.TAG === /* Ok */0) {
          var nameResult = match_0$3;
          if (nameResult.TAG === /* Ok */0) {
            var dropoffAddressResult = match_0$4;
            if (dropoffAddressResult.TAG === /* Ok */0) {
              var pickupAddressResult = match_0$5;
              if (pickupAddressResult.TAG === /* Ok */0) {
                var cityResult = match_0$6;
                if (cityResult.TAG === /* Ok */0) {
                  return {
                          TAG: /* Valid */0,
                          output: {
                            city: cityResult._0,
                            pickupAddress: pickupAddressResult._0,
                            dropoffAddress: dropoffAddressResult._0,
                            name: nameResult._0,
                            mobile: mobileResult._0,
                            consent_for_callback: consent_for_callbackResult._0,
                            requirement: requirementResult._0
                          },
                          fieldsStatuses: {
                            requirement: /* Dirty */{
                              _0: requirementResult,
                              _1: /* Shown */0
                            },
                            consent_for_callback: /* Dirty */{
                              _0: consent_for_callbackResult,
                              _1: /* Shown */0
                            },
                            mobile: /* Dirty */{
                              _0: mobileResult,
                              _1: /* Shown */0
                            },
                            name: /* Dirty */{
                              _0: nameResult,
                              _1: /* Hidden */1
                            },
                            dropoffAddress: /* Dirty */{
                              _0: dropoffAddressResult,
                              _1: /* Shown */0
                            },
                            pickupAddress: /* Dirty */{
                              _0: pickupAddressResult,
                              _1: /* Shown */0
                            },
                            city: /* Dirty */{
                              _0: cityResult,
                              _1: /* Shown */0
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                requirementResult$1 = requirementResult;
              } else {
                requirementResult$1 = requirementResult;
              }
            } else {
              requirementResult$1 = requirementResult;
            }
          } else {
            requirementResult$1 = requirementResult;
          }
        } else {
          requirementResult$1 = requirementResult;
        }
      } else {
        requirementResult$1 = requirementResult;
      }
    } else {
      requirementResult$1 = requirementResult;
    }
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              requirement: /* Dirty */{
                _0: requirementResult$1,
                _1: /* Shown */0
              },
              consent_for_callback: /* Dirty */{
                _0: match_0$1,
                _1: /* Shown */0
              },
              mobile: /* Dirty */{
                _0: match_0$2,
                _1: /* Shown */0
              },
              name: /* Dirty */{
                _0: match_0$3,
                _1: /* Hidden */1
              },
              dropoffAddress: /* Dirty */{
                _0: match_0$4,
                _1: /* Shown */0
              },
              pickupAddress: /* Dirty */{
                _0: match_0$5,
                _1: /* Shown */0
              },
              city: /* Dirty */{
                _0: match_0$6,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  };
  var useForm = function (initialInput, onSubmit) {
    var memoizedInitialState = React.useMemo((function () {
            return initialState(initialInput);
          }), [initialInput]);
    var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
            if (typeof action === "number") {
              switch (action) {
                case /* BlurRequirementField */0 :
                    var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.requirement, validators_requirement, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: status,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: init.mobile,
                                    name: init.name,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: init.pickupAddress,
                                    city: init.city
                                  };
                          }));
                    if (result !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurConsent_for_callbackField */1 :
                    var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.consent_for_callback, validators_consent_for_callback, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: status,
                                    mobile: init.mobile,
                                    name: init.name,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: init.pickupAddress,
                                    city: init.city
                                  };
                          }));
                    if (result$1 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurMobileField */2 :
                    var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.mobile, validators_mobile, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: status,
                                    name: init.name,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: init.pickupAddress,
                                    city: init.city
                                  };
                          }));
                    if (result$2 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$2,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurNameField */3 :
                    var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.name, state.fieldsStatuses.name, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: init.mobile,
                                    name: status,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: init.pickupAddress,
                                    city: init.city
                                  };
                          }));
                    if (result$3 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$3,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurDropoffAddressField */4 :
                    var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dropoffAddress, validators_dropoffAddress, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: init.mobile,
                                    name: init.name,
                                    dropoffAddress: status,
                                    pickupAddress: init.pickupAddress,
                                    city: init.city
                                  };
                          }));
                    if (result$4 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$4,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurPickupAddressField */5 :
                    var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.pickupAddress, validators_pickupAddress, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: init.mobile,
                                    name: init.name,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: status,
                                    city: init.city
                                  };
                          }));
                    if (result$5 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$5,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurCityField */6 :
                    var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    requirement: init.requirement,
                                    consent_for_callback: init.consent_for_callback,
                                    mobile: init.mobile,
                                    name: init.name,
                                    dropoffAddress: init.dropoffAddress,
                                    pickupAddress: init.pickupAddress,
                                    city: status
                                  };
                          }));
                    if (result$6 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$6,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* Submit */7 :
                    var match = state.formStatus;
                    if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                      return /* NoUpdate */0;
                    }
                    var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                    if (match$1.TAG !== /* Valid */0) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: match$1.fieldsStatuses,
                                collectionsStatuses: match$1.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: /* AttemptedToSubmit */1
                              }
                            };
                    }
                    var output = match$1.output;
                    var error = state.formStatus;
                    var tmp;
                    tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: tmp
                              },
                              submissionStatus: /* AttemptedToSubmit */1
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                return Curry._2(onSubmit, output, {
                                            notifyOnSuccess: (function (input) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetSubmittedStatus */7,
                                                            _0: input
                                                          });
                                              }),
                                            notifyOnFailure: (function (error) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetSubmissionFailedStatus */8,
                                                            _0: error
                                                          });
                                              }),
                                            reset: (function (param) {
                                                return Curry._1(dispatch, /* Reset */10);
                                              }),
                                            dismissSubmissionResult: (function (param) {
                                                return Curry._1(dispatch, /* DismissSubmissionResult */9);
                                              })
                                          });
                              })
                          };
                    break;
                case /* DismissSubmissionError */8 :
                    var match$2 = state.formStatus;
                    if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                      return /* NoUpdate */0;
                    } else {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                case /* DismissSubmissionResult */9 :
                    var match$3 = state.formStatus;
                    if (typeof match$3 === "number") {
                      if (match$3 === /* Editing */0) {
                        return /* NoUpdate */0;
                      }
                      
                    } else if (match$3.TAG === /* Submitting */0) {
                      return /* NoUpdate */0;
                    }
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* Reset */10 :
                    return {
                            TAG: /* Update */0,
                            _0: initialState(initialInput)
                          };
                
              }
            } else {
              switch (action.TAG | 0) {
                case /* UpdateRequirementField */0 :
                    var nextInput = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.requirement, state.submissionStatus, validators_requirement, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: status,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: init.mobile,
                                              name: init.name,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: init.pickupAddress,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateConsent_for_callbackField */1 :
                    var nextInput$1 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$1,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.consent_for_callback, state.submissionStatus, validators_consent_for_callback, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: status,
                                              mobile: init.mobile,
                                              name: init.name,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: init.pickupAddress,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateMobileField */2 :
                    var nextInput$2 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$2,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.mobile, state.submissionStatus, validators_mobile, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: status,
                                              name: init.name,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: init.pickupAddress,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateNameField */3 :
                    var nextInput$3 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$3,
                              fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.name, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: init.mobile,
                                              name: status,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: init.pickupAddress,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateDropoffAddressField */4 :
                    var nextInput$4 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$4,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.dropoffAddress, state.submissionStatus, validators_dropoffAddress, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: init.mobile,
                                              name: init.name,
                                              dropoffAddress: status,
                                              pickupAddress: init.pickupAddress,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdatePickupAddressField */5 :
                    var nextInput$5 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$5,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.pickupAddress, state.submissionStatus, validators_pickupAddress, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: init.mobile,
                                              name: init.name,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: status,
                                              city: init.city
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateCityField */6 :
                    var nextInput$6 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$6,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              requirement: init.requirement,
                                              consent_for_callback: init.consent_for_callback,
                                              mobile: init.mobile,
                                              name: init.name,
                                              dropoffAddress: init.dropoffAddress,
                                              pickupAddress: init.pickupAddress,
                                              city: status
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* SetSubmittedStatus */7 :
                    var input = action._0;
                    if (input !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: input,
                                fieldsStatuses: initialFieldsStatuses(input),
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: initialFieldsStatuses(state.input),
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                case /* SetSubmissionFailedStatus */8 :
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: action._0
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* MapSubmissionError */9 :
                    var map = action._0;
                    var error$1 = state.formStatus;
                    if (typeof error$1 === "number") {
                      return /* NoUpdate */0;
                    }
                    if (error$1.TAG !== /* Submitting */0) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: {
                                  TAG: /* SubmissionFailed */1,
                                  _0: Curry._1(map, error$1._0)
                                },
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                    var error$2 = error$1._0;
                    if (error$2 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: {
                                  TAG: /* Submitting */0,
                                  _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                                },
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                
              }
            }
          }));
    var dispatch = match[1];
    var state = match[0];
    var match$1 = state.formStatus;
    var tmp;
    tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
    return {
            updateRequirement: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateRequirementField */0,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateConsent_for_callback: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateConsent_for_callbackField */1,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateMobile: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateMobileField */2,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateName: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateNameField */3,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateDropoffAddress: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateDropoffAddressField */4,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updatePickupAddress: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdatePickupAddressField */5,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateCity: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateCityField */6,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            blurRequirement: (function (param) {
                return Curry._1(dispatch, /* BlurRequirementField */0);
              }),
            blurConsent_for_callback: (function (param) {
                return Curry._1(dispatch, /* BlurConsent_for_callbackField */1);
              }),
            blurMobile: (function (param) {
                return Curry._1(dispatch, /* BlurMobileField */2);
              }),
            blurName: (function (param) {
                return Curry._1(dispatch, /* BlurNameField */3);
              }),
            blurDropoffAddress: (function (param) {
                return Curry._1(dispatch, /* BlurDropoffAddressField */4);
              }),
            blurPickupAddress: (function (param) {
                return Curry._1(dispatch, /* BlurPickupAddressField */5);
              }),
            blurCity: (function (param) {
                return Curry._1(dispatch, /* BlurCityField */6);
              }),
            requirementResult: Formality.exposeFieldResult(state.fieldsStatuses.requirement),
            consent_for_callbackResult: Formality.exposeFieldResult(state.fieldsStatuses.consent_for_callback),
            mobileResult: Formality.exposeFieldResult(state.fieldsStatuses.mobile),
            nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
            dropoffAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.dropoffAddress),
            pickupAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.pickupAddress),
            cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
            input: state.input,
            status: state.formStatus,
            dirty: (function (param) {
                var match = state.fieldsStatuses;
                if (match.requirement || match.consent_for_callback || match.mobile || match.name || match.dropoffAddress || match.pickupAddress || match.city) {
                  return true;
                } else {
                  return false;
                }
              }),
            valid: (function (param) {
                var match = validateForm(state.input, validators, state.fieldsStatuses);
                if (match.TAG === /* Valid */0) {
                  return true;
                } else {
                  return false;
                }
              }),
            submitting: tmp,
            submit: (function (param) {
                return Curry._1(dispatch, /* Submit */7);
              }),
            dismissSubmissionError: (function (param) {
                return Curry._1(dispatch, /* DismissSubmissionError */8);
              }),
            dismissSubmissionResult: (function (param) {
                return Curry._1(dispatch, /* DismissSubmissionResult */9);
              }),
            mapSubmissionError: (function (map) {
                return Curry._1(dispatch, {
                            TAG: /* MapSubmissionError */9,
                            _0: map
                          });
              }),
            reset: (function (param) {
                return Curry._1(dispatch, /* Reset */10);
              })
          };
  };
  var publishConsentForCallBackEvent = function (output) {
    return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.consentBoxChacked, {
                event_category: event_category,
                event_label: EventsHelper$FrontendWebsite.getNumberedEventLabel({
                      hd: output.name,
                      tl: {
                        hd: output.mobile,
                        tl: {
                          hd: "frequency-personal",
                          tl: /* [] */0
                        }
                      }
                    }),
                language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
              });
  };
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        tmp = true;
        break;
    case /* TR */2 :
        tmp = false;
        break;
    
  }
  var form = useForm({
        city: initialCity !== undefined ? List.find_opt((function (selectOpt) {
                  return selectOpt.key === initialCity.toLowerCase();
                }), citiesSelectComponentList) : undefined,
        pickupAddress: {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        },
        dropoffAddress: {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        },
        name: "",
        mobile: "",
        consent_for_callback: tmp,
        requirement: undefined
      }, (function (output, param) {
          if (cookieConsent.neccesary) {
            
          } else {
            CookieConsentHelper$FrontendWebsite.acceptAll(undefined);
          }
          if (output.consent_for_callback) {
            publishConsentForCallBackEvent(output);
          }
          Curry._2(get_trip_estimate, {
                geo_region: String(CityHelper$FrontendWebsite.cityToGeoRegionId(output.city, country)),
                pickup_address: output.pickupAddress,
                drop_address: output.dropoffAddress,
                name: output.name,
                mobile: output.mobile,
                requirement: output.requirement
              }, output.consent_for_callback);
          return Curry._1(setIsSubmitting, (function (param) {
                        return true;
                      }));
        }));
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setIsSubmitting, (function (param) {
                                return false;
                              }));
                }), 5000);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), [isSubmitting]);
  var publishInvalidAddressEvent = function (addresType, address) {
    return GAEvents$FrontendWebsite.publishEventParameter(Curry._1(GAConfigActions.autoCompleteInvalidAddress, addresType), {
                event_category: event_category,
                event_label: address,
                language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
              });
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPickUpError = match$1[1];
  var showPickUpError = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowDropOffError = match$2[1];
  var showDropOffError = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setConsentError = match$3[1];
  var showConsentError = match$3[0];
  React.useEffect((function () {
          if (form.input.pickupAddress.landmark.length !== 0) {
            if (Caml_obj.caml_equal(form.input.pickupAddress.location, invalidLocation)) {
              publishInvalidAddressEvent("Pickup", form.input.pickupAddress.landmark);
              Curry._1(setShowPickUpError, (function (param) {
                      return true;
                    }));
              JsBindings$FrontendWebsite.switchDisplay(".pickup-address > .input-error-container", 0, false);
              JsBindings$FrontendWebsite.switchDisplay(".pac-container", 0, true);
            } else if (showPickUpError) {
              Curry._1(setShowPickUpError, (function (param) {
                      return false;
                    }));
              JsBindings$FrontendWebsite.switchDisplay(".pickup-address > .input-error-container", 0, true);
            }
            
          } else {
            Curry._1(setShowPickUpError, (function (param) {
                    return false;
                  }));
          }
          
        }), [form.input.pickupAddress]);
  React.useEffect((function () {
          if (form.input.dropoffAddress.landmark.length !== 0) {
            if (Caml_obj.caml_equal(form.input.dropoffAddress.location, invalidLocation)) {
              publishInvalidAddressEvent("Dropoff", form.input.dropoffAddress.landmark);
              Curry._1(setShowDropOffError, (function (param) {
                      return true;
                    }));
              JsBindings$FrontendWebsite.switchDisplay(".dropoff-address > .input-error-container", 0, false);
              JsBindings$FrontendWebsite.switchDisplay(".pac-container", 1, true);
            } else if (showDropOffError) {
              Curry._1(setShowDropOffError, (function (param) {
                      return false;
                    }));
              JsBindings$FrontendWebsite.switchDisplay(".dropoff-address > .input-error-container", 0, true);
            }
            
          } else {
            Curry._1(setShowDropOffError, (function (param) {
                    return false;
                  }));
          }
          
        }), [form.input.dropoffAddress]);
  React.useEffect((function () {
          if (showConsentError) {
            Curry._1(setConsentError, (function (param) {
                    return !form.input.consent_for_callback;
                  }));
          }
          
        }), [form.input.consent_for_callback]);
  return React.createElement(React.Fragment, undefined, isSubmitting ? React.createElement("div", {
                    className: "modal-content-overlay"
                  }, React.createElement(CircularLoader$Catalyst.make, {})) : null, showPickUpError ? React.createElement("div", {
                    className: "error-container-bubble"
                  }, React.createElement("div", {
                        className: "error"
                      }, "Select from suggestions")) : null, showDropOffError ? React.createElement("div", {
                    className: "error-container-bubble error-container-bubble__drop-off"
                  }, React.createElement("div", {
                        className: "error"
                      }, "Select from suggestions")) : null, React.createElement("form", {
                  className: "trip-estimate-form",
                  autoComplete: "off",
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      
                    })
                }, React.createElement(FormSelectInput$Catalyst.make, {
                      input_props: FormInput$Catalyst.make_props(Content.Home.form_heading_1, (function ($$event) {
                              Curry._2(form.updateCity, (function (input, value) {
                                      return {
                                              city: value,
                                              pickupAddress: input.pickupAddress,
                                              dropoffAddress: input.dropoffAddress,
                                              name: input.name,
                                              mobile: input.mobile,
                                              consent_for_callback: input.consent_for_callback,
                                              requirement: input.requirement
                                            };
                                    }), SelectComponent$Catalyst.getSelectedItem(FormHelper$Catalyst.TargetEventWrapper.value($$event), citiesSelectComponentList));
                              GAEvents$FrontendWebsite.publishEventParameter("City Change Selector", {
                                    event_category: event_category,
                                    event_label: "Get Trip Estimate Form",
                                    event_value: CityHelper$FrontendWebsite.cityToGeoRegionId(FormHelper$Catalyst.TargetEventWrapper.value($$event), country),
                                    event_label: "Get Trip Estimate Form",
                                    language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                  });
                              return Curry._1(setCity, FormHelper$Catalyst.TargetEventWrapper.value($$event));
                            }), undefined, undefined, Content.Home.select_city_label, undefined, undefined, undefined, form.cityResult, undefined, getInputClass("trip-estimate__input", form.cityResult), undefined, undefined, undefined, undefined, undefined),
                      select_props: {
                        value: form.input.city,
                        items: citiesSelectComponentList,
                        isNoneRequired: false
                      }
                    }), React.createElement(GoogleAutoCompleteInputSimplified$FrontendWebsite.make, {
                      autocomplete_props: GoogleAutoCompleteInputSimplified$FrontendWebsite.GoogleAutoCompleteInputSimplifiedProps.make_props({
                            googleMapsApiKey: Config$FrontendWebsite.Env.getGoogleMapApiKey(undefined),
                            libraries: Config$FrontendWebsite.Env.libraries
                          }, {
                            hd: {
                              label: Content.Home.form_heading_2,
                              placeholder: Content.Home.pickup_address_placeholder,
                              className: getInputClass("trip-estimate__input", form.pickupAddressResult),
                              onChange: (function (addressVal, _postalAddress) {
                                  return Curry._2(form.updatePickupAddress, (function (input, value) {
                                                return {
                                                        city: input.city,
                                                        pickupAddress: value,
                                                        dropoffAddress: input.dropoffAddress,
                                                        name: input.name,
                                                        mobile: input.mobile,
                                                        consent_for_callback: input.consent_for_callback,
                                                        requirement: input.requirement
                                                      };
                                              }), addressVal);
                                }),
                              value: form.input.pickupAddress.landmark,
                              result: form.pickupAddressResult
                            },
                            tl: {
                              hd: {
                                label: Content.Home.form_heading_3,
                                placeholder: Content.Home.dropoff_address_placeholder,
                                className: getInputClass("trip-estimate__input", form.dropoffAddressResult),
                                onChange: (function (addressVal, _postalAddress) {
                                    return Curry._2(form.updateDropoffAddress, (function (input, value) {
                                                  return {
                                                          city: input.city,
                                                          pickupAddress: input.pickupAddress,
                                                          dropoffAddress: value,
                                                          name: input.name,
                                                          mobile: input.mobile,
                                                          consent_for_callback: input.consent_for_callback,
                                                          requirement: input.requirement
                                                        };
                                                }), addressVal);
                                  }),
                                value: form.input.dropoffAddress.landmark,
                                result: form.dropoffAddressResult
                              },
                              tl: /* [] */0
                            }
                          }, bounds, undefined, undefined, undefined, autocomp_restrictions, undefined)
                    }), React.createElement(FormTextInput$Catalyst.make, {
                      input_props: FormInput$Catalyst.make_props(Content.Home.form_heading_4, (function ($$event) {
                              return Curry._2(form.updateMobile, (function (input, value) {
                                            return {
                                                    city: input.city,
                                                    pickupAddress: input.pickupAddress,
                                                    dropoffAddress: input.dropoffAddress,
                                                    name: input.name,
                                                    mobile: TrackLiveTripFormUtils$FrontendWebsite.mobileConverter(value),
                                                    consent_for_callback: input.consent_for_callback,
                                                    requirement: input.requirement
                                                  };
                                          }), FormHelper$Catalyst.TargetEventWrapper.value($$event));
                            }), undefined, undefined, Content.Home.form_heading_5, undefined, undefined, undefined, form.mobileResult, undefined, getInputClass("trip-estimate__input", form.mobileResult), undefined, undefined, {
                            className: "form-input-text ",
                            startAdornment: React.createElement("div", {
                                  className: "text-input-adornment"
                                }, isd_code + " - ")
                          }, undefined, undefined),
                      textinput_props: {
                        value: form.input.mobile
                      }
                    }), React.createElement(FormTextInput$Catalyst.make, {
                      input_props: FormInput$Catalyst.make_props(Content.Home.form_heading_6, (function ($$event) {
                              return Curry._2(form.updateName, (function (input, value) {
                                            return {
                                                    city: input.city,
                                                    pickupAddress: input.pickupAddress,
                                                    dropoffAddress: input.dropoffAddress,
                                                    name: value,
                                                    mobile: input.mobile,
                                                    consent_for_callback: input.consent_for_callback,
                                                    requirement: input.requirement
                                                  };
                                          }), FormHelper$Catalyst.TargetEventWrapper.value($$event));
                            }), undefined, undefined, Content.Home.optional_placeholder, undefined, undefined, undefined, form.nameResult, undefined, Curry._2(HomeHelper.getInputClass, "modal-form__input", form.nameResult), undefined, undefined, undefined, undefined, undefined),
                      textinput_props: {
                        value: form.input.name
                      }
                    }), React.createElement(FormSelectInput$Catalyst.make, {
                      input_props: FormInput$Catalyst.make_props(Content.Home.form_heading_7, (function ($$event) {
                              return Curry._2(form.updateRequirement, (function (input, value) {
                                            return {
                                                    city: input.city,
                                                    pickupAddress: input.pickupAddress,
                                                    dropoffAddress: input.dropoffAddress,
                                                    name: input.name,
                                                    mobile: input.mobile,
                                                    consent_for_callback: input.consent_for_callback,
                                                    requirement: value
                                                  };
                                          }), SelectComponent$Catalyst.getSelectedItem(FormHelper$Catalyst.TargetEventWrapper.value($$event), requirementList));
                            }), undefined, undefined, "Select Requirement", undefined, undefined, undefined, form.requirementResult, undefined, getInputClass("trip-estimate__input", form.requirementResult), undefined, undefined, undefined, undefined, undefined),
                      select_props: {
                        value: form.input.requirement,
                        items: requirementList,
                        isNoneRequired: false
                      }
                    }), React.createElement("div", {
                      className: "consent-input"
                    }, React.createElement(CheckBox$Catalyst.make, {
                          is_selected: form.input.consent_for_callback,
                          onClick: Curry._1(form.updateConsent_for_callback, (function (input, value) {
                                  return {
                                          city: input.city,
                                          pickupAddress: input.pickupAddress,
                                          dropoffAddress: input.dropoffAddress,
                                          name: input.name,
                                          mobile: input.mobile,
                                          consent_for_callback: value,
                                          requirement: input.requirement
                                        };
                                })),
                          class_name: "consent-input__checkbox",
                          text: ""
                        }), React.createElement("div", {
                          className: "consent-input__text " + (
                            showConsentError ? "error" : ""
                          )
                        }, consent_display_text)), React.createElement(RoundedPrimaryButton$Catalyst.make, {
                      buttonProps: ButtonUtils$Catalyst.getDefaultButtonProps({
                            TAG: /* Text */0,
                            _0: Content.Home.form_button_title
                          }, undefined, (function (param) {
                              var match = UtmUtils$FrontendWebsite.getUtmString(undefined);
                              GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.tripEstimateClick, {
                                    event_category: WebsiteConfig$FrontendWebsite.ga_title_home,
                                    event_label: EventsHelper$FrontendWebsite.getNumberedEventLabel({
                                          hd: form.input.name,
                                          tl: {
                                            hd: form.input.mobile,
                                            tl: {
                                              hd: "frequency-personal",
                                              tl: {
                                                hd: match[0],
                                                tl: {
                                                  hd: match[1],
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                  });
                              Curry._1(setConsentError, (function (param) {
                                      return !form.input.consent_for_callback;
                                    }));
                              Curry._1(setShowPickUpError, (function (param) {
                                      return false;
                                    }));
                              JsBindings$FrontendWebsite.switchDisplay(".pickup-address > .input-error-container", 0, true);
                              JsBindings$FrontendWebsite.switchDisplay(".dropoff-address > .input-error-container", 0, true);
                              Curry._1(setShowDropOffError, (function (param) {
                                      return false;
                                    }));
                              return Curry._1(form.submit, undefined);
                            }), undefined, undefined, undefined, undefined, "estimate-form-button " + (
                            form.input.consent_for_callback ? "" : "disabled"
                          ), undefined, undefined)
                    })));
}

var make = TripEstimateCmp;

export {
  getInputClass ,
  make ,
  
}
/* react Not a pure module */
