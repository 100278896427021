// Generated by ReScript, PLEASE EDIT WITH CARE


function createUrl(a) {
  return a;
}

function createAssetPath(a) {
  return a;
}

function createTitle(a) {
  return a;
}

function createPlainText(a) {
  return a;
}

function createTel(a) {
  return a;
}

function titleToString(a) {
  return a;
}

function urltoString(a) {
  return a;
}

function assetPathToString(a) {
  return a;
}

function telToString(a) {
  return a;
}

export {
  createUrl ,
  createAssetPath ,
  createTitle ,
  createPlainText ,
  createTel ,
  titleToString ,
  urltoString ,
  assetPathToString ,
  telToString ,
  
}
/* No side effect */
