// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SentryJs from "./Sentry.js";

function init(prim) {
  SentryJs.init(prim);
  
}

function captureException(prim) {
  return SentryJs.captureException(prim);
}

function captureExceptionWithContext(prim0, prim1) {
  return SentryJs.captureExceptionWithContext(prim0, prim1);
}

function captureMessage(prim) {
  return SentryJs.captureMessage(prim);
}

function captureMessageWithContext(prim0, prim1, prim2) {
  return SentryJs.captureMessageWithContext(prim0, prim1, prim2);
}

function capturePromiseError(prim) {
  return SentryJs.capturePromiseError(prim);
}

function capturePromiseErrorWithContext(prim0, prim1) {
  return SentryJs.capturePromiseErrorWithContext(prim0, prim1);
}

function setTag(prim0, prim1) {
  SentryJs.setTag(prim0, prim1);
  
}

function setContext(prim0, prim1) {
  SentryJs.setContext(prim0, prim1);
  
}

function withProfiler(prim) {
  return SentryJs.withProfiler(prim);
}

export {
  init ,
  captureException ,
  captureExceptionWithContext ,
  captureMessage ,
  captureMessageWithContext ,
  capturePromiseError ,
  capturePromiseErrorWithContext ,
  setTag ,
  setContext ,
  withProfiler ,
  
}
/* ./Sentry.js Not a pure module */
