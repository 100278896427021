// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as MapMod$FrontendWebsite from "../../../utils/services/maps/types/MapMod.bs.js";
import * as MarkerMod$FrontendWebsite from "../../../utils/services/maps/types/MarkerMod.bs.js";
import * as ReasonReactUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ReasonReactUtils.bs.js";
import * as OverlayViewMod$FrontendWebsite from "../../../utils/services/maps/types/OverlayViewMod.bs.js";
import * as MapComponentMod$FrontendWebsite from "../../../utils/services/maps/views/MapComponentMod.bs.js";
import * as MarkerComponentMod$FrontendWebsite from "../../../utils/services/maps/views/MarkerComponentMod.bs.js";
import * as OverlayComponentMod$FrontendWebsite from "../../../utils/services/maps/views/OverlayComponentMod.bs.js";
import * as TrackLiveTripConfig$FrontendWebsite from "../TrackLiveTripConfig.bs.js";
import * as TrackLiveTripMapVMMapper$FrontendWebsite from "../TrackLiveTripMapVMMapper.bs.js";

function getIconPath(icon_data) {
  if (icon_data.TAG === /* Img */0) {
    return icon_data._0;
  } else {
    return "";
  }
}

function getMarkerComponent(marker, key) {
  return React.createElement(MarkerComponentMod$FrontendWebsite.make, {
              config: MarkerMod$FrontendWebsite.make_props(marker.location, undefined, undefined, undefined, getIconPath(marker.icon_data), undefined, undefined, undefined),
              children: null,
              key: key
            });
}

function TrackLiveTripMapCmp(Props) {
  var order_detail = Props.order_detail;
  var vmObj = TrackLiveTripMapVMMapper$FrontendWebsite.getMapVM(order_detail);
  var match = React.useState(function () {
        return null;
      });
  var setMap = match[1];
  var map = match[0];
  var match$1 = React.useState(function () {
        return vmObj.centerLocation;
      });
  var setStoreCenter = match$1[1];
  var onLoad = function (map_inst) {
    var bounds = TrackLiveTripMapVMMapper$FrontendWebsite.getBounds(order_detail);
    map_inst.fitBounds(bounds, TrackLiveTripConfig$FrontendWebsite.google_map_padding);
    return Curry._1(setMap, (function (param) {
                  return map_inst;
                }));
  };
  var getPixelPositionOffset = function (width, height) {
    return {
            x: -(width / 2 | 0) | 0,
            y: -height | 0
          };
  };
  var onDragEnd = function (param) {
    if (Caml_obj.caml_notequal(map, null)) {
      return Curry._1(setStoreCenter, (function (param) {
                    return {
                            lat: Curry._1(map.getCenter().lat, undefined),
                            lng: Curry._1(map.getCenter().lng, undefined)
                          };
                  }));
    }
    
  };
  var data = vmObj.partnerMarkerOptions;
  var tmp;
  if (data !== undefined) {
    var elem = data.icon_data;
    var tmp$1;
    tmp$1 = elem.TAG === /* Img */0 ? elem._0 : "";
    tmp = React.createElement(OverlayComponentMod$FrontendWebsite.make, {
          config: OverlayViewMod$FrontendWebsite.make_props(data.location, undefined, undefined, Caml_option.some(getPixelPositionOffset), undefined),
          children: React.createElement("img", {
                className: "partner-icon",
                src: tmp$1
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement(MapComponentMod$FrontendWebsite.make, {
              config: MapMod$FrontendWebsite.make_props("track_map", match$1[0], 11, undefined, undefined, "track-live-trip-map__canvas", undefined, undefined, undefined, undefined, onLoad, undefined, undefined, undefined, onDragEnd, undefined),
              children: React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "current-location-button",
                        onClick: (function (param) {
                            var bounds = TrackLiveTripMapVMMapper$FrontendWebsite.getBounds(order_detail);
                            map.fitBounds(bounds, TrackLiveTripConfig$FrontendWebsite.google_map_padding);
                            map.panTo(vmObj.centerLocation);
                            
                          })
                      }, React.createElement("img", {
                            className: "current-location-button-icon",
                            src: TrackLiveTripConfig$FrontendWebsite.current_location_icon
                          })), getMarkerComponent(vmObj.pickupMarkerOptions, "pickup"), Belt_Option.mapWithDefault(vmObj.dropMarkerOptions, null, (function (marker) {
                          return getMarkerComponent(marker, "drop");
                        })), tmp, ReasonReactUtils$Catalyst.listToReactArray(List.mapi((function (idx, waypoint) {
                              var elem = waypoint.icon_data;
                              var tmp;
                              tmp = elem.TAG === /* Img */0 ? null : elem._0;
                              return React.createElement(OverlayComponentMod$FrontendWebsite.make, {
                                          config: OverlayViewMod$FrontendWebsite.make_props(waypoint.location, undefined, undefined, Caml_option.some(getPixelPositionOffset), undefined),
                                          children: tmp,
                                          key: "waypoint" + String(idx)
                                        });
                            }), vmObj.waypointsMarkerOptions)))
            });
}

var make = TrackLiveTripMapCmp;

export {
  getIconPath ,
  getMarkerComponent ,
  make ,
  
}
/* react Not a pure module */
