// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as H2TitleCmp$FrontendWebsite from "../../../website/view/components/H2TitleCmp.bs.js";
import * as ServiceableCitiesMobileCmp$FrontendWebsite from "../../../website/view/components/mobile/ServiceableCitiesMobileCmp.bs.js";

function VehicleServiceableCitiesMobileCmp(Props) {
  var serviceableCitiesSectionData = Props.serviceableCitiesSectionData;
  return React.createElement("div", {
              className: "vehicle-mobile-serviceable-cities"
            }, React.createElement(H2TitleCmp$FrontendWebsite.make, {
                  title: $$String.uppercase_ascii(serviceableCitiesSectionData.title),
                  class_name: "mobile-title-heading"
                }), React.createElement(ServiceableCitiesMobileCmp$FrontendWebsite.make, {
                  serviceableCitiesSectionData: serviceableCitiesSectionData
                }));
}

var make = VehicleServiceableCitiesMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
