// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactGa from "react-ga";
import * as ReactGa4 from "react-ga4";

function publishGaEvent(payload) {
  ReactGa4.default.event(payload);
  
}

function publishGaPageview(payload) {
  ReactGa.pageview(payload.path, payload.title);
  
}

function publishGaEventParameter(event_name, payload) {
  ReactGa4.default.event(event_name, payload);
  
}

export {
  publishGaEvent ,
  publishGaPageview ,
  publishGaEventParameter ,
  
}
/* react-ga Not a pure module */
