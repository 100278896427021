// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as AboutUsCMSApiModel$FrontendWebsite from "../api-model/AboutUsCMSApiModel.bs.js";

function bannerFormatToEntity(format) {
  return {
          url: format.url,
          width: format.width,
          height: format.height
        };
}

function bannerFormatsToEntity(attributes) {
  var small = attributes.small;
  var medium = attributes.medium;
  var large = attributes.large;
  return {
          small: small !== undefined ? bannerFormatToEntity(small) : undefined,
          medium: medium !== undefined ? bannerFormatToEntity(medium) : undefined,
          large: large !== undefined ? bannerFormatToEntity(large) : undefined
        };
}

function bannerAttributesToEntity(bannerAttribute) {
  return {
          name: bannerAttribute.name,
          alternativeText: bannerAttribute.alternativeText,
          formats: bannerFormatsToEntity(bannerAttribute.formats)
        };
}

function multipleBannerDataToEntity(bannerData) {
  return $$Array.map((function (data) {
                return {
                        id: data.id,
                        attributes: bannerAttributesToEntity(data.attributes)
                      };
              }), bannerData);
}

function bannerDataToEntity(bannerData) {
  return {
          id: bannerData.id,
          attributes: bannerAttributesToEntity(bannerData.attributes)
        };
}

function bannerToEntity(banner) {
  return {
          data: bannerDataToEntity(banner.data)
        };
}

function bannersToEntity(banner) {
  return {
          data: multipleBannerDataToEntity(banner.data)
        };
}

function childrenToEntity(children) {
  return $$Array.map((function (child) {
                return {
                        text: child.text
                      };
              }), children);
}

function descriptionToEntity(descriptions) {
  return $$Array.map((function (description) {
                return {
                        children: childrenToEntity(description.children)
                      };
              }), descriptions);
}

function descriptionWithBannerToEntityToEntity(data) {
  return {
          id: data.id,
          description: descriptionToEntity(data.description),
          banner: {
            data: bannerDataToEntity(data.banner.data)
          }
        };
}

function statsToEntity(stats) {
  return {
          count: stats.count,
          id: stats.id,
          label: stats.label
        };
}

function attributesToEntity(attributes) {
  return {
          title: attributes.title,
          createdAt: attributes.createdAt,
          updatedAt: attributes.updatedAt,
          publishedAt: attributes.publishedAt,
          overview: descriptionWithBannerToEntityToEntity(attributes.overview),
          commitment: descriptionWithBannerToEntityToEntity(attributes.commitment),
          porterStats: {
            id: attributes.porterStats.id,
            title: attributes.porterStats.title,
            teamCount: statsToEntity(attributes.porterStats.teamCount),
            customers: statsToEntity(attributes.porterStats.customers),
            yearFounded: statsToEntity(attributes.porterStats.yearFounded)
          },
          joinPorter: {
            id: attributes.joinPorter.id,
            title: attributes.joinPorter.title,
            subText: attributes.joinPorter.subText,
            openPositionsCtaLabel: attributes.joinPorter.openPositionsCtaLabel,
            openPositionsLink: attributes.joinPorter.openPositionsLink
          },
          banner: {
            data: multipleBannerDataToEntity(attributes.banner.data)
          }
        };
}

function apiModelToEntity(model) {
  return {
          data: {
            id: model.data.id,
            attributes: attributesToEntity(model.data.attributes)
          }
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(AboutUsCMSApiModel$FrontendWebsite.t_decode(json), "AboutUsEntityMapper.res"));
}

export {
  bannerFormatToEntity ,
  bannerFormatsToEntity ,
  bannerAttributesToEntity ,
  multipleBannerDataToEntity ,
  bannerDataToEntity ,
  bannerToEntity ,
  bannersToEntity ,
  childrenToEntity ,
  descriptionToEntity ,
  descriptionWithBannerToEntityToEntity ,
  statsToEntity ,
  attributesToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
