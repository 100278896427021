// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LinkCmp$FrontendWebsite from "./LinkCmp.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";

function AppDownloadCmp(Props) {
  var appLinks = Props.appLinks;
  return React.createElement("div", {
              className: "apps-download"
            }, React.createElement(LinkCmp$FrontendWebsite.make, {
                  href: "/iphone-app",
                  target: "_blank",
                  className: "common " + WebsiteTypes$FrontendWebsite.assetPathToString(appLinks.appStoreIcon.url),
                  style: {
                    backgroundImage: "url(" + WebsiteTypes$FrontendWebsite.assetPathToString(appLinks.appStoreIcon.url) + ")"
                  },
                  children: null,
                  key: appLinks.appStoreIcon.url
                }), React.createElement(LinkCmp$FrontendWebsite.make, {
                  href: "/android-app",
                  target: "_blank",
                  className: "common " + WebsiteTypes$FrontendWebsite.assetPathToString(appLinks.playStoreIcon.url),
                  style: {
                    backgroundImage: "url(" + WebsiteTypes$FrontendWebsite.assetPathToString(appLinks.playStoreIcon.url) + ")"
                  },
                  children: null,
                  key: WebsiteTypes$FrontendWebsite.urltoString(appLinks.playStoreIcon.url)
                }));
}

var make = AppDownloadCmp;

export {
  make ,
  
}
/* react Not a pure module */
