// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as MediaVMMapper$FrontendWebsite from "../../../website/cms/view-model/MediaVMMapper.bs.js";
import * as AppDownloadVMMapper$FrontendWebsite from "../../../website/cms/view-model/AppDownloadVMMapper.bs.js";
import * as ServiceableCitiesVMMapper$FrontendWebsite from "../../../website/cms/view-model/ServiceableCitiesVMMapper.bs.js";

function getCityInfoSection(cmsData) {
  return {
          title: cmsData.title,
          description: cmsData.description,
          banner: MediaVMMapper$FrontendWebsite.getBanner(cmsData.banner.data),
          cityName: cmsData.cityName
        };
}

function getVehicles(vehiclesData) {
  return $$Array.map((function (vehicle) {
                var match = vehicle.attributes;
                return {
                        vehicleName: match.vehicleName,
                        vehicleType: match.vehicleType,
                        url: match.url,
                        slug: match.slug,
                        homePageVehicleCard: match.homePageVehicleCard,
                        city: match.city.data.attributes.cityName,
                        vehicleBanner: MediaVMMapper$FrontendWebsite.getBanner(match.vehicleBanner.data),
                        vehicleIcon: MediaVMMapper$FrontendWebsite.getBanner(match.vehicleIcon.data)
                      };
              }), vehiclesData);
}

function getAvailableVehiclesSection(cmsData) {
  var availableVehicles = cmsData.availableVehicles;
  return {
          title: availableVehicles.title,
          vehicles: getVehicles(availableVehicles.vehicles.data)
        };
}

function getCouponBanner(banner) {
  var alt = banner.data.attributes.alternativeText;
  return {
          path: banner.data.attributes.url,
          alt_text: alt !== undefined ? alt : ""
        };
}

function getPorterOfferData(porterOffers) {
  return {
          title: porterOffers.title,
          coupons: List.map((function (coupon) {
                  return {
                          banner_img: getCouponBanner(coupon.bannerImg),
                          coupon_info: coupon.couponInfo,
                          coupon_code: coupon.couponCode,
                          coupon_validity_text: coupon.couponValidityText,
                          details_link: ""
                        };
                }), porterOffers.coupons)
        };
}

function cmsVM(cmsData) {
  var attributes = Caml_array.get(cmsData.data, 0).attributes;
  return {
          cityInfoSection: getCityInfoSection(attributes),
          availableVehicles: getAvailableVehiclesSection(attributes),
          porterOffers: getPorterOfferData(attributes.porterOffers),
          serviceableCitiesSectionData: ServiceableCitiesVMMapper$FrontendWebsite.getServiceableCities(attributes.serviceableCities),
          appDownload: AppDownloadVMMapper$FrontendWebsite.getAppDownloadData(attributes.appDownload)
        };
}

export {
  getCityInfoSection ,
  getVehicles ,
  getAvailableVehiclesSection ,
  getCouponBanner ,
  getPorterOfferData ,
  cmsVM ,
  
}
/* No side effect */
