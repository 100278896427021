// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as CouponEntityMapper$FrontendWebsite from "../entity-mapper/CouponEntityMapper.bs.js";

var execute = CouponEntityMapper$FrontendWebsite.execute;

var DiscountCouponApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var DiscountCouponApiResponseConverter = partial_arg(DiscountCouponApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  DiscountCouponApiConfig ,
  DiscountCouponApiResponseConverter ,
  
}
/* DiscountCouponApiResponseConverter Not a pure module */
