// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ErrorBoundary$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/custom/error-boundary/ErrorBoundary.bs.js";
import * as ErrorFallbackCmp$FrontendWebsite from "./ErrorFallbackCmp.bs.js";

function ErrorBoundaryHOC(Props) {
  var children = Props.children;
  return React.createElement(ErrorBoundary$Catalyst.make, {
              children: children,
              fallBackComponent: (function (error) {
                  return React.createElement(ErrorFallbackCmp$FrontendWebsite.make, {
                              error: error
                            });
                })
            });
}

var make = ErrorBoundaryHOC;

export {
  make ,
  
}
/* react Not a pure module */
