// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as HomeRepo$FrontendWebsite from "./HomeRepo.bs.js";
import * as DummyHomeResponse$FrontendWebsite from "./DummyHomeResponse.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return {
            getFareVehicles: DummyHomeResponse$FrontendWebsite.getFareVehicles,
            getTripEstimate: DummyHomeResponse$FrontendWebsite.getTripEstimate,
            getGeoregionBounds: DummyHomeResponse$FrontendWebsite.getGeoregionBounds,
            getFareEstimateResult: DummyHomeResponse$FrontendWebsite.getFareEstimateResult,
            sendSms: DummyHomeResponse$FrontendWebsite.sendSms,
            getHomePageCMSData: DummyHomeResponse$FrontendWebsite.getHomePageCMSData
          };
  } else {
    return {
            getFareVehicles: HomeRepo$FrontendWebsite.getFareVehicles,
            getTripEstimate: HomeRepo$FrontendWebsite.getTripEstimate,
            getGeoregionBounds: HomeRepo$FrontendWebsite.getGeoregionBounds,
            getFareEstimateResult: HomeRepo$FrontendWebsite.getFareEstimateResult,
            sendSms: HomeRepo$FrontendWebsite.sendSms,
            getHomePageCMSData: HomeRepo$FrontendWebsite.getHomePageCMSData
          };
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
