// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as GeoRegionBoundsApiModel$FrontendWebsite from "../api-model/GeoRegionBoundsApiModel.bs.js";

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(GeoRegionBoundsApiModel$FrontendWebsite.t_decode(json), "GeoRegionBoundsMapper.res");
  return {
          ne: {
            lat: res.bounds.max_lat,
            lng: res.bounds.max_lng
          },
          sw: {
            lat: res.bounds.min_lat,
            lng: res.bounds.min_lng
          }
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
