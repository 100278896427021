// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as TripEstimateModal$FrontendWebsite from "../../app/website/view/components/TripEstimateModal.bs.js";
import * as CookieManagementModal$FrontendWebsite from "../../app/cookie-consent/view/component/CookieManagementModal.bs.js";
import * as TrackLiveTripFormModal$FrontendWebsite from "../../app/track-live-order/view/components/TrackLiveTripFormModal.bs.js";

function getModalComponent(modalState, toggleModal) {
  if (modalState !== undefined) {
    if (typeof modalState === "number") {
      return React.createElement(TrackLiveTripFormModal$FrontendWebsite.make, {
                  close_modal: (function (param) {
                      return Curry._1(toggleModal, (function (param) {
                                    
                                  }));
                    })
                });
    } else if (modalState.TAG === /* TripEstimate */0) {
      return React.createElement(TripEstimateModal$FrontendWebsite.make, {
                  event_category: modalState._0,
                  close_modal: (function (param) {
                      return Curry._1(toggleModal, (function (param) {
                                    
                                  }));
                    })
                });
    } else {
      return React.createElement(CookieManagementModal$FrontendWebsite.make, {
                  setConsentStatus: modalState._0,
                  close_modal: (function (param) {
                      return Curry._1(toggleModal, (function (param) {
                                    
                                  }));
                    })
                });
    }
  } else {
    return null;
  }
}

function useModal(initialState) {
  var match = React.useState(function () {
        return initialState;
      });
  var toggleModal = match[1];
  var modalState = match[0];
  var modalComponent = getModalComponent(modalState, toggleModal);
  return [
          modalComponent,
          modalState,
          toggleModal
        ];
}

export {
  getModalComponent ,
  useModal ,
  
}
/* react Not a pure module */
