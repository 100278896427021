// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CircularLoader$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../../website/view/components/CircularLoader.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../website/view/components/ResponsiveLayout.bs.js";
import * as CityCMSViewMapper$FrontendWebsite from "./view-mapper/CityCMSViewMapper.bs.js";
import * as CityCMSRepoManager$FrontendWebsite from "../repo/CityCMSRepoManager.bs.js";
import * as CityMobileCmpLazyWrapper$FrontendWebsite from "./components/mobile/CityMobileCmpLazyWrapper.bs.js";
import * as CityDesktopCmpLazyWrapper$FrontendWebsite from "./components/desktop/CityDesktopCmpLazyWrapper.bs.js";

function CityCmp(Props) {
  var city = Props.city;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var RepoInstance = CityCMSRepoManager$FrontendWebsite.getRepo(undefined);
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setCMSData = match$1[1];
  var cmsData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._3(RepoInstance.fetchCityCMSData, countryConfig, language, city);
                }), (function (data) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  if (typeof cmsData === "number") {
    return React.createElement(CircularLoader$Catalyst.make, {});
  }
  switch (cmsData.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(CircularLoader$Catalyst.make, {});
    case /* Live */1 :
        var cmsVM = CityCMSViewMapper$FrontendWebsite.cmsVM(cmsData._0);
        return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                    desktop_view: React.createElement(React.Suspense, {
                          fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                          children: React.createElement(CityDesktopCmpLazyWrapper$FrontendWebsite.make, {
                                data: cmsVM
                              })
                        }),
                    mobile_view: React.createElement(React.Suspense, {
                          fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                          children: React.createElement(CityMobileCmpLazyWrapper$FrontendWebsite.make, {
                                data: cmsVM
                              })
                        })
                  });
    case /* Error */2 :
        return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                    error: cmsData._0
                  });
    
  }
}

var make = CityCmp;

export {
  make ,
  
}
/* react Not a pure module */
