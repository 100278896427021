// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as GAConfig$FrontendWebsite from "../../../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../../../utils/library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as ToastSnackbarHook$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/hooks/toast-notification/ToastSnackbarHook.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../../../utils/context/events-manager/EventsHelper.bs.js";
import * as OfferValidCmp$FrontendWebsite from "../OfferValidCmp.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";
import * as AppLinkSMSConfig$FrontendWebsite from "../../../helper/AppLinkSMSConfig.bs.js";
import * as AppLinkSMSHelper$FrontendWebsite from "../../../helper/AppLinkSMSHelper.bs.js";
import * as FareEstimateResultVehicleCardDesktopCmp$FrontendWebsite from "./FareEstimateResultVehicleCardDesktopCmp.bs.js";

function FareEstimateResultVehiclesListDesktopCmp(Props) {
  var form_data = Props.form_data;
  var vehicles = Props.vehicles;
  var send_AppLink_sms = Props.send_AppLink_sms;
  var vehicle_section = Props.vehicle_section;
  var qr_section = Props.qr_section;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var match = ToastSnackbarHook$Catalyst.useSnackbar(3000, undefined, undefined);
  var openSnackbar = match[1];
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = React.createElement("div", {
              className: "send-app-link-sms"
            }, React.createElement("div", {
                  className: "send-app-link-sms__heading"
                }, qr_section.callToActionPrefix, React.createElement("span", {
                      className: "sms-link",
                      onClick: (function (param) {
                          if (AppLinkSMSHelper$FrontendWebsite.isExpired(AppLinkSMSConfig$FrontendWebsite.key)) {
                            GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.sendAppLinkSMSClick, {
                                  event_category: "Fare Estimate Result",
                                  event_label: form_data.mobile,
                                  language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                });
                            return Curry._2(send_AppLink_sms, form_data.mobile, openSnackbar);
                          }
                          var time_left = AppLinkSMSHelper$FrontendWebsite.getTimeLeft(undefined);
                          Curry._1(openSnackbar, {
                                TAG: /* Success */0,
                                _0: "Message already sent. Try again after " + time_left + " seconds"
                              });
                          return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.disableAppLinkSMSClick, {
                                      event_category: "Fare Estimate Result",
                                      event_label: form_data.mobile,
                                      language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                    });
                        })
                    }, qr_section.callToActionLinkText), qr_section.smsLinkDescription), match[0]);
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp = null;
        break;
    
  }
  return React.createElement("div", {
              className: "fare-estimate-result-desktop-vehicles-container"
            }, React.createElement("div", {
                  className: "heading"
                }, React.createElement("div", {
                      className: "title"
                    }, vehicle_section.title), React.createElement("div", {
                      className: "desc"
                    }, vehicle_section.subtitle)), React.createElement("div", {
                  className: "vehicles-list"
                }, $$Array.of_list(List.map((function (vehicle) {
                            return React.createElement(FareEstimateResultVehicleCardDesktopCmp$FrontendWebsite.make, {
                                        vehicle: vehicle,
                                        vehicleSection: vehicle_section
                                      });
                          }), vehicles))), Belt_List.some(vehicles, (function (value) {
                    return value.discount !== undefined;
                  })) ? React.createElement(OfferValidCmp$FrontendWebsite.make, {
                    title: vehicle_section.offerMessage
                  }) : null, React.createElement("div", {
                  className: "app-link-container"
                }, React.createElement("div", {
                      className: "qr-container"
                    }, React.createElement("img", {
                          className: "qr-img",
                          alt: "Featured Img",
                          src: qr_section.qrImage.url
                        })), React.createElement("div", {
                      className: "content-container"
                    }, React.createElement("div", {
                          className: "heading"
                        }, qr_section.title), React.createElement("div", {
                          className: "sub-heading"
                        }, qr_section.desription), tmp)));
}

var make = FareEstimateResultVehiclesListDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
