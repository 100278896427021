// Generated by ReScript, PLEASE EDIT WITH CARE


function getPriceWithCurrency(price, currency) {
  switch (currency) {
    case /* Dollar */0 :
        return String(price) + "$";
    case /* BDT */1 :
        return "|৳| " + String(price);
    case /* TRY */2 :
        return "|₺| " + String(price);
    
  }
}

function fromString(unit) {
  switch (unit) {
    case "dollar" :
        return /* Dollar */0;
    case "lira" :
        return /* TRY */2;
    case "rupee" :
        return /* BDT */1;
    default:
      return /* Dollar */0;
  }
}

export {
  getPriceWithCurrency ,
  fromString ,
  
}
/* No side effect */
