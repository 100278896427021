// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../website/view/components/CircularLoader.bs.js";
import * as HomeRepoManager$FrontendWebsite from "./repo/HomeRepoManager.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";
import * as HomeMobileCmpLazyWrapper$FrontendWebsite from "./components/mobile/HomeMobileCmpLazyWrapper.bs.js";
import * as HomeDesktopCmpLazyWrapper$FrontendWebsite from "./components/desktop/HomeDesktopCmpLazyWrapper.bs.js";

function HomeCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var RepoInstance = HomeRepoManager$FrontendWebsite.getRepo(undefined);
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setHomePageCMSData = match$1[1];
  var homePageCMSData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._2(RepoInstance.getHomePageCMSData, countryConfig, language);
                }), (function (data) {
                  return Curry._1(setHomePageCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setHomePageCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  return React.createElement("div", {
              className: "home-page"
            }, React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                  desktop_view: React.createElement(React.Suspense, {
                        fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                        children: React.createElement(HomeDesktopCmpLazyWrapper$FrontendWebsite.make, {
                              homePageCMSData: homePageCMSData
                            })
                      }),
                  mobile_view: React.createElement(React.Suspense, {
                        fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                        children: React.createElement(HomeMobileCmpLazyWrapper$FrontendWebsite.make, {
                              homePageCMSData: homePageCMSData
                            })
                      })
                }));
}

var make = HomeCmp;

export {
  make ,
  
}
/* react Not a pure module */
