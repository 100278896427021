// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Timestamp$Catalyst from "../../../../node_modules/@porterin/catalyst/src/entities/time-stamp/Timestamp.bs.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as AppLinkSMSConfig$FrontendWebsite from "./AppLinkSMSConfig.bs.js";

function t_encode(v) {
  return Js_dict.fromArray([[
                "expiry",
                Timestamp$Catalyst.t_encode(v.expiry)
              ]]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var expiry = Timestamp$Catalyst.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "expiry"), null));
  if (expiry.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              expiry: expiry._0
            }
          };
  }
  var e = expiry._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".expiry" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function execute(json) {
  return DeccoUtils$Catalyst.resolveResult(t_decode(json), "AppLinkSMSHelper.res");
}

var Decoder = {
  execute: execute
};

var make = t_encode;

var Encoder = {
  make: make
};

function isExpired(key) {
  var data = localStorage.getItem(key);
  if (data === null) {
    return true;
  }
  var json = JSON.parse(data);
  var decodedData = DeccoUtils$Catalyst.resolveResult(t_decode(json), "AppLinkSMSHelper.res");
  return Caml_obj.caml_lessequal(decodedData.expiry, Timestamp$Catalyst.now(undefined));
}

function getTimeLeft(param) {
  var data = localStorage.getItem(AppLinkSMSConfig$FrontendWebsite.key);
  if (data === null) {
    return "";
  }
  var json = JSON.parse(data);
  var decodedData = DeccoUtils$Catalyst.resolveResult(t_decode(json), "AppLinkSMSHelper.res");
  return String(Timestamp$Catalyst.milliSecondsToSeconds(Timestamp$Catalyst.toFloat(decodedData.expiry) - Timestamp$Catalyst.toFloat(Timestamp$Catalyst.now(undefined))));
}

function set(key, duration, unit) {
  var item = {
    expiry: Timestamp$Catalyst.getDateTimeAfterElapsedTime(duration, unit, Timestamp$Catalyst.now(undefined))
  };
  var stringifiedData = JSON.stringify(t_encode(item));
  localStorage.setItem(key, stringifiedData);
  
}

export {
  t_encode ,
  t_decode ,
  Decoder ,
  Encoder ,
  isExpired ,
  getTimeLeft ,
  set ,
  
}
/* Timestamp-Catalyst Not a pure module */
