// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FareEstimateResultFormDesktopCmp$FrontendWebsite from "./FareEstimateResultFormDesktopCmp.bs.js";
import * as FareEstimateResultLocationDetailsDesktopCmp$FrontendWebsite from "./FareEstimateResultLocationDetailsDesktopCmp.bs.js";

function FareEstimateResultAddressDesktopCmp(Props) {
  var form_data = Props.form_data;
  var update_fare_estimate_result = Props.update_fare_estimate_result;
  var city_bounds = Props.city_bounds;
  var show_edit_form = Props.show_edit_form;
  var edit_form_handler = Props.edit_form_handler;
  var show_error = Props.show_error;
  var set_city = Props.set_city;
  var editSection = Props.editSection;
  if (show_edit_form && typeof city_bounds !== "number" && city_bounds.TAG === /* Live */1) {
    return React.createElement(FareEstimateResultFormDesktopCmp$FrontendWebsite.make, {
                bounds: city_bounds._0,
                form_data: form_data,
                form_handler: edit_form_handler,
                update_fare_estimate_result: update_fare_estimate_result,
                show_error: show_error,
                set_city: set_city,
                edit_section: editSection
              });
  }
  return React.createElement(FareEstimateResultLocationDetailsDesktopCmp$FrontendWebsite.make, {
              pickup_address: form_data.pickup_address.landmark,
              drop_address: form_data.drop_address.landmark,
              form_handler: edit_form_handler,
              edit_section: editSection
            });
}

var make = FareEstimateResultAddressDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
