// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function ImageContentCmp(Props) {
  var title = Props.title;
  var class_name = Props.class_name;
  var background_img = Props.background_img;
  var container_class_name = Props.container_class_name;
  return React.createElement("div", {
              className: container_class_name
            }, React.createElement("div", {
                  className: "img-wrapper"
                }, React.createElement("div", {
                      className: "background " + class_name,
                      style: {
                        backgroundImage: "url(" + background_img + ")"
                      }
                    })), React.createElement("div", {
                  className: "title"
                }, title));
}

var make = ImageContentCmp;

export {
  make ,
  
}
/* react Not a pure module */
