// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as VehicleEntityMapper$FrontendWebsite from "../entity-mapper/VehicleEntityMapper.bs.js";
import * as VehiclePageCMSEntityMapper$FrontendWebsite from "../entity-mapper/VehiclePageCMSEntityMapper.bs.js";

var execute = VehicleEntityMapper$FrontendWebsite.execute;

var VehicleApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var VehicleResponseConverter = partial_arg(VehicleApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$1 = VehiclePageCMSEntityMapper$FrontendWebsite.execute;

var VehiclePageCMSApiConfig = {
  execute: execute$1
};

var partial_arg$1 = ResponseHandler$Catalyst.ResponseConverter;

var VehicleCMSResponseConverter = partial_arg$1(VehiclePageCMSApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  VehicleApiConfig ,
  VehicleResponseConverter ,
  VehiclePageCMSApiConfig ,
  VehicleCMSResponseConverter ,
  
}
/* VehicleResponseConverter Not a pure module */
