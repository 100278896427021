// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as LinkCmp$FrontendWebsite from "./LinkCmp.bs.js";
import * as OptionTypeUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/OptionTypeUtils.bs.js";
import * as JsBindings$FrontendWebsite from "../../../../utils/library/bindings/JsBindings.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../../utils/helper/ContentHelper.bs.js";

var default_root_margin = "-190px";

function StickyComponent(Props) {
  var link_with_text_action = Props.link_with_text_action;
  var offer_details_info = Props.offer_details_info;
  var targetOpt = Props.target;
  var observerOptionOpt = Props.observerOption;
  var target = targetOpt !== undefined ? targetOpt : "mobile-footer-content";
  var observerOption = observerOptionOpt !== undefined ? observerOptionOpt : ({
        rootMargin: default_root_margin
      });
  var text_with_action_data = ContentHelper$FrontendWebsite.getContent(link_with_text_action);
  var offer_details_data = ContentHelper$FrontendWebsite.getContent(OptionTypeUtils$Catalyst.optionResolver(offer_details_info));
  var handleStickyDownloadBtn = function (entries) {
    var target_observer = document.getElementById("sticky-wrapper-id");
    $$Array.iter((function (entry) {
            if (entry.isIntersecting) {
              return JsBindings$FrontendWebsite.addClass(target_observer, "hide-display");
            } else {
              return JsBindings$FrontendWebsite.removeClass(target_observer, "hide-display");
            }
          }), entries);
    
  };
  React.useEffect((function () {
          var target_observer = document.getElementById(target);
          var observer = new (window.IntersectionObserver)(handleStickyDownloadBtn, observerOption);
          observer.observe(target_observer);
          
        }), []);
  return React.createElement("div", {
              className: "sticky-wrapper",
              id: "sticky-wrapper-id"
            }, React.createElement("div", {
                  className: "content"
                }, offer_details_info !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "icon-wrapper"
                          }, React.createElement("img", {
                                className: "img",
                                alt: "Price Tag",
                                src: WebsiteTypes$FrontendWebsite.assetPathToString(offer_details_data.src_path)
                              })), React.createElement("div", {
                            className: "info"
                          }, React.createElement("div", {
                                className: "prefix"
                              }, WebsiteTypes$FrontendWebsite.titleToString(offer_details_data.text)), React.createElement("div", {
                                className: "suffix"
                              }, OptionTypeUtils$Catalyst.getStringFrmOptionalType(offer_details_data.desc)))) : null, React.createElement(LinkCmp$FrontendWebsite.make, {
                      href: WebsiteTypes$FrontendWebsite.urltoString(text_with_action_data.link_with_text.target),
                      children: WebsiteTypes$FrontendWebsite.titleToString(text_with_action_data.link_with_text.text),
                      on_click: (function (param) {
                          return Curry._1(text_with_action_data.action, undefined);
                        })
                    })));
}

var make = StickyComponent;

export {
  default_root_margin ,
  make ,
  
}
/* react Not a pure module */
