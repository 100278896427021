// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";

function ResponsiveLayout(Props) {
  var desktop_view = Props.desktop_view;
  var mobile_view = Props.mobile_view;
  if (MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined)) {
    return desktop_view;
  } else {
    return mobile_view;
  }
}

var make = ResponsiveLayout;

export {
  make ,
  
}
/* No side effect */
