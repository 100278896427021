// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CityConfig$FrontendWebsite from "./CityConfig.bs.js";
import * as DhakaConfig$FrontendWebsite from "./configs/DhakaConfig.bs.js";
import * as DubaiConfig$FrontendWebsite from "./configs/DubaiConfig.bs.js";
import * as SharjahConfig$FrontendWebsite from "./configs/SharjahConfig.bs.js";
import * as IstanbulConfig$FrontendWebsite from "./configs/IstanbulConfig.bs.js";

function getConfig(city, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        if (city === CityConfig$FrontendWebsite.UAE.dubai) {
          return {
                  MetaTags: {
                    getCityHeadTags: DubaiConfig$FrontendWebsite.MetaTags.getCityHeadTags,
                    getVehicleHeadTags: DubaiConfig$FrontendWebsite.MetaTags.getVehicleHeadTags
                  }
                };
        }
        if (city === CityConfig$FrontendWebsite.UAE.sharjah) {
          return {
                  MetaTags: {
                    getCityHeadTags: SharjahConfig$FrontendWebsite.MetaTags.getCityHeadTags,
                    getVehicleHeadTags: SharjahConfig$FrontendWebsite.MetaTags.getVehicleHeadTags
                  }
                };
        }
        break;
    case /* BD */1 :
        if (city === CityConfig$FrontendWebsite.BD.dhaka) {
          return {
                  MetaTags: {
                    getCityHeadTags: DhakaConfig$FrontendWebsite.MetaTags.getCityHeadTags,
                    getVehicleHeadTags: DhakaConfig$FrontendWebsite.MetaTags.getVehicleHeadTags
                  }
                };
        }
        break;
    case /* TR */2 :
        if (city === CityConfig$FrontendWebsite.TR.istanbul) {
          return {
                  MetaTags: {
                    getCityHeadTags: IstanbulConfig$FrontendWebsite.MetaTags.getCityHeadTags,
                    getVehicleHeadTags: IstanbulConfig$FrontendWebsite.MetaTags.getVehicleHeadTags
                  }
                };
        }
        break;
    
  }
  return {
          MetaTags: {
            getCityHeadTags: DubaiConfig$FrontendWebsite.MetaTags.getCityHeadTags,
            getVehicleHeadTags: DubaiConfig$FrontendWebsite.MetaTags.getVehicleHeadTags
          }
        };
}

export {
  getConfig ,
  
}
/* DhakaConfig-FrontendWebsite Not a pure module */
