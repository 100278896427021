// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Row$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Row.bs.js";
import * as Button$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/buttons/Button.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../../routing/RoutesConfig.bs.js";
import * as CookieConsentForm$FrontendWebsite from "../../utils/CookieConsentForm.bs.js";
import * as CookieCategoryList$FrontendWebsite from "./CookieCategoryList.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../../utils/CookieConsentHelper.bs.js";

function CookieManagementCmp(Props) {
  var close_modal = Props.close_modal;
  var desc = Props.desc;
  var link_text = Props.link_text;
  var acceept_text = Props.acceept_text;
  var confirm_text = Props.confirm_text;
  var form = CookieConsentForm$FrontendWebsite.Form.useForm({
        functional: false,
        analytics: false,
        marketing: false
      }, (function (output, submissionCbs) {
          Curry._1(submissionCbs.notifyOnSuccess, undefined);
          CookieConsentHelper$FrontendWebsite.setCookieConsent(output.functional, output.marketing, output.analytics);
          return Curry._1(close_modal, undefined);
        }));
  return React.createElement("div", {
              className: "c-cookie-management"
            }, React.createElement("div", {
                  className: "description"
                }, desc, React.createElement("a", {
                      className: "link-text",
                      href: RoutesConfig$FrontendWebsite.privacyPolicyUrl
                    }, link_text)), React.createElement(CookieCategoryList$FrontendWebsite.make, {
                  form: form
                }), React.createElement(Row$Catalyst.make, {
                  children: null,
                  className: "submit-btn-container"
                }, React.createElement(Button$Catalyst.make, {
                      text: acceept_text,
                      cb: (function (param) {
                          Curry._1(close_modal, undefined);
                          return CookieConsentHelper$FrontendWebsite.acceptAll(undefined);
                        }),
                      className: "submit-btn"
                    }), React.createElement(Button$Catalyst.make, {
                      text: confirm_text,
                      cb: (function (param) {
                          return Curry._1(form.submit, undefined);
                        }),
                      className: "submit-btn"
                    })));
}

var make = CookieManagementCmp;

export {
  make ,
  
}
/* react Not a pure module */
