// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CircularLoader$Catalyst from "../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as ServicesRepo$FrontendWebsite from "./repo/ServicesRepo.bs.js";
import * as CircularLoader$FrontendWebsite from "../website/view/components/CircularLoader.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";
import * as ServicesMobileCmpLazyWrapper$FrontendWebsite from "./view/components/mobile/ServicesMobileCmpLazyWrapper.bs.js";
import * as ServicesDesktopCmpLazyWrapper$FrontendWebsite from "./view/components/desktop/ServicesDesktopCmpLazyWrapper.bs.js";

function ServicesPageCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setServicesCMSData = match$1[1];
  var servicesCMSData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return ServicesRepo$FrontendWebsite.getServicesCMSData(countryConfig, language);
                }), (function (data) {
                  return Curry._1(setServicesCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setServicesCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  if (typeof servicesCMSData !== "number") {
    switch (servicesCMSData.TAG | 0) {
      case /* Loading */0 :
          break;
      case /* Live */1 :
          var data = servicesCMSData._0;
          return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                      desktop_view: React.createElement(React.Suspense, {
                            children: React.createElement(ServicesDesktopCmpLazyWrapper$FrontendWebsite.make, {
                                  servicesCMSData: data
                                }),
                            fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                          }),
                      mobile_view: React.createElement(React.Suspense, {
                            children: React.createElement(ServicesMobileCmpLazyWrapper$FrontendWebsite.make, {
                                  servicesCMSData: data
                                }),
                            fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                          })
                    });
      case /* Error */2 :
          return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                      error: servicesCMSData._0
                    });
      
    }
  }
  return React.createElement("div", {
              className: "loaderWrapper"
            }, React.createElement(CircularLoader$Catalyst.make, {}));
}

var make = ServicesPageCmp;

export {
  make ,
  
}
/* react Not a pure module */
