// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "../../../../../../node_modules/re-formality/src/Formality.bs.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ReactModal from "react-modal";
import * as FormInput$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormInput.bs.js";
import * as FormHelper$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/generic/form/FormHelper.bs.js";
import * as Config$FrontendWebsite from "../../../../Config.bs.js";
import * as Formality__ReactUpdate from "../../../../../../node_modules/re-formality/src/Formality__ReactUpdate.bs.js";
import * as FormSelectInput$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormSelectInput.bs.js";
import * as SelectComponent$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/generic/drop-downs/SelectComponent.bs.js";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as HomeHelper$FrontendWebsite from "../../../../../utils/helper/HomeHelper.bs.js";
import * as CityCookieHook$FrontendWebsite from "../../../../../utils/hooks/CityCookieHook.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";
import * as FareEstimateResultConfig$FrontendWebsite from "../../FareEstimateResultConfig.bs.js";
import * as FareEstimateResultHelper$FrontendWebsite from "../../FareEstimateResultHelper.bs.js";
import * as GoogleAutoCompleteInputSimplified$FrontendWebsite from "../../../../home/components/GoogleAutoCompleteInputSimplified.bs.js";

function FareEstimateResultMobileFormCmp(Props) {
  var bounds = Props.bounds;
  var form_data = Props.form_data;
  var form_handler = Props.form_handler;
  var update_fare_estimate_result = Props.update_fare_estimate_result;
  var show_error = Props.show_error;
  var set_city = Props.set_city;
  var edit_section = Props.edit_section;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(match.language);
  var HomeHelper = HomeHelper$FrontendWebsite.Helper(Content.Home);
  var citiesSelectComponentList = Curry._2(HomeHelper.getSelectComponentItems, CityCookieHook$FrontendWebsite.cities, (function (value) {
          return [
                  value,
                  value
                ];
        }));
  var validators_city = {
    strategy: /* OnSubmit */4,
    validate: (function (param) {
        return FareEstimateResultHelper$FrontendWebsite.validateCity(param.city);
      })
  };
  var validators_drop = {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        return FareEstimateResultHelper$FrontendWebsite.validateAddress(param.drop, bounds, edit_section.pickUpError);
      })
  };
  var validators_pickup = {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (param) {
        return FareEstimateResultHelper$FrontendWebsite.validateAddress(param.pickup, bounds, edit_section.dropError);
      })
  };
  var validators = {
    city: validators_city,
    drop: validators_drop,
    pickup: validators_pickup
  };
  var initialState = function (input) {
    return {
            input: input,
            fieldsStatuses: {
              city: /* Pristine */0,
              drop: /* Pristine */0,
              pickup: /* Pristine */0
            },
            collectionsStatuses: undefined,
            formStatus: /* Editing */0,
            submissionStatus: /* NeverSubmitted */0
          };
  };
  var validateForm = function (input, validators, fieldsStatuses) {
    var match = fieldsStatuses.city;
    var match_0 = match ? match._0 : Curry._1(validators.city.validate, input);
    var match$1 = fieldsStatuses.drop;
    var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.drop.validate, input);
    var match$2 = fieldsStatuses.pickup;
    var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.pickup.validate, input);
    var cityResult = match_0;
    var cityResult$1;
    if (cityResult.TAG === /* Ok */0) {
      var dropResult = match_0$1;
      if (dropResult.TAG === /* Ok */0) {
        var pickupResult = match_0$2;
        if (pickupResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    pickup: pickupResult._0,
                    drop: dropResult._0,
                    city: cityResult._0
                  },
                  fieldsStatuses: {
                    city: /* Dirty */{
                      _0: cityResult,
                      _1: /* Shown */0
                    },
                    drop: /* Dirty */{
                      _0: dropResult,
                      _1: /* Shown */0
                    },
                    pickup: /* Dirty */{
                      _0: pickupResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        cityResult$1 = cityResult;
      } else {
        cityResult$1 = cityResult;
      }
    } else {
      cityResult$1 = cityResult;
    }
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              city: /* Dirty */{
                _0: cityResult$1,
                _1: /* Shown */0
              },
              drop: /* Dirty */{
                _0: match_0$1,
                _1: /* Shown */0
              },
              pickup: /* Dirty */{
                _0: match_0$2,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  };
  var useForm = function (initialInput, onSubmit) {
    var memoizedInitialState = React.useMemo((function () {
            return initialState(initialInput);
          }), [initialInput]);
    var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
            if (typeof action === "number") {
              switch (action) {
                case /* BlurCityField */0 :
                    var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.city, validators_city, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    city: status,
                                    drop: init.drop,
                                    pickup: init.pickup
                                  };
                          }));
                    if (result !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurDropField */1 :
                    var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.drop, validators_drop, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    city: init.city,
                                    drop: status,
                                    pickup: init.pickup
                                  };
                          }));
                    if (result$1 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* BlurPickupField */2 :
                    var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.pickup, validators_pickup, (function (status) {
                            var init = state.fieldsStatuses;
                            return {
                                    city: init.city,
                                    drop: init.drop,
                                    pickup: status
                                  };
                          }));
                    if (result$2 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: result$2,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                case /* Submit */3 :
                    var match = state.formStatus;
                    if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                      return /* NoUpdate */0;
                    }
                    var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                    if (match$1.TAG !== /* Valid */0) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: match$1.fieldsStatuses,
                                collectionsStatuses: match$1.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: /* AttemptedToSubmit */1
                              }
                            };
                    }
                    var output = match$1.output;
                    var error = state.formStatus;
                    var tmp;
                    tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: tmp
                              },
                              submissionStatus: /* AttemptedToSubmit */1
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                return Curry._2(onSubmit, output, {
                                            notifyOnSuccess: (function (input) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetSubmittedStatus */3,
                                                            _0: input
                                                          });
                                              }),
                                            notifyOnFailure: (function (error) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetSubmissionFailedStatus */4,
                                                            _0: error
                                                          });
                                              }),
                                            reset: (function (param) {
                                                return Curry._1(dispatch, /* Reset */6);
                                              }),
                                            dismissSubmissionResult: (function (param) {
                                                return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                              })
                                          });
                              })
                          };
                    break;
                case /* DismissSubmissionError */4 :
                    var match$2 = state.formStatus;
                    if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                      return /* NoUpdate */0;
                    } else {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                case /* DismissSubmissionResult */5 :
                    var match$3 = state.formStatus;
                    if (typeof match$3 === "number") {
                      if (match$3 === /* Editing */0) {
                        return /* NoUpdate */0;
                      }
                      
                    } else if (match$3.TAG === /* Submitting */0) {
                      return /* NoUpdate */0;
                    }
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* Reset */6 :
                    return {
                            TAG: /* Update */0,
                            _0: initialState(initialInput)
                          };
                
              }
            } else {
              switch (action.TAG | 0) {
                case /* UpdateCityField */0 :
                    var nextInput = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.city, state.submissionStatus, validators_city, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              city: status,
                                              drop: init.drop,
                                              pickup: init.pickup
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdateDropField */1 :
                    var nextInput$1 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$1,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.drop, state.submissionStatus, validators_drop, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              city: init.city,
                                              drop: status,
                                              pickup: init.pickup
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* UpdatePickupField */2 :
                    var nextInput$2 = Curry._1(action._0, state.input);
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: nextInput$2,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.pickup, state.submissionStatus, validators_pickup, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              city: init.city,
                                              drop: init.drop,
                                              pickup: status
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* SetSubmittedStatus */3 :
                    var input = action._0;
                    if (input !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: input,
                                fieldsStatuses: {
                                  city: /* Pristine */0,
                                  drop: /* Pristine */0,
                                  pickup: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: {
                                  city: /* Pristine */0,
                                  drop: /* Pristine */0,
                                  pickup: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                case /* SetSubmissionFailedStatus */4 :
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: action._0
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                case /* MapSubmissionError */5 :
                    var map = action._0;
                    var error$1 = state.formStatus;
                    if (typeof error$1 === "number") {
                      return /* NoUpdate */0;
                    }
                    if (error$1.TAG !== /* Submitting */0) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: {
                                  TAG: /* SubmissionFailed */1,
                                  _0: Curry._1(map, error$1._0)
                                },
                                submissionStatus: state.submissionStatus
                              }
                            };
                    }
                    var error$2 = error$1._0;
                    if (error$2 !== undefined) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: {
                                  TAG: /* Submitting */0,
                                  _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                                },
                                submissionStatus: state.submissionStatus
                              }
                            };
                    } else {
                      return /* NoUpdate */0;
                    }
                
              }
            }
          }));
    var dispatch = match[1];
    var state = match[0];
    var match$1 = state.formStatus;
    var tmp;
    tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
    return {
            updateCity: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateCityField */0,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updateDrop: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdateDropField */1,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            updatePickup: (function (nextInputFn, nextValue) {
                return Curry._1(dispatch, {
                            TAG: /* UpdatePickupField */2,
                            _0: (function (__x) {
                                return Curry._2(nextInputFn, __x, nextValue);
                              })
                          });
              }),
            blurCity: (function (param) {
                return Curry._1(dispatch, /* BlurCityField */0);
              }),
            blurDrop: (function (param) {
                return Curry._1(dispatch, /* BlurDropField */1);
              }),
            blurPickup: (function (param) {
                return Curry._1(dispatch, /* BlurPickupField */2);
              }),
            cityResult: Formality.exposeFieldResult(state.fieldsStatuses.city),
            dropResult: Formality.exposeFieldResult(state.fieldsStatuses.drop),
            pickupResult: Formality.exposeFieldResult(state.fieldsStatuses.pickup),
            input: state.input,
            status: state.formStatus,
            dirty: (function (param) {
                var match = state.fieldsStatuses;
                if (match.city || match.drop || match.pickup) {
                  return true;
                } else {
                  return false;
                }
              }),
            valid: (function (param) {
                var match = validateForm(state.input, validators, state.fieldsStatuses);
                if (match.TAG === /* Valid */0) {
                  return true;
                } else {
                  return false;
                }
              }),
            submitting: tmp,
            submit: (function (param) {
                return Curry._1(dispatch, /* Submit */3);
              }),
            dismissSubmissionError: (function (param) {
                return Curry._1(dispatch, /* DismissSubmissionError */4);
              }),
            dismissSubmissionResult: (function (param) {
                return Curry._1(dispatch, /* DismissSubmissionResult */5);
              }),
            mapSubmissionError: (function (map) {
                return Curry._1(dispatch, {
                            TAG: /* MapSubmissionError */5,
                            _0: map
                          });
              }),
            reset: (function (param) {
                return Curry._1(dispatch, /* Reset */6);
              })
          };
  };
  var form = useForm({
        pickup: {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        },
        drop: {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        },
        city: List.find_opt((function (selectOpt) {
                return selectOpt.key === form_data.city.toLowerCase();
              }), citiesSelectComponentList)
      }, (function (output, submissionCbs) {
          console.log(output);
          var updated_form_data_name = form_data.name;
          var updated_form_data_mobile = form_data.mobile;
          var updated_form_data_city = form_data.city;
          var updated_form_data_requirement = form_data.requirement;
          var updated_form_data_pickup_address = output.pickup;
          var updated_form_data_drop_address = output.drop;
          var updated_form_data_fare_estimate_token = form_data.fare_estimate_token;
          var updated_form_data_consent_for_callback = form_data.consent_for_callback;
          var updated_form_data = {
            name: updated_form_data_name,
            mobile: updated_form_data_mobile,
            city: updated_form_data_city,
            requirement: updated_form_data_requirement,
            pickup_address: updated_form_data_pickup_address,
            drop_address: updated_form_data_drop_address,
            fare_estimate_token: updated_form_data_fare_estimate_token,
            consent_for_callback: updated_form_data_consent_for_callback
          };
          Curry._1(update_fare_estimate_result, updated_form_data);
          Curry._1(form_handler, undefined);
          return Curry._1(submissionCbs.notifyOnSuccess, undefined);
        }));
  return React.createElement(ReactModal, {
              isOpen: true,
              onRequestClose: (function (param) {
                  
                }),
              className: "modal",
              shouldCloseOnOverlayClick: true,
              children: React.createElement("form", {
                    className: "fare-estimate-result-mobile-form",
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        return Curry._1(form.submit, undefined);
                      })
                  }, React.createElement("div", {
                        className: "heading"
                      }, React.createElement("div", {
                            className: "title"
                          }, edit_section.title), React.createElement("img", {
                            className: "close-icon",
                            src: edit_section.closeIcon.url,
                            onClick: (function (param) {
                                return Curry._1(form_handler, undefined);
                              })
                          })), React.createElement("div", {
                        className: "form-body"
                      }, React.createElement(FormSelectInput$Catalyst.make, {
                            input_props: FormInput$Catalyst.make_props(edit_section.selectCityLabel, (function ($$event) {
                                    Curry._2(form.updateCity, (function (input, value) {
                                            return {
                                                    pickup: value !== input.city ? (Curry._2(form.updatePickup, (function (input, _value) {
                                                                return {
                                                                        pickup: FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined),
                                                                        drop: input.drop,
                                                                        city: input.city
                                                                      };
                                                              }), FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined)), FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined)) : input.pickup,
                                                    drop: value !== input.city ? (Curry._2(form.updateDrop, (function (input, _value) {
                                                                return {
                                                                        pickup: input.pickup,
                                                                        drop: FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined),
                                                                        city: input.city
                                                                      };
                                                              }), FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined)), FareEstimateResultHelper$FrontendWebsite.resetAddress(undefined)) : input.drop,
                                                    city: value
                                                  };
                                          }), SelectComponent$Catalyst.getSelectedItem(FormHelper$Catalyst.TargetEventWrapper.value($$event), citiesSelectComponentList));
                                    return Curry._1(set_city, FormHelper$Catalyst.TargetEventWrapper.value($$event));
                                  }), undefined, undefined, edit_section.selectCityLabel, undefined, undefined, undefined, form.cityResult, undefined, "fare-estimate-form-input", undefined, undefined, undefined, undefined, undefined),
                            select_props: {
                              value: form.input.city,
                              items: citiesSelectComponentList,
                              isNoneRequired: false
                            }
                          }), React.createElement("div", {
                            className: "fare-estimate-result-google-auto-complete"
                          }, React.createElement(GoogleAutoCompleteInputSimplified$FrontendWebsite.make, {
                                autocomplete_props: GoogleAutoCompleteInputSimplified$FrontendWebsite.GoogleAutoCompleteInputSimplifiedProps.make_props({
                                      googleMapsApiKey: Config$FrontendWebsite.Env.getGoogleMapApiKey(undefined),
                                      libraries: Config$FrontendWebsite.Env.libraries
                                    }, {
                                      hd: {
                                        label: edit_section.pickupLabel,
                                        placeholder: edit_section.pickupLabel,
                                        className: "fare-estimate-form-input",
                                        onChange: (function (addressVal, _postalAddress) {
                                            return Curry._2(form.updatePickup, (function (input, value) {
                                                          return {
                                                                  pickup: value,
                                                                  drop: input.drop,
                                                                  city: input.city
                                                                };
                                                        }), addressVal);
                                          }),
                                        value: form.input.pickup.landmark,
                                        result: form.pickupResult
                                      },
                                      tl: {
                                        hd: {
                                          label: edit_section.dropLabel,
                                          placeholder: edit_section.dropLabel,
                                          className: "fare-estimate-form-input",
                                          onChange: (function (addressVal, _postalAddress) {
                                              return Curry._2(form.updateDrop, (function (input, value) {
                                                            return {
                                                                    pickup: input.pickup,
                                                                    drop: value,
                                                                    city: input.city
                                                                  };
                                                          }), addressVal);
                                            }),
                                          value: form.input.drop.landmark,
                                          result: form.dropResult
                                        },
                                        tl: /* [] */0
                                      }
                                    }, bounds, true, undefined, undefined, Curry._1(HomeHelper.getAutoCompRestrictions, country), undefined)
                              })), React.createElement("button", {
                            className: "request-button"
                          }, edit_section.submitButtonLabel), show_error ? React.createElement("div", {
                              className: "form-error"
                            }, FareEstimateResultConfig$FrontendWebsite.default_error_msg) : null))
            });
}

var make = FareEstimateResultMobileFormCmp;

export {
  make ,
  
}
/* react Not a pure module */
