// Generated by ReScript, PLEASE EDIT WITH CARE


var sh_100_px = 100;

var sh_200_px = 200;

var sh_500_px = 500;

var sh_1000_px = 1000;

var sh_2000_px = 2000;

var sh_5000_px = 5000;

var sh_10000_px = 10000;

var sh_20000_px = 20000;

var duration_10secs = 10000;

var duration_30secs = 30000;

var duration_60secs = 60000;

var duration_120secs = 120000;

var duration_180secs = 180000;

var duration_300secs = 300000;

var duration_600secs = 600000;

var viewed_pct_10 = 10;

var viewed_pct_25 = 25;

var viewed_pct_50 = 50;

var viewed_pct_75 = 75;

var viewed_pct_100 = 100;

export {
  sh_100_px ,
  sh_200_px ,
  sh_500_px ,
  sh_1000_px ,
  sh_2000_px ,
  sh_5000_px ,
  sh_10000_px ,
  sh_20000_px ,
  duration_10secs ,
  duration_30secs ,
  duration_60secs ,
  duration_120secs ,
  duration_180secs ,
  duration_300secs ,
  duration_600secs ,
  viewed_pct_10 ,
  viewed_pct_25 ,
  viewed_pct_50 ,
  viewed_pct_75 ,
  viewed_pct_100 ,
  
}
/* No side effect */
