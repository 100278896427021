// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as Title$FrontendWebsite from "../Title.bs.js";
import * as ChipsCmp$FrontendWebsite from "../ChipsCmp.bs.js";
import * as JsBindings$FrontendWebsite from "../../../../../utils/library/bindings/JsBindings.bs.js";

function ServiceableCitiesMobileCmp(Props) {
  var showTitleOpt = Props.showTitle;
  var serviceableCitiesSectionData = Props.serviceableCitiesSectionData;
  var showTitle = showTitleOpt !== undefined ? showTitleOpt : false;
  var selectCityHandler = JsBindings$FrontendWebsite.redirect;
  return React.createElement("div", {
              className: "common-mobile-cities-container"
            }, showTitle ? React.createElement(Title$FrontendWebsite.make, {
                    title: serviceableCitiesSectionData.title,
                    class_name: "section-header cities-header"
                  }) : null, React.createElement("div", {
                  className: "cities-wrapper"
                }, React.createElement("div", {
                      className: "chips-container"
                    }, $$Array.map((function (city) {
                            return React.createElement(ChipsCmp$FrontendWebsite.make, {
                                        label: $$String.capitalize_ascii(city.title),
                                        handler: selectCityHandler,
                                        isSelected: false,
                                        redirectTo: city.cityUrl
                                      });
                          }), serviceableCitiesSectionData.cities))));
}

var make = ServiceableCitiesMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
