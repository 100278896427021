// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as SpotFAQsRepo$FrontendWebsite from "./SpotFAQsRepo.bs.js";
import * as DummySpotFAQsRepo$FrontendWebsite from "./DummySpotFAQsRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummySpotFAQsRepo$FrontendWebsite;
  } else {
    return SpotFAQsRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
