// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function DownloadAppsDesktopCmp(Props) {
  var container_class_nameOpt = Props.container_class_name;
  var appDownloadData = Props.appDownloadData;
  var container_class_name = container_class_nameOpt !== undefined ? container_class_nameOpt : "";
  return React.createElement("div", {
              className: "download-apps-container " + container_class_name
            }, React.createElement("div", {
                  className: "title"
                }, appDownloadData.title), React.createElement("div", {
                  className: "apps"
                }, React.createElement("a", {
                      className: "android",
                      href: appDownloadData.googlePlayStoreUrl
                    }, React.createElement("img", {
                          className: "img",
                          src: appDownloadData.googlePlayStoreBanner.url
                        })), React.createElement("a", {
                      className: "ios",
                      href: appDownloadData.appStoreUrl
                    }, React.createElement("img", {
                          className: "img",
                          src: appDownloadData.appStoreBanner.url
                        }))));
}

var make = DownloadAppsDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
