// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactModal from "react-modal";
import * as Icon$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/icons/Icon.bs.js";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as ErrorUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as JsBindings$FrontendWebsite from "../../../../utils/library/bindings/JsBindings.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as TrackLiveTripFormCmp$FrontendWebsite from "./TrackLiveTripFormCmp.bs.js";
import * as TrackLiveTripRepoManager$FrontendWebsite from "../../repo/TrackLiveTripRepoManager.bs.js";

function TrackLiveTripFormModal(Props) {
  var close_modal = Props.close_modal;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var RepoInstance = TrackLiveTripRepoManager$FrontendWebsite.getRepo(undefined);
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var get_track_order_info = function (trackOrder) {
    return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._3(RepoInstance.submitForm, trackOrder, country, language);
                }), (function (data) {
                  return JsBindings$FrontendWebsite.redirect(data.redirect_path);
                }), (function (error) {
                  return Curry._1(setError, (function (param) {
                                return ErrorUtils$Catalyst.resolveError(error);
                              }));
                }));
  };
  return React.createElement(ReactModal, {
              isOpen: true,
              onRequestClose: close_modal,
              className: "modal",
              shouldCloseOnOverlayClick: true,
              children: React.createElement("div", {
                    className: "modal-container"
                  }, React.createElement("div", {
                        className: "modal-titlebar"
                      }, React.createElement("div", {
                            className: "modal-titlebar__title"
                          }, React.createElement("b", undefined, Content.TrackOrder.track_order_heading)), React.createElement("div", {
                            className: "modal-titlebar__close-btn",
                            onClick: (function (param) {
                                return Curry._1(close_modal, undefined);
                              })
                          }, React.createElement(Icon$Catalyst.CloseIcon.make, {}))), React.createElement(TrackLiveTripFormCmp$FrontendWebsite.make, {
                        get_track_order_info: get_track_order_info,
                        error: match[0]
                      }))
            });
}

var make = TrackLiveTripFormModal;

export {
  make ,
  
}
/* react Not a pure module */
