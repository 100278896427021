// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as FormInput$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormInput.bs.js";
import * as FormTextInput$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormTextInput.bs.js";
import * as GoogleAutoCompleteInput$FrontendWebsite from "./GoogleAutoCompleteInput.bs.js";

function getLoadingElement(input_props_list) {
  return $$Array.of_list(List.mapi((function (key, ics) {
                    return React.createElement(FormTextInput$Catalyst.make, {
                                input_props: FormInput$Catalyst.make_props(ics.label, (function (_event) {
                                        
                                      }), undefined, undefined, ics.placeholder, undefined, undefined, undefined, ics.result, undefined, ics.className, undefined, undefined, undefined, undefined, undefined),
                                textinput_props: {
                                  value: ics.value
                                },
                                key: String(key)
                              });
                  }), input_props_list));
}

function make_props(load_config, input_props_list, bounds, is_strict_boundsOpt, chromeAutoSuggestionOpt, modeOpt, country_restrictions, param) {
  var is_strict_bounds = is_strict_boundsOpt !== undefined ? is_strict_boundsOpt : false;
  var chromeAutoSuggestion = chromeAutoSuggestionOpt !== undefined ? chromeAutoSuggestionOpt : true;
  var mode = modeOpt !== undefined ? modeOpt : /* TURBO */1;
  return {
          load_config: load_config,
          input_props_list: input_props_list,
          bounds: bounds,
          is_strict_bounds: is_strict_bounds,
          chromeAutoSuggestion: chromeAutoSuggestion,
          mode: mode,
          country_restrictions: country_restrictions
        };
}

var GoogleAutoCompleteInputSimplifiedProps = {
  make_props: make_props
};

function GoogleAutoCompleteInputSimplified(Props) {
  var autocomplete_props = Props.autocomplete_props;
  var initialLocation = {
    lat: 0.0,
    lng: 0.0
  };
  var conv = function (icS) {
    var partial_arg = icS.onChange;
    return {
            label: icS.label,
            placeholder: icS.placeholder,
            className: icS.className,
            onChange: (function (param, param$1, param$2) {
                if (param$1 !== undefined) {
                  if (param$2 !== undefined) {
                    return Curry._2(partial_arg, param$1, param$2);
                  } else {
                    return Curry._2(partial_arg, param$1, {
                                pincode: undefined,
                                state: undefined,
                                city: undefined,
                                locality: undefined,
                                sub_locality: undefined
                              });
                  }
                } else {
                  return Curry._2(partial_arg, {
                              landmark: param,
                              location: initialLocation
                            }, {
                              pincode: undefined,
                              state: undefined,
                              city: undefined,
                              locality: undefined,
                              sub_locality: undefined
                            });
                }
              }),
            value: icS.value,
            result: icS.result
          };
  };
  var new_input_props_list = List.map(conv, autocomplete_props.input_props_list);
  return React.createElement(GoogleAutoCompleteInput$FrontendWebsite.make, {
              autocomplete_props: GoogleAutoCompleteInput$FrontendWebsite.GoogleAutoCompleteInputProps.make_props(autocomplete_props.load_config, new_input_props_list, autocomplete_props.bounds, autocomplete_props.is_strict_bounds, Caml_option.some(getLoadingElement(autocomplete_props.input_props_list)), autocomplete_props.chromeAutoSuggestion, autocomplete_props.mode, autocomplete_props.country_restrictions, undefined)
            });
}

var make = GoogleAutoCompleteInputSimplified;

export {
  getLoadingElement ,
  GoogleAutoCompleteInputSimplifiedProps ,
  make ,
  
}
/* react Not a pure module */
