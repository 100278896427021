// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AppDownloadApiModel$FrontendWebsite from "../../website/cms/api-model/AppDownloadApiModel.bs.js";

function faq_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var question = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "question"), null));
    if (question.TAG === /* Ok */0) {
      var answer = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "answer"), null));
      if (answer.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  question: question._0,
                  answer: answer._0
                }
              };
      }
      var e = answer._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".answer" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = question._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".question" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function faqs_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var questions = Decco.arrayFromJson(faq_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "questions"), null));
    if (questions.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                questions: questions._0
              }
            };
    }
    var e = questions._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".questions" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function iconAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
  if (name.TAG === /* Ok */0) {
    var alternativeText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alternativeText"), null));
    if (alternativeText.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  name: name._0,
                  alternativeText: alternativeText._0,
                  url: url._0
                }
              };
      }
      var e = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = alternativeText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".alternativeText" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function iconData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = iconAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function icon_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = iconData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function category_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        var vehicleIcon = icon_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleIcon"), null));
        if (vehicleIcon.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    order: order._0,
                    title: title._0,
                    url: url._0,
                    vehicleIcon: vehicleIcon._0
                  }
                };
        }
        var e = vehicleIcon._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleIcon" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function availableCategories_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var categories = Decco.arrayFromJson(category_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categories"), null));
    if (categories.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                categories: categories._0
              }
            };
    }
    var e = categories._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".categories" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function bookingStep_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var stepLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "stepLabel"), null));
    if (stepLabel.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                order: order._0,
                stepLabel: stepLabel._0
              }
            };
    }
    var e = stepLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".stepLabel" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function serviceBookingGuide_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var bookingSteps = Decco.arrayFromJson(bookingStep_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bookingSteps"), null));
      if (bookingSteps.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  description: description._0,
                  bookingSteps: bookingSteps._0
                }
              };
      }
      var e = bookingSteps._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".bookingSteps" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function keywordsAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
  if (slug.TAG === /* Ok */0) {
    var keyword = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keyword"), null));
    if (keyword.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                slug: slug._0,
                keyword: keyword._0
              }
            };
    }
    var e = keyword._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".keyword" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = slug._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".slug" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function keywordsData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = keywordsAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function keywords_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(keywordsData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function keywordsList_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var keywords = keywords_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keywords"), null));
    if (keywords.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                keywords: keywords._0
              }
            };
    }
    var e = keywords._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".keywords" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function feature_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var subTitle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subTitle"), null));
      if (subTitle.TAG === /* Ok */0) {
        var featureIcon = icon_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "featureIcon"), null));
        if (featureIcon.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    order: order._0,
                    title: title._0,
                    subTitle: subTitle._0,
                    featureIcon: featureIcon._0
                  }
                };
        }
        var e = featureIcon._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".featureIcon" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = subTitle._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".subTitle" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function serviceFeatures_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var features = Decco.arrayFromJson(feature_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "features"), null));
    if (features.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                features: features._0
              }
            };
    }
    var e = features._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".features" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function rowDetails_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
    if (label.TAG === /* Ok */0) {
      var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  label: label._0,
                  value: value._0
                }
              };
      }
      var e = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function serviceInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var serviceDetailsRow = Decco.arrayFromJson(rowDetails_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceDetailsRow"), null));
      if (serviceDetailsRow.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  description: description._0,
                  serviceDetailsRow: serviceDetailsRow._0
                }
              };
      }
      var e = serviceDetailsRow._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".serviceDetailsRow" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bannerAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
  if (name.TAG === /* Ok */0) {
    var alternativeText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alternativeText"), null));
    if (alternativeText.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        var ext = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "ext"), null));
        if (ext.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    name: name._0,
                    alternativeText: alternativeText._0,
                    url: url._0,
                    ext: ext._0
                  }
                };
        }
        var e = ext._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".ext" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = alternativeText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".alternativeText" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function bannerData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = bannerAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function banner_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = bannerData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var banner = banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner"), null));
      if (banner.TAG === /* Ok */0) {
        var serviceInfo = serviceInfo_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceInfo"), null));
        if (serviceInfo.TAG === /* Ok */0) {
          var serviceFeatures = serviceFeatures_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceFeatures"), null));
          if (serviceFeatures.TAG === /* Ok */0) {
            var keywordsList = keywordsList_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keywordsList"), null));
            if (keywordsList.TAG === /* Ok */0) {
              var serviceBookingGuide = serviceBookingGuide_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceBookingGuide"), null));
              if (serviceBookingGuide.TAG === /* Ok */0) {
                var availableCategories = availableCategories_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "availableCategories"), null));
                if (availableCategories.TAG === /* Ok */0) {
                  var faqs = faqs_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "faqs"), null));
                  if (faqs.TAG === /* Ok */0) {
                    var appDownload = AppDownloadApiModel$FrontendWebsite.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownload"), null));
                    if (appDownload.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                title: title._0,
                                description: description._0,
                                banner: banner._0,
                                serviceInfo: serviceInfo._0,
                                serviceFeatures: serviceFeatures._0,
                                keywordsList: keywordsList._0,
                                serviceBookingGuide: serviceBookingGuide._0,
                                availableCategories: availableCategories._0,
                                faqs: faqs._0,
                                appDownload: appDownload._0
                              }
                            };
                    }
                    var e = appDownload._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".appDownload" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = faqs._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".faqs" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = availableCategories._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".availableCategories" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = serviceBookingGuide._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".serviceBookingGuide" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = keywordsList._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".keywordsList" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = serviceFeatures._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".serviceFeatures" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = serviceInfo._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".serviceInfo" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = banner._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".banner" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function keywordsCMSData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(keywordsCMSData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  faq_decode ,
  faqs_decode ,
  iconAttributes_decode ,
  iconData_decode ,
  icon_decode ,
  category_decode ,
  availableCategories_decode ,
  bookingStep_decode ,
  serviceBookingGuide_decode ,
  keywordsAttributes_decode ,
  keywordsData_decode ,
  keywords_decode ,
  keywordsList_decode ,
  feature_decode ,
  serviceFeatures_decode ,
  rowDetails_decode ,
  serviceInfo_decode ,
  bannerAttributes_decode ,
  bannerData_decode ,
  banner_decode ,
  attributes_decode ,
  keywordsCMSData_decode ,
  t_decode ,
  
}
/* No side effect */
