// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../node_modules/rescript/lib/es6/array.js";
import * as Caml_array from "../../node_modules/rescript/lib/es6/caml_array.js";

function getEmptyStringFrmOptionalType(data) {
  if (data !== undefined) {
    return data;
  } else {
    return "";
  }
}

function getCookies(param) {
  var cookies = document.cookie;
  var cookiesLength = cookies.length;
  var ca = cookiesLength !== 0 ? $$Array.to_list(cookies.replace(/ /g, "").split(";")) : /* [] */0;
  return List.map((function (a) {
                var cookie = a.split(/=(.+)/g);
                var match = cookie.length;
                if (match === 0 || match === 1) {
                  var data = Caml_array.get(cookie, 0);
                  return {
                          key: data !== undefined ? data : "",
                          value: ""
                        };
                }
                var data$1 = Caml_array.get(cookie, 0);
                var data$2 = Caml_array.get(cookie, 1);
                return {
                        key: data$1 !== undefined ? data$1 : "",
                        value: data$2 !== undefined ? data$2 : ""
                      };
              }), ca);
}

function getCookie(cookieName) {
  var cookies = getCookies(undefined);
  var cookie = List.exists((function (a) {
          return a.key === cookieName;
        }), cookies) ? List.find((function (a) {
            return a.key === cookieName;
          }), cookies) : undefined;
  if (cookie !== undefined) {
    return cookie.value;
  } else {
    return "";
  }
}

var setCookie = (function (cookie) {
  document.cookie=cookie
 });

export {
  getEmptyStringFrmOptionalType ,
  getCookies ,
  getCookie ,
  setCookie ,
  
}
/* No side effect */
