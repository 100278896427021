// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

function MakeLazy(Item) {
  var _addDefault = (function(comp) {
      return comp.make ? {"default": comp.make} : undefined
    });
  var ImportError = /* @__PURE__ */Caml_exceptions.create("ReactLazy-FrontendWebsite.MakeLazy(Item).ImportError");
  var make = function (func, makeProps) {
    var l = React.lazy(function (param) {
          var __x = Curry._1(func, undefined);
          return __x.then(function (comp) {
                      var m = _addDefault(comp);
                      if (m !== undefined) {
                        return Promise.resolve(Caml_option.valFromOption(m));
                      } else {
                        return Promise.reject({
                                    RE_EXN_ID: ImportError,
                                    _1: "Loaded module is not a component"
                                  });
                      }
                    });
        });
    return {
            make: l,
            makeProps: makeProps
          };
  };
  return {
          _addDefault: _addDefault,
          ImportError: ImportError,
          make: make
        };
}

var Component = {};

var _addDefault = (function(comp) {
      return comp.make ? {"default": comp.make} : undefined
    });

var ImportError = /* @__PURE__ */Caml_exceptions.create("ReactLazy-FrontendWebsite.MakeLazy(Item).ImportError");

function make(func, makeProps) {
  var l = React.lazy(function (param) {
        var __x = Curry._1(func, undefined);
        return __x.then(function (comp) {
                    var m = _addDefault(comp);
                    if (m !== undefined) {
                      return Promise.resolve(Caml_option.valFromOption(m));
                    } else {
                      return Promise.reject({
                                  RE_EXN_ID: ImportError,
                                  _1: "Loaded module is not a component"
                                });
                    }
                  });
      });
  return {
          make: l,
          makeProps: makeProps
        };
}

var L = {
  _addDefault: _addDefault,
  ImportError: ImportError,
  make: make
};

function make$1(__x) {
  return make(__x, (function (param) {
                
              }));
}

var NoProps = {
  Component: Component,
  L: L,
  make: make$1
};

var Suspense = {};

export {
  MakeLazy ,
  NoProps ,
  Suspense ,
  
}
/* react Not a pure module */
