// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";

function fromRouteString(route, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        switch (route) {
          case "rent-mini-trucks-in-dubai" :
              return /* RoutingMiniTrucks */{
                      _0: "dubai"
                    };
          case "rent-mini-trucks-in-sharjah" :
              return /* RoutingMiniTrucks */{
                      _0: "sharjah"
                    };
          default:
            return ;
        }
    case /* BD */1 :
        if (route === "rent-mini-trucks-in-dhaka") {
          return /* RoutingMiniTrucks */{
                  _0: "dhaka"
                };
        } else {
          return ;
        }
    case /* TR */2 :
        if (route === "rent-mini-trucks-in-istanbul") {
          return /* RoutingMiniTrucks */{
                  _0: "istanbul"
                };
        } else {
          return ;
        }
    
  }
}

function getCityFromRoutes(city_route) {
  return city_route._0;
}

function toRouteString(city, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        var match = $$String.lowercase_ascii(city);
        switch (match) {
          case "dubai" :
              return "/rent-mini-trucks-in-dubai";
          case "sharjah" :
              return "/rent-mini-trucks-in-sharjah";
          default:
            return "/";
        }
    case /* BD */1 :
        var match$1 = $$String.lowercase_ascii(city);
        if (match$1 === "dhaka") {
          return "/rent-mini-trucks-in-dhaka";
        } else {
          return "/";
        }
    case /* TR */2 :
        var match$2 = $$String.lowercase_ascii(city);
        if (match$2 === "istanbul") {
          return "/rent-mini-trucks-in-istanbul";
        } else {
          return "/";
        }
    
  }
}

export {
  fromRouteString ,
  getCityFromRoutes ,
  toRouteString ,
  
}
/* No side effect */
