// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "../../node_modules/rescript/lib/es6/js_exn.js";
import * as Caml_option from "../../node_modules/rescript/lib/es6/caml_option.js";
import * as Logger$Catalyst from "../../node_modules/@porterin/catalyst/src/helper-modules/logger/Logger.bs.js";
import * as Caml_js_exceptions from "../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as ErrorUtils$Catalyst from "../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";
import * as Sentry$FrontendWebsite from "./library/sentry/Sentry.bs.js";

function catchPromiseError(promise, requestUrl) {
  return promise.catch(function (error) {
              Logger$Catalyst.error("------promise caught error------");
              Logger$Catalyst.error(error);
              Sentry$FrontendWebsite.capturePromiseErrorWithContext(error, {
                    tags: {
                      type: "API_ERROR_CONTRACT"
                    },
                    contexts: {
                      request: {
                        API_URL: requestUrl
                      }
                    }
                  });
              return Promise.resolve({
                          TAG: /* Error */1,
                          _0: ErrorUtils$Catalyst.getDefaultErrorEntity(JSON.stringify(error), undefined, undefined)
                        });
            });
}

function captureException(error) {
  var data = Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error));
  if (data !== undefined) {
    Sentry$FrontendWebsite.captureException(Caml_option.valFromOption(data));
    return ;
  }
  
}

export {
  catchPromiseError ,
  captureException ,
  
}
/* Sentry-FrontendWebsite Not a pure module */
