// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as Pervasives from "../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UrlUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as CityConfig$FrontendWebsite from "../../city/CityConfig.bs.js";
import * as CityHelper$FrontendWebsite from "../../city/CityHelper.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../utils/helper/CommonHelper.bs.js";
import * as FareEstimateResultRequirement$FrontendWebsite from "./FareEstimateResultRequirement.bs.js";

function validateAddress(address, bounds, error_string) {
  if ($$String.trim(address.landmark).length !== 0 && CommonHelper$FrontendWebsite.hasAtleastOneAlphabet(address.landmark)) {
    if (address.location.lat > 0.0 && address.location.lat < 1000.0) {
      if (address.location.lat > bounds.sw.lat && address.location.lng > bounds.sw.lng && address.location.lat < bounds.ne.lat && address.location.lng < bounds.ne.lng) {
        return {
                TAG: /* Ok */0,
                _0: address
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Location is not within city bounds"
              };
      }
    } else {
      return {
              TAG: /* Error */1,
              _0: "No result found"
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: error_string
          };
  }
}

function validateCity(city) {
  if (city !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: city.value
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Select city"
          };
  }
}

function resetAddress(param) {
  return {
          landmark: "",
          location: {
            lat: 0.0,
            lng: 0.0
          }
        };
}

var getCityId = CityHelper$FrontendWebsite.cityToGeoRegionId;

function getServiceableCities__UAE(param) {
  return {
          hd: CityConfig$FrontendWebsite.UAE.dubai,
          tl: {
            hd: CityConfig$FrontendWebsite.UAE.sharjah,
            tl: /* [] */0
          }
        };
}

function getFareEstimateResultParams(form_data, is_device_mobileOpt, toStringOpt, country, param) {
  var is_device_mobile = is_device_mobileOpt !== undefined ? is_device_mobileOpt : true;
  var toString = toStringOpt !== undefined ? toStringOpt : true;
  var dict = UrlUtils$Catalyst.getURLParams(CommonHelper$FrontendWebsite.getUTmParamString(undefined));
  dict["from_address_landmark"] = window.encodeURIComponent(form_data.pickup_address.landmark);
  dict["from_address_lat"] = form_data.pickup_address.location.lat.toString();
  dict["from_address_long"] = form_data.pickup_address.location.lng.toString();
  dict["to_address_landmark"] = window.encodeURIComponent(form_data.drop_address.landmark);
  dict["to_address_lat"] = form_data.drop_address.location.lat.toString();
  dict["to_address_long"] = form_data.drop_address.location.lng.toString();
  dict["geo_region_id"] = String(CityHelper$FrontendWebsite.cityToGeoRegionId(form_data.city, country));
  dict["customer_name"] = form_data.name;
  dict["customer_mobile"] = form_data.mobile;
  dict["frequency"] = toString ? FareEstimateResultRequirement$FrontendWebsite.toString(form_data.requirement) : FareEstimateResultRequirement$FrontendWebsite.toDependencyString(form_data.requirement);
  dict["brand"] = "porter";
  dict["is_device_mobile"] = Pervasives.string_of_bool(is_device_mobile);
  dict["fare_estimate_token"] = Belt_Option.getWithDefault(form_data.fare_estimate_token, "");
  var data = form_data.consent_for_callback;
  if (data !== undefined) {
    dict["consent_for_callback"] = Pervasives.string_of_bool(data);
  }
  return dict;
}

export {
  validateAddress ,
  validateCity ,
  resetAddress ,
  getCityId ,
  getServiceableCities__UAE ,
  getFareEstimateResultParams ,
  
}
/* CommonHelper-FrontendWebsite Not a pure module */
