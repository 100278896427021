// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as TripEstimateFormApiModel$FrontendWebsite from "../api-model/TripEstimateFormApiModel.bs.js";

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(TripEstimateFormApiModel$FrontendWebsite.t_decode(json), "FareEstimateResultHashStateMapper.res");
  return {
          fare_estimate_token: res.response.fare_estimate_token
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
