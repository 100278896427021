// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CountryConfig$FrontendWebsite from "../../../app/CountryConfig.bs.js";

var defaultValue_language = {
  TAG: /* UAE */0,
  _0: /* En_UAE */0
};

function defaultValue_setLanguage(_lang) {
  
}

function defaultValue_setCountryRegions(_regions) {
  
}

var defaultValue_countryConfig = CountryConfig$FrontendWebsite.getConfig(/* UAE */0);

var defaultValue = {
  language: defaultValue_language,
  setLanguage: defaultValue_setLanguage,
  setCountryRegions: defaultValue_setCountryRegions,
  countryConfig: defaultValue_countryConfig
};

var context = React.createContext(defaultValue);

function useAppContext(param) {
  return React.useContext(context);
}

export {
  defaultValue ,
  context ,
  useAppContext ,
  
}
/* defaultValue Not a pure module */
