// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";
import * as TrackLiveTripInfo$FrontendWebsite from "../track-live-order/entity/TrackLiveTripInfo.bs.js";

var four_wheeler_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("four_wheeler.svg");

var two_wheeler_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("two_wheeler.svg");

var pickup_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("pickup_icon.svg");

var drop_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("drop_icon.svg");

var single_holder = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("single_holder.svg");

var map_banner = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("map_banner.svg");

function getVehicleIcon(orderDetail) {
  var icons = orderDetail.vehicle.icons;
  if (icons !== undefined) {
    return {
            TAG: /* Img */0,
            _0: icons.map_marker
          };
  }
  var partner = orderDetail.partner;
  if (partner !== undefined) {
    var match = TrackLiveTripInfo$FrontendWebsite.getVehicleTypeFromString(partner.vehicleType);
    if (match) {
      return {
              TAG: /* Img */0,
              _0: Belt_Option.mapWithDefault(orderDetail.vehicle.icons, four_wheeler_icon, (function (icons) {
                      return icons.map_marker;
                    }))
            };
    } else {
      return {
              TAG: /* Img */0,
              _0: Belt_Option.mapWithDefault(orderDetail.vehicle.icons, two_wheeler_icon, (function (icons) {
                      return icons.map_marker;
                    }))
            };
    }
  }
  throw {
        RE_EXN_ID: "Assert_failure",
        _1: [
          "TrackLiveTripMapVMMapper.res",
          28,
          14
        ],
        Error: new Error()
      };
}

function getCenter(orderDetail) {
  var match = orderDetail.status;
  if (match === 3 || match === 2) {
    return Belt_Option.getWithDefault(orderDetail.partnerLocation, orderDetail.pickupLocation.location);
  } else {
    var waypoint_coordinates_sum = List.fold_left((function (prev, current) {
            return {
                    lat: prev.lat + current.lat,
                    lng: prev.lng + current.lng
                  };
          }), {
          lat: 0.0,
          lng: 0.0
        }, List.map((function (ele) {
                return {
                        lat: ele.location.lat,
                        lng: ele.location.lng
                      };
              }), orderDetail.waypointLocations));
    var denominator = List.length(orderDetail.waypointLocations) + 1.0;
    var denominator_with_drop_location = List.length(orderDetail.waypointLocations) + 2.0;
    var value = orderDetail.dropLocation;
    var value$1 = orderDetail.dropLocation;
    return {
            lat: value !== undefined ? (orderDetail.pickupLocation.location.lat + waypoint_coordinates_sum.lat + value.location.lat) / denominator_with_drop_location : (orderDetail.pickupLocation.location.lat + waypoint_coordinates_sum.lat) / denominator,
            lng: value$1 !== undefined ? (orderDetail.pickupLocation.location.lng + waypoint_coordinates_sum.lng + value$1.location.lng) / denominator_with_drop_location : (orderDetail.pickupLocation.location.lng + waypoint_coordinates_sum.lng) / denominator
          };
  }
}

function getDropLocOpts(order_detail) {
  var loc = order_detail.dropLocation;
  if (loc !== undefined) {
    return {
            location: loc.location,
            icon_data: {
              TAG: /* Img */0,
              _0: drop_icon
            }
          };
  }
  
}

function getPartnerLocOpts(order_detail) {
  var loc = order_detail.partnerLocation;
  if (loc === undefined) {
    return ;
  }
  var match = order_detail.status;
  if (match > 5 || match < 2) {
    return ;
  } else {
    return {
            location: loc,
            icon_data: getVehicleIcon(order_detail)
          };
  }
}

function getWaypointOpts(order_detail) {
  return List.mapi((function (index, loc) {
                return {
                        location: loc.location,
                        icon_data: {
                          TAG: /* Elem */1,
                          _0: React.createElement("div", {
                                className: "waypoint_composite waypoint-icon"
                              }, React.createElement("div", {
                                    className: "waypoint-marker"
                                  }, React.createElement("div", undefined, String(index + 1 | 0))), React.createElement("img", {
                                    src: single_holder
                                  }))
                        }
                      };
              }), order_detail.waypointLocations);
}

function getBounds(order_detail) {
  var markerLocs = $$Array.append([
        order_detail.pickupLocation.location,
        Belt_Option.mapWithDefault(getDropLocOpts(order_detail), order_detail.pickupLocation.location, (function (opts) {
                return opts.location;
              })),
        Belt_Option.mapWithDefault(getPartnerLocOpts(order_detail), order_detail.pickupLocation.location, (function (opts) {
                return opts.location;
              }))
      ], $$Array.of_list(List.map((function (waypoint) {
                  return waypoint.location;
                }), getWaypointOpts(order_detail))));
  var bounds = new (window.google.maps.LatLngBounds)(undefined);
  return Belt_Array.reduce(markerLocs, bounds, (function (accumulator, input) {
                return accumulator.extend(input);
              }));
}

function getMapVM(order_detail) {
  return {
          pickupMarkerOptions: {
            location: order_detail.pickupLocation.location,
            icon_data: {
              TAG: /* Img */0,
              _0: pickup_icon
            }
          },
          dropMarkerOptions: order_detail.isRental ? undefined : getDropLocOpts(order_detail),
          partnerMarkerOptions: getPartnerLocOpts(order_detail),
          waypointsMarkerOptions: getWaypointOpts(order_detail),
          centerLocation: getCenter(order_detail)
        };
}

export {
  getMapVM ,
  getBounds ,
  map_banner ,
  
}
/* four_wheeler_icon Not a pure module */
