// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as AboutUsRepo$FrontendWebsite from "./AboutUsRepo.bs.js";
import * as AboutUsDummyRepo$FrontendWebsite from "./AboutUsDummyRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return AboutUsDummyRepo$FrontendWebsite;
  } else {
    return AboutUsRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
