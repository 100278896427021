// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../node_modules/rescript/lib/es6/array.js";
import * as Js_dict from "../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Option from "../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UrlUtils$Catalyst from "../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as RescriptReactRouter from "../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";

var utm_source = "utm_source";

var utm_medium = "utm_medium";

var utm_campaign = "utm_campaign";

var utm_term = "utm_term";

var utm_content = "utm_content";

var utm_params_keys = [
  utm_source,
  utm_medium,
  utm_campaign,
  utm_term,
  utm_content
];

function getUtmString(param) {
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  var urlParams = UrlUtils$Catalyst.getURLParams(url.search);
  var utm_source$1 = Belt_Option.mapWithDefault(Js_dict.get(urlParams, utm_source), "", (function (x) {
          return x;
        }));
  var utm_campaign$1 = Belt_Option.mapWithDefault(Js_dict.get(urlParams, utm_campaign), "", (function (x) {
          return x;
        }));
  return [
          utm_source$1,
          utm_campaign$1
        ];
}

function getAllUtmParams(url) {
  var dict = {};
  var urlParams = UrlUtils$Catalyst.getURLParams(url.search);
  $$Array.iter((function (utm_param) {
          dict[utm_param] = Belt_Option.mapWithDefault(Js_dict.get(urlParams, utm_param), "", (function (x) {
                  return x;
                }));
          
        }), utm_params_keys);
  return dict;
}

var web_tracking_id = "web_tracking_id";

export {
  utm_source ,
  utm_medium ,
  utm_campaign ,
  utm_term ,
  utm_content ,
  web_tracking_id ,
  utm_params_keys ,
  getUtmString ,
  getAllUtmParams ,
  
}
/* RescriptReactRouter Not a pure module */
