// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ParagraphModified$FrontendWebsite from "../../../../view/common/paragraph/paragraphModified/ParagraphModified.bs.js";
import * as PartnersTermsOfServiceStoreEn$FrontendWebsite from "../../repository/PartnersTermsOfServiceStoreEn.bs.js";

function get_uae_content(param) {
  return [ParagraphModified$FrontendWebsite.make_props(PartnersTermsOfServiceStoreEn$FrontendWebsite.uae_content_desc, undefined, /* None */2, undefined, undefined, undefined)];
}

export {
  get_uae_content ,
  
}
/* No side effect */
