// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as TrackLiveTripStateMapper$FrontendWebsite from "../mapper/TrackLiveTripStateMapper.bs.js";
import * as TrackLiveTripResponseStateMapper$FrontendWebsite from "../mapper/TrackLiveTripResponseStateMapper.bs.js";

var execute = TrackLiveTripResponseStateMapper$FrontendWebsite.execute;

var PostFormApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var PostApiResponseConverter = partial_arg(PostFormApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

function execute$1(info) {
  return TrackLiveTripStateMapper$FrontendWebsite.TrackLiveTripInfo.execute(info);
}

var TrackLiveTripInfoConverterConfig = {
  execute: execute$1
};

var partial_arg$1 = ResponseHandler$Catalyst.ResponseConverter;

var TrackLiveTripResponseConverter = partial_arg$1(TrackLiveTripInfoConverterConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

function execute$2(promoInfo) {
  return TrackLiveTripStateMapper$FrontendWebsite.TrackLiveTripMarketingInfo.execute(promoInfo);
}

var TrackLiveTripMarketingInfoConverterConfig = {
  execute: execute$2
};

var partial_arg$2 = ResponseHandler$Catalyst.ResponseConverter;

var TrackLiveTripPromoResponseConverter = partial_arg$2(TrackLiveTripMarketingInfoConverterConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  PostFormApiConfig ,
  PostApiResponseConverter ,
  TrackLiveTripInfoConverterConfig ,
  TrackLiveTripResponseConverter ,
  TrackLiveTripMarketingInfoConverterConfig ,
  TrackLiveTripPromoResponseConverter ,
  
}
/* PostApiResponseConverter Not a pure module */
