// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Cmp404$FrontendWebsite from "../website/components/default/Cmp404.bs.js";
import * as CityCmp$FrontendWebsite from "../city/view/CityCmp.bs.js";
import * as HomeCmp$FrontendWebsite from "../home/HomeCmp.bs.js";
import * as AboutUsCmp$FrontendWebsite from "../about-us/view/components/AboutUsCmp.bs.js";
import * as SupportCmp$FrontendWebsite from "../support-page/view/components/SupportCmp.bs.js";
import * as VehicleCmp$FrontendWebsite from "../vehicle/VehicleCmp.bs.js";
import * as WebsiteCmp$FrontendWebsite from "../website/WebsiteCmp.bs.js";
import * as SpotFaqsCmp$FrontendWebsite from "../spot-faqs/view/components/SpotFaqsCmp.bs.js";
import * as VehicleType$FrontendWebsite from "../vehicle/VehicleType.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../utils/NetworkUtils.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../website/types/WebsiteTypes.bs.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";
import * as VehicleRoutes$FrontendWebsite from "./VehicleRoutes.bs.js";
import * as CircularLoader$FrontendWebsite from "../website/view/components/CircularLoader.bs.js";
import * as ImagePreLoader$FrontendWebsite from "../website/view/components/ImagePreLoader.bs.js";
import * as KeywordPageCmp$FrontendWebsite from "../keywords-page/KeywordPageCmp.bs.js";
import * as CookiePolicyCmp$FrontendWebsite from "../cookie-policy/view/components/CookiePolicyCmp.bs.js";
import * as RefundPolicyCmp$FrontendWebsite from "../refund-policy/view/components/RefundPolicyCmp.bs.js";
import * as ServicesPageCmp$FrontendWebsite from "../services-page/ServicesPageCmp.bs.js";
import * as CampiagnsPageCmp$FrontendWebsite from "../offers-page/view/components/CampiagnsPageCmp.bs.js";
import * as PrivacyPolicyCmp$FrontendWebsite from "../privacy-policy/view/components/PrivacyPolicyCmp.bs.js";
import * as CityConfigManager$FrontendWebsite from "../city/CityConfigManager.bs.js";
import * as DriverPartnersCmp$FrontendWebsite from "../driver-partners/view/components/DriverPartnersCmp.bs.js";
import * as TermsOfServiceCmp$FrontendWebsite from "../terms-of-service/view/components/TermsOfServiceCmp.bs.js";
import * as PrivacyPolicyV1Cmp$FrontendWebsite from "../privacy-policy-v1/view/components/PrivacyPolicyV1Cmp.bs.js";
import * as FareEstimateResultCmp$FrontendWebsite from "../fare-estimate-result/view/FareEstimateResultCmp.bs.js";
import * as PartnersTermsOfServiceCmp$FrontendWebsite from "../terms-of-service-partner/views/components/PartnersTermsOfServiceCmp.bs.js";
import * as TermsOfServiceDubaiBranchCmp$FrontendWebsite from "../terms-of-service-dubai-branch/view/components/TermsOfServiceDubaiBranchCmp.bs.js";
import * as TrackLiveTripWrapperComponent$FrontendWebsite from "../track-live-trip/wrapper/TrackLiveTripWrapperComponent.bs.js";

function getWebsiteComponentFromRoute(websiteRoute, country) {
  if (typeof websiteRoute === "number") {
    switch (websiteRoute) {
      case /* DefaultScreen */0 :
          NetworkUtils$FrontendWebsite.captureException("404 - Unhandled Route. URL: " + window.location.href);
          return React.createElement(Cmp404$FrontendWebsite.make, {});
      case /* Home */1 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(HomeCmp$FrontendWebsite.make, {})
                    });
      case /* DriverPartners */2 :
          return React.createElement(React.Fragment, undefined, React.createElement(ImagePreLoader$FrontendWebsite.make, {
                          img_src: WebsiteTypes$FrontendWebsite.assetPathToString(AssetsManager$FrontendWebsite.getDriverPartnersImage("mobile_partner_hero_banner.jpeg")),
                          webp_src: WebsiteTypes$FrontendWebsite.assetPathToString(AssetsManager$FrontendWebsite.getDriverPartnersImage("mobile_partner_hero_banner.webp"))
                        }), React.createElement(WebsiteCmp$FrontendWebsite.make, {
                          route: websiteRoute,
                          website_cmp: React.createElement(DriverPartnersCmp$FrontendWebsite.make, {})
                        }));
      case /* AboutUs */3 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(AboutUsCmp$FrontendWebsite.make, {})
                    });
      case /* TermsOfService */4 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(TermsOfServiceCmp$FrontendWebsite.make, {})
                    });
      case /* TermsOfServiceDubaiBranch */5 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(TermsOfServiceDubaiBranchCmp$FrontendWebsite.make, {})
                    });
      case /* PartnerTermsOfService */6 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(PartnersTermsOfServiceCmp$FrontendWebsite.make, {})
                    });
      case /* RefundPolicy */7 :
          switch (country.TAG | 0) {
            case /* BD */1 :
                return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                            route: websiteRoute,
                            website_cmp: React.createElement(RefundPolicyCmp$FrontendWebsite.make, {})
                          });
            case /* UAE */0 :
            case /* TR */2 :
                return null;
            
          }
      case /* CookiePolicy */8 :
          switch (country.TAG | 0) {
            case /* UAE */0 :
            case /* BD */1 :
                return null;
            case /* TR */2 :
                return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                            route: websiteRoute,
                            website_cmp: React.createElement(CookiePolicyCmp$FrontendWebsite.make, {})
                          });
            
          }
      case /* SpotFaq */9 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(SpotFaqsCmp$FrontendWebsite.make, {})
                    });
      case /* Support */10 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(SupportCmp$FrontendWebsite.make, {})
                    });
      case /* ServicesPage */11 :
          switch (country.TAG | 0) {
            case /* UAE */0 :
                return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                            route: websiteRoute,
                            website_cmp: React.createElement(ServicesPageCmp$FrontendWebsite.make, {})
                          });
            case /* BD */1 :
            case /* TR */2 :
                return React.createElement(Cmp404$FrontendWebsite.make, {});
            
          }
      case /* CourierPage */12 :
          switch (country.TAG | 0) {
            case /* UAE */0 :
                var vehicle = VehicleType$FrontendWebsite.fromString("Courier", country);
                return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                            route: websiteRoute,
                            website_cmp: React.createElement(VehicleCmp$FrontendWebsite.make, {
                                  city: "dubai",
                                  vehicle: vehicle,
                                  slug: "courier"
                                })
                          });
            case /* BD */1 :
            case /* TR */2 :
                return React.createElement(Cmp404$FrontendWebsite.make, {});
            
          }
      
    }
  } else {
    switch (websiteRoute.TAG | 0) {
      case /* CityPage */0 :
          var city = websiteRoute._0;
          CityConfigManager$FrontendWebsite.getConfig(city, country);
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(CityCmp$FrontendWebsite.make, {
                            city: city
                          })
                    });
      case /* VehiclePage */1 :
          var vehicle$1 = websiteRoute._1;
          var city$1 = websiteRoute._0;
          CityConfigManager$FrontendWebsite.getConfig(city$1, country);
          var slug = VehicleRoutes$FrontendWebsite.getSlugFromVehicleTypeAndCity(vehicle$1, city$1);
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(VehicleCmp$FrontendWebsite.make, {
                            city: city$1,
                            vehicle: vehicle$1,
                            slug: slug
                          })
                    });
      case /* TrackLiveTrip */2 :
          return React.createElement(React.Suspense, {
                      children: React.createElement(TrackLiveTripWrapperComponent$FrontendWebsite.make, {
                            booking_id: websiteRoute._0,
                            customer_uuid: websiteRoute._1
                          }),
                      fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                    });
      case /* PrivacyPolicy */3 :
          var version = websiteRoute._0;
          if (version === "v1") {
            return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                        route: websiteRoute,
                        website_cmp: React.createElement(React.Suspense, {
                              children: React.createElement(PrivacyPolicyV1Cmp$FrontendWebsite.make, {}),
                              fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                            })
                      });
          }
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(React.Suspense, {
                            children: React.createElement(PrivacyPolicyCmp$FrontendWebsite.make, {}),
                            fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                          })
                    });
      case /* FareEstimateResult */4 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(FareEstimateResultCmp$FrontendWebsite.make, {
                            form_data: websiteRoute._0
                          })
                    });
      case /* OffersPage */5 :
          return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                      route: websiteRoute,
                      website_cmp: React.createElement(CampiagnsPageCmp$FrontendWebsite.make, {
                            id: websiteRoute.discount_id
                          })
                    });
      case /* KeywordPage */6 :
          switch (country.TAG | 0) {
            case /* UAE */0 :
                return React.createElement(WebsiteCmp$FrontendWebsite.make, {
                            route: websiteRoute,
                            website_cmp: React.createElement(KeywordPageCmp$FrontendWebsite.make, {
                                  keyword: websiteRoute._0
                                })
                          });
            case /* BD */1 :
            case /* TR */2 :
                return React.createElement(Cmp404$FrontendWebsite.make, {});
            
          }
      
    }
  }
}

export {
  getWebsiteComponentFromRoute ,
  
}
/* react Not a pure module */
