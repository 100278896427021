// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as VehicleRepoConfig$FrontendWebsite from "./VehicleRepoConfig.bs.js";
import * as CMSDataSourceConfig$FrontendWebsite from "../../../utils/cms/CMSDataSourceConfig.bs.js";

var dummyVehicleResponse = {
    "geo_region": {
      "id": 1,
      "name": "Dubai",
    },
    "selected_vehicle": {
      "id": 1,
      "display_name": "1 Ton 7 Feet",
      "vehicle_type": "1 Ton 7 Feet",
      "geo_region": {
        "id": 1, 
        "name": "Dubai",
      },
      "image_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/large_vehicles/tata_ace_closed-e92a36b3508e6e3b1804fd2015adbfb6c948bc35afab36699440785ed83b5b24.png",
      "image_alt_text": "1 Ton 7 Feet in Dhaka",
      "vehicle_icon_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/vehicle_icons/tata_ace-0ef1cc8a772e4180aa010c40bf34202934ab66bbbfd00f241f8182acc70cac59.svg",
      "description": "Want to send your goods within Dubai? Worry not! We have got you covered. Book Porter 1 Ton 7 Feet truck service and get your commercial goods delivered safely \u0026 smoothly with on-demand pickup. Get economical and affordable pricing with professional services. These Pickup - 1 Tonne trucks can carry industrial materials, ceramics, sanitary, hardwares as well as home furniture and appliances. We are here to complete all your instant delivery needs. Book Porter and avail rental truck services at the lowest fares. These Pickup - 1 Tonne trucks can carry goods of home appliances and industrial machinery as well.",
      "description_2": "Want to send your goods within Dubai? Worry not! We have got you covered. Book Porter 1 Ton 7 Feet truck service and get your commercial goods delivered safely \u0026 smoothly with on-demand pickup. Get economical and affordable pricing with professional services. These Pickup - 1 Tonne trucks can carry industrial materials, ceramics, sanitary, hardwares as well as home furniture and appliances. We are here to complete all your instant delivery needs. Book Porter and avail rental truck services at the lowest fares. These Pickup - 1 Tonne trucks can carry goods of home appliances and industrial machinery as well.",
      "description_3": "Want to send your goods within Dubai? Worry not! We have got you covered. Book Porter 1 Ton 7 Feet truck service and get your commercial goods delivered safely \u0026 smoothly with on-demand pickup. Get economical and affordable pricing with professional services. These Pickup - 1 Tonne trucks can carry industrial materials, ceramics, sanitary, hardwares as well as home furniture and appliances. We are here to complete all your instant delivery needs. Book Porter and avail rental truck services at the lowest fares. These Pickup - 1 Tonne trucks can carry goods of home appliances and industrial machinery as well.",
      "capacity": {
        "value": 1050.0,
        "unit": "kg",
      },
      "capacity_display": "1050kg",
      "size": {
        "length": {
          "value": 7.6,
          "unit": "ft",
        },
        "breadth": {
          "value": 5.4,
          "unit": "ft",
        },
        "height": {
          "value": 4.2,
          "unit": "ft",
        },
        "type": "goods_vehicle",
      },
      "size_display": "7.6ft x 5.4ft x 4.2ft",
      "base_fare": {
        "value": 30.0,
        "currency": "AED",
      },
      "base_fare_display": "AED 30",
      "free_time_distance_text": "Base fare includes 2.0 km distance and 90 minutes of time",
      "url": "/rent-mini-trucks-in-dhaka/1-ton-7feet",
      "css_class": "pickup_1tonne",
      "rental_package": null,
      "snippet_details": {
        "heading_text": "Transport rate for Pick- 1 Tonne up in Dubai",
        "content": "Get best moving prices per km and per day using Porter app. Base rate starts from AED 30, which includes 2.0 km distance and 90 minutes waiting time. Pricing may vary based on your locality, road tax \u0026 parking etc are applicable over and above the ride fare. Download the Porter app \u0026 get the exact transportation charges and get started with your instant deliveries.",
        "content_2": "Get best moving prices per km and per day using Porter app. Base rate starts from AED 30, which includes 2.0 km distance and 90 minutes waiting time. Pricing may vary based on your locality, road tax \u0026 parking etc are applicable over and above the ride fare. Download the Porter app \u0026 get the exact transportation charges and get started with your instant deliveries.",
      },
    },
    "remaining_vehicles": [
      {
        "id": 2,
        "display_name": "12 Feet",
        "vehicle_type": "12 Feet",
        "geo_region": {
          "id": 1,
          "name": "Dubai",
        },
        "image_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/large_vehicles/canter_3ton-e90b94fb3bffacd6d30fa80c63256dfb38033266674f2f5f0739d6c4de2fe5a6.png",
        "image_alt_text": "12 Feet in Dubai",
        "vehicle_icon_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/vehicle_icons/tata_ace-0ef1cc8a772e4180aa010c40bf34202934ab66bbbfd00f241f8182acc70cac59.svg",
        "description": "Dubai, the key financial and trading hub, can now experience goods delivery with Porter Canter. You can book Canter trucks anytime, anywhere and at affordable prices. This rental truck service can help you move medium to heavy loads up to 3500 kgs. Porter Canter trucks will also solve all your house shifting needs. Get on-demand canter truck bookings with instant delivery.We are here to help you with your deliveries, all you need to do is book Porter, and get all your commercial/personal goods picked up \u0026 sent to the desired location. These truck rentals can be used to deliver machines, equipment, spare parts, metals from their warehouses to the desired location.",
        "capacity": {
          "value": 3510.0,
          "unit": "kg",
        },
        "capacity_display": "3510kg",
        "size": {
          "length": {
            "value": 15.2,
            "unit": "ft",
          },
          "breadth": {
            "value": 5.5,
            "unit": "ft",
          },
          "height": {
            "value": 6.5,
            "unit": "ft",
          },
          "type": "goods_vehicle",
        },
        "size_display": "15.2ft x 5.5ft x 6.5ft",
        "base_fare": {
          "value": 57.0,
          "currency": "AED",
        },
        "base_fare_display": "AED 57",
        "free_time_distance_text": "Base fare includes 3.0 km distance and 140 minutes of time",
        "url": "/rent-mini-trucks-in-dhaka/12-feet",
        "css_class": "canter_3tonne",
        "rental_package": null,
        "snippet_details": {
          "heading_text": "Transport Rate for Canter in Dubai",
          "content": "Book Porter Canter truck at the best charge. Looking for a logistics partner to safely transport \u0026 move your personal or commercial goods? Porter is the right place. We provide hassle-free services, verified drivers and low costs for you to grow your business in Dubai. The delivery costs start at AED 57, which includes 3.0 km distance and 140 minutes waiting time. Download the Porter app \u0026 get the exact transportation charges! Pricing may vary based on your locality. Road tax \u0026 parking etc are applicable over and above the ride fare.",
          "content_2": "Get best moving prices per km and per day using Porter app. Base rate starts from AED 30, which includes 2.0 km distance and 90 minutes waiting time. Pricing may vary based on your locality, road tax \u0026 parking etc are applicable over and above the ride fare. Download the Porter app \u0026 get the exact transportation charges and get started with your instant deliveries.",
        },
      },
      {
        "id": 7,
        "display_name": "2 Wheeler",
        "vehicle_type": "2 Wheeler",
        "geo_region": {
          "id": 1,
          "name": "Dubai",
        },
        "image_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/large_vehicles/2_wheeler-947e3c6aac74dcdd11fdd4059e4ee72132b276200dff063d6dff2c445f7aab5b.png",
        "image_alt_text": "2 Wheeler in Dubai",
        "vehicle_icon_url": "https://dxug9q8ntkbri.cloudfront.net/assets/website_revamp/vehicle_icons/2_wheeler-2d6c4e21b352bc2d75797c082b31f272e574d31acc376b54a1d8a0729f91374f.svg",
        "description": "Need instant delivery? Worry not! Porter is here to help you with 2 wheeler delivery services. Send small packages that are needed to be delivered within Dubai instantly. Forgot your house key, charger or want to send an important package to your client? At Porter, we’ve got you covered for all your transportation \u0026 delivery requirements at affordable prices. This service can be availed for both personal as well as commercial needs for smaller packages upto 20 kgs. Get a 2 wheeler in 15 mins with Porter. Get your instant deliveries sorted with us!",
        "capacity": {
          "value": 20.0,
          "unit": "kg",
        },
        "capacity_display": "20kg",
        "size": {
          "length": {
            "value": 1.47,
            "unit": "ft",
          },
          "breadth": {
            "value": 1.47,
            "unit": "ft",
          },
          "height": {
            "value": 1.47,
            "unit": "ft",
          },
          "type": "goods_vehicle",
        },
        "size_display": "1.47ft x 1.47ft x 1.47ft",
        "base_fare": {
          "value": 9.0,
          "currency": "AED",
        },
        "base_fare_display": "AED 9",
        "free_time_distance_text": "Base fare includes 5.0 km distance and 30 minutes of time",
        "url": "/bike-parcel-delivery-dubai",
        "css_class": "two_wheeler",
        "rental_package": null,
        "snippet_details": {
          "heading_text": "Bike parcel delivery \u0026 Delivery Services Charges",
          "content": "Don't let your goods get stuck in the city's infamous traffic, just book a Porter 2 wheeler to send across your small packages weighing up to 20kgs. Looking for someone to pick up \u0026 drop your important packages from the office? Choose Porter 2 wheeler. It’s really that simple. Door to door delivery service at the best transparent rates. Base rate starts from AED 9, which includes 5.0 km distance and 30 minutes waiting time. Pricing may vary based on your locality. Book Porter 2 wheeler now!",
          "content_2": "Get best moving prices per km and per day using Porter app. Base rate starts from AED 30, which includes 2.0 km distance and 90 minutes waiting time. Pricing may vary based on your locality, road tax \u0026 parking etc are applicable over and above the ride fare. Download the Porter app \u0026 get the exact transportation charges and get started with your instant deliveries.",
        },
      },
    ],
    "offers": null,
  };

function getVehiclePageInfo(_city_name, _vehicle, _country, _language) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummyVehicleResponse
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(VehicleRepoConfig$FrontendWebsite.VehicleResponseConverter.execute, result);
                            });
                }), "getVehiclePageInfo");
}

function getVehiclePageCMSData(cityName, vehicleSlug, country, language) {
  var requestUrl = CMSDataSourceConfig$FrontendWebsite.getVehicleInventoryRequestUrl(country, language, vehicleSlug, cityName);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, undefined, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(VehicleRepoConfig$FrontendWebsite.VehicleCMSResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  dummyVehicleResponse ,
  getVehiclePageInfo ,
  getVehiclePageCMSData ,
  
}
/* NetworkV2-Catalyst Not a pure module */
