// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function coupon_content_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var scheme_id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "scheme_id"), null));
  if (scheme_id.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var coupon_code = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "coupon_code"), null));
      if (coupon_code.TAG === /* Ok */0) {
        var short_description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "short_description"), null));
        if (short_description.TAG === /* Ok */0) {
          var long_description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "long_description"), null));
          if (long_description.TAG === /* Ok */0) {
            var validity_text = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "validity_text"), null));
            if (validity_text.TAG === /* Ok */0) {
              var main_image_link = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "main_image_link"), null));
              if (main_image_link.TAG === /* Ok */0) {
                var terms_and_conditions = Decco.optionFromJson((function (param) {
                        return Decco.arrayFromJson(Decco.stringFromJson, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "terms_and_conditions"), null));
                if (terms_and_conditions.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            scheme_id: scheme_id._0,
                            title: title._0,
                            coupon_code: coupon_code._0,
                            short_description: short_description._0,
                            long_description: long_description._0,
                            validity_text: validity_text._0,
                            main_image_link: main_image_link._0,
                            terms_and_conditions: terms_and_conditions._0
                          }
                        };
                }
                var e = terms_and_conditions._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".terms_and_conditions" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = main_image_link._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".main_image_link" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = validity_text._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".validity_text" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = long_description._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".long_description" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = short_description._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".short_description" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = coupon_code._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".coupon_code" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = scheme_id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".scheme_id" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.arrayFromJson(coupon_content_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  coupon_content_decode ,
  t_decode ,
  
}
/* No side effect */
