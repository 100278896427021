// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as SendSmsApiModel$FrontendWebsite from "../api-model/SendSmsApiModel.bs.js";

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(SendSmsApiModel$FrontendWebsite.t_decode(json), "SendSmsStateMapper.res");
  return {
          status: res.status,
          message: res.response
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
