// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as FaqEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/FaqEntityMapper.bs.js";
import * as SpotFAQsCMSApiModel$FrontendWebsite from "../api-model/SpotFAQsCMSApiModel.bs.js";

function dataToEntity(data) {
  return {
          attributes: {
            title: data.attributes.title,
            faqs: FaqEntityMapper$FrontendWebsite.faqsToEntity(data.attributes.faqs)
          }
        };
}

function apiModelToEntity(model) {
  return {
          data: dataToEntity(model.data)
        };
}

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(SpotFAQsCMSApiModel$FrontendWebsite.t_decode(json), "HomePageCMSEntityMapper.res");
  return {
          data: dataToEntity(model.data)
        };
}

export {
  dataToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
