// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Row$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Row.bs.js";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";
import * as DiscountFareCmp$FrontendWebsite from "../DiscountFareCmp.bs.js";

function showVehicleInfo(label, value) {
  return React.createElement("div", {
              className: "info"
            }, React.createElement("div", {
                  className: "prefix"
                }, label), React.createElement("div", {
                  className: "suffix"
                }, value));
}

function FareEstimateResultMobileVehicleCardCmp(Props) {
  var vehicle = Props.vehicle;
  var vehicleSection = Props.vehicleSection;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  var discount = vehicle.discount;
  var discount$1 = vehicle.discount;
  return React.createElement("div", {
              className: "fare-estimate-result-mobile-vehicle-card-container"
            }, discount !== undefined ? React.createElement("div", {
                    className: "discount-container"
                  }, React.createElement("div", {
                        className: "value"
                      }, discount.value + "% " + vehicleSection.offLabel), React.createElement("div", {
                        className: "maximum-discount"
                      }, vehicleSection.uptoLabel + " " + discount.max_discount)) : null, React.createElement(Row$Catalyst.make, {
                  children: null
                }, React.createElement("div", {
                      className: "vehicle-title"
                    }, React.createElement("div", {
                          className: "name"
                        }, vehicle.display_name), React.createElement("div", {
                          className: "vehicle-icon-wrapper"
                        }, React.createElement("img", {
                              className: "icon",
                              alt: vehicle.icon.alt_text,
                              src: vehicle.icon.path
                            }))), React.createElement("div", {
                      className: "vehicle-details"
                    }, discount$1 !== undefined ? React.createElement(DiscountFareCmp$FrontendWebsite.make, {
                            old_fare: vehicle.base_fare,
                            new_fare: discount$1.discounted_fare
                          }) : React.createElement("div", {
                            className: "fare"
                          }, vehicle.base_fare), React.createElement("div", undefined, showVehicleInfo(vehicleSection.capacityLabel, vehicle.capacity), showVehicleInfo(vehicleSection.sizeLabel, vehicle.size)))));
}

var make = FareEstimateResultMobileVehicleCardCmp;

export {
  showVehicleInfo ,
  make ,
  
}
/* react Not a pure module */
