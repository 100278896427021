// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as FareEstimateRepo$FrontendWebsite from "./FareEstimateRepo.bs.js";
import * as DummyFareEstimateRepo$FrontendWebsite from "./DummyFareEstimateRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyFareEstimateRepo$FrontendWebsite;
  } else {
    return FareEstimateRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
