// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$Catalyst from "../../../node_modules/@porterin/catalyst/src/components/generic/icons/Icon.bs.js";
import * as CookieUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/CookieUtils.bs.js";
import * as GAConfig$FrontendWebsite from "../library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../context/app-contexts/AppContext.bs.js";
import * as JsBindings$FrontendWebsite from "../library/bindings/JsBindings.bs.js";
import * as EventsHelper$FrontendWebsite from "../context/events-manager/EventsHelper.bs.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../app/routing/WebsiteConfig.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../../app/cookie-consent/utils/CookieConsentHelper.bs.js";

function LanguageSelectorCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var setLanguage = match.setLanguage;
  var language = match.language;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var cookieConsent = CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var languageValue;
  switch (language.TAG | 0) {
    case /* UAE */0 :
        languageValue = {
          key: "1",
          value: "en_uae",
          text: "English"
        };
        break;
    case /* BD */1 :
        languageValue = language._0 ? ({
              key: "2",
              value: "bd",
              text: "বাংলা"
            }) : ({
              key: "1",
              value: "en_bd",
              text: "English"
            });
        break;
    case /* TR */2 :
        languageValue = language._0 ? ({
              key: "2",
              value: "tr",
              text: "Türkçe"
            }) : ({
              key: "1",
              value: "en_tr",
              text: "English"
            });
        break;
    
  }
  var items;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        items = {
          hd: {
            key: "1",
            value: "en_uae",
            text: "English"
          },
          tl: /* [] */0
        };
        break;
    case /* BD */1 :
        items = {
          hd: {
            key: "1",
            value: "en_bd",
            text: "English"
          },
          tl: {
            hd: {
              key: "2",
              value: "bd",
              text: "Bangla"
            },
            tl: /* [] */0
          }
        };
        break;
    case /* TR */2 :
        items = {
          hd: {
            key: "1",
            value: "en_tr",
            text: "English"
          },
          tl: {
            hd: {
              key: "2",
              value: "tr",
              text: "Türkçe"
            },
            tl: /* [] */0
          }
        };
        break;
    
  }
  var publishLanguageChangeGAEvent = function (selectedLanguage) {
    return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.viewDetailsClicked, {
                event_category: WebsiteConfig$FrontendWebsite.ga_title_home,
                event_label: selectedLanguage,
                language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
              });
  };
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "LanguageSelectorCmp.res",
                50,
                7
              ],
              Error: new Error()
            };
    case /* BD */1 :
        tmp = React.createElement("img", {
              src: AssetsManager$FrontendWebsite.getLangaugeImage("en_bd.svg")
            });
        break;
    case /* TR */2 :
        tmp = React.createElement("img", {
              src: AssetsManager$FrontendWebsite.getLangaugeImage("en_tr.svg")
            });
        break;
    
  }
  return React.createElement("div", {
              className: "language-selector"
            }, React.createElement("div", {
                  className: "selected"
                }, tmp, React.createElement("p", undefined, languageValue.text), React.createElement(Icon$Catalyst.KeyboardArrowDownIconIcon.make, {})), React.createElement("div", {
                  className: "selector-wrapper"
                }, React.createElement("ul", undefined, $$Array.of_list(List.map((function (e) {
                                var match = e.value;
                                var tmp;
                                switch (match) {
                                  case "bd" :
                                      tmp = "ব";
                                      break;
                                  case "en_bd" :
                                  case "en_tr" :
                                  case "en_uae" :
                                      tmp = "En";
                                      break;
                                  case "tr" :
                                      tmp = "Tr";
                                      break;
                                  default:
                                    tmp = "";
                                }
                                return React.createElement("li", {
                                            onClick: (function (param) {
                                                publishLanguageChangeGAEvent(e.value);
                                                var match = e.value;
                                                var tmp;
                                                switch (match) {
                                                  case "bd" :
                                                      CookieUtils$Catalyst.setCookie("selected_language=bd");
                                                      JsBindings$FrontendWebsite.reloadPage(undefined);
                                                      tmp = {
                                                        TAG: /* BD */1,
                                                        _0: /* BD */1
                                                      };
                                                      break;
                                                  case "en_bd" :
                                                      CookieUtils$Catalyst.setCookie("selected_language=english");
                                                      JsBindings$FrontendWebsite.reloadPage(undefined);
                                                      tmp = {
                                                        TAG: /* BD */1,
                                                        _0: /* En_BD */0
                                                      };
                                                      break;
                                                  case "en_tr" :
                                                      if (cookieConsent.functional) {
                                                        CookieUtils$Catalyst.setCookie("selected_language=english");
                                                      }
                                                      JsBindings$FrontendWebsite.reloadPage(undefined);
                                                      tmp = {
                                                        TAG: /* TR */2,
                                                        _0: /* En_TR */0
                                                      };
                                                      break;
                                                  case "en_uae" :
                                                      tmp = {
                                                        TAG: /* UAE */0,
                                                        _0: /* En_UAE */0
                                                      };
                                                      break;
                                                  case "tr" :
                                                      if (cookieConsent.functional) {
                                                        CookieUtils$Catalyst.setCookie("selected_language=tr");
                                                      }
                                                      JsBindings$FrontendWebsite.reloadPage(undefined);
                                                      tmp = {
                                                        TAG: /* TR */2,
                                                        _0: /* TR */1
                                                      };
                                                      break;
                                                  default:
                                                    tmp = {
                                                      TAG: /* UAE */0,
                                                      _0: /* En_UAE */0
                                                    };
                                                }
                                                return Curry._1(setLanguage, tmp);
                                              })
                                          }, React.createElement("p", undefined, tmp), React.createElement("p", undefined, e.text));
                              }), items)))));
}

var make = LanguageSelectorCmp;

export {
  make ,
  
}
/* react Not a pure module */
