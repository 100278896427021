// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as FaqEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/FaqEntityMapper.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as DriverPartnerCMSApiModel$FrontendWebsite from "../api-model/DriverPartnerCMSApiModel.bs.js";

function decodePoint(data) {
  return $$Array.map((function (e) {
                return {
                        title: e.title,
                        description: e.description
                      };
              }), data);
}

function decodeAppDownload(data) {
  return {
          title: data.title,
          googlePlayStoreUrl: data.googlePlayStoreUrl,
          googlePlayStoreBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.googlePlayStoreBanner)
        };
}

function decodeHeroSection(data) {
  return {
          title: data.title,
          description: data.description,
          image: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.image),
          appDownload: decodeAppDownload(data.appDownload)
        };
}

function decodeSection(data) {
  return {
          title: data.title,
          image: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.image),
          points: decodePoint(data.points)
        };
}

function apiModelToEntity(json) {
  var data = json.data.attributes;
  return {
          heroSection: decodeHeroSection(data.heroSection),
          porterAdvantage: decodeSection(data.porterAdvantage),
          lifeEasySection: decodeSection(data.lifeEasySection),
          ownVehicleSection: decodeSection(data.ownVehicleSection),
          faqs: FaqEntityMapper$FrontendWebsite.faqsToEntity(data.faqs)
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(DriverPartnerCMSApiModel$FrontendWebsite.t_decode(json), "DriverPartnerCMSEntityMapper.res"));
}

export {
  decodePoint ,
  decodeAppDownload ,
  decodeHeroSection ,
  decodeSection ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
