// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";

function getBanner(bannerData) {
  var match = bannerData.attributes;
  return {
          alternativeText: match.alternativeText,
          url: match.url,
          ext: match.ext
        };
}

function getBanners(banners) {
  return $$Array.map(getBanner, banners.data);
}

export {
  getBanner ,
  getBanners ,
  
}
/* No side effect */
