// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";

function SocialMediaCmp(Props) {
  var data = Props.data;
  var targetOpt = Props.target;
  var target = targetOpt !== undefined ? targetOpt : "_self";
  return React.createElement("div", {
              className: "icons"
            }, $$Array.mapi((function (i, link) {
                    var icon = data.icon;
                    return React.createElement("a", {
                                key: link,
                                "aria-label": "",
                                className: "common common" + String(i + 1 | 0),
                                style: {
                                  backgroundImage: "url(" + (
                                    icon !== undefined ? icon.url : ""
                                  ) + ")"
                                },
                                href: link,
                                target: target
                              }, null);
                  }), data.links));
}

var make = SocialMediaCmp;

export {
  make ,
  
}
/* react Not a pure module */
