// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactGa4 from "react-ga4";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as ScriptFetcher$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/script-fetcher/ScriptFetcher.bs.js";

var gtagExecCommand = (function(measurement_id) {
     window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments);};
    gtag('js', new Date());
    gtag('config', measurement_id, {'send_page_view':false});
  });

function useGaInitializer(load_ga_scriptOpt, measurement_id, tracker_name) {
  var load_ga_script = load_ga_scriptOpt !== undefined ? load_ga_scriptOpt : false;
  var match = React.useState(function () {
        return null;
      });
  var setState = match[1];
  var script_url = "https://www.googletagmanager.com/gtag/js?id=" + measurement_id;
  React.useEffect((function () {
          var initializeOptions = {
            gaOptions: Belt_Option.mapWithDefault(tracker_name, undefined, (function (tracker_name) {
                    return {
                            name: tracker_name
                          };
                  }))
          };
          ReactGa4.default.initialize(measurement_id, initializeOptions);
          Curry._1(setState, (function (param) {
                  if (load_ga_script) {
                    gtagExecCommand(measurement_id);
                    return React.createElement(ScriptFetcher$Catalyst.make, {
                                scripts: [script_url],
                                children: null
                              });
                  } else {
                    return null;
                  }
                }));
          
        }), []);
  return match[0];
}

export {
  useGaInitializer ,
  
}
/* react Not a pure module */
