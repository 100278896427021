// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as HomeConfig$FrontendWebsite from "../../../../home/HomeConfig.bs.js";
import * as PicturesCmp$FrontendWebsite from "../../../../website/view/components/PicturesCmp.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../../../website/types/WebsiteTypes.bs.js";
import * as NativeCarouselCmp$FrontendWebsite from "../../../../website/view/components/img-slider/NativeCarouselCmp.bs.js";
import * as BackgroundImageCmp$FrontendWebsite from "../../../../website/view/components/BackgroundImageCmp.bs.js";
import * as FareEstimateResultAppsDesktopCmp$FrontendWebsite from "./FareEstimateResultAppsDesktopCmp.bs.js";
import * as FareEstimateResultBookingStepDesktopCmp$FrontendWebsite from "./FareEstimateResultBookingStepDesktopCmp.bs.js";

function FareEstimateResultReqCallbackDesktopCmp(Props) {
  var appDownloadSection = Props.appDownloadSection;
  return React.createElement("div", {
              className: "fare-estimate-result-desktop-download-app-container"
            }, React.createElement("div", {
                  className: "title"
                }, appDownloadSection.title), React.createElement("div", {
                  className: "bar"
                }), React.createElement(FareEstimateResultAppsDesktopCmp$FrontendWebsite.make, {
                  data: appDownloadSection
                }), React.createElement(FareEstimateResultBookingStepDesktopCmp$FrontendWebsite.make, {
                  step: 2,
                  heading: appDownloadSection.point2,
                  children: null
                }), React.createElement(FareEstimateResultBookingStepDesktopCmp$FrontendWebsite.make, {
                  step: 3,
                  heading: appDownloadSection.point3,
                  children: null
                }), React.createElement("div", {
                  className: "feature-swiper-display"
                }, React.createElement(BackgroundImageCmp$FrontendWebsite.make, {
                      background_img: WebsiteTypes$FrontendWebsite.assetPathToString(HomeConfig$FrontendWebsite.phoneSkin),
                      class_name: "phone-display",
                      height: "50rem",
                      width: "35rem"
                    }), React.createElement("div", {
                      className: "phone-swiper-container"
                    }, React.createElement(NativeCarouselCmp$FrontendWebsite.make, {
                          class_name: "carousel-phone",
                          children: $$Array.map((function (pic) {
                                  return React.createElement("div", undefined, React.createElement(PicturesCmp$FrontendWebsite.make, {
                                                  class_name: "phone-display-img",
                                                  img_src: WebsiteTypes$FrontendWebsite.assetPathToString(pic.url),
                                                  webp_src: WebsiteTypes$FrontendWebsite.assetPathToString(pic.url),
                                                  alt_text: "",
                                                  height: "34rem",
                                                  width: "19.4rem"
                                                }));
                                }), appDownloadSection.phoneCarousel.phoneCarouselBanners),
                          show_indicators: false,
                          delay: 1500,
                          is_mobile: false
                        }))));
}

var make = FareEstimateResultReqCallbackDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
