// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../../Config.bs.js";
import * as WebsiteCMSRepo$FrontendWebsite from "./WebsiteCMSRepo.bs.js";
import * as DummyWebsiteCMSRepo$FrontendWebsite from "./DummyWebsiteCMSRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyWebsiteCMSRepo$FrontendWebsite;
  } else {
    return WebsiteCMSRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
