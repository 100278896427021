// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Api from "@react-google-maps/api";
import * as Config$FrontendWebsite from "../../../../app/Config.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../../app/website/view/components/CircularLoader.bs.js";

function MapComponentMod(Props) {
  var lib_nameOpt = Props.lib_name;
  var config = Props.config;
  var children = Props.children;
  var lib_name = lib_nameOpt !== undefined ? lib_nameOpt : /* GoogleMap */0;
  if (lib_name) {
    return null;
  }
  var match = Api.useJsApiLoader({
        id: "google-maps-is-loaded",
        googleMapsApiKey: Config$FrontendWebsite.Env.getGoogleMapApiKey(undefined),
        libraries: Config$FrontendWebsite.Env.libraries
      });
  if (match.isLoaded) {
    return React.createElement(Api.GoogleMap, {
                center: config.center,
                zoom: Belt_Option.getWithDefault(config.zoom, 11),
                id: config.map_elem_id,
                mapContainerClassName: config.mapContainerClassName,
                clickableIcons: config.clickableIcons,
                onLoad: config.onLoad,
                onTilesLoaded: config.onTilesLoaded,
                options: config.options,
                ref: config.ref,
                onDragEnd: Belt_Option.getWithDefault(config.onDragEnd, (function (unit) {
                        return unit;
                      })),
                children: children
              });
  } else if (match.loadError !== undefined) {
    return React.createElement(React.Fragment, undefined);
  } else {
    return React.createElement("div", {
                className: "centered"
              }, React.createElement(CircularLoader$FrontendWebsite.make, {}));
  }
}

var make = MapComponentMod;

export {
  make ,
  
}
/* react Not a pure module */
