// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as FaqEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/FaqEntityMapper.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as HomePageCMSApiModel$FrontendWebsite from "../api-model/HomePageCMSApiModel.bs.js";
import * as AppDownloadEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/AppDownloadEntityMapper.bs.js";
import * as TripEstimateEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/TripEstimateEntityMapper.bs.js";
import * as ServiceableCitiesEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/ServiceableCitiesEntityMapper.bs.js";

function featureItemsToEntity(featureItems) {
  return $$Array.map((function (featureItem) {
                return {
                        order: featureItem.order,
                        title: featureItem.title,
                        subText: featureItem.subText
                      };
              }), featureItems);
}

function phoneCarouselToEntity(phoneCarousel) {
  return {
          phoneCarouselBanners: MediaEntityMapper$FrontendWebsite.bannersToEntity(phoneCarousel.phoneCarouselBanners),
          phoneSkin: MediaEntityMapper$FrontendWebsite.bannerToEntity(phoneCarousel.phoneSkin)
        };
}

function orderTrackingSectionToEntity(orderTrackingSection) {
  return {
          mobileAppLabel: orderTrackingSection.mobileAppLabel,
          trackOrderLabel: orderTrackingSection.trackOrderLabel,
          conjunctionLabel: orderTrackingSection.conjunctionLabel
        };
}

function featuresToEntity(features) {
  return {
          phoneCarousel: phoneCarouselToEntity(features.phoneCarousel),
          appDownload: AppDownloadEntityMapper$FrontendWebsite.appDownloadToEntity(features.appDownload),
          featureItems: featureItemsToEntity(features.featureItems),
          orderTrackingSection: orderTrackingSectionToEntity(features.orderTrackingSection)
        };
}

function uspsToEntity(usps) {
  return $$Array.map((function (usp) {
                return {
                        title: usp.title,
                        subText: usp.subText,
                        uspImage: MediaEntityMapper$FrontendWebsite.bannerToEntity(usp.uspImage),
                        key: usp.key
                      };
              }), usps);
}

function offeringsToEntity(offerings) {
  return {
          title: offerings.title,
          usps: uspsToEntity(offerings.usps)
        };
}

function vehicleDetailsRowsToEntity(vehicleDetailsRows) {
  return $$Array.map((function (vehicleDetailsRow) {
                return {
                        order: vehicleDetailsRow.order,
                        label: vehicleDetailsRow.label,
                        value: vehicleDetailsRow.value
                      };
              }), vehicleDetailsRows);
}

function homePageVehicleCardToEntity(homePageVehicleCard) {
  return {
          title: homePageVehicleCard.title,
          baseFareLabel: homePageVehicleCard.baseFareLabel,
          baseFareDescription: homePageVehicleCard.baseFareDescription,
          knowMoreLabel: homePageVehicleCard.knowMoreLabel,
          vehicleDetailsRow: vehicleDetailsRowsToEntity(homePageVehicleCard.vehicleDetailsRow)
        };
}

function cityAttributesToEntity(cityAttributes) {
  return {
          cityName: cityAttributes.cityName
        };
}

function cityDataToEntity(cityData) {
  return {
          attributes: {
            cityName: cityData.attributes.cityName
          }
        };
}

function cityToEntity(city) {
  return {
          data: {
            attributes: {
              cityName: city.data.attributes.cityName
            }
          }
        };
}

function vehicleAttributesToEntity(vehicleAttribute) {
  return {
          vehicleName: vehicleAttribute.vehicleName,
          slug: vehicleAttribute.slug,
          url: vehicleAttribute.url,
          vehicleType: vehicleAttribute.vehicleType,
          homePageVehicleCard: homePageVehicleCardToEntity(vehicleAttribute.homePageVehicleCard),
          city: {
            data: {
              attributes: {
                cityName: vehicleAttribute.city.data.attributes.cityName
              }
            }
          },
          vehicleBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(vehicleAttribute.vehicleBanner),
          vehicleIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(vehicleAttribute.vehicleIcon)
        };
}

function vehiclesToEntity(vehicles) {
  return $$Array.map((function (vehicle) {
                return {
                        attributes: vehicleAttributesToEntity(vehicle.attributes)
                      };
              }), vehicles);
}

function porterVehiclesDataToEntity(porterVehiclesData) {
  return {
          data: vehiclesToEntity(porterVehiclesData.data)
        };
}

function porterVehiclesToEntity(porterVehicles) {
  return {
          title: porterVehicles.title,
          citiesDropdownLabel: porterVehicles.citiesDropdownLabel,
          vehicles: {
            data: vehiclesToEntity(porterVehicles.vehicles.data)
          },
          citiesDropdown: TripEstimateEntityMapper$FrontendWebsite.dropdownItemsToEntity(porterVehicles.citiesDropdown)
        };
}

function moreFaqsToEntity(moreFaqs) {
  return {
          title: moreFaqs.title,
          buttonLabel: moreFaqs.buttonLabel,
          buttonRedirectionUrl: moreFaqs.buttonRedirectionUrl,
          icon: MediaEntityMapper$FrontendWebsite.bannerToEntity(moreFaqs.icon)
        };
}

function growthStatItemsToEntity(growthStatItems) {
  return $$Array.map((function (item) {
                return {
                        count: item.count,
                        label: item.label
                      };
              }), growthStatItems);
}

function growthStatsToEntity(growthStats) {
  return {
          title: growthStats.title,
          growthStatItems: growthStatItemsToEntity(growthStats.growthStatItems)
        };
}

function bannerItemToEntity(bannerItems) {
  return $$Array.map((function (bannerItem) {
                return {
                        redirectionUrl: bannerItem.redirectionUrl,
                        banner: MediaEntityMapper$FrontendWebsite.bannerToEntity(bannerItem.banner)
                      };
              }), bannerItems);
}

function heroSectionToEntity(heroSection) {
  return {
          mobileBanners: bannerItemToEntity(heroSection.mobileBanners),
          desktopBanners: bannerItemToEntity(heroSection.desktopBanners)
        };
}

function attributesToEntity(attributes) {
  return {
          heroSection: heroSectionToEntity(attributes.heroSection),
          tripEstimateForm: TripEstimateEntityMapper$FrontendWebsite.tripEstimateFormToEntity(attributes.tripEstimateForm),
          features: featuresToEntity(attributes.features),
          offerings: offeringsToEntity(attributes.offerings),
          porterVehicles: porterVehiclesToEntity(attributes.porterVehicles),
          serviceableCities: ServiceableCitiesEntityMapper$FrontendWebsite.serviceableCitiesToEntity(attributes.serviceableCities),
          faqs: FaqEntityMapper$FrontendWebsite.faqsToEntity(attributes.faqs),
          moreFaqs: moreFaqsToEntity(attributes.moreFaqs),
          growthStats: growthStatsToEntity(attributes.growthStats)
        };
}

function dataToEntity(data) {
  return {
          attributes: attributesToEntity(data.attributes)
        };
}

function apiModelToEntity(model) {
  return {
          data: {
            attributes: attributesToEntity(model.data.attributes)
          }
        };
}

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(HomePageCMSApiModel$FrontendWebsite.t_decode(json), "HomePageCMSEntityMapper.res");
  return {
          data: {
            attributes: attributesToEntity(model.data.attributes)
          }
        };
}

export {
  featureItemsToEntity ,
  phoneCarouselToEntity ,
  orderTrackingSectionToEntity ,
  featuresToEntity ,
  uspsToEntity ,
  offeringsToEntity ,
  vehicleDetailsRowsToEntity ,
  homePageVehicleCardToEntity ,
  cityAttributesToEntity ,
  cityDataToEntity ,
  cityToEntity ,
  vehicleAttributesToEntity ,
  vehiclesToEntity ,
  porterVehiclesDataToEntity ,
  porterVehiclesToEntity ,
  moreFaqsToEntity ,
  growthStatItemsToEntity ,
  growthStatsToEntity ,
  bannerItemToEntity ,
  heroSectionToEntity ,
  attributesToEntity ,
  dataToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
