// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as CityCMSEntityMapper$FrontendWebsite from "../entity-mapper/CityCMSEntityMapper.bs.js";

var execute = CityCMSEntityMapper$FrontendWebsite.execute;

var CityCMSApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var CityCMSResponseConverter = partial_arg(CityCMSApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  CityCMSApiConfig ,
  CityCMSResponseConverter ,
  
}
/* CityCMSResponseConverter Not a pure module */
