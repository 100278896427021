// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Sentry$FrontendWebsite from "../sentry/Sentry.bs.js";
import * as GeocodingApi$FrontendWebsite from "./geocode-api/GeocodingApi.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../NetworkUtils.bs.js";

function getAddressTypeDetail(place, address_type) {
  var detail = List.filter(function (curr) {
          return List.exists((function (current_type) {
                        return current_type === address_type;
                      }), curr.address_types);
        })(place);
  if (List.length(detail) !== 0) {
    return List.hd(detail).long_name;
  }
  
}

function getAddress(autocomp) {
  var place = autocomp.getPlace();
  if (place.geometry == null) {
    return {
            landmark: place.name,
            location: {
              lat: 1000.0,
              lng: 1000.0
            }
          };
  } else {
    return {
            landmark: place.name + (", " + place.formatted_address),
            location: {
              lat: Curry._1(place.geometry.location.lat, undefined),
              lng: Curry._1(place.geometry.location.lng, undefined)
            }
          };
  }
}

function getAddressV2(autocomp, onChange) {
  var place = autocomp.getPlace();
  if (place.place_id == null) {
    return Curry._2(onChange, {
                landmark: "Select from Dropdown",
                location: {
                  lat: 1000.0,
                  lng: 1000.0
                }
              }, {
                pincode: undefined,
                state: undefined,
                city: undefined,
                locality: undefined,
                sub_locality: undefined
              });
  } else {
    return GeocodingApi$FrontendWebsite.getGeocodeFromPlaceId(place.place_id, (function (geocode) {
                  return Curry._2(onChange, {
                              landmark: geocode.formatted_address,
                              location: geocode.geometry.location
                            }, {
                              pincode: getAddressTypeDetail(geocode.address_components, "postal_code"),
                              state: getAddressTypeDetail(geocode.address_components, "administrative_area_level_1"),
                              city: getAddressTypeDetail(geocode.address_components, "administrative_area_level_2"),
                              locality: getAddressTypeDetail(geocode.address_components, "sublocality_level_1"),
                              sub_locality: getAddressTypeDetail(geocode.address_components, "sublocality_level_2")
                            });
                }), (function (error) {
                  Sentry$FrontendWebsite.capturePromiseError(error);
                  NetworkUtils$FrontendWebsite.captureException("Error on google geo code");
                  return Curry._2(onChange, {
                              landmark: "Try Again",
                              location: {
                                lat: 1000.0,
                                lng: 1000.0
                              }
                            }, {
                              pincode: undefined,
                              state: undefined,
                              city: undefined,
                              locality: undefined,
                              sub_locality: undefined
                            });
                }));
  }
}

export {
  getAddressTypeDetail ,
  getAddress ,
  getAddressV2 ,
  
}
/* Sentry-FrontendWebsite Not a pure module */
