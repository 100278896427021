// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as CityCMSApiModel$FrontendWebsite from "../api-model/CityCMSApiModel.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as AppDownloadEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/AppDownloadEntityMapper.bs.js";
import * as ServiceableCitiesEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/ServiceableCitiesEntityMapper.bs.js";

function vehicleDetailsRowsToEntity(vehicleDetailsRows) {
  return $$Array.map((function (vehicleDetailsRow) {
                return {
                        order: vehicleDetailsRow.order,
                        label: vehicleDetailsRow.label,
                        value: vehicleDetailsRow.value
                      };
              }), vehicleDetailsRows);
}

function homePageVehicleCardToEntity(homePageVehicleCard) {
  return {
          title: homePageVehicleCard.title,
          baseFareLabel: homePageVehicleCard.baseFareLabel,
          baseFareDescription: homePageVehicleCard.baseFareDescription,
          knowMoreLabel: homePageVehicleCard.knowMoreLabel,
          vehicleDetailsRow: vehicleDetailsRowsToEntity(homePageVehicleCard.vehicleDetailsRow)
        };
}

function cityToEntity(city) {
  return {
          data: {
            attributes: {
              cityName: city.data.attributes.cityName
            }
          }
        };
}

function vehicleAttributesToEntity(vehicleAttribute) {
  return {
          vehicleName: vehicleAttribute.vehicleName,
          slug: vehicleAttribute.slug,
          url: vehicleAttribute.url,
          vehicleType: vehicleAttribute.vehicleType,
          homePageVehicleCard: homePageVehicleCardToEntity(vehicleAttribute.homePageVehicleCard),
          city: cityToEntity(vehicleAttribute.city),
          vehicleBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(vehicleAttribute.vehicleBanner),
          vehicleIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(vehicleAttribute.vehicleIcon)
        };
}

function vehiclesToEntity(vehicles) {
  return $$Array.map((function (vehicle) {
                return {
                        attributes: vehicleAttributesToEntity(vehicle.attributes)
                      };
              }), vehicles);
}

function vehiclesDataToEntity(porterVehiclesData) {
  return {
          data: vehiclesToEntity(porterVehiclesData.data)
        };
}

function availableVehiclesToEntity(availableVehicles) {
  return {
          title: availableVehicles.title,
          vehicles: {
            data: vehiclesToEntity(availableVehicles.vehicles.data)
          }
        };
}

function porterOffersToEntity(porterOffers) {
  return {
          title: porterOffers.title,
          coupons: List.map((function (coupon) {
                  return {
                          couponInfo: coupon.couponInfo,
                          couponCode: coupon.couponCode,
                          couponValidityText: coupon.couponValidityText,
                          detailsLink: coupon.detailsLink,
                          bannerImg: MediaEntityMapper$FrontendWebsite.bannerToEntity(coupon.bannerImg)
                        };
                }), porterOffers.coupons)
        };
}

function attributesToEntity(attributes) {
  return {
          title: attributes.title,
          description: attributes.description,
          banner: MediaEntityMapper$FrontendWebsite.bannerToEntity(attributes.banner),
          slug: attributes.slug,
          cityName: attributes.cityName,
          availableVehicles: availableVehiclesToEntity(attributes.availableVehicles),
          porterOffers: porterOffersToEntity(attributes.porterOffers),
          appDownload: AppDownloadEntityMapper$FrontendWebsite.appDownloadToEntity(attributes.appDownload),
          serviceableCities: ServiceableCitiesEntityMapper$FrontendWebsite.serviceableCitiesToEntity(attributes.serviceableCities)
        };
}

function dataToEntity(data) {
  return $$Array.map((function (cityData) {
                return {
                        attributes: attributesToEntity(cityData.attributes)
                      };
              }), data);
}

function apiModelToEntity(model) {
  return {
          data: dataToEntity(model.data)
        };
}

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(CityCMSApiModel$FrontendWebsite.t_decode(json), "CityCMSEntityMapper.res");
  return {
          data: dataToEntity(model.data)
        };
}

export {
  vehicleDetailsRowsToEntity ,
  homePageVehicleCardToEntity ,
  cityToEntity ,
  vehicleAttributesToEntity ,
  vehiclesToEntity ,
  vehiclesDataToEntity ,
  availableVehiclesToEntity ,
  porterOffersToEntity ,
  attributesToEntity ,
  dataToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
