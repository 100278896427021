// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as DriverPartnerCMSEntityMapper$FrontendWebsite from "../entity-mapper/DriverPartnerCMSEntityMapper.bs.js";

var execute = DriverPartnerCMSEntityMapper$FrontendWebsite.execute;

var DriverPartnerCMSConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var DriverPartnerCMSResponseConverter = partial_arg(DriverPartnerCMSConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  DriverPartnerCMSConfig ,
  DriverPartnerCMSResponseConverter ,
  
}
/* DriverPartnerCMSResponseConverter Not a pure module */
