// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../node_modules/rescript/lib/es6/string.js";
import * as FormValidationUtils$Catalyst from "../../node_modules/@porterin/catalyst/src/utils/FormValidationUtils.bs.js";

function crnConverter(crn) {
  if (crn.length <= 8) {
    return crn;
  }
  var _error = FormValidationUtils$Catalyst.validateCRN(crn);
  if (_error.TAG === /* Ok */0) {
    return $$String.sub(_error._0, 3, 8);
  } else {
    return crn;
  }
}

function mobileConverter(mobile) {
  if (mobile.length > 10 && /^(\+91)(\d{10})$/i.test(mobile)) {
    return $$String.sub(mobile, 3, 10);
  } else {
    return mobile;
  }
}

export {
  crnConverter ,
  mobileConverter ,
  
}
/* No side effect */
