// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as FareEstimateResultPinLocationCmp$FrontendWebsite from "./FareEstimateResultPinLocationCmp.bs.js";

function FareEstimateResultLocationDetailsDesktopCmp(Props) {
  var pickup_address = Props.pickup_address;
  var drop_address = Props.drop_address;
  var form_handler = Props.form_handler;
  var edit_section = Props.edit_section;
  var address_displayed = function (address) {
    return $$String.map((function (charac) {
                  if (charac === /* '+' */43) {
                    return /* ' ' */32;
                  } else {
                    return charac;
                  }
                }), address);
  };
  return React.createElement("div", {
              className: "fare-estimate-result-desktop-location-details-container"
            }, React.createElement(FareEstimateResultPinLocationCmp$FrontendWebsite.make, {
                  address: address_displayed(pickup_address),
                  title: edit_section.pickupLabel,
                  class_name: "dot-green"
                }), React.createElement(FareEstimateResultPinLocationCmp$FrontendWebsite.make, {
                  address: address_displayed(drop_address),
                  title: edit_section.dropLabel,
                  class_name: "dot-red"
                }), React.createElement("div", {
                  className: "location-edit-button",
                  onClick: (function (param) {
                      return Curry._1(form_handler, undefined);
                    })
                }, edit_section.editButtonLabel));
}

var make = FareEstimateResultLocationDetailsDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
