// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";

function H2TitleCmp(Props) {
  var title = Props.title;
  var class_nameOpt = Props.class_name;
  var class_name = class_nameOpt !== undefined ? class_nameOpt : "";
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "h2-title-wrapper " + class_name : "h2-title-wrapper-mobile " + class_name
            }, React.createElement("h2", {
                  className: "title"
                }, title), React.createElement("div", {
                  className: "bar"
                }));
}

var make = H2TitleCmp;

export {
  make ,
  
}
/* react Not a pure module */
