// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as MediaVMMapper$FrontendWebsite from "./MediaVMMapper.bs.js";

function getSocialMediaLinksLabel(data) {
  var array = Belt_List.toArray(Belt_List.keepMap({
            hd: data.instagramURL,
            tl: {
              hd: data.facebookURL,
              tl: {
                hd: data.linkdinURL,
                tl: {
                  hd: data.twitterURL,
                  tl: {
                    hd: data.youtubeURL,
                    tl: /* [] */0
                  }
                }
              }
            }
          }, (function (url) {
              return url;
            })));
  var icon = data.socialMediaIcons;
  return {
          icon: icon !== undefined ? MediaVMMapper$FrontendWebsite.getBanner(icon.data) : undefined,
          links: array
        };
}

function getLinkLabel(data) {
  var match = data.url;
  if (match === "") {
    return {
            TAG: /* NoURL */0,
            _0: data.label
          };
  } else {
    return {
            TAG: /* URLable */1,
            _0: data.label,
            _1: data.url
          };
  }
}

function getLinkLableArray(data) {
  return $$Array.map(getLinkLabel, data);
}

function getAppLinks(appStoreIcon, playStoreIcon) {
  return {
          appStoreIcon: appStoreIcon,
          playStoreIcon: playStoreIcon
        };
}

function getNavSection(data) {
  return {
          primaryNavLinks: $$Array.map(getLinkLabel, data.primaryNavLinks),
          secondaryNavLinks: $$Array.map(getLinkLabel, data.secondaryNavLinks)
        };
}

function getLanguageSelector(data) {
  if (data !== undefined) {
    return {
            icon: MediaVMMapper$FrontendWebsite.getBanner(data.icon.data),
            englishLabel: data.englishLabel,
            banglaLabel: data.banglaLabel,
            englishPrefix: data.banglaPrefix,
            banglaPrefix: data.banglaPrefix
          };
  }
  
}

function getPartnerSection(data) {
  return {
          title: data.title,
          subTitle: data.subTitle,
          tagLine: data.tagLine,
          icon: MediaVMMapper$FrontendWebsite.getBanner(data.icon.data)
        };
}

function getLogoSection(data) {
  return {
          title: data.title,
          logo: MediaVMMapper$FrontendWebsite.getBanner(data.logo.data),
          partnerSection: getPartnerSection(data.partnerSection),
          languageSelector: getLanguageSelector(data.languageSelector)
        };
}

function getCompanyInfo(data) {
  if (data !== undefined) {
    return {
            pinIcon: MediaVMMapper$FrontendWebsite.getBanner(data.pinIcon.data),
            companyName: data.companyName,
            trnNumber: data.trnNumber,
            tncLinkLabel: getLinkLabel(data.tncLinkLabel),
            refundLinkLabel: getLinkLabel(data.refundLinkLabel)
          };
  }
  
}

function getFooter(data) {
  return {
          logo: MediaVMMapper$FrontendWebsite.getBanner(data.logo.data),
          title: data.title,
          appLinks: {
            appStoreIcon: MediaVMMapper$FrontendWebsite.getBanner(data.appStoreIcon.data),
            playStoreIcon: MediaVMMapper$FrontendWebsite.getBanner(data.playStoreIcon.data)
          },
          cityLinkLabel: [{
                TAG: /* NoURL */0,
                _0: data.cityLinksTitle
              }].concat($$Array.map(getLinkLabel, data.cityLinkLabel)),
          companyLinksLabel: $$Array.map(getLinkLabel, data.companyLinksLabel),
          serviceLinksLabel: $$Array.map(getLinkLabel, data.serviceLinksLabel),
          supportLinksLabel: $$Array.map(getLinkLabel, data.supportLinksLabel),
          socialMediaLinksLabel: getSocialMediaLinksLabel(data)
        };
}

function getVm(data) {
  return {
          companyInfo: getCompanyInfo(data.companyInfo),
          logoSection: getLogoSection(data.logoSection),
          navSection: getNavSection(data.navSection),
          footer: getFooter(data.footer)
        };
}

export {
  getSocialMediaLinksLabel ,
  getLinkLabel ,
  getLinkLableArray ,
  getAppLinks ,
  getNavSection ,
  getLanguageSelector ,
  getPartnerSection ,
  getLogoSection ,
  getCompanyInfo ,
  getFooter ,
  getVm ,
  
}
/* No side effect */
