// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

var isAndroid = (function isAndroid() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    ua = ua.toLowerCase()
    return (ua.indexOf("android") > -1);
  });

function useAndroidDetector(param) {
  return React.useState(function () {
                return Curry._1(isAndroid, undefined);
              })[0];
}

var isMac = (function isMac() {
    return navigator.platform.indexOf('Mac') > -1
  });

var isLocalStorageAvailable = (function() {
    let isLocalStorageAvailable = false
    try {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem('feature_test', 'yes');
            if (localStorage.getItem('feature_test') === 'yes') {
                localStorage.removeItem('feature_test');
                isLocalStorageAvailable = true
            } else {
                isLocalStorageAvailable = false
            }
        } else {
            isLocalStorageAvailable = false
        }
    } catch (e) {
        isLocalStorageAvailable = false
    }
    return isLocalStorageAvailable
  });

export {
  isAndroid ,
  useAndroidDetector ,
  isMac ,
  isLocalStorageAvailable ,
  
}
/* react Not a pure module */
