// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "../../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";

function scrollToSlide(currentSlide, childrenCount) {
  var slideToIndex = currentSlide < (childrenCount - 1 | 0) ? currentSlide + 1 | 0 : 0;
  var scrollToProps_left = Math.imul(slideToIndex, screen.width);
  var scrollToProps = {
    behavior: "smooth",
    left: scrollToProps_left
  };
  var element = document.getElementById("carousel");
  if (!(element == null)) {
    element.scrollTo(scrollToProps);
    return ;
  }
  
}

function returnStartSetTimeoutId(autoplay, setCurrentSlide, childrenCount, currentSlide, is_mobile, delay) {
  if (autoplay) {
    return Caml_option.some(setTimeout((function (param) {
                      Curry._1(setCurrentSlide, (function (prev) {
                              if (prev < (childrenCount - 1 | 0)) {
                                return prev + 1 | 0;
                              } else {
                                return 0;
                              }
                            }));
                      if (is_mobile) {
                        scrollToSlide(currentSlide, childrenCount);
                        return ;
                      }
                      
                    }), delay));
  }
  
}

function stopTimer(autoplay, startTimer) {
  if (autoplay && startTimer !== undefined) {
    clearTimeout(Caml_option.valFromOption(startTimer));
    return ;
  }
  
}

function setSlide(current, is_mobile, childrenCount, setCurrentSlide) {
  if (is_mobile) {
    scrollToSlide(current - 1 | 0, childrenCount);
  }
  return Curry._1(setCurrentSlide, (function (param) {
                return current;
              }));
}

function getTransformStyle(is_mobile, currentSlide) {
  if (is_mobile) {
    return "";
  } else {
    return "translateX(" + (String(Math.imul(-currentSlide | 0, 100)) + "%)");
  }
}

function childrenWrapper(children, is_mobile, currentSlide) {
  return React.Children.map(children, (function (child, index) {
                return React.createElement("div", {
                            key: "carousel-item-" + String(index),
                            className: "carousel-item",
                            style: {
                              transform: getTransformStyle(is_mobile, currentSlide)
                            }
                          }, child);
              }));
}

function getTouchCoordinate($$event) {
  return Caml_array.get($$event.changedTouches, 0).clientX;
}

function autoScroll(touchstartX, touchendX, setCurrentSlide, childrenCount) {
  var value = touchendX - touchstartX | 0;
  if (value > 0) {
    return Curry._1(setCurrentSlide, (function (prev) {
                  if (prev === 0) {
                    return prev;
                  } else {
                    return prev - 1 | 0;
                  }
                }));
  } else if (value < 0) {
    return Curry._1(setCurrentSlide, (function (prev) {
                  if (prev === (childrenCount - 1 | 0)) {
                    return prev;
                  } else {
                    return prev + 1 | 0;
                  }
                }));
  } else {
    return ;
  }
}

var scrollShiftPercentage = 100;

export {
  scrollShiftPercentage ,
  scrollToSlide ,
  returnStartSetTimeoutId ,
  stopTimer ,
  setSlide ,
  getTransformStyle ,
  childrenWrapper ,
  getTouchCoordinate ,
  autoScroll ,
  
}
/* react Not a pure module */
