// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function BackgroundImageCmp(Props) {
  var background_img = Props.background_img;
  var class_name = Props.class_name;
  var height = Props.height;
  var width = Props.width;
  var on_clickOpt = Props.on_click;
  var on_click = on_clickOpt !== undefined ? on_clickOpt : (function (param) {
        
      });
  return React.createElement("div", {
              className: class_name,
              style: {
                backgroundImage: "url(" + background_img + ")",
                height: height,
                width: width
              },
              onClick: on_click
            });
}

var make = BackgroundImageCmp;

export {
  make ,
  
}
/* react Not a pure module */
