// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as WebsiteTypes$FrontendWebsite from "../../website/types/WebsiteTypes.bs.js";

function VehicleCardCmp(Props) {
  var vehicle = Props.vehicle;
  return React.createElement("a", {
              className: "vehicle-card-container",
              href: WebsiteTypes$FrontendWebsite.urltoString(vehicle.url)
            }, React.createElement("div", {
                  className: "img-wrapper"
                }, React.createElement("img", {
                      className: "img",
                      alt: vehicle.vehicleName,
                      src: vehicle.vehicleBanner.url
                    })), React.createElement("div", {
                  className: "title"
                }, vehicle.vehicleName), React.createElement("div", {
                  className: "desc"
                }, vehicle.description));
}

var make = VehicleCardCmp;

export {
  make ,
  
}
/* react Not a pure module */
