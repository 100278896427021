// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AppContext$FrontendWebsite from "../../context/app-contexts/AppContext.bs.js";

function autoCompleteInvalidAddress(addresType) {
  return "Autocomplete Invalid " + addresType + " Address";
}

function knowMoreClick(vehicleName) {
  return "Know More " + vehicleName;
}

var Actions = {
  category: "Fare Estimate",
  get_trip_estimate_successful: "Get Trip Estimate Successful UAE",
  get_trip_estimate_failure: "Get Trip Estimate Failure UAE",
  duration_events_action: "User Duration UAE",
  scroll_events_action: "User Scroll UAE",
  page_view_pct_events_action: "Page Viewed Pct UAE",
  tab_visibility_events_action: "User Focus Tab UAE",
  vehicleSelected: "Vehcile Selected Change UAE",
  tripEstimateModalOpen: "Get Trip Estimate Modal Open UAE",
  tripEstimateModalClose: "Get Trip Estimate Modal Close UAE",
  tripEstimateSuccess: "Fare Estimate Successful V1 UAE",
  moreFaqClicked: "More Faqs Clicked UAE",
  trackOrderModalOpen: "Track Live Order Modal Open UAE",
  consentBoxClicked: "Consent Box Checked UAE",
  tripEstimateClicked: "Get Trip Estimate Click UAE",
  googlePlayStoreButtonClicked: "Google Play button clicked UAE",
  appStoreButtonClicked: "App Store button clicked UAE",
  liveOrderModal: "Track Live Order Modal Open UAE",
  supportClicked: "Clicked Contact Support",
  customerCallPlacerClicked: "Clicked Customer Call Placer",
  callPartnerClicked: "Clicked Call Partner",
  callPartnerClickedCopy: "Clicked Call Partner Copy",
  viewDetailsClicked: "Clicked View Details",
  consentBoxChacked: "Consent Box Checked UAE",
  autoCompleteInvalidAddress: autoCompleteInvalidAddress,
  tripEstimateClick: "Get Trip Estimate Click UAE",
  vehicleSelectedChange: "Vehicle Selected Change UAE",
  reCaptchaExecuteSucceess: "ReCaptcha Execute Succeed UAE",
  reCaptchaExecuteFail: "ReCaptcha Execute Failed UAE",
  cityChangeSelector: "City Change Selector UAE",
  languageChangeClick: "Language Change Click",
  driveWithUsClick: "Drive with us click",
  knowMoreClick: knowMoreClick,
  sendAppLinkSMSClick: "Send App Link SMS Click",
  disableAppLinkSMSClick: "Disbale App Link SMS Click"
};

var UAE = {
  Actions: Actions
};

function autoCompleteInvalidAddress$1(addresType) {
  return "Autocomplete Invalid " + addresType + " Address";
}

function knowMoreClick$1(vehicleName) {
  return "Know More " + vehicleName;
}

var Actions$1 = {
  category: "Fare Estimate",
  get_trip_estimate_successful: "Get Trip Estimate Successful Bangladesh",
  get_trip_estimate_failure: "Get Trip Estimate Failure Bangladesh",
  duration_events_action: "User Duration Bangladesh",
  scroll_events_action: "User Scroll Bangladesh",
  page_view_pct_events_action: "Page Viewed Pct Bangladesh",
  tab_visibility_events_action: "User Focus Tab Bangladesh",
  vehicleSelected: "Vehcile Selected Change Bangladesh",
  tripEstimateModalOpen: "Get Trip Estimate Modal Open Bangladesh",
  tripEstimateModalClose: "Get Trip Estimate Modal Close Bangladesh",
  tripEstimateSuccess: "Fare Estimate Successful V1 Bangladesh",
  moreFaqClicked: "More Faqs Clicked Bangladesh",
  trackOrderModalOpen: "Track Live Order Modal Open Bangladesh",
  consentBoxClicked: "Consent Box Checked Bangladesh",
  tripEstimateClicked: "Get Trip Estimate Click Bangladesh",
  googlePlayStoreButtonClicked: "Google Play button clicked Bangladesh",
  appStoreButtonClicked: "App Store button clicked Bangladesh",
  liveOrderModal: "Track Live Order Modal Open Bangladesh",
  supportClicked: "Clicked Contact Support",
  customerCallPlacerClicked: "Clicked Customer Call Placer",
  callPartnerClicked: "Clicked Call Partner",
  callPartnerClickedCopy: "Clicked Call Partner Copy",
  viewDetailsClicked: "Clicked View Details",
  consentBoxChacked: "Consent Box Checked Bangladesh",
  autoCompleteInvalidAddress: autoCompleteInvalidAddress$1,
  tripEstimateClick: "Get Trip Estimate Click Bangladesh",
  vehicleSelectedChange: "Vehicle Selected Change Bangladesh",
  reCaptchaExecuteSucceess: "ReCaptcha Execute Succeed Bangladesh",
  reCaptchaExecuteFail: "ReCaptcha Execute Failed Bangladesh",
  cityChangeSelector: "City Change Selector Bangladesh",
  languageChangeClick: "Language Change Click",
  driveWithUsClick: "Drive with us click",
  knowMoreClick: knowMoreClick$1,
  sendAppLinkSMSClick: "Send App Link SMS Click",
  disableAppLinkSMSClick: "Disbale App Link SMS Click"
};

var BD = {
  Actions: Actions$1
};

function autoCompleteInvalidAddress$2(addresType) {
  return "Autocomplete Invalid " + addresType + " Address";
}

function knowMoreClick$2(vehicleName) {
  return "Know More " + vehicleName;
}

var Actions$2 = {
  category: "Fare Estimate",
  get_trip_estimate_successful: "Get Trip Estimate Successful Turkiye",
  get_trip_estimate_failure: "Get Trip Estimate Failure Turkiye",
  duration_events_action: "User Duration Turkiye",
  scroll_events_action: "User Scroll Turkiye",
  page_view_pct_events_action: "Page Viewed Pct Turkiye",
  tab_visibility_events_action: "User Focus Tab Turkiye",
  vehicleSelected: "Vehcile Selected Change Turkiye",
  tripEstimateModalOpen: "Get Trip Estimate Modal Open Turkiye",
  tripEstimateModalClose: "Get Trip Estimate Modal Close Turkiye",
  tripEstimateSuccess: "Fare Estimate Successful V1 Turkiye",
  moreFaqClicked: "More Faqs Clicked Turkiye",
  trackOrderModalOpen: "Track Live Order Modal Open Turkiye",
  consentBoxClicked: "Consent Box Checked Turkiye",
  tripEstimateClicked: "Get Trip Estimate Click Turkiye",
  googlePlayStoreButtonClicked: "Google Play button clicked Turkiye",
  appStoreButtonClicked: "App Store button clicked Turkiye",
  liveOrderModal: "Track Live Order Modal Open Turkiye",
  supportClicked: "Clicked Contact Support",
  customerCallPlacerClicked: "Clicked Customer Call Placer",
  callPartnerClicked: "Clicked Call Partner",
  callPartnerClickedCopy: "Clicked Call Partner Copy",
  viewDetailsClicked: "Clicked View Details",
  consentBoxChacked: "Consent Box Checked Turkiye",
  autoCompleteInvalidAddress: autoCompleteInvalidAddress$2,
  tripEstimateClick: "Get Trip Estimate Click Turkiye",
  vehicleSelectedChange: "Vehicle Selected Change Turkiye",
  reCaptchaExecuteSucceess: "ReCaptcha Execute Succeed Turkiye",
  reCaptchaExecuteFail: "ReCaptcha Execute Failed Turkiye",
  cityChangeSelector: "City Change Selector Turkiye",
  languageChangeClick: "Language Change Click",
  driveWithUsClick: "Drive with us click",
  knowMoreClick: knowMoreClick$2,
  sendAppLinkSMSClick: "Send App Link SMS Click",
  disableAppLinkSMSClick: "Disbale App Link SMS Click"
};

var TR = {
  Actions: Actions$2
};

function getGAConfigActions(param) {
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return Actions;
    case /* BD */1 :
        return Actions$1;
    case /* TR */2 :
        return Actions$2;
    
  }
}

export {
  UAE ,
  BD ,
  TR ,
  getGAConfigActions ,
  
}
/* AppContext-FrontendWebsite Not a pure module */
