// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CampaignsRepoConfig$FrontendWebsite from "./CampaignsRepoConfig.bs.js";

var dummySupportCitiesResponse = {
      "response": [
        {
          "scheme_id": 2,
          "geo_region_id": 3,
          "type": "monthly",
          "title": "New Cashback Scheme",
          "description": "Get 250 on completing N number of rides",
          "highlights": [
            "Cashback will be credited to PayTM account on Monday",
            "Applicable only for App orders"
          ],
          "main_image_link": null,
          "validity_text": "Valid between April 28, 2016 to November 28, 2023.",
          "headers": [
            "Monthly Orders",
            "Cashback Amount"
          ],
          "header_values": [
            {
              "Monthly Orders": "3",
              "Cashback Amount": "200"
            },
            {
              "Monthly Orders": "6",
              "Cashback Amount": "300"
            },
            {
              "Monthly Orders": "10",
              "Cashback Amount": "500"
            }
          ],
          "terms_and_conditions": [
            "Cashback scheme is valid only for Porter app customers. In other words, cashback can only be earned on orders booked through Porter app. However, earned cashback can be redeemed against orders booked through either Porter app or call center.",
            "The earned cashback will be in the form of credits equivalent to the rupee value applicable of such points which will be credited to the customer’s Porter account.",
            "It supersedes and replaces all other existing cash back schemes.",
            "There is no upper limit on the redemption amount, i.e. a customer can claim his / her entire trip fare under the proposed cashback scheme as long as the trip fare is less than the earned cashback. However, there is a usage validity of 60 days since the date of credit beyond which the cashback shall be deemed expired.",
            "It cannot be combined with any other offer/scheme or cannot be transferred/ assigned to any other person/customer."
          ]
        }
      ]
    };

function fetchOffers(_re_id, _country) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummySupportCitiesResponse
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(CampaignsRepoConfig$FrontendWebsite.DiscountCouponApiResponseConverter.execute, result);
                            });
                }), "fetchOffers");
}

export {
  dummySupportCitiesResponse ,
  fetchOffers ,
  
}
/* NetworkUtils-FrontendWebsite Not a pure module */
