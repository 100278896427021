// Generated by ReScript, PLEASE EDIT WITH CARE


function getAboutUsImage(name) {
  var requireImage = (function(name){
        return require(`AboutUsAssets/${name}`).default
    });
  return requireImage(name);
}

function getCommonImage(name) {
  var requireImage = (function(name){
        return require(`CommonAssets/${name}`).default
        });
  return requireImage(name);
}

function getTrackLiveTripsImage(name) {
  var requireImage = (function(name){
        return require(`TrackLiveTripAssets/${name}`).default
        });
  return requireImage(name);
}

function getHomeImage(name) {
  var requireImage = (function(name){
        return require(`HomeAssets/${name}`).default
      });
  return requireImage(name);
}

function getSupportImage(name) {
  var requireImage = (function(name){
        return require(`SupportAssets/${name}`).default
      });
  return requireImage(name);
}

function getDriverPartnersImage(name) {
  var requireImage = (function(name){
        return require(`DriverPartnersAssets/${name}`).default
      });
  return requireImage(name);
}

function getCityImage(name) {
  var requireImage = (function(name){
    return require(`CityAssets/${name}`).default
  });
  return requireImage(name);
}

function getVehicleImage(name) {
  var requireImage = (function(name){
    return require(`VehicleAssets/${name}`).default
  });
  return requireImage(name);
}

function getFareEstimateImage(name) {
  var requireImage = (function(name){
        return require(`FareEstimateResultAssets/${name}`).default
      });
  return requireImage(name);
}

function getLangaugeImage(name) {
  var requireImage = (function(name){
        return require(`LanguageResultAssets/${name}`).default
      });
  return requireImage(name);
}

function getOffersImage(name) {
  var requireImage = (function(name){
        return require(`OffersAssets/${name}`).default
      });
  return requireImage(name);
}

function importCss(param) {
  return (require(`Assets/style/main.scss`));
}

export {
  getAboutUsImage ,
  getCommonImage ,
  getTrackLiveTripsImage ,
  getHomeImage ,
  getSupportImage ,
  getDriverPartnersImage ,
  getCityImage ,
  getVehicleImage ,
  getFareEstimateImage ,
  getLangaugeImage ,
  getOffersImage ,
  importCss ,
  
}
/* No side effect */
