// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as FaqApiModel$FrontendWebsite from "../../website/cms/api-model/FaqApiModel.bs.js";
import * as MediaApiModel$FrontendWebsite from "../../website/cms/api-model/MediaApiModel.bs.js";
import * as AppDownloadApiModel$FrontendWebsite from "../../website/cms/api-model/AppDownloadApiModel.bs.js";
import * as TripEstimateApiModel$FrontendWebsite from "../../website/cms/api-model/TripEstimateApiModel.bs.js";
import * as ServiceableCitiesApiModel$FrontendWebsite from "../../website/cms/api-model/ServiceableCitiesApiModel.bs.js";

function featureItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var subText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subText"), null));
      if (subText.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  title: title._0,
                  subText: subText._0
                }
              };
      }
      var e = subText._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".subText" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function phoneCarousel_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var phoneCarouselBanners = MediaApiModel$FrontendWebsite.banners_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneCarouselBanners"), null));
  if (phoneCarouselBanners.TAG === /* Ok */0) {
    var phoneSkin = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneSkin"), null));
    if (phoneSkin.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                phoneCarouselBanners: phoneCarouselBanners._0,
                phoneSkin: phoneSkin._0
              }
            };
    }
    var e = phoneSkin._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".phoneSkin" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = phoneCarouselBanners._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".phoneCarouselBanners" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function orderTrackingSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var mobileAppLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "mobileAppLabel"), null));
  if (mobileAppLabel.TAG === /* Ok */0) {
    var trackOrderLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "trackOrderLabel"), null));
    if (trackOrderLabel.TAG === /* Ok */0) {
      var conjunctionLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "conjunctionLabel"), null));
      if (conjunctionLabel.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  mobileAppLabel: mobileAppLabel._0,
                  trackOrderLabel: trackOrderLabel._0,
                  conjunctionLabel: conjunctionLabel._0
                }
              };
      }
      var e = conjunctionLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".conjunctionLabel" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = trackOrderLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".trackOrderLabel" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = mobileAppLabel._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".mobileAppLabel" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function features_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var phoneCarousel = phoneCarousel_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "phoneCarousel"), null));
  if (phoneCarousel.TAG === /* Ok */0) {
    var appDownload = AppDownloadApiModel$FrontendWebsite.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownload"), null));
    if (appDownload.TAG === /* Ok */0) {
      var featureItems = Decco.arrayFromJson(featureItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "featureItems"), null));
      if (featureItems.TAG === /* Ok */0) {
        var orderTrackingSection = orderTrackingSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderTrackingSection"), null));
        if (orderTrackingSection.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    phoneCarousel: phoneCarousel._0,
                    appDownload: appDownload._0,
                    featureItems: featureItems._0,
                    orderTrackingSection: orderTrackingSection._0
                  }
                };
        }
        var e = orderTrackingSection._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".orderTrackingSection" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = featureItems._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".featureItems" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = appDownload._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".appDownload" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = phoneCarousel._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".phoneCarousel" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function usp_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var subText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subText"), null));
    if (subText.TAG === /* Ok */0) {
      var uspImage = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uspImage"), null));
      if (uspImage.TAG === /* Ok */0) {
        var key = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "key"), null));
        if (key.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    subText: subText._0,
                    uspImage: uspImage._0,
                    key: key._0
                  }
                };
        }
        var e = key._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".key" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = uspImage._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".uspImage" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = subText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subText" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function offerings_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var usps = Decco.arrayFromJson(usp_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usps"), null));
    if (usps.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                usps: usps._0
              }
            };
    }
    var e = usps._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".usps" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function vehicleDetailsRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var order = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "order"), null));
  if (order.TAG === /* Ok */0) {
    var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
    if (label.TAG === /* Ok */0) {
      var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  order: order._0,
                  label: label._0,
                  value: value._0
                }
              };
      }
      var e = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = order._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".order" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function homePageVehicleCard_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var baseFareLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "baseFareLabel"), null));
    if (baseFareLabel.TAG === /* Ok */0) {
      var baseFareDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "baseFareDescription"), null));
      if (baseFareDescription.TAG === /* Ok */0) {
        var knowMoreLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "knowMoreLabel"), null));
        if (knowMoreLabel.TAG === /* Ok */0) {
          var vehicleDetailsRow = Decco.arrayFromJson(vehicleDetailsRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleDetailsRow"), null));
          if (vehicleDetailsRow.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      title: title._0,
                      baseFareLabel: baseFareLabel._0,
                      baseFareDescription: baseFareDescription._0,
                      knowMoreLabel: knowMoreLabel._0,
                      vehicleDetailsRow: vehicleDetailsRow._0
                    }
                  };
          }
          var e = vehicleDetailsRow._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".vehicleDetailsRow" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = knowMoreLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".knowMoreLabel" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = baseFareDescription._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".baseFareDescription" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = baseFareLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".baseFareLabel" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function cityAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var cityName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "cityName"), null));
  if (cityName.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              cityName: cityName._0
            }
          };
  }
  var e = cityName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".cityName" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function cityData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = cityAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function city_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = cityData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function vehicleAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var vehicleName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleName"), null));
  if (vehicleName.TAG === /* Ok */0) {
    var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
    if (slug.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        var vehicleType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
        if (vehicleType.TAG === /* Ok */0) {
          var homePageVehicleCard = homePageVehicleCard_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "homePageVehicleCard"), null));
          if (homePageVehicleCard.TAG === /* Ok */0) {
            var city = city_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "city"), null));
            if (city.TAG === /* Ok */0) {
              var vehicleBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleBanner"), null));
              if (vehicleBanner.TAG === /* Ok */0) {
                var vehicleIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleIcon"), null));
                if (vehicleIcon.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            vehicleName: vehicleName._0,
                            slug: slug._0,
                            url: url._0,
                            vehicleType: vehicleType._0,
                            homePageVehicleCard: homePageVehicleCard._0,
                            city: city._0,
                            vehicleBanner: vehicleBanner._0,
                            vehicleIcon: vehicleIcon._0
                          }
                        };
                }
                var e = vehicleIcon._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".vehicleIcon" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = vehicleBanner._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".vehicleBanner" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = city._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".city" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = homePageVehicleCard._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".homePageVehicleCard" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = vehicleType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".vehicleType" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = slug._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".slug" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = vehicleName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".vehicleName" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function vehicle_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = vehicleAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function vehicles_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(vehicle_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function porterVehicles_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var citiesDropdownLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "citiesDropdownLabel"), null));
    if (citiesDropdownLabel.TAG === /* Ok */0) {
      var vehicles = vehicles_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicles"), null));
      if (vehicles.TAG === /* Ok */0) {
        var citiesDropdown = Decco.arrayFromJson(TripEstimateApiModel$FrontendWebsite.dropdownItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "citiesDropdown"), null));
        if (citiesDropdown.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    citiesDropdownLabel: citiesDropdownLabel._0,
                    vehicles: vehicles._0,
                    citiesDropdown: citiesDropdown._0
                  }
                };
        }
        var e = citiesDropdown._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".citiesDropdown" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = vehicles._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".vehicles" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = citiesDropdownLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".citiesDropdownLabel" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function moreFaqs_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var buttonLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "buttonLabel"), null));
    if (buttonLabel.TAG === /* Ok */0) {
      var buttonRedirectionUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "buttonRedirectionUrl"), null));
      if (buttonRedirectionUrl.TAG === /* Ok */0) {
        var icon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "icon"), null));
        if (icon.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    buttonLabel: buttonLabel._0,
                    buttonRedirectionUrl: buttonRedirectionUrl._0,
                    icon: icon._0
                  }
                };
        }
        var e = icon._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".icon" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = buttonRedirectionUrl._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".buttonRedirectionUrl" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = buttonLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".buttonLabel" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function growthStatItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var count = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "count"), null));
  if (count.TAG === /* Ok */0) {
    var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
    if (label.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                count: count._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = count._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".count" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function growthStats_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var growthStatItems = Decco.arrayFromJson(growthStatItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "growthStatItems"), null));
    if (growthStatItems.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                growthStatItems: growthStatItems._0
              }
            };
    }
    var e = growthStatItems._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".growthStatItems" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function bannerItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var redirectionUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "redirectionUrl"), null));
  if (redirectionUrl.TAG === /* Ok */0) {
    var banner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner"), null));
    if (banner.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                redirectionUrl: redirectionUrl._0,
                banner: banner._0
              }
            };
    }
    var e = banner._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".banner" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = redirectionUrl._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".redirectionUrl" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function heroSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var mobileBanners = Decco.arrayFromJson(bannerItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mobileBanners"), null));
  if (mobileBanners.TAG === /* Ok */0) {
    var desktopBanners = Decco.arrayFromJson(bannerItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "desktopBanners"), null));
    if (desktopBanners.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                mobileBanners: mobileBanners._0,
                desktopBanners: desktopBanners._0
              }
            };
    }
    var e = desktopBanners._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".desktopBanners" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = mobileBanners._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".mobileBanners" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var heroSection = heroSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "heroSection"), null));
  if (heroSection.TAG === /* Ok */0) {
    var tripEstimateForm = TripEstimateApiModel$FrontendWebsite.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "tripEstimateForm"), null));
    if (tripEstimateForm.TAG === /* Ok */0) {
      var features = features_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "features"), null));
      if (features.TAG === /* Ok */0) {
        var offerings = offerings_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "offerings"), null));
        if (offerings.TAG === /* Ok */0) {
          var porterVehicles = porterVehicles_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "porterVehicles"), null));
          if (porterVehicles.TAG === /* Ok */0) {
            var serviceableCities = ServiceableCitiesApiModel$FrontendWebsite.serviceableCities_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceableCities"), null));
            if (serviceableCities.TAG === /* Ok */0) {
              var faqs = FaqApiModel$FrontendWebsite.faqs_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "faqs"), null));
              if (faqs.TAG === /* Ok */0) {
                var moreFaqs = moreFaqs_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "moreFaqs"), null));
                if (moreFaqs.TAG === /* Ok */0) {
                  var growthStats = growthStats_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "growthStats"), null));
                  if (growthStats.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              heroSection: heroSection._0,
                              tripEstimateForm: tripEstimateForm._0,
                              features: features._0,
                              offerings: offerings._0,
                              porterVehicles: porterVehicles._0,
                              serviceableCities: serviceableCities._0,
                              faqs: faqs._0,
                              moreFaqs: moreFaqs._0,
                              growthStats: growthStats._0
                            }
                          };
                  }
                  var e = growthStats._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".growthStats" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = moreFaqs._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".moreFaqs" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = faqs._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".faqs" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = serviceableCities._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".serviceableCities" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = porterVehicles._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".porterVehicles" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = offerings._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".offerings" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = features._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".features" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = tripEstimateForm._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".tripEstimateForm" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = heroSection._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".heroSection" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function data_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = data_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  featureItem_decode ,
  phoneCarousel_decode ,
  orderTrackingSection_decode ,
  features_decode ,
  usp_decode ,
  offerings_decode ,
  vehicleDetailsRow_decode ,
  homePageVehicleCard_decode ,
  cityAttributes_decode ,
  cityData_decode ,
  city_decode ,
  vehicleAttributes_decode ,
  vehicle_decode ,
  vehicles_decode ,
  porterVehicles_decode ,
  moreFaqs_decode ,
  growthStatItem_decode ,
  growthStats_decode ,
  bannerItem_decode ,
  heroSection_decode ,
  attributes_decode ,
  data_decode ,
  t_decode ,
  
}
/* No side effect */
