// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Logger$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/logger/Logger.bs.js";
import * as SomethingWentWrongCmp$FrontendWebsite from "../../../app/website/components/default/SomethingWentWrongCmp.bs.js";

function ErrorFallbackCmp(Props) {
  var error = Props.error;
  Logger$Catalyst.error(error);
  return React.createElement(SomethingWentWrongCmp$FrontendWebsite.make, {
              reload_cb: (function (param) {
                  window.location.reload();
                  
                })
            });
}

var make = ErrorFallbackCmp;

export {
  make ,
  
}
/* react Not a pure module */
