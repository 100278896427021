// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as HeaderFooterCMSEntityMapper$FrontendWebsite from "../entity-mapper/HeaderFooterCMSEntityMapper.bs.js";

var execute = HeaderFooterCMSEntityMapper$FrontendWebsite.execute;

var HeaderFooterCMSConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var HeaderFooterCMSResponseConverter = partial_arg(HeaderFooterCMSConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  HeaderFooterCMSConfig ,
  HeaderFooterCMSResponseConverter ,
  
}
/* HeaderFooterCMSResponseConverter Not a pure module */
