// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as BrowserUtils$FrontendWebsite from "../../../../../utils/BrowserUtils.bs.js";
import * as FooterConfig$FrontendWebsite from "../../../../footer/FooterConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";

function FareEstimateResultMobileFooterCmp(Props) {
  var footer = Props.footer;
  var isAndroid = BrowserUtils$FrontendWebsite.useAndroidDetector(undefined);
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  return React.createElement("div", {
              className: "fare-estimate-result-mobile-footer-container"
            }, React.createElement("div", {
                  className: "info"
                }, React.createElement("div", {
                      className: "title"
                    }, footer.title), React.createElement("div", {
                      className: "desc"
                    }, footer.subtitle)), React.createElement("a", {
                  className: "app-button",
                  href: isAndroid ? FooterConfig$FrontendWebsite.androidLinkUrl : FooterConfig$FrontendWebsite.iosLinkUrl
                }, footer.buttonLabel));
}

var make = FareEstimateResultMobileFooterCmp;

export {
  make ,
  
}
/* react Not a pure module */
