// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as SupportCMSApiModel$FrontendWebsite from "../api-model/SupportCMSApiModel.bs.js";

function supportItemsToEntity(supportItems) {
  return $$Array.map((function (supportItem) {
                return {
                        title: supportItem.title,
                        description: supportItem.description,
                        icon: MediaEntityMapper$FrontendWebsite.bannerToEntity(supportItem.icon)
                      };
              }), supportItems);
}

function officeItemsToEntity(officeItems) {
  return $$Array.map((function (officeItem) {
                return {
                        location: officeItem.location,
                        address: officeItem.address,
                        getDirectionLabel: officeItem.getDirectionLabel,
                        getDirectionUrl: officeItem.getDirectionUrl
                      };
              }), officeItems);
}

function officeToEntity(office) {
  return {
          title: office.title,
          officeItems: officeItemsToEntity(office.officeItems)
        };
}

function dataToEntity(data) {
  var attributes = data.attributes;
  return {
          attributes: {
            title: attributes.title,
            subText: attributes.subText,
            ourOfficesTitle: attributes.ourOfficesTitle,
            supportItems: supportItemsToEntity(attributes.supportItems),
            headOffice: officeToEntity(attributes.headOffice),
            regionalOffices: officeToEntity(attributes.regionalOffices)
          }
        };
}

function apiModelToEntity(model) {
  return {
          data: dataToEntity(model.data)
        };
}

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(SupportCMSApiModel$FrontendWebsite.t_decode(json), "SupportCMSEntityMapper.res");
  return {
          data: dataToEntity(model.data)
        };
}

export {
  supportItemsToEntity ,
  officeItemsToEntity ,
  officeToEntity ,
  dataToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
