// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as FareEstimateCMSEntityMapper$FrontendWebsite from "../entity-mapper/FareEstimateCMSEntityMapper.bs.js";

var execute = FareEstimateCMSEntityMapper$FrontendWebsite.execute;

var FareEstimateCMSConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var FareEstimateCMSResponseConverter = partial_arg(FareEstimateCMSConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  FareEstimateCMSConfig ,
  FareEstimateCMSResponseConverter ,
  
}
/* FareEstimateCMSResponseConverter Not a pure module */
