// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Icon$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/icons/Icon.bs.js";
import * as FormInput$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormInput.bs.js";
import * as FormHelper$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/form/FormHelper.bs.js";
import * as Api from "@react-google-maps/api";
import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as FormTextInput$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormTextInput.bs.js";
import * as JsBindings$FrontendWebsite from "../../../utils/library/bindings/JsBindings.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../utils/helper/CommonHelper.bs.js";
import * as CircularLoader$FrontendWebsite from "../../website/view/components/CircularLoader.bs.js";
import * as GoogleAutoComplete$FrontendWebsite from "../../../utils/library/Google/GoogleAutoComplete.bs.js";

function GoogleAutoCompleteInput$GoogleAutoCompleteInputCmp(Props) {
  var input_props = Props.input_props;
  var bounds = Props.bounds;
  var is_strict_boundsOpt = Props.is_strict_bounds;
  var chromeAutoSuggestion = Props.chromeAutoSuggestion;
  var modeOpt = Props.mode;
  var country_restrictions = Props.country_restrictions;
  var is_strict_bounds = is_strict_boundsOpt !== undefined ? is_strict_boundsOpt : false;
  var mode = modeOpt !== undefined ? modeOpt : /* TURBO */1;
  var match = React.useState(function () {
        
      });
  var setAutocomplete = match[1];
  var autocomplete = match[0];
  var onLoad = function (autocom) {
    return Curry._1(setAutocomplete, (function (param) {
                  return Caml_option.some(autocom);
                }));
  };
  React.useEffect((function () {
          if (chromeAutoSuggestion) {
            JsBindings$FrontendWebsite.setChromeAutocompleteOff(".google-autocomplete", ".form-input-text > input");
          }
          
        }), [autocomplete]);
  var googleBounds = function (bounds) {
    var bnds = Belt_Option.getWithDefault(bounds, CommonHelper$FrontendWebsite.getDefaultCityBound(undefined));
    return {
            north: bnds.ne.lat,
            south: bnds.sw.lat,
            west: bnds.sw.lng,
            east: bnds.ne.lng
          };
  };
  var getFields = function (mode) {
    switch (mode) {
      case /* ECO */0 :
          return ["place_id"];
      case /* TURBO */1 :
          return [
                  "address_component",
                  "adr_address",
                  "formatted_address",
                  "geometry.location",
                  "icon",
                  "name",
                  "place_id",
                  "plus_code",
                  "type",
                  "url",
                  "vicinity"
                ];
      case /* ALTERNATE */2 :
          throw {
                RE_EXN_ID: "Assert_failure",
                _1: [
                  "GoogleAutoCompleteInput.res",
                  63,
                  21
                ],
                Error: new Error()
              };
      
    }
  };
  if (mode >= 2) {
    return null;
  } else {
    return React.createElement(Api.Autocomplete, {
                bounds: googleBounds(bounds),
                className: "google-autocomplete",
                restrictions: {
                  country: country_restrictions
                },
                fields: getFields(mode),
                onLoad: onLoad,
                onPlaceChanged: (function (param) {
                    if (autocomplete !== undefined) {
                      var autocomp = Caml_option.valFromOption(autocomplete);
                      switch (mode) {
                        case /* ECO */0 :
                            return GoogleAutoComplete$FrontendWebsite.getAddressV2(autocomp, (function (address, postalAddress) {
                                          return Curry._3(input_props.onChange, "", address, postalAddress);
                                        }));
                        case /* TURBO */1 :
                            Curry._3(input_props.onChange, "", GoogleAutoComplete$FrontendWebsite.getAddress(autocomp), undefined);
                            return ;
                        case /* ALTERNATE */2 :
                            throw {
                                  RE_EXN_ID: "Assert_failure",
                                  _1: [
                                    "GoogleAutoCompleteInput.res",
                                    35,
                                    23
                                  ],
                                  Error: new Error()
                                };
                        
                      }
                    } else {
                      console.log("Autocomplete is not loaded yet!");
                      return ;
                    }
                  }),
                options: {
                  strictBounds: is_strict_bounds
                },
                children: React.createElement(React.Fragment, undefined, React.createElement(FormTextInput$Catalyst.make, {
                          input_props: FormInput$Catalyst.make_props(input_props.label, (function ($$event) {
                                  return Curry._3(input_props.onChange, FormHelper$Catalyst.TargetEventWrapper.value($$event), undefined, undefined);
                                }), undefined, undefined, input_props.placeholder, undefined, undefined, undefined, input_props.result, undefined, input_props.className, undefined, undefined, undefined, undefined, undefined),
                          textinput_props: {
                            value: input_props.value
                          }
                        }), React.createElement("div", {
                          className: "empty-button",
                          onClick: (function (param) {
                              return Curry._3(input_props.onChange, "", undefined, undefined);
                            })
                        }, React.createElement(Icon$Catalyst.CloseIcon.make, {})))
              });
  }
}

var GoogleAutoCompleteInputCmp = {
  make: GoogleAutoCompleteInput$GoogleAutoCompleteInputCmp
};

function make_props(load_config, input_props_list, bounds, is_strict_boundsOpt, loadingElementOpt, chromeAutoSuggestionOpt, modeOpt, country_restrictions, param) {
  var is_strict_bounds = is_strict_boundsOpt !== undefined ? is_strict_boundsOpt : false;
  var loadingElement = loadingElementOpt !== undefined ? Caml_option.valFromOption(loadingElementOpt) : null;
  var chromeAutoSuggestion = chromeAutoSuggestionOpt !== undefined ? chromeAutoSuggestionOpt : true;
  var mode = modeOpt !== undefined ? modeOpt : /* TURBO */1;
  return {
          load_config: load_config,
          input_props_list: input_props_list,
          bounds: bounds,
          is_strict_bounds: is_strict_bounds,
          loadingElement: loadingElement,
          chromeAutoSuggestion: chromeAutoSuggestion,
          mode: mode,
          country_restrictions: country_restrictions
        };
}

var GoogleAutoCompleteInputProps = {
  make_props: make_props
};

function GoogleAutoCompleteInput(Props) {
  var autocomplete_props = Props.autocomplete_props;
  var match = Api.useJsApiLoader({
        id: "google-autocomplete-is-loaded",
        googleMapsApiKey: autocomplete_props.load_config.googleMapsApiKey,
        libraries: Config$FrontendWebsite.Env.libraries
      });
  return React.createElement("div", {
              className: "address_container"
            }, match.isLoaded ? $$Array.of_list(List.mapi((function (key, input_props) {
                          return React.createElement(GoogleAutoCompleteInput$GoogleAutoCompleteInputCmp, {
                                      input_props: input_props,
                                      bounds: autocomplete_props.bounds,
                                      is_strict_bounds: autocomplete_props.is_strict_bounds,
                                      chromeAutoSuggestion: autocomplete_props.chromeAutoSuggestion,
                                      mode: autocomplete_props.mode,
                                      country_restrictions: autocomplete_props.country_restrictions,
                                      key: String(key)
                                    });
                        }), autocomplete_props.input_props_list)) : (
                match.loadError !== undefined ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                        className: "centered"
                      }, React.createElement(CircularLoader$FrontendWebsite.make, {}))
              ));
}

var make = GoogleAutoCompleteInput;

export {
  GoogleAutoCompleteInputCmp ,
  GoogleAutoCompleteInputProps ,
  make ,
  
}
/* react Not a pure module */
