// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../utils/library/react-lazy/ReactLazy.bs.js";

var HomeMobileCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(HomeMobileCmpLazyWrapper);

function HomeMobileCmpLazyWrapper$1(Props) {
  var homePageCMSData = Props.homePageCMSData;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./HomeMobileCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            homePageCMSData: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              homePageCMSData: homePageCMSData
            });
}

var make = HomeMobileCmpLazyWrapper$1;

export {
  HomeMobileCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
