// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as ServicesEntityMapper$FrontendWebsite from "../entity-mapper/ServicesEntityMapper.bs.js";

var execute = ServicesEntityMapper$FrontendWebsite.execute;

var ServicesApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var ServicesResponseConverter = partial_arg(ServicesApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  ServicesApiConfig ,
  ServicesResponseConverter ,
  
}
/* ServicesResponseConverter Not a pure module */
