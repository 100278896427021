// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "../../../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ApiUtils$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as ErrorUtils$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";
import * as RescriptReactRouter from "../../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CircularLoader$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as GAConfig$FrontendWebsite from "../../../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../../../utils/library/ga/GAEvents.bs.js";
import * as UtmUtils$FrontendWebsite from "../../../../../utils/UtmUtils.bs.js";
import * as ReactGoogleRecaptchaV3 from "react-google-recaptcha-v3";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CityConfig$FrontendWebsite from "../../../../city/CityConfig.bs.js";
import * as CityHelper$FrontendWebsite from "../../../../city/CityHelper.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../../../utils/helper/CommonHelper.bs.js";
import * as EventContext$FrontendWebsite from "../../../../../utils/context/events-manager/EventContext.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../../../utils/context/events-manager/EventsHelper.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../../../utils/NetworkUtils.bs.js";
import * as CityCookieHook$FrontendWebsite from "../../../../../utils/hooks/CityCookieHook.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as HomeRepoManager$FrontendWebsite from "../../../../home/repo/HomeRepoManager.bs.js";
import * as QueryParamsConstructor$Catalyst from "../../../../../../node_modules/@porterin/catalyst/src/helper-modules/query-params/QueryParamsConstructor.bs.js";
import * as TripEstimateCmp$FrontendWebsite from "../TripEstimateCmp.bs.js";

function EstimateFormWrapperDesktopCmp(Props) {
  var container_class_nameOpt = Props.container_class_name;
  var city = Props.city;
  var container_class_name = container_class_nameOpt !== undefined ? container_class_nameOpt : "";
  var RepoInstance = HomeRepoManager$FrontendWebsite.getRepo(undefined);
  var userEvents = EventContext$FrontendWebsite.useEventContext(undefined);
  var recaptcha_execute = ReactGoogleRecaptchaV3.useGoogleReCaptcha();
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var language = match.language;
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var getTripEstimateInfo = function (tripEstimate, consent_for_callback) {
    var match = UtmUtils$FrontendWebsite.getUtmString(undefined);
    var utm_campaign = match[1];
    var utm_source = match[0];
    var consent_for_callback_url_param = consent_for_callback !== undefined ? "&consent_for_callback=" + Pervasives.string_of_bool(consent_for_callback) : "";
    EventsHelper$FrontendWebsite.publishFareEstimateSuccessfulEventParameter(tripEstimate.name, tripEstimate.mobile, CityHelper$FrontendWebsite.geoRegionIdToCity(Caml_format.caml_int_of_string(tripEstimate.geo_region), country), tripEstimate.requirement, GAConfigActions.get_trip_estimate_successful, EventsHelper$FrontendWebsite.getEventLanguage(language));
    var recaptcha_val = recaptcha_execute.executeRecaptcha("homepage");
    if (recaptcha_val !== undefined) {
      Caml_option.valFromOption(recaptcha_val).then(function (token) {
              CommonHelper$FrontendWebsite.publishReCaptchaGaEvents(userEvents.category, true, country, language);
              return Promise.resolve(ApiUtils$Catalyst.handleAPIRequest((function (param) {
                                return Curry._4(RepoInstance.getTripEstimate, tripEstimate, token, country, language);
                              }), (function (data) {
                                var form_url_param = Belt_Option.getWithDefault(QueryParamsConstructor$Catalyst.create(CommonHelper$FrontendWebsite.encodeToUrlTripEstimateFormInfo(tripEstimate)), "");
                                return RescriptReactRouter.push("/fare_estimate_mob?" + form_url_param + "&fare_estimate_token=" + data.fare_estimate_token + consent_for_callback_url_param + "&" + CommonHelper$FrontendWebsite.getUTmParamString(undefined));
                              }), (function (error) {
                                GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.get_trip_estimate_failure, {
                                      event_category: GAConfigActions.category,
                                      event_label: "1_" + tripEstimate.name + "#2_" + tripEstimate.mobile + "#3_frequency-" + tripEstimate.requirement + "#4_" + utm_source + "#5_" + utm_campaign,
                                      language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                    });
                                return NetworkUtils$FrontendWebsite.captureException("City/Vehicle - FareEstimateHash API Failed - " + ErrorUtils$Catalyst.resolveError(error));
                              })));
            }).catch(function (error) {
            NetworkUtils$FrontendWebsite.captureException("City/Vehicle - ReCaptcha Failed - " + error.toString());
            return Promise.resolve(undefined);
          });
      return ;
    } else {
      CommonHelper$FrontendWebsite.publishReCaptchaGaEvents(userEvents.category, false, country, language);
      return NetworkUtils$FrontendWebsite.captureException("City/Vehicle - ReCaptcha Init Failed");
    }
  };
  var match$1 = CityCookieHook$FrontendWebsite.useCity(undefined);
  var match$2 = React.useState(function () {
        return /* Init */0;
      });
  var setCityBounds = match$2[1];
  var cityBounds = match$2[0];
  var get_geo_region_info = function (city) {
    var cityId = CityHelper$FrontendWebsite.cityToGeoRegionId(city, country);
    return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._3(RepoInstance.getGeoregionBounds, cityId, country, language);
                }), (function (data) {
                  return Curry._1(setCityBounds, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setCityBounds, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
  };
  React.useEffect((function () {
          if (city !== undefined) {
            get_geo_region_info(city);
          } else {
            get_geo_region_info(CityConfig$FrontendWebsite.defaultCity(country));
          }
          
        }), [city]);
  var tmp;
  if (typeof cityBounds === "number") {
    tmp = React.createElement(CircularLoader$Catalyst.make, {});
  } else {
    switch (cityBounds.TAG | 0) {
      case /* Loading */0 :
          tmp = React.createElement(CircularLoader$Catalyst.make, {});
          break;
      case /* Live */1 :
          tmp = React.createElement("div", {
                className: "modal-container"
              }, React.createElement(TripEstimateCmp$FrontendWebsite.make, {
                    initialCity: city,
                    setCity: match$1[1],
                    bounds: cityBounds._0,
                    get_trip_estimate: getTripEstimateInfo,
                    event_category: userEvents.category
                  }));
          break;
      case /* Error */2 :
          tmp = React.createElement(ErrorDisplayComponent$Catalyst.make, {
                error: cityBounds._0
              });
          break;
      
    }
  }
  return React.createElement("div", {
              className: container_class_name
            }, React.createElement("div", {
                  className: "common-desktop-estimate-form-wrapper"
                }, tmp));
}

var make = EstimateFormWrapperDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
