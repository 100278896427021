// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../../app/Config.bs.js";

var baseRouterUrl = Config$FrontendWebsite.Env.getFEUrl(undefined);

var personal = "personal";

var business = "business";

function getRequirementStrings(str) {
  switch (str) {
    case "Business" :
        return business;
    case "Personal" :
        return personal;
    default:
      return str;
  }
}

var Select = {
  personal: personal,
  business: business,
  getRequirementStrings: getRequirementStrings
};

var terms_of_service_url = baseRouterUrl + "/terms-and-conditions";

var English_heading = "";

var English_driverPartnerHeading = "DRIVE WITH US";

var English_driverPartnerHeadingDesc = "turn kms into money";

var English_driverPartnerJoinUsTitle = "JOIN US";

var English_navbar_title_1 = "City Tempo";

var English_navbar_title_2 = "Driver Partners";

var English_form_heading_1 = "City";

var English_form_heading_2 = "Pick Up Address";

var English_form_heading_3 = "DropOff Address";

var English_form_heading_4 = "Mobile Number";

var English_form_heading_5 = "Enter Mobile Number";

var English_form_heading_6 = "Name(Optional)";

var English_form_heading_7 = "Requirement";

var English_form_button_title = "Get an Estimate";

var English_form_consent_box_title = "By checking this, you give consent to Porter to reach out to you via phone/WhatsApp to help with booking, etc";

var English_about_us_link_text = "About Us";

var English_privacy_policy_link_text = "Privacy Policy";

var English_terms_of_service_link_text = "Terms & Conditions";

var English_refund_policy_link_text = "Refund Policy";

var English_tariff_subtitle_1 = "Pricing may vary based on your locality.";

var English_tariff_subtitle_2 = "Road tax, parking etc as applicable over and above ride fare.";

var English_tariff_button_title = "Know More";

var English_fare_start_text = "Fares starting from";

var English_companyCinNo = "TRAD/DNCC/001055/2023";

var English_companyCopyrightText = "২০২৩ পোর্টার টেকনোলজিস বাংলাদেশ প্রাইভেট লিমিটেড";

var English = {
  Select: Select,
  heading: English_heading,
  select_city_label: "Select City",
  driverPartnerHeading: English_driverPartnerHeading,
  driverPartnerHeadingDesc: English_driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: English_driverPartnerJoinUsTitle,
  navbar_title_1: English_navbar_title_1,
  navbar_title_2: English_navbar_title_2,
  form_heading_1: English_form_heading_1,
  form_heading_2: English_form_heading_2,
  form_heading_3: English_form_heading_3,
  form_heading_4: English_form_heading_4,
  form_heading_5: English_form_heading_5,
  form_heading_6: English_form_heading_6,
  form_heading_7: English_form_heading_7,
  form_button_title: English_form_button_title,
  form_consent_box_title: English_form_consent_box_title,
  about_us_link_text: English_about_us_link_text,
  privacy_policy_link_text: English_privacy_policy_link_text,
  terms_of_service_link_text: English_terms_of_service_link_text,
  terms_of_service_url: terms_of_service_url,
  refund_policy_link_text: English_refund_policy_link_text,
  tariff_subtitle_1: English_tariff_subtitle_1,
  tariff_subtitle_2: English_tariff_subtitle_2,
  tariff_button_title: English_tariff_button_title,
  fare_start_text: English_fare_start_text,
  download_porter_button_label: "Download Porter",
  pickup_address_placeholder: "Enter PickUp Address",
  dropoff_address_placeholder: "Enter DropOff Address",
  get_estimate_title: "Get Trip Estimate",
  footer_title: "BOOK NOW",
  optional_placeholder: "Optional",
  companyCinNo: English_companyCinNo,
  companyCopyrightText: English_companyCopyrightText,
  newCompanyCopyrightText: "",
  newCompanyCinNo: ""
};

var personal$1 = "ব্যক্তিগত";

var business$1 = "ব্যবসা";

function getRequirementStrings$1(str) {
  var match = str.toLowerCase();
  switch (match) {
    case "business" :
        return business$1;
    case "personal" :
        return personal$1;
    default:
      return str;
  }
}

var Select$1 = {
  personal: personal$1,
  business: business$1,
  getRequirementStrings: getRequirementStrings$1
};

var terms_of_service_url$1 = baseRouterUrl + "/terms-and-conditions";

var BD_heading = "";

var BD_select_city_label = "শহর নির্বাচন করুন";

var BD_driverPartnerHeading = "আমাদের সাথে ড্রাইভ করুন";

var BD_driverPartnerHeadingDesc = "দাপিয়ে বেড়ান, আয় করুন";

var BD_driverPartnerJoinUsTitle = "আমাদের সাথে যোগ দিন";

var BD_navbar_title_1 = "সিটি টেম্পো";

var BD_navbar_title_2 = "ড্রাইভার পার্টনার";

var BD_form_heading_1 = "শহর";

var BD_form_heading_2 = "পিকআপের ঠিকানা";

var BD_form_heading_3 = "ড্রপঅফের ঠিকানা";

var BD_form_heading_4 = "মোবাইল নম্বর";

var BD_form_heading_5 = "মোবাইল নম্বর লিখুন";

var BD_form_heading_6 = "নাম (ঐচ্ছিক)";

var BD_form_heading_7 = "প্রয়োজনীয় শর্ত";

var BD_form_button_title = "আনুমানিক হিসাব পান";

var BD_form_consent_box_title = "এটিতে টিক চিহ্ন দিয়ে, আপনি পোর্টার-কে বুকিং ইত্যাদিতে সহায়তা করার জন্য ফোন / WhatsApp-এর মাধ্যমে আপনার সাথে যোগাযোগের সম্মতি দিচ্ছেন।";

var BD_about_us_link_text = "আমাদের সম্পর্কে";

var BD_privacy_policy_link_text = "গোপনীয়তা নীতি";

var BD_refund_policy_link_text = "চার্জ ফেরতের নীতিমালা";

var BD_terms_of_service_link_text = "শর্তাবলী";

var BD_footer_column_heading_2 = "footer_column_heading_2";

var BD_download_porter_button_label = "পোর্টার ডাউনলোড করুন";

var BD_pickup_address_placeholder = "পিকআপ ঠিকানা লিখুন";

var BD_dropoff_address_placeholder = "ড্রপঅফ ঠিকানা লিখুন";

var BD_get_estimate_title = "ট্রিপ এস্টিমেট পান";

var BD_footer_title = "বুক করুন";

var BD_optional_placeholder = "ঐচ্ছিক";

var BD_companyCinNo = "TRAD/DNCC/001055/2023";

var BD_companyCopyrightText = "২০২৩ পোর্টার টেকনোলজিস বাংলাদেশ প্রাইভেট লিমিটেড";

var BD = {
  Select: Select$1,
  heading: BD_heading,
  select_city_label: BD_select_city_label,
  driverPartnerHeading: BD_driverPartnerHeading,
  driverPartnerHeadingDesc: BD_driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: BD_driverPartnerJoinUsTitle,
  navbar_title_1: BD_navbar_title_1,
  navbar_title_2: BD_navbar_title_2,
  form_heading_1: BD_form_heading_1,
  form_heading_2: BD_form_heading_2,
  form_heading_3: BD_form_heading_3,
  form_heading_4: BD_form_heading_4,
  form_heading_5: BD_form_heading_5,
  form_heading_6: BD_form_heading_6,
  form_heading_7: BD_form_heading_7,
  form_button_title: BD_form_button_title,
  form_consent_box_title: BD_form_consent_box_title,
  about_us_link_text: BD_about_us_link_text,
  privacy_policy_link_text: BD_privacy_policy_link_text,
  refund_policy_link_text: BD_refund_policy_link_text,
  terms_of_service_link_text: BD_terms_of_service_link_text,
  terms_of_service_url: terms_of_service_url$1,
  footer_column_heading_2: BD_footer_column_heading_2,
  download_porter_button_label: BD_download_porter_button_label,
  pickup_address_placeholder: BD_pickup_address_placeholder,
  dropoff_address_placeholder: BD_dropoff_address_placeholder,
  get_estimate_title: BD_get_estimate_title,
  footer_title: BD_footer_title,
  optional_placeholder: BD_optional_placeholder,
  companyCinNo: BD_companyCinNo,
  companyCopyrightText: BD_companyCopyrightText,
  newCompanyCopyrightText: "",
  newCompanyCinNo: ""
};

export {
  baseRouterUrl ,
  English ,
  BD ,
  
}
/* baseRouterUrl Not a pure module */
