// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as GAEvents$FrontendWebsite from "../../library/ga/GAEvents.bs.js";
import * as VehicleType$FrontendWebsite from "../../../app/vehicle/VehicleType.bs.js";

function routeToEventCategory(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Home */1 :
          return "Home";
      case /* DriverPartners */2 :
          return "Partner";
      case /* AboutUs */3 :
          return "About Us";
      case /* TermsOfService */4 :
          return "Terms of Service";
      case /* SpotFaq */9 :
          return "Spot Faq";
      case /* Support */10 :
          return "Support";
      default:
        return "";
    }
  } else {
    switch (route.TAG | 0) {
      case /* CityPage */0 :
          return route._0 + " City";
      case /* VehiclePage */1 :
          return "Vehicle " + (VehicleType$FrontendWebsite.toString(route._1) + (" " + route._0));
      case /* PrivacyPolicy */3 :
          if (route._0 !== undefined) {
            return "Privacy Policy";
          } else {
            return "";
          }
      default:
        return "";
    }
  }
}

function userEventHandler(category, action, label, value, language) {
  return GAEvents$FrontendWebsite.publishEventParameter(action, {
              event_category: category,
              event_label: label,
              event_value: value,
              nonInteraction: true,
              language_selected: language
            });
}

function getNumberedEventLabel(labels) {
  var modified_labels = List.mapi((function (index, label) {
          return String(index + 1 | 0) + ("_" + label);
        }), labels);
  return $$String.concat("#", modified_labels);
}

function getEventLanguage(language) {
  switch (language.TAG | 0) {
    case /* UAE */0 :
        return "English";
    case /* BD */1 :
        if (language._0) {
          return "Bangla";
        } else {
          return "English";
        }
    case /* TR */2 :
        if (language._0) {
          return "Turkish";
        } else {
          return "English";
        }
    
  }
}

function publishFareEstimateSuccessfulEventParameter(name, mobile, city, frequency, action, language) {
  return GAEvents$FrontendWebsite.publishEventParameter(action, {
              name: name,
              mobile_number: mobile,
              city: city,
              frequency: frequency,
              language_selected: language
            });
}

export {
  routeToEventCategory ,
  userEventHandler ,
  getNumberedEventLabel ,
  getEventLanguage ,
  publishFareEstimateSuccessfulEventParameter ,
  
}
/* GAEvents-FrontendWebsite Not a pure module */
