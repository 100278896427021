// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as MediaVMMapper$FrontendWebsite from "../../website/cms/view-model/MediaVMMapper.bs.js";

function getHeroSection(data) {
  return {
          title: data.title,
          description: data.description,
          image: MediaVMMapper$FrontendWebsite.getBanner(data.image.data),
          appTitle: data.appDownload.title,
          googlePlayStoreUrl: data.appDownload.googlePlayStoreUrl,
          googlePlayStoreBanner: MediaVMMapper$FrontendWebsite.getBanner(data.appDownload.googlePlayStoreBanner.data)
        };
}

function getSection(data) {
  return {
          title: data.title,
          image: MediaVMMapper$FrontendWebsite.getBanner(data.image.data),
          points: $$Array.map((function (e) {
                  return {
                          title: e.title,
                          description: e.description
                        };
                }), data.points)
        };
}

function getVm(data) {
  return {
          heroSection: getHeroSection(data.heroSection),
          porterAdvantage: getSection(data.porterAdvantage),
          lifeEasySection: getSection(data.lifeEasySection),
          ownVehicleSection: getSection(data.ownVehicleSection),
          faqs: data.faqs
        };
}

export {
  getHeroSection ,
  getSection ,
  getVm ,
  
}
/* No side effect */
