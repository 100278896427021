// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";

function getFaqs(questions) {
  return $$Array.map((function (question) {
                return {
                        order: question.order,
                        question: question.question,
                        answer: question.answer
                      };
              }), questions);
}

function getFaqsSectionData(faqs) {
  return {
          title: faqs.title,
          questions: getFaqs(faqs.questions)
        };
}

function cmsVM(cmsData) {
  var match = cmsData.data.attributes;
  return {
          pageTitle: match.title,
          faqData: getFaqsSectionData(match.faqs)
        };
}

export {
  getFaqs ,
  getFaqsSectionData ,
  cmsVM ,
  
}
/* No side effect */
