// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ExternalLink$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/external-link/ExternalLink.bs.js";
import * as GAConfig$FrontendWebsite from "../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../utils/library/ga/GAEvents.bs.js";
import * as ReasonReactUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ReasonReactUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../utils/context/events-manager/EventsHelper.bs.js";
import * as AssetsManager$FrontendWebsite from "../../../view/assets/AssetsManager.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

var pickup_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("pickup_address_icon.svg");

var drop_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("drop_address_icon.svg");

var waypoint_img = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("waypoint.svg");

var mobile = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("mobile_icon.svg");

var getCountryDailingCode = WebsiteConfig$FrontendWebsite.isd_code;

function getPickupAddress(pickupAddress) {
  return React.createElement("div", {
              className: "track-live-trip-header__detail-address-row"
            }, React.createElement("div", {
                  className: "waypoint-icon waypoint-icon__green"
                }, React.createElement("div", {
                      className: "icon icon__pickup"
                    })), React.createElement("div", {
                  className: "track-live-trip-header__detail-address"
                }, pickupAddress), React.createElement("div", {
                  className: "track-live-trip-header__vertical-line"
                }));
}

function getWaypointInfo(details) {
  return ReasonReactUtils$Catalyst.listToReactArray(List.mapi((function (idx, waypoint) {
                    var tmp;
                    if (idx === (List.length(details.waypointAddresses) - 1 | 0)) {
                      var match = details.isRental;
                      var match$1 = details.dropAddress;
                      tmp = match || match$1 === undefined ? null : React.createElement("div", {
                              className: "track-live-trip-header__vertical-line"
                            });
                    } else {
                      tmp = React.createElement("div", {
                            className: "track-live-trip-header__vertical-line"
                          });
                    }
                    return React.createElement("div", {
                                key: String(idx),
                                className: "track-live-trip-header__detail-address-row"
                              }, React.createElement("div", {
                                    className: "waypoint-icon"
                                  }, React.createElement("div", undefined, String(idx + 1 | 0))), React.createElement("div", {
                                    className: "track-live-trip-header__detail-address"
                                  }, waypoint), tmp);
                  }), details.waypointAddresses));
}

function getDropAddress(details) {
  var match = details.isRental;
  var match$1 = details.dropAddress;
  if (match || match$1 === undefined) {
    return null;
  } else {
    return React.createElement("div", {
                className: "track-live-trip-header__detail-address-row"
              }, React.createElement("div", {
                    className: "waypoint-icon waypoint-icon__red"
                  }, React.createElement("div", {
                        className: "icon"
                      })), React.createElement("div", {
                    className: "track-live-trip-header__detail-address"
                  }, match$1));
  }
}

function TrackLiveTripHeaderDetailComponent(Props) {
  var details = Props.details;
  var onDetailClick = Props.onDetailClick;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var language = match.language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var getHeaderTitle = function (title) {
    return React.createElement("div", {
                className: "track-live-trip-header__detail-trip-title"
              }, title);
  };
  var getCrnDetails = function (crn) {
    return React.createElement("div", {
                className: "track-live-trip-header__crn-details"
              }, Content.TrackOrder.order_id_text + crn);
  };
  var getHeaderDetailAddressSegment = function (details) {
    return React.createElement("div", {
                className: "track-live-trip-header__detail-trip-address"
              }, React.createElement("div", {
                    className: "track-live-trip-header__detail-address-block"
                  }, getCrnDetails(details.crn), getPickupAddress(details.pickupAddress), getWaypointInfo(details), getDropAddress(details)));
  };
  var getHeaderDetailTripSegment = function (details) {
    return React.createElement("div", {
                className: "track-live-trip-header__detail-trip-container"
              }, getHeaderTitle(Content.TrackOrder.order_details_text), getHeaderDetailAddressSegment(details));
  };
  var getCallIcon = function (details) {
    if (details.customerInfo.mobile !== "") {
      return React.createElement("div", {
                  className: "track-live-trip-header__title-action-button",
                  onClick: (function (param) {
                      return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.customerCallPlacerClicked, {
                                  event_category: WebsiteConfig$FrontendWebsite.ga_title_track_live_trip,
                                  event_label: details.crn,
                                  language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                });
                    })
                }, React.createElement(ExternalLink$Catalyst.make, {
                      link: "tel:" + (WebsiteConfig$FrontendWebsite.isd_code(country) + details.customerInfo.mobile),
                      className: "track-live-trip-header__title-mobile-link",
                      children: React.createElement("img", {
                            className: "track-live-trip-header__title-mobile-img",
                            src: mobile
                          })
                    }));
    } else {
      return null;
    }
  };
  var getCustomerDetails = function (details) {
    return React.createElement("div", {
                className: "track-live-trip-header__detail-trip-container"
              }, React.createElement("div", {
                    className: "track-live-trip-header__sender_info"
                  }, React.createElement("div", {
                        className: "track-live-trip-header__details_sender"
                      }, getHeaderTitle(Content.TrackOrder.order_placed_by_text), details.customerInfo.name), getCallIcon(details)));
  };
  var renderContactSupport = function (details) {
    var match = details.status;
    if (match === 5 || match === 4) {
      return null;
    } else if (details.customerSupportContact !== "") {
      return React.createElement("div", {
                  className: "track-live-trip-header__detail-trip-container"
                }, React.createElement("div", {
                      className: "track-live-trip-header__contact-support-section"
                    }, React.createElement("div", undefined, Content.TrackOrder.experiencing_issues_text), React.createElement(ExternalLink$Catalyst.make, {
                          link: "tel:" + (WebsiteConfig$FrontendWebsite.isd_code(country) + details.customerSupportContact),
                          className: "",
                          children: React.createElement("div", {
                                onClick: (function (param) {
                                    return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.supportClicked, {
                                                event_category: WebsiteConfig$FrontendWebsite.ga_title_track_live_trip,
                                                event_label: details.crn,
                                                language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                              });
                                  })
                              }, Content.TrackOrder.contact_support_text)
                        })));
    } else {
      return null;
    }
  };
  var hideOrderDetails = function (onDetailClick) {
    return React.createElement("div", {
                className: "track-live-trip-header__show-order-details-container",
                onClick: (function (_event) {
                    return Curry._1(onDetailClick, undefined);
                  })
              }, React.createElement("div", undefined, Content.TrackOrder.hide_order_details_text), React.createElement("div", {
                    className: "track-live-trip-header__show-order-details-container__indicator__hide"
                  }, React.createElement("img", {
                        src: AssetsManager$FrontendWebsite.getTrackLiveTripsImage("drop-down-arrow.svg")
                      })));
  };
  return React.createElement("div", {
              className: "track-live-trip-header__detail-container"
            }, getHeaderDetailTripSegment(details), getCustomerDetails(details), renderContactSupport(details), hideOrderDetails(onDetailClick));
}

var make = TrackLiveTripHeaderDetailComponent;

export {
  pickup_icon ,
  drop_icon ,
  waypoint_img ,
  mobile ,
  getCountryDailingCode ,
  getPickupAddress ,
  getWaypointInfo ,
  getDropAddress ,
  make ,
  
}
/* pickup_icon Not a pure module */
