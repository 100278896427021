// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ParagraphViewCmp$FrontendWebsite from "./ParagraphViewCmp.bs.js";
import * as ParagraphOrderedViewCmp$FrontendWebsite from "./ParagraphOrderedViewCmp.bs.js";
import * as ParagraphUnOrderedViewCmp$FrontendWebsite from "./ParagraphUnOrderedViewCmp.bs.js";

function ParagraphViewManager(Props) {
  var paragraph = Props.paragraph;
  var childrenOpt = Props.children;
  var index = Props.index;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var match = paragraph.show_dot;
  switch (match) {
    case /* Bullet */0 :
        return React.createElement(ParagraphUnOrderedViewCmp$FrontendWebsite.make, {
                    paragraph: paragraph,
                    children: children
                  });
    case /* Numbered */1 :
        return React.createElement(ParagraphOrderedViewCmp$FrontendWebsite.make, {
                    paragraph: paragraph,
                    children: children,
                    index: index
                  });
    case /* None */2 :
        return React.createElement(ParagraphViewCmp$FrontendWebsite.make, {
                    paragraph: paragraph,
                    children: children
                  });
    
  }
}

var make = ParagraphViewManager;

export {
  make ,
  
}
/* react Not a pure module */
