// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function callback_request_response_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var callback_request_id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "callback_request_id"), null));
  if (callback_request_id.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              callback_request_id: callback_request_id._0
            }
          };
  }
  var e = callback_request_id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".callback_request_id" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function max_discount_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var amount = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
  if (amount.TAG === /* Ok */0) {
    var currency = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "currency"), null));
    if (currency.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                amount: amount._0,
                currency: currency._0
              }
            };
    }
    var e = currency._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".currency" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function discount_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var status = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
  if (status.TAG === /* Ok */0) {
    var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
    if (value.TAG === /* Ok */0) {
      var max_discount = max_discount_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "max_discount"), null));
      if (max_discount.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  status: status._0,
                  value: value._0,
                  max_discount: max_discount._0
                }
              };
      }
      var e = max_discount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".max_discount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = value._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".value" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = status._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".status" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function fareInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var value = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
  if (value.TAG === /* Ok */0) {
    var currency = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "currency"), null));
    if (currency.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                value: value._0,
                currency: currency._0
              }
            };
    }
    var e = currency._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".currency" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = value._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".value" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function vehicle_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var display_name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "display_name"), null));
  if (display_name.TAG === /* Ok */0) {
    var vehicle_type = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicle_type"), null));
    if (vehicle_type.TAG === /* Ok */0) {
      var capacity_display = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "capacity_display"), null));
      if (capacity_display.TAG === /* Ok */0) {
        var size_display = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "size_display"), null));
        if (size_display.TAG === /* Ok */0) {
          var image_url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_url"), null));
          if (image_url.TAG === /* Ok */0) {
            var image_alt_text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image_alt_text"), null));
            if (image_alt_text.TAG === /* Ok */0) {
              var base_fare_display = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "base_fare_display"), null));
              if (base_fare_display.TAG === /* Ok */0) {
                var base_fare = fareInfo_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "base_fare"), null));
                if (base_fare.TAG === /* Ok */0) {
                  var discount = discount_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "discount"), null));
                  if (discount.TAG === /* Ok */0) {
                    var new_base_fare_display = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "new_base_fare_display"), null));
                    if (new_base_fare_display.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                display_name: display_name._0,
                                vehicle_type: vehicle_type._0,
                                capacity_display: capacity_display._0,
                                size_display: size_display._0,
                                image_url: image_url._0,
                                image_alt_text: image_alt_text._0,
                                base_fare_display: base_fare_display._0,
                                base_fare: base_fare._0,
                                discount: discount._0,
                                new_base_fare_display: new_base_fare_display._0
                              }
                            };
                    }
                    var e = new_base_fare_display._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".new_base_fare_display" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = discount._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".discount" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = base_fare._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".base_fare" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = base_fare_display._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".base_fare_display" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = image_alt_text._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".image_alt_text" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = image_url._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".image_url" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = size_display._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".size_display" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = capacity_display._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".capacity_display" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = vehicle_type._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".vehicle_type" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = display_name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".display_name" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var vehicles = Decco.listFromJson(vehicle_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicles"), null));
  if (vehicles.TAG === /* Ok */0) {
    var callback_request_response = callback_request_response_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "callback_request_response"), null));
    if (callback_request_response.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                vehicles: vehicles._0,
                callback_request_response: callback_request_response._0
              }
            };
    }
    var e = callback_request_response._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".callback_request_response" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = vehicles._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".vehicles" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  callback_request_response_decode ,
  max_discount_decode ,
  discount_decode ,
  fareInfo_decode ,
  vehicle_decode ,
  t_decode ,
  
}
/* No side effect */
