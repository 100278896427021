// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as TermsOfServiceDubaiBranchRepo$FrontendWebsite from "./TermsOfServiceDubaiBranchRepo.bs.js";
import * as DummyTermsOfServiceDubaiBranchRepo$FrontendWebsite from "./DummyTermsOfServiceDubaiBranchRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyTermsOfServiceDubaiBranchRepo$FrontendWebsite;
  } else {
    return TermsOfServiceDubaiBranchRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
