// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as Config$FrontendWebsite from "../Config.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../website/types/WebsiteTypes.bs.js";
import * as ContentManager$FrontendWebsite from "../../utils/languang_and_content/ContentManager.bs.js";

function getVehicleNavBarLinks(vehicle_name, city, language, slug) {
  var Content = ContentManager$FrontendWebsite.getContent(language);
  if (slug === "courier") {
    return {
            hd: {
              name: Content.Navigation.home_label,
              path: WebsiteTypes$FrontendWebsite.createUrl(Config$FrontendWebsite.Env.getFEUrl(undefined))
            },
            tl: {
              hd: {
                name: vehicle_name,
                path: WebsiteTypes$FrontendWebsite.createUrl("")
              },
              tl: /* [] */0
            }
          };
  } else {
    return {
            hd: {
              name: Content.Navigation.home_label,
              path: WebsiteTypes$FrontendWebsite.createUrl(Config$FrontendWebsite.Env.getFEUrl(undefined))
            },
            tl: {
              hd: {
                name: Curry._1(Content.Navigation.city_label, city),
                path: WebsiteTypes$FrontendWebsite.createUrl(Config$FrontendWebsite.Env.getFEUrl(undefined) + ("/rent-mini-trucks-in-" + $$String.lowercase_ascii(city)))
              },
              tl: {
                hd: {
                  name: vehicle_name,
                  path: WebsiteTypes$FrontendWebsite.createUrl("")
                },
                tl: /* [] */0
              }
            }
          };
  }
}

export {
  getVehicleNavBarLinks ,
  
}
/* Config-FrontendWebsite Not a pure module */
