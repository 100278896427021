// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as Belt_List from "../../../node_modules/rescript/lib/es6/belt_List.js";
import * as CityConfig$FrontendWebsite from "./CityConfig.bs.js";

function convertStringToCity(str, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        var match = str.toLowerCase().trim();
        switch (match) {
          case "dubai" :
              return CityConfig$FrontendWebsite.UAE.dubai;
          case "sharjah" :
              return CityConfig$FrontendWebsite.UAE.sharjah;
          default:
            return CityConfig$FrontendWebsite.defaultCity(country);
        }
    case /* BD */1 :
        var match$1 = str.toLowerCase().trim();
        if (match$1 === "dhaka") {
          return CityConfig$FrontendWebsite.BD.dhaka;
        } else {
          return CityConfig$FrontendWebsite.defaultCity(country);
        }
    case /* TR */2 :
        var match$2 = str.toLowerCase().trim();
        if (match$2 === "istanbul") {
          return CityConfig$FrontendWebsite.TR.istanbul;
        } else {
          return CityConfig$FrontendWebsite.defaultCity(country);
        }
    
  }
}

function filterDataBasedOnCity(geo_regions, city) {
  return Belt_List.keep(geo_regions, (function (geo_region) {
                return $$String.lowercase_ascii(geo_region.name) === city;
              }));
}

function filterDataBasedOnId(geo_regions, id) {
  return Belt_List.keep(geo_regions, (function (geo_region) {
                return geo_region.id === id;
              }));
}

function getGeoRegionData(country) {
  return country._0.geoRegions;
}

function cityToGeoRegionId(city, country) {
  var data = country._0.geoRegions;
  var filteredCitiesData = filterDataBasedOnCity(data, city);
  if (List.length(filteredCitiesData) > 0) {
    return List.hd(filteredCitiesData).id;
  } else {
    return CityConfig$FrontendWebsite.defaultGeoRegionId(country);
  }
}

function geoRegionIdToCity(id, country) {
  var data = country._0.geoRegions;
  var filteredIdData = filterDataBasedOnId(data, id);
  if (List.length(filteredIdData) > 0) {
    return convertStringToCity(List.hd(filteredIdData).name, country);
  } else {
    return CityConfig$FrontendWebsite.defaultCity(country);
  }
}

export {
  convertStringToCity ,
  filterDataBasedOnCity ,
  filterDataBasedOnId ,
  getGeoRegionData ,
  cityToGeoRegionId ,
  geoRegionIdToCity ,
  
}
/* No side effect */
