// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CircularLoader$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as EventContext$FrontendWebsite from "../../../../utils/context/events-manager/EventContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../website/view/components/CircularLoader.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as PolicyContainerCmp$FrontendWebsite from "../../../website/components/policy/view/PolicyContainerCmp.bs.js";
import * as RefundPolicyRepoManager$FrontendWebsite from "../../repo/RefundPolicyRepoManager.bs.js";

function RefundPolicyCmp(Props) {
  var RepoInstance = RefundPolicyRepoManager$FrontendWebsite.getRepo(undefined);
  var userEvents = EventContext$FrontendWebsite.useEventContext(undefined);
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setCMSData = match$1[1];
  var cmsData = match$1[0];
  React.useEffect((function () {
          Curry._1(userEvents.pageLoadHandler, true);
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._2(RepoInstance.fetchRefundPolicyCMSData, countryConfig, language);
                }), (function (data) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  if (typeof cmsData === "number") {
    return React.createElement(CircularLoader$Catalyst.make, {});
  }
  switch (cmsData.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(CircularLoader$Catalyst.make, {});
    case /* Live */1 :
        var match$2 = cmsData._0.data;
        var attributes = match$2.attributes;
        return React.createElement(React.Suspense, {
                    fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                    children: React.createElement(PolicyContainerCmp$FrontendWebsite.make, {
                          title: attributes.title,
                          policyItems: attributes.policyItems
                        })
                  });
    case /* Error */2 :
        return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                    error: cmsData._0
                  });
    
  }
}

var make = RefundPolicyCmp;

export {
  make ,
  
}
/* react Not a pure module */
