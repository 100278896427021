// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../../Config.bs.js";
import * as GeoRegionRepo$FrontendWebsite from "./GeoRegionRepo.bs.js";
import * as DummyGeoRegionRepo$FrontendWebsite from "./DummyGeoRegionRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return {
            fetchGeoRegions: DummyGeoRegionRepo$FrontendWebsite.fetchGeoRegions
          };
  } else {
    return GeoRegionRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
