// Generated by ReScript, PLEASE EDIT WITH CARE


function make_props(text, is_boldOpt, show_dot, childlistOpt, show_htmlOpt, param) {
  var is_bold = is_boldOpt !== undefined ? is_boldOpt : false;
  var childlist = childlistOpt !== undefined ? childlistOpt : [];
  var show_html = show_htmlOpt !== undefined ? show_htmlOpt : /* Text */0;
  return {
          text: text,
          is_bold: is_bold,
          show_dot: show_dot,
          childlist: childlist,
          show_html: show_html
        };
}

export {
  make_props ,
  
}
/* No side effect */
