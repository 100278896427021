// Generated by ReScript, PLEASE EDIT WITH CARE


var English = {
  consentSettingTitle: "We Respect your Privacy!",
  consentSettingDesc: "We use cookies to offer you a better user experience: Learn more on our cookie policy from ",
  consentManagementTitle: "Cookie Preferences",
  consentManagementDesc: "If you allow the use of cookies, your data will be processed domestically and abroad. For further information about cookies, you can read our ",
  consentManagementLinkText: "Cookie Policy",
  neccessaryCategoryTitle: "Strictly Necessary Cookies",
  neccessaryCategoryDesc: "Used for monitoring the systems and report any issues",
  functionalCategoryTitle: "Functional Cookies",
  functionalCategoryDesc: "Used for improving the functionality and experience",
  analyticsCategoryTitle: "Analytics/Performance Cookies",
  analyticsCategoryDesc: "To help us improve our website and make it user-friendly",
  marketingCategoryTitle: "Marketing Cookies",
  marketingCategoryDesc: "Personal data like name, mobile number when submitting fare estimate form. Campaign details and other relevant data used by Facebook",
  acceptAllBtn: "Accept all",
  adjustCoookieSettingBtn: "Modify cookie settings",
  cnfCookiePreferenceBtn: "Confirm Preferences"
};

var TR_consentSettingTitle = "Gizliliğinize saygı duyuyoruz!";

var TR_consentSettingDesc = "Size daha iyi bir kullanıcı deneyimi sunmak için çerezleri kullanıyoruz: Çerez politikamız hakkında daha fazla bilgiyi şu adresten alabilirsiniz: ";

var TR_consentManagementTitle = "Çerez Tercihleri";

var TR_consentManagementDesc = "Çerez kullanımına izin vermeniz halinde verileriniz yurt içinde ve yurt dışında işlenecektir. Çerezler hakkında daha detaylı bilgi için yazımızı okuyabilirsiniz ";

var TR_consentManagementLinkText = "Çerez politikası";

var TR_neccessaryCategoryTitle = "Kesinlikle Gerekli Çerezler";

var TR_neccessaryCategoryDesc = "Sistemlerin izlenmesi ve sorunların raporlanması için kullanılır";

var TR_functionalCategoryTitle = "Fonksiyonel Çerezler";

var TR_functionalCategoryDesc = "İşlevselliği ve deneyimi geliştirmek için kullanılır";

var TR_analyticsCategoryTitle = "Analitik/Performans Çerezi";

var TR_analyticsCategoryDesc = "Web sitemizi geliştirmemize ve kullanıcı dostu hale getirmemize yardımcı olmak için";

var TR_marketingCategoryTitle = "Pazarlama Çerezleri";

var TR_marketingCategoryDesc = "Ücret tahmin formunu gönderirken isim, cep telefonu numarası gibi kişisel veriler. Facebook tarafından kullanılan kampanya ayrıntıları ve diğer ilgili veriler";

var TR_acceptAllBtn = "Hepsini kabul et";

var TR_adjustCoookieSettingBtn = "Çerez ayarlarını düzenleyin";

var TR_cnfCookiePreferenceBtn = "Tercihleri ​​Onayla";

var TR = {
  consentSettingTitle: TR_consentSettingTitle,
  consentSettingDesc: TR_consentSettingDesc,
  consentManagementTitle: TR_consentManagementTitle,
  consentManagementDesc: TR_consentManagementDesc,
  consentManagementLinkText: TR_consentManagementLinkText,
  neccessaryCategoryTitle: TR_neccessaryCategoryTitle,
  neccessaryCategoryDesc: TR_neccessaryCategoryDesc,
  functionalCategoryTitle: TR_functionalCategoryTitle,
  functionalCategoryDesc: TR_functionalCategoryDesc,
  analyticsCategoryTitle: TR_analyticsCategoryTitle,
  analyticsCategoryDesc: TR_analyticsCategoryDesc,
  marketingCategoryTitle: TR_marketingCategoryTitle,
  marketingCategoryDesc: TR_marketingCategoryDesc,
  acceptAllBtn: TR_acceptAllBtn,
  adjustCoookieSettingBtn: TR_adjustCoookieSettingBtn,
  cnfCookiePreferenceBtn: TR_cnfCookiePreferenceBtn
};

export {
  English ,
  TR ,
  
}
/* No side effect */
