// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as MediaEntityMapper$FrontendWebsite from "./MediaEntityMapper.bs.js";

function cityAttributesToEntity(cityAttributes) {
  return {
          title: cityAttributes.title,
          description: cityAttributes.description,
          cityName: cityAttributes.cityName,
          cityUrl: cityAttributes.cityUrl,
          cityIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(cityAttributes.cityIcon)
        };
}

function cityDataToEntity(cityData) {
  return {
          id: cityData.id,
          attributes: cityAttributesToEntity(cityData.attributes)
        };
}

function cityToEntity(city) {
  return {
          data: cityDataToEntity(city.data)
        };
}

function availableCityDataToEntity(availableCityData) {
  return $$Array.map((function (city) {
                return {
                        attributes: cityAttributesToEntity(city.attributes)
                      };
              }), availableCityData);
}

function availableCitiesToEntity(availableCities) {
  return {
          data: availableCityDataToEntity(availableCities.data)
        };
}

function serviceableCitiesToEntity(serviceableCities) {
  return {
          title: serviceableCities.title,
          cities: {
            data: availableCityDataToEntity(serviceableCities.cities.data)
          }
        };
}

export {
  cityAttributesToEntity ,
  cityDataToEntity ,
  cityToEntity ,
  availableCityDataToEntity ,
  availableCitiesToEntity ,
  serviceableCitiesToEntity ,
  
}
/* No side effect */
