// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";

function LinkCmp(Props) {
  var href = Props.href;
  var targetOpt = Props.target;
  var classNameOpt = Props.className;
  var styleOpt = Props.style;
  var children = Props.children;
  var on_clickOpt = Props.on_click;
  var target = targetOpt !== undefined ? targetOpt : "_self";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({});
  var on_click = on_clickOpt !== undefined ? on_clickOpt : (function (param) {
        
      });
  return React.createElement("a", {
              "aria-label": className,
              className: "link " + className,
              style: style,
              href: href,
              target: target,
              onClick: on_click
            }, children);
}

var make = LinkCmp;

export {
  make ,
  
}
/* react Not a pure module */
