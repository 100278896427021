// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as PrivacyPolicyRepo$FrontendWebsite from "./PrivacyPolicyRepo.bs.js";
import * as DummyPrivacyPolicyRepo$FrontendWebsite from "./DummyPrivacyPolicyRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyPrivacyPolicyRepo$FrontendWebsite;
  } else {
    return PrivacyPolicyRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
