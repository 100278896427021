// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function ImagePreLoader(Props) {
  var img_src = Props.img_src;
  var webp_src = Props.webp_src;
  var img_typeOpt = Props.img_type;
  var img_type = img_typeOpt !== undefined ? img_typeOpt : "image/png";
  return React.createElement("picture", undefined, React.createElement("source", {
                  className: "",
                  srcSet: webp_src,
                  type: "image/webp"
                }), React.createElement("source", {
                  className: "",
                  srcSet: img_src,
                  type: img_type
                }), React.createElement("img", {
                  className: "preloading-images",
                  style: {
                    height: "1px",
                    width: "1px"
                  },
                  alt: "",
                  src: img_src
                }));
}

var make = ImagePreLoader;

export {
  make ,
  
}
/* react Not a pure module */
