// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as EventContext$FrontendWebsite from "../../../../utils/context/events-manager/EventContext.bs.js";
import * as PartnersTermsOfServiceInfoCmp$FrontendWebsite from "./common/PartnersTermsOfServiceInfoCmp.bs.js";
import * as PartnersTermsOfServiceInfoCmp_UAE$FrontendWebsite from "./common/PartnersTermsOfServiceInfoCmp_UAE.bs.js";

((require('../assets/styles/desktop.scss')));

((require('../assets/styles/common.scss')));

((require('../assets/styles/mobile.scss')));

function PartnersTermsOfServiceCmp(Props) {
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var userEvents = EventContext$FrontendWebsite.useEventContext(undefined);
  var match = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var title;
  switch (match.TAG | 0) {
    case /* UAE */0 :
        title = "Driver Partner Terms and Conditions";
        break;
    case /* BD */1 :
        title = match._0 ? "পরিষেবা অংশীদারের শর্তাবলী" : "Driver Partner Terms and Conditions";
        break;
    case /* TR */2 :
        title = match._0 ? "Sürücü Ortak Hizmet Şartları" : "Driver Partner Terms and Conditions";
        break;
    
  }
  React.useEffect((function () {
          Curry._1(userEvents.pageLoadHandler, true);
          
        }), []);
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = React.createElement(PartnersTermsOfServiceInfoCmp_UAE$FrontendWebsite.make, {});
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp = React.createElement(PartnersTermsOfServiceInfoCmp$FrontendWebsite.make, {});
        break;
    
  }
  return React.createElement("div", {
              className: "terms-of-service-container"
            }, React.createElement("div", {
                  className: "terms-of-service-header-wrapper"
                }, React.createElement("h1", {
                      className: "terms-of-service-header-title"
                    }, title)), tmp);
}

var make = PartnersTermsOfServiceCmp;

export {
  make ,
  
}
/*  Not a pure module */
