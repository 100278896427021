// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EventsConfig$FrontendWebsite from "./EventsConfig.bs.js";
import * as EventsHelper$FrontendWebsite from "./EventsHelper.bs.js";

var listOfPageViewsPct_1 = {
  hd: EventsConfig$FrontendWebsite.viewed_pct_25,
  tl: {
    hd: EventsConfig$FrontendWebsite.viewed_pct_50,
    tl: {
      hd: EventsConfig$FrontendWebsite.viewed_pct_75,
      tl: {
        hd: EventsConfig$FrontendWebsite.viewed_pct_100,
        tl: /* [] */0
      }
    }
  }
};

var listOfPageViewsPct = {
  hd: EventsConfig$FrontendWebsite.viewed_pct_10,
  tl: listOfPageViewsPct_1
};

function getViewPctAndMaxViewedPct(user_viewed_perc) {
  if (user_viewed_perc < EventsConfig$FrontendWebsite.viewed_pct_25) {
    return [
            EventsConfig$FrontendWebsite.viewed_pct_10,
            EventsConfig$FrontendWebsite.viewed_pct_25 - 1 | 0
          ];
  } else if (user_viewed_perc < EventsConfig$FrontendWebsite.viewed_pct_50) {
    return [
            EventsConfig$FrontendWebsite.viewed_pct_25,
            EventsConfig$FrontendWebsite.viewed_pct_50 - 1 | 0
          ];
  } else if (user_viewed_perc < EventsConfig$FrontendWebsite.viewed_pct_75) {
    return [
            EventsConfig$FrontendWebsite.viewed_pct_50,
            EventsConfig$FrontendWebsite.viewed_pct_75 - 1 | 0
          ];
  } else if (user_viewed_perc < EventsConfig$FrontendWebsite.viewed_pct_100) {
    return [
            EventsConfig$FrontendWebsite.viewed_pct_75,
            EventsConfig$FrontendWebsite.viewed_pct_100 - 1 | 0
          ];
  } else {
    return [
            EventsConfig$FrontendWebsite.viewed_pct_100,
            EventsConfig$FrontendWebsite.viewed_pct_100 + 50 | 0
          ];
  }
}

function setPageViewEvents(route, viewed_pct, action, language) {
  return EventsHelper$FrontendWebsite.userEventHandler(EventsHelper$FrontendWebsite.routeToEventCategory(route), action, "Page Viewed " + (String(viewed_pct) + "%"), viewed_pct, language);
}

export {
  listOfPageViewsPct ,
  getViewPctAndMaxViewedPct ,
  setPageViewEvents ,
  
}
/* EventsHelper-FrontendWebsite Not a pure module */
