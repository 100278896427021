// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Home_TR$FrontendWebsite from "./Home_TR.bs.js";
import * as Vehicle_TR$FrontendWebsite from "./Vehicle_TR.bs.js";
import * as Navigation_TR$FrontendWebsite from "./Navigation_TR.bs.js";
import * as TrackOrder_TR$FrontendWebsite from "./TrackOrder_TR.bs.js";

var English_Home = {
  Select: Home_TR$FrontendWebsite.English.Select,
  heading: Home_TR$FrontendWebsite.English.heading,
  select_city_label: Home_TR$FrontendWebsite.English.select_city_label,
  driverPartnerHeading: Home_TR$FrontendWebsite.English.driverPartnerHeading,
  driverPartnerHeadingDesc: Home_TR$FrontendWebsite.English.driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: Home_TR$FrontendWebsite.English.driverPartnerJoinUsTitle,
  navbar_title_1: Home_TR$FrontendWebsite.English.navbar_title_1,
  navbar_title_2: Home_TR$FrontendWebsite.English.navbar_title_2,
  form_heading_1: Home_TR$FrontendWebsite.English.form_heading_1,
  form_heading_2: Home_TR$FrontendWebsite.English.form_heading_2,
  form_heading_3: Home_TR$FrontendWebsite.English.form_heading_3,
  form_heading_4: Home_TR$FrontendWebsite.English.form_heading_4,
  form_heading_5: Home_TR$FrontendWebsite.English.form_heading_5,
  form_heading_6: Home_TR$FrontendWebsite.English.form_heading_6,
  form_heading_7: Home_TR$FrontendWebsite.English.form_heading_7,
  form_button_title: Home_TR$FrontendWebsite.English.form_button_title,
  form_consent_box_title: Home_TR$FrontendWebsite.English.form_consent_box_title,
  about_us_link_text: Home_TR$FrontendWebsite.English.about_us_link_text,
  privacy_policy_link_text: Home_TR$FrontendWebsite.English.privacy_policy_link_text,
  terms_of_service_link_text: Home_TR$FrontendWebsite.English.terms_of_service_link_text,
  terms_of_service_url: Home_TR$FrontendWebsite.English.terms_of_service_url,
  refund_policy_link_text: Home_TR$FrontendWebsite.English.refund_policy_link_text,
  download_porter_button_label: Home_TR$FrontendWebsite.English.download_porter_button_label,
  pickup_address_placeholder: Home_TR$FrontendWebsite.English.pickup_address_placeholder,
  dropoff_address_placeholder: Home_TR$FrontendWebsite.English.dropoff_address_placeholder,
  get_estimate_title: Home_TR$FrontendWebsite.English.get_estimate_title,
  footer_title: Home_TR$FrontendWebsite.English.footer_title,
  optional_placeholder: Home_TR$FrontendWebsite.English.optional_placeholder,
  companyCopyrightText: Home_TR$FrontendWebsite.English.companyCopyrightText,
  companyCinNo: Home_TR$FrontendWebsite.English.companyCinNo,
  newCompanyCopyrightText: Home_TR$FrontendWebsite.English.newCompanyCopyrightText,
  newCompanyCinNo: Home_TR$FrontendWebsite.English.newCompanyCinNo
};

var English = {
  Home: English_Home,
  TrackOrder: TrackOrder_TR$FrontendWebsite.English,
  Vehicle: Vehicle_TR$FrontendWebsite.English,
  Navigation: Navigation_TR$FrontendWebsite.English
};

var TR_Home = {
  Select: Home_TR$FrontendWebsite.TR.Select,
  heading: Home_TR$FrontendWebsite.TR.heading,
  select_city_label: Home_TR$FrontendWebsite.TR.select_city_label,
  driverPartnerHeading: Home_TR$FrontendWebsite.TR.driverPartnerHeading,
  driverPartnerHeadingDesc: Home_TR$FrontendWebsite.TR.driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: Home_TR$FrontendWebsite.TR.driverPartnerJoinUsTitle,
  navbar_title_1: Home_TR$FrontendWebsite.TR.navbar_title_1,
  navbar_title_2: Home_TR$FrontendWebsite.TR.navbar_title_2,
  form_heading_1: Home_TR$FrontendWebsite.TR.form_heading_1,
  form_heading_2: Home_TR$FrontendWebsite.TR.form_heading_2,
  form_heading_3: Home_TR$FrontendWebsite.TR.form_heading_3,
  form_heading_4: Home_TR$FrontendWebsite.TR.form_heading_4,
  form_heading_5: Home_TR$FrontendWebsite.TR.form_heading_5,
  form_heading_6: Home_TR$FrontendWebsite.TR.form_heading_6,
  form_heading_7: Home_TR$FrontendWebsite.TR.form_heading_7,
  form_button_title: Home_TR$FrontendWebsite.TR.form_button_title,
  form_consent_box_title: Home_TR$FrontendWebsite.TR.form_consent_box_title,
  about_us_link_text: Home_TR$FrontendWebsite.TR.about_us_link_text,
  privacy_policy_link_text: Home_TR$FrontendWebsite.TR.privacy_policy_link_text,
  terms_of_service_link_text: Home_TR$FrontendWebsite.TR.terms_of_service_link_text,
  terms_of_service_url: Home_TR$FrontendWebsite.TR.terms_of_service_url,
  refund_policy_link_text: Home_TR$FrontendWebsite.TR.refund_policy_link_text,
  download_porter_button_label: Home_TR$FrontendWebsite.TR.download_porter_button_label,
  pickup_address_placeholder: Home_TR$FrontendWebsite.TR.pickup_address_placeholder,
  dropoff_address_placeholder: Home_TR$FrontendWebsite.TR.dropoff_address_placeholder,
  get_estimate_title: Home_TR$FrontendWebsite.TR.get_estimate_title,
  footer_title: Home_TR$FrontendWebsite.TR.footer_title,
  optional_placeholder: Home_TR$FrontendWebsite.TR.optional_placeholder,
  companyCopyrightText: Home_TR$FrontendWebsite.TR.companyCopyrightText,
  companyCinNo: Home_TR$FrontendWebsite.TR.companyCinNo,
  newCompanyCopyrightText: Home_TR$FrontendWebsite.TR.newCompanyCopyrightText,
  newCompanyCinNo: Home_TR$FrontendWebsite.TR.newCompanyCinNo
};

var TR = {
  Home: TR_Home,
  TrackOrder: TrackOrder_TR$FrontendWebsite.TR,
  Vehicle: Vehicle_TR$FrontendWebsite.TR,
  Navigation: Navigation_TR$FrontendWebsite.TR
};

export {
  English ,
  TR ,
  
}
/* Home_TR-FrontendWebsite Not a pure module */
