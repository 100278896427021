// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";

function FareEstimateResultMobileHeaderCmp(Props) {
  var edit_form_handler = Props.edit_form_handler;
  var vehicle_Section = Props.vehicle_Section;
  var edit_section = Props.edit_section;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  return React.createElement("div", {
              className: "fare-estimate-result-mobile-header-container"
            }, React.createElement("div", {
                  className: "info"
                }, React.createElement("div", {
                      className: "title"
                    }, vehicle_Section.title), React.createElement("div", {
                      className: "desc"
                    }, vehicle_Section.subtitle)), React.createElement("div", {
                  className: "fare-estimate-result-button",
                  onClick: edit_form_handler
                }, edit_section.editButtonLabel));
}

var make = FareEstimateResultMobileHeaderCmp;

export {
  make ,
  
}
/* react Not a pure module */
