// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "../../website/cms/api-model/MediaApiModel.bs.js";

function supportItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var icon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "icon"), null));
      if (icon.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  description: description._0,
                  icon: icon._0
                }
              };
      }
      var e = icon._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".icon" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function officeItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var $$location = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "location"), null));
  if ($$location.TAG === /* Ok */0) {
    var address = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "address"), null));
    if (address.TAG === /* Ok */0) {
      var getDirectionLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "getDirectionLabel"), null));
      if (getDirectionLabel.TAG === /* Ok */0) {
        var getDirectionUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "getDirectionUrl"), null));
        if (getDirectionUrl.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    location: $$location._0,
                    address: address._0,
                    getDirectionLabel: getDirectionLabel._0,
                    getDirectionUrl: getDirectionUrl._0
                  }
                };
        }
        var e = getDirectionUrl._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".getDirectionUrl" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = getDirectionLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".getDirectionLabel" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = address._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".address" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = $$location._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".location" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function office_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var officeItems = Decco.arrayFromJson(officeItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "officeItems"), null));
    if (officeItems.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                officeItems: officeItems._0
              }
            };
    }
    var e = officeItems._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".officeItems" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var subText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subText"), null));
    if (subText.TAG === /* Ok */0) {
      var ourOfficesTitle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "ourOfficesTitle"), null));
      if (ourOfficesTitle.TAG === /* Ok */0) {
        var supportItems = Decco.arrayFromJson(supportItem_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportItems"), null));
        if (supportItems.TAG === /* Ok */0) {
          var headOffice = office_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "headOffice"), null));
          if (headOffice.TAG === /* Ok */0) {
            var regionalOffices = office_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "regionalOffices"), null));
            if (regionalOffices.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        title: title._0,
                        subText: subText._0,
                        ourOfficesTitle: ourOfficesTitle._0,
                        supportItems: supportItems._0,
                        headOffice: headOffice._0,
                        regionalOffices: regionalOffices._0
                      }
                    };
            }
            var e = regionalOffices._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".regionalOffices" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = headOffice._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".headOffice" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = supportItems._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".supportItems" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = ourOfficesTitle._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".ourOfficesTitle" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = subText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subText" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function data_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = data_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  supportItem_decode ,
  officeItem_decode ,
  office_decode ,
  attributes_decode ,
  data_decode ,
  t_decode ,
  
}
/* No side effect */
