// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";

function fromString(status) {
  switch (status) {
    case "accepted" :
        return /* Accepted */2;
    case "allocated" :
        return /* Allocated */1;
    case "cancelled" :
        return /* Cancelled */8;
    case "completed" :
        return /* Completed */7;
    case "ended" :
        return /* Ended */6;
    case "live" :
        return /* Live */3;
    case "open" :
        return /* Open */0;
    case "rescheduled" :
        return /* Rescheduled */9;
    default:
      return ErrorUtils$Catalyst.raiseError("TripStatusUtils.re", "Invalid order status", status);
  }
}

function getDisplayString(status, content) {
  switch (status) {
    case /* Open */0 :
        return content.open_order_status;
    case /* Allocated */1 :
        return content.allocated_order_status;
    case /* Accepted */2 :
        return content.accepted_order_status;
    case /* Live */3 :
        return content.live_order_status;
    case /* Loading */4 :
        return content.loading_order_status;
    case /* Unloading */5 :
        return content.unloading_order_status;
    case /* Ended */6 :
        return content.ended_order_status;
    case /* Completed */7 :
        return content.completed_order_status;
    case /* Cancelled */8 :
        return content.cancelled_order_status;
    case /* Rescheduled */9 :
        return content.rescheduled_order_status;
    
  }
}

function getDisplayColor(status) {
  switch (status) {
    case /* Open */0 :
    case /* Allocated */1 :
        return "orange";
    case /* Accepted */2 :
    case /* Live */3 :
    case /* Ended */6 :
    case /* Completed */7 :
        return "green";
    case /* Loading */4 :
    case /* Unloading */5 :
    case /* Cancelled */8 :
    case /* Rescheduled */9 :
        return "red";
    
  }
}

export {
  fromString ,
  getDisplayString ,
  getDisplayColor ,
  
}
/* No side effect */
