// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import * as H2TitleCmp$FrontendWebsite from "./H2TitleCmp.bs.js";

function VehicleSeoContentCmp(Props) {
  var seoContent = Props.seoContent;
  var title = Props.title;
  return React.createElement("div", {
              className: "vehicle-seo-content-container"
            }, React.createElement(H2TitleCmp$FrontendWebsite.make, {
                  title: title
                }), $$Array.map((function (content) {
                    return React.createElement("div", {
                                className: "seo-content"
                              }, React.createElement("h3", undefined, content.title), React.createElement("div", {
                                    className: "description"
                                  }, React.createElement(ReactMarkdown, {
                                        children: content.description
                                      })));
                  }), seoContent));
}

var make = VehicleSeoContentCmp;

export {
  make ,
  
}
/* react Not a pure module */
