// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as GAConfig$FrontendWebsite from "../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../utils/library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as TripStatus$FrontendWebsite from "../../track-live-order/entity/TripStatus.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../utils/context/events-manager/EventsHelper.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";
import * as TrackLiveTripConfig$FrontendWebsite from "../TrackLiveTripConfig.bs.js";
import * as TrackLiveTripHeaderTitleVMMapper$FrontendWebsite from "./TrackLiveTripHeaderTitleVMMapper.bs.js";
import * as TrackLiveTripHeaderDetailVMMapper$FrontendWebsite from "./TrackLiveTripHeaderDetailVMMapper.bs.js";
import * as TrackLiveTripHeaderTitleComponent$FrontendWebsite from "./TrackLiveTripHeaderTitleComponent.bs.js";
import * as TrackLiveTripHeaderDetailComponent$FrontendWebsite from "./TrackLiveTripHeaderDetailComponent.bs.js";

function reducer(_state, action) {
  if (action.TAG === /* SetDetailVisibility */0) {
    return {
            isDetailVisible: action._0,
            isBarScrollDown: _state.isBarScrollDown
          };
  } else {
    return {
            isDetailVisible: _state.isDetailVisible,
            isBarScrollDown: action._0
          };
  }
}

function TrackLiveTripOrderDetails(Props) {
  var order_detail = Props.order_detail;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var language = match.language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var TrackLiveTripHeaderDetailVM = TrackLiveTripHeaderDetailVMMapper$FrontendWebsite.VM(Content.TrackOrder);
  var TrackLiveTripHeaderTitleVM = TrackLiveTripHeaderTitleVMMapper$FrontendWebsite.VM(Content.TrackOrder);
  var match$1 = React.useReducer(reducer, {
        isDetailVisible: false,
        isBarScrollDown: false
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var getOrderStatus = function (order_detail) {
    var tmp;
    if (order_detail.status === /* Ended */6 || order_detail.status === /* Completed */7) {
      var endTime = order_detail.trip_ended_time;
      tmp = endTime !== undefined && ((new Date().getTime() | 0) - endTime | 0) > 3600 ? TrackLiveTripConfig$FrontendWebsite.ga_analytics_after_time_text : TrackLiveTripConfig$FrontendWebsite.ga_analytics_before_time_text;
    } else {
      tmp = "";
    }
    return TripStatus$FrontendWebsite.toString(order_detail.status) + tmp;
  };
  var onDetailClick = function (param) {
    if (!state.isDetailVisible) {
      GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.viewDetailsClicked, {
            event_category: WebsiteConfig$FrontendWebsite.ga_title_track_live_trip,
            event_label: order_detail.crn,
            language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
          });
      GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.viewDetailsClicked, {
            event_category: WebsiteConfig$FrontendWebsite.ga_title_track_live_trip,
            event_label: getOrderStatus(order_detail),
            language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
          });
    }
    return Curry._1(dispatch, {
                TAG: /* SetDetailVisibility */0,
                _0: !state.isDetailVisible
              });
  };
  var onBarClick = function (param) {
    return Curry._1(dispatch, {
                TAG: /* SetBarScrollDown */1,
                _0: !state.isBarScrollDown
              });
  };
  return React.createElement("div", {
              className: (
                state.isDetailVisible ? "track-live-trip-header__container__expand" : ""
              ) + (" track-live-trip-header__container" + (
                  state.isBarScrollDown ? " track-live-trip-header__container__down" : ""
                ))
            }, React.createElement(TrackLiveTripHeaderTitleComponent$FrontendWebsite.make, {
                  title: Curry._1(TrackLiveTripHeaderTitleVM.getVM, order_detail),
                  isDetailVisible: state.isDetailVisible,
                  onDetailClick: onDetailClick,
                  barScrollDown: state.isBarScrollDown,
                  onBarClick: onBarClick
                }), state.isDetailVisible ? React.createElement(TrackLiveTripHeaderDetailComponent$FrontendWebsite.make, {
                    details: Curry._1(TrackLiveTripHeaderDetailVM.getVM, order_detail),
                    onDetailClick: onDetailClick
                  }) : null);
}

var make = TrackLiveTripOrderDetails;

export {
  reducer ,
  make ,
  
}
/* react Not a pure module */
