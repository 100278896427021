// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as UrlUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as Config$FrontendWebsite from "../../../Config.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../../utils/NetworkUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../../CountryConfig.bs.js";
import * as LanguageConfig$FrontendWebsite from "../../../../utils/languang_and_content/LanguageConfig.bs.js";
import * as GeoRegionRepoConfig$FrontendWebsite from "./GeoRegionRepoConfig.bs.js";

function fetchGeoRegions(country, language) {
  var requestUrl = UrlUtils$Catalyst.createRequestURLfrmParams(Config$FrontendWebsite.Env.getOMSUrl(undefined), "/geo_regions/all_geo_regions", {});
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(GeoRegionRepoConfig$FrontendWebsite.GeoRegionResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  fetchGeoRegions ,
  
}
/* NetworkV2-Catalyst Not a pure module */
