// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";
import * as VehicleMobileCmp$FrontendWebsite from "./components/mobile/VehicleMobileCmp.bs.js";
import * as VehicleDesktopCmp$FrontendWebsite from "./components/desktop/VehicleDesktopCmp.bs.js";
import * as VehicleRepoManager$FrontendWebsite from "./repo/VehicleRepoManager.bs.js";

function VehicleCmp(Props) {
  var city = Props.city;
  var slug = Props.slug;
  var RepoInstance = VehicleRepoManager$FrontendWebsite.getRepo(undefined);
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setVehicleCMSData = match$1[1];
  var vehicleCMSData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._4(RepoInstance.getVehiclePageCMSData, city, slug, countryConfig, language);
                }), (function (data) {
                  return Curry._1(setVehicleCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setVehicleCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                  desktop_view: React.createElement(VehicleDesktopCmp$FrontendWebsite.make, {
                        city: city,
                        vehicleCMSData: vehicleCMSData
                      }),
                  mobile_view: React.createElement(VehicleMobileCmp$FrontendWebsite.make, {
                        vehicleCMSData: vehicleCMSData
                      })
                }));
}

var make = VehicleCmp;

export {
  make ,
  
}
/* react Not a pure module */
