// Generated by ReScript, PLEASE EDIT WITH CARE


var dubai = "dubai";

var UAE = {
  dubai: dubai,
  sharjah: "sharjah"
};

var dhaka = "dhaka";

var BD = {
  dhaka: dhaka
};

var istanbul = "istanbul";

var TR = {
  istanbul: istanbul
};

function defaultCity(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return dubai;
    case /* BD */1 :
        return dhaka;
    case /* TR */2 :
        return istanbul;
    
  }
}

function defaultGeoRegionId(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return 1;
    case /* BD */1 :
        return 3;
    case /* TR */2 :
        return 4;
    
  }
}

var head_office = "bangalore";

export {
  head_office ,
  UAE ,
  BD ,
  TR ,
  defaultCity ,
  defaultGeoRegionId ,
  
}
/* No side effect */
