// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as LanguageConfig$FrontendWebsite from "../../../utils/languang_and_content/LanguageConfig.bs.js";
import * as VehicleRepoConfig$FrontendWebsite from "./VehicleRepoConfig.bs.js";
import * as CMSDataSourceConfig$FrontendWebsite from "../../../utils/cms/CMSDataSourceConfig.bs.js";

function getVehiclePageInfo(city_name, vehicle, country, language) {
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + ("/city/" + (city_name + ("/inventory/" + window.encodeURIComponent(vehicle))));
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(VehicleRepoConfig$FrontendWebsite.VehicleResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function getVehiclePageCMSData(cityName, vehicleSlug, country, language) {
  var requestUrl = CMSDataSourceConfig$FrontendWebsite.getVehicleInventoryRequestUrl(country, language, vehicleSlug, cityName);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, undefined, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(VehicleRepoConfig$FrontendWebsite.VehicleCMSResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  getVehiclePageInfo ,
  getVehiclePageCMSData ,
  
}
/* NetworkV2-Catalyst Not a pure module */
