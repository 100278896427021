// Generated by ReScript, PLEASE EDIT WITH CARE


function city_label(city) {
  return city;
}

var English = {
  home_label: "Home",
  city_label: city_label
};

function city_label$1(city) {
  if (city === "Dhaka") {
    return "ঢাকা";
  } else {
    return "ঢাকা";
  }
}

var BD_home_label = "হোম";

var BD = {
  home_label: BD_home_label,
  city_label: city_label$1
};

export {
  English ,
  BD ,
  
}
/* No side effect */
