// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_encode from "../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as UrlUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/UrlUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as HomeHelper$FrontendWebsite from "../../../utils/helper/HomeHelper.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as HomeRepoConfig$FrontendWebsite from "./HomeRepoConfig.bs.js";
import * as LanguageConfig$FrontendWebsite from "../../../utils/languang_and_content/LanguageConfig.bs.js";
import * as CMSDataSourceConfig$FrontendWebsite from "../../../utils/cms/CMSDataSourceConfig.bs.js";
import * as FareEstimateResultHelper$FrontendWebsite from "../../fare-estimate-result/view/FareEstimateResultHelper.bs.js";

function getFareVehicles(geo_region_id, country, language) {
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + ("/website/get_vehicles_fare_details?geo_region_id=" + String(geo_region_id));
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(HomeRepoConfig$FrontendWebsite.FareDetailsResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function getTripEstimate(form, re_id, country, language) {
  var payload = HomeHelper$FrontendWebsite.encodeToUrlTripEstimateFormInfo(form);
  var json_encode = Json_encode.object_({
        hd: [
          "re_id",
          re_id
        ],
        tl: /* [] */0
      });
  var requestUrl = UrlUtils$Catalyst.createRequestURLfrmParams(Config$FrontendWebsite.Env.getOMSUrl(undefined), "/fare_estimate_secure", payload);
  var payloadString = JSON.stringify(json_encode);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.post(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, payloadString, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(HomeRepoConfig$FrontendWebsite.FareEstimateResultHashApiResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function encodeGeoRegionId(geo_region_id) {
  return Json_encode.object_({
              hd: [
                "geo_region_id",
                geo_region_id
              ],
              tl: /* [] */0
            });
}

function getGeoregionBounds(geo_region_id, country, language) {
  var payloadString = Json.stringify(encodeGeoRegionId(geo_region_id));
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + "/geo_regions/region_bounds";
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.post(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, payloadString, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(HomeRepoConfig$FrontendWebsite.GeoRegionBoundsResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function getFareEstimateResult(form_data, param, country, language, web_tracking_id) {
  var urlParams = FareEstimateResultHelper$FrontendWebsite.getFareEstimateResultParams(form_data, undefined, undefined, country, undefined);
  var requestUrl = UrlUtils$Catalyst.createRequestURLfrmParams(Config$FrontendWebsite.Env.getOMSUrl(undefined), "/fare_estimate_v2", urlParams) + ("&web_tracking_id=" + Belt_Option.getWithDefault(web_tracking_id, "null"));
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(HomeRepoConfig$FrontendWebsite.FareEstimateResultApiResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function sendSms(mobile, re_id, country, language) {
  var payloadString = JSON.stringify(Json_encode.object_({
            hd: [
              "mobile",
              mobile
            ],
            tl: {
              hd: [
                "re_id",
                re_id
              ],
              tl: /* [] */0
            }
          }));
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + "/website/send_app_link_sms";
  return NetworkV2$Catalyst.post(requestUrl, {
                country: CountryConfig$FrontendWebsite.countryToString(country),
                language: LanguageConfig$FrontendWebsite.languageToString(language)
              }, payloadString, undefined, undefined, undefined).then(function (result) {
              return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                          return Curry._1(HomeRepoConfig$FrontendWebsite.SendSmsApiResponseConverter.execute, result);
                        });
            });
}

function getHomePageCMSData(country, language) {
  var requestUrl = CMSDataSourceConfig$FrontendWebsite.getHomeRequestUrl(country, language);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, undefined, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(HomeRepoConfig$FrontendWebsite.HomePageCMSResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  getFareVehicles ,
  getTripEstimate ,
  encodeGeoRegionId ,
  getGeoregionBounds ,
  getFareEstimateResult ,
  sendSms ,
  getHomePageCMSData ,
  
}
/* NetworkV2-Catalyst Not a pure module */
