// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Footer$FrontendWebsite from "../footer/Footer.bs.js";
import * as Header$FrontendWebsite from "../header/Header.bs.js";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as MediaQuery$FrontendWebsite from "../../utils/hooks/MediaQuery.bs.js";
import * as VehicleType$FrontendWebsite from "../vehicle/VehicleType.bs.js";
import * as EventsHelper$FrontendWebsite from "../../utils/context/events-manager/EventsHelper.bs.js";
import * as ModalManager$FrontendWebsite from "../../view/modals/ModalManager.bs.js";
import * as WebsiteCMSDM$FrontendWebsite from "./cms/data-machine/WebsiteCMSDM.bs.js";
import * as WebsiteTypes$FrontendWebsite from "./types/WebsiteTypes.bs.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";
import * as ModalProvider$FrontendWebsite from "../../view/modals/ModalProvider.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../utils/languang_and_content/ContentManager.bs.js";
import * as EventWrapperCmp$FrontendWebsite from "../../utils/context/events-manager/EventWrapperCmp.bs.js";
import * as StickyComponent$FrontendWebsite from "./view/components/StickyComponent.bs.js";
import * as CookieConsentCmp$FrontendWebsite from "../cookie-consent/view/component/CookieConsentCmp.bs.js";
import * as FooterFallBackCmp$FrontendWebsite from "../footer/components/FooterFallBackCmp.bs.js";
import * as HeaderFallBackCmp$FrontendWebsite from "../header/components/HeaderFallBackCmp.bs.js";
import * as HeaderFooterCMSSM$FrontendWebsite from "./cms/state-machine/HeaderFooterCMSSM.bs.js";
import * as HeaderFooterCMSVM$FrontendWebsite from "./cms/view-model/HeaderFooterCMSVM.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../cookie-consent/utils/CookieConsentHelper.bs.js";

function WebsiteCmp(Props) {
  var route = Props.route;
  var website_cmp = Props.website_cmp;
  var match = React.useState(function () {
        return 0;
      });
  var setScroll = match[1];
  var scroll = match[0];
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var cookieConsent = CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var match$1 = React.useState(function () {
        return true;
      });
  var setNavBar = match$1[1];
  var match$2 = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  var isDesktop = match$2[0];
  var match$3 = React.useState(function () {
        return cookieConsent.neccesary;
      });
  var setConsentStatus = match$3[1];
  var match$4 = React.useReducer(HeaderFooterCMSSM$FrontendWebsite.StateMachine.machine, /* Init */0);
  var dispatch = match$4[1];
  var cmsData = match$4[0];
  React.useEffect((function () {
          WebsiteCMSDM$FrontendWebsite.FetchHeaderFooterCMS.execute((function (data) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchHeaderFooterCMSData */0,
                              _0: data
                            });
                }), (function (error) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchHeaderFooterCMSDataFailed */1,
                              _0: error
                            });
                }), country, language);
          
        }), []);
  var offer_details_info = {
    TAG: /* LabelledImgWithDesc */6,
    _0: {
      src_path: WebsiteTypes$FrontendWebsite.createAssetPath(AssetsManager$FrontendWebsite.getCommonImage("price_tag.svg")),
      text: WebsiteTypes$FrontendWebsite.createTitle(Content.Home.footer_title),
      desc: "",
      class_name: ""
    }
  };
  var get_utm_param_linked = function (param_value, category) {
    return {
            TAG: /* LinkTextWithAction */7,
            _0: {
              link_with_text: {
                text: WebsiteTypes$FrontendWebsite.createTitle(Content.Home.download_porter_button_label),
                target: WebsiteTypes$FrontendWebsite.createUrl(WebsiteConfig$FrontendWebsite.appUrl + "?" + WebsiteConfig$FrontendWebsite.get_utm_param_with_value(param_value))
              },
              action: (function (param) {
                  var params = WebsiteConfig$FrontendWebsite.get_utm_param_with_value(param_value);
                  return WebsiteConfig$FrontendWebsite.publishStickyGAEvents(WebsiteConfig$FrontendWebsite.stickyGaAction, params, category, EventsHelper$FrontendWebsite.getEventLanguage(language), undefined);
                })
            }
          };
  };
  var getstickyinfo = function (route) {
    if (typeof route === "number") {
      if (route === /* Home */1) {
        var param_value = [
          "website_sticky_DL",
          "sticky_bottom_organic",
          "sticky_bottom_organic_home",
          "website_sticky_bottom_organic_home",
          "",
          "Sticky DL Btn - Home"
        ];
        return get_utm_param_linked(param_value, "home");
      }
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "WebsiteCmp.res",
              57,
              4
            ],
            Error: new Error()
          };
    } else {
      switch (route.TAG | 0) {
        case /* CityPage */0 :
            var city = route._0;
            var param_value$1 = [
              "website_sticky_DL",
              "sticky_bottom_organic",
              "sticky_bottom_organic_" + city,
              "website_sticky_bottom_organic_" + city,
              "",
              "Sticky DL Btn - " + city
            ];
            return get_utm_param_linked(param_value$1, city);
        case /* VehiclePage */1 :
            var vehicle = route._1;
            var city$1 = route._0;
            var param_value$2 = [
              "website_sticky_DL",
              "sticky_bottom_organic",
              "sticky_bottom_organic_" + city$1 + "_" + VehicleType$FrontendWebsite.toString(vehicle),
              "website_sticky_bottom_organic_" + city$1 + "_" + VehicleType$FrontendWebsite.toString(vehicle),
              "",
              "Sticky DL Btn - " + city$1 + "," + VehicleType$FrontendWebsite.toString(vehicle)
            ];
            return get_utm_param_linked(param_value$2, city$1 + VehicleType$FrontendWebsite.toString(vehicle));
        default:
          throw {
                RE_EXN_ID: "Match_failure",
                _1: [
                  "WebsiteCmp.res",
                  57,
                  4
                ],
                Error: new Error()
              };
      }
    }
  };
  var scrollHandler = function ($$event) {
    var scrollTopValue = $$event.target.scrollTop;
    if (scrollTopValue > scroll && scrollTopValue > 65) {
      Curry._1(setNavBar, (function (param) {
              return false;
            }));
    } else {
      Curry._1(setNavBar, (function (param) {
              return true;
            }));
    }
    if (scrollTopValue > 65) {
      return Curry._1(setScroll, (function (param) {
                    return scrollTopValue;
                  }));
    }
    
  };
  React.useEffect((function () {
          var vh = (window.innerHeight * 0.01).toString();
          document.documentElement.style.setProperty("--vh", vh + "px");
          
        }), []);
  React.useEffect((function () {
          if (typeof route !== "number" && route.TAG === /* FareEstimateResult */4) {
            Curry._1(setConsentStatus, (function (param) {
                    return true;
                  }));
          }
          
        }), [cookieConsent]);
  var match$5 = ModalManager$FrontendWebsite.useModal(undefined);
  var toggleModal = match$5[2];
  var tmp;
  tmp = typeof cmsData === "number" ? React.createElement(HeaderFallBackCmp$FrontendWebsite.make, {}) : (
      cmsData.TAG === /* Data */0 ? React.createElement(Header$FrontendWebsite.make, {
              cmsData: HeaderFooterCMSVM$FrontendWebsite.getVm(cmsData._0)
            }) : null
    );
  var tmp$1;
  if (typeof cmsData === "number") {
    tmp$1 = React.createElement(FooterFallBackCmp$FrontendWebsite.make, {});
  } else if (cmsData.TAG === /* Data */0) {
    var tmp$2;
    if (isDesktop) {
      tmp$2 = null;
    } else {
      var exit = 0;
      if (typeof route === "number") {
        if (route === /* Home */1) {
          exit = 1;
        } else {
          tmp$2 = null;
        }
      } else {
        switch (route.TAG | 0) {
          case /* CityPage */0 :
          case /* VehiclePage */1 :
              exit = 1;
              break;
          default:
            tmp$2 = null;
        }
      }
      if (exit === 1) {
        tmp$2 = React.createElement(StickyComponent$FrontendWebsite.make, {
              link_with_text_action: getstickyinfo(route),
              offer_details_info: offer_details_info
            });
      }
      
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Footer$FrontendWebsite.make, {
              cmsData: HeaderFooterCMSVM$FrontendWebsite.getVm(cmsData._0).footer
            }), tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement(ModalProvider$FrontendWebsite.make, ModalProvider$FrontendWebsite.makeProps({
                  modal: match$5[1],
                  toggleModal: (function (modal) {
                      return Curry._1(toggleModal, (function (param) {
                                    return modal;
                                  }));
                    })
                }, React.createElement("div", {
                      className: isDesktop ? "website-home" : "website-home-mobile",
                      onScroll: scrollHandler
                    }, tmp, React.createElement(EventWrapperCmp$FrontendWebsite.make, {
                          country: country,
                          route: route,
                          scroll_height: scroll,
                          children: website_cmp
                        }), match$3[0] ? null : React.createElement(CookieConsentCmp$FrontendWebsite.make, {
                            setConsentStatus: setConsentStatus,
                            isDesktop: isDesktop
                          }), tmp$1, match$5[0]), undefined));
}

var make = WebsiteCmp;

export {
  make ,
  
}
/* react Not a pure module */
