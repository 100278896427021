// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";

function questionsToEntity(questions) {
  return $$Array.map((function (question) {
                return {
                        order: question.order,
                        question: question.question,
                        answer: question.answer
                      };
              }), questions);
}

function faqsToEntity(faqs) {
  return {
          title: faqs.title,
          questions: questionsToEntity(faqs.questions)
        };
}

export {
  questionsToEntity ,
  faqsToEntity ,
  
}
/* No side effect */
