// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as LinkCmp$FrontendWebsite from "../LinkCmp.bs.js";
import * as ImageContentCmp$FrontendWebsite from "../ImageContentCmp.bs.js";

function ServiceableCitiesDesktopCmp(Props) {
  var serviceableCitiesSectionData = Props.serviceableCitiesSectionData;
  var cities = serviceableCitiesSectionData.cities;
  if (cities.length !== 0) {
    return React.createElement("div", {
                className: "flex-col-align-center common-desktop-cities-container"
              }, React.createElement("div", {
                    className: "cities-wrapper"
                  }, $$Array.mapi((function (index, city) {
                          return React.createElement("div", {
                                      key: String(index),
                                      className: "city-container"
                                    }, React.createElement(LinkCmp$FrontendWebsite.make, {
                                          href: city.cityUrl,
                                          className: "city-box",
                                          children: React.createElement(ImageContentCmp$FrontendWebsite.make, {
                                                title: $$String.capitalize_ascii(city.title),
                                                class_name: "city-img " + city.cityName,
                                                background_img: city.cityIcon.url,
                                                container_class_name: "city-content-wrapper"
                                              })
                                        }));
                        }), cities)));
  } else {
    return null;
  }
}

var make = ServiceableCitiesDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
