// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Timestamp$Catalyst from "../../node_modules/@porterin/catalyst/src/entities/time-stamp/Timestamp.bs.js";
import * as RescriptReactRouter from "../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as Cmp404$FrontendWebsite from "./website/components/default/Cmp404.bs.js";
import * as Config$FrontendWebsite from "./Config.bs.js";
import * as Metapixel$FrontendWebsite from "../utils/library/metapixel/Metapixel.bs.js";
import * as ReactGoogleRecaptchaV3 from "react-google-recaptcha-v3";
import * as AppProvider$FrontendWebsite from "../utils/context/app-contexts/AppProvider.bs.js";
import * as InitializeGa$FrontendWebsite from "../utils/library/initializer/InitializeGa.bs.js";
import * as AssetsManager$FrontendWebsite from "../view/assets/AssetsManager.bs.js";
import * as CountryConfig$FrontendWebsite from "./CountryConfig.bs.js";
import * as GeoRegionHook$FrontendWebsite from "./website/geo-region/GeoRegionHook.bs.js";
import * as LanguageConfig$FrontendWebsite from "../utils/languang_and_content/LanguageConfig.bs.js";
import * as ErrorBoundaryHOC$FrontendWebsite from "../utils/library/error-boundary/ErrorBoundaryHOC.bs.js";
import * as HelmetWrapperCmp$FrontendWebsite from "./HelmetWrapperCmp.bs.js";
import * as RescriptReactUtils$FrontendWebsite from "../utils/RescriptReactUtils.bs.js";
import * as WebsiteRouteConfig$FrontendWebsite from "./routing/WebsiteRouteConfig.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "./cookie-consent/utils/CookieConsentHelper.bs.js";
import * as RouteComponentFactory$FrontendWebsite from "./routing/RouteComponentFactory.bs.js";

AssetsManager$FrontendWebsite.importCss(undefined);

function App(Props) {
  var match = React.useState(function () {
        return CountryConfig$FrontendWebsite.getCountryConfig(undefined);
      });
  var setCountry = match[1];
  var country = match[0];
  var match$1 = React.useState(function () {
        return LanguageConfig$FrontendWebsite.getLanguage(undefined);
      });
  var setLanguage = match$1[1];
  var language = match$1[0];
  var cookieConsent = CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined);
  var setGeoRegions = function (geo_regions) {
    return Curry._1(setCountry, (function (param) {
                  switch (country.TAG | 0) {
                    case /* UAE */0 :
                        var config = country._0;
                        return {
                                TAG: /* UAE */0,
                                _0: {
                                  currency: config.currency,
                                  timeZone: config.timeZone,
                                  geoRegions: geo_regions
                                }
                              };
                    case /* BD */1 :
                        var config$1 = country._0;
                        return {
                                TAG: /* BD */1,
                                _0: {
                                  currency: config$1.currency,
                                  timeZone: config$1.timeZone,
                                  geoRegions: geo_regions
                                }
                              };
                    case /* TR */2 :
                        var config$2 = country._0;
                        return {
                                TAG: /* TR */2,
                                _0: {
                                  currency: config$2.currency,
                                  timeZone: config$2.timeZone,
                                  geoRegions: geo_regions
                                }
                              };
                    
                  }
                }));
  };
  React.useEffect((function () {
          GeoRegionHook$FrontendWebsite.getGeoRegionIds(country, setGeoRegions, language);
          
        }), []);
  var route = WebsiteRouteConfig$FrontendWebsite.getRoute(RescriptReactUtils$FrontendWebsite.optimizeRoute(RescriptReactRouter.useUrl(undefined, undefined)), country);
  Timestamp$Catalyst.setTz(country._0.timeZone);
  if (Config$FrontendWebsite.Env.env !== "development") {
    InitializeGa$FrontendWebsite.useGaInitializer(cookieConsent.analytics, Config$FrontendWebsite.Env.getGaTrackingId(country), Config$FrontendWebsite.Env.getGaTrackingName(country));
  }
  if (cookieConsent.marketing) {
    Metapixel$FrontendWebsite.initMetapixelCfg(Config$FrontendWebsite.Env.getMetaPixelId(country));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  id: "map"
                }), React.createElement(ReactGoogleRecaptchaV3.GoogleReCaptchaProvider, {
                  reCaptchaKey: Config$FrontendWebsite.Env.recaptcha_site_key,
                  children: React.createElement(AppProvider$FrontendWebsite.make, AppProvider$FrontendWebsite.makeProps({
                            language: language,
                            setLanguage: (function (lang) {
                                return Curry._1(setLanguage, (function (param) {
                                              switch (lang.TAG | 0) {
                                                case /* UAE */0 :
                                                    return {
                                                            TAG: /* UAE */0,
                                                            _0: lang._0
                                                          };
                                                case /* BD */1 :
                                                    return {
                                                            TAG: /* BD */1,
                                                            _0: lang._0
                                                          };
                                                case /* TR */2 :
                                                    return {
                                                            TAG: /* TR */2,
                                                            _0: lang._0
                                                          };
                                                
                                              }
                                            }));
                              }),
                            setCountryRegions: (function (geoRegions) {
                                return Curry._1(setCountry, (function (param) {
                                              switch (country.TAG | 0) {
                                                case /* UAE */0 :
                                                    var config = country._0;
                                                    return {
                                                            TAG: /* UAE */0,
                                                            _0: {
                                                              currency: config.currency,
                                                              timeZone: config.timeZone,
                                                              geoRegions: geoRegions
                                                            }
                                                          };
                                                case /* BD */1 :
                                                    var config$1 = country._0;
                                                    return {
                                                            TAG: /* BD */1,
                                                            _0: {
                                                              currency: config$1.currency,
                                                              timeZone: config$1.timeZone,
                                                              geoRegions: geoRegions
                                                            }
                                                          };
                                                case /* TR */2 :
                                                    var config$2 = country._0;
                                                    return {
                                                            TAG: /* TR */2,
                                                            _0: {
                                                              currency: config$2.currency,
                                                              timeZone: config$2.timeZone,
                                                              geoRegions: geoRegions
                                                            }
                                                          };
                                                
                                              }
                                            }));
                              }),
                            countryConfig: country
                          }, route !== undefined ? React.createElement(HelmetWrapperCmp$FrontendWebsite.make, {
                                  route: route,
                                  children: React.createElement(ErrorBoundaryHOC$FrontendWebsite.make, {
                                        children: RouteComponentFactory$FrontendWebsite.getWebsiteComponentFromRoute(route, country)
                                      })
                                }) : React.createElement(HelmetWrapperCmp$FrontendWebsite.make, {
                                  children: React.createElement(Cmp404$FrontendWebsite.make, {})
                                }), undefined))
                }));
}

var make = App;

export {
  make ,
  
}
/*  Not a pure module */
