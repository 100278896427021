// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as SupportCMSRepo$FrontendWebsite from "./SupportCMSRepo.bs.js";
import * as DummySupportCMSRepo$FrontendWebsite from "./DummySupportCMSRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummySupportCMSRepo$FrontendWebsite;
  } else {
    return SupportCMSRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
