// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as JsBindings$FrontendWebsite from "../../../../utils/library/bindings/JsBindings.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../website/view/components/CircularLoader.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../../website/view/components/ResponsiveLayout.bs.js";
import * as AboutUsRepoManager$FrontendWebsite from "../../repo/AboutUsRepoManager.bs.js";
import * as AboutUsMobileCmpLazyWrapper$FrontendWebsite from "./mobile/AboutUsMobileCmpLazyWrapper.bs.js";
import * as AboutUsDesktopCmpLazyWrapper$FrontendWebsite from "./desktop/AboutUsDesktopCmpLazyWrapper.bs.js";

function AboutUsCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var RepoInstance = AboutUsRepoManager$FrontendWebsite.getRepo(undefined);
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setAboutUsCMSData = match$1[1];
  var aboutUsCMSData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._2(RepoInstance.getAboutUsCMSData, countryConfig, language);
                }), (function (data) {
                  return Curry._1(setAboutUsCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  Curry._1(setAboutUsCMSData, (function (param) {
                          return {
                                  TAG: /* Error */2,
                                  _0: error
                                };
                        }));
                  return JsBindings$FrontendWebsite.redirect("/404");
                }));
          
        }), []);
  return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
              desktop_view: React.createElement(React.Suspense, {
                    fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                    children: React.createElement(AboutUsDesktopCmpLazyWrapper$FrontendWebsite.make, {
                          aboutUsCMSData: aboutUsCMSData
                        })
                  }),
              mobile_view: React.createElement(React.Suspense, {
                    fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                    children: React.createElement(AboutUsMobileCmpLazyWrapper$FrontendWebsite.make, {
                          aboutUsCMSData: aboutUsCMSData
                        })
                  })
            });
}

var make = AboutUsCmp;

export {
  make ,
  
}
/* react Not a pure module */
