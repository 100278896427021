// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as Currency$FrontendWebsite from "../../Currency.bs.js";
import * as VehicleType$FrontendWebsite from "../../vehicle/VehicleType.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as FareEstimateResultApiModel$FrontendWebsite from "../api-model/FareEstimateResultApiModel.bs.js";

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(FareEstimateResultApiModel$FrontendWebsite.t_decode(json), "FareEstimateResultStateMapper.res");
  var country = CountryConfig$FrontendWebsite.getCountryConfig(undefined);
  return {
          vehicles: List.map((function (result) {
                  return {
                          display_name: result.display_name,
                          vehicle_type: VehicleType$FrontendWebsite.fromString(result.vehicle_type, country),
                          capacity: result.capacity_display,
                          size: result.size_display,
                          icon: {
                            path: result.image_url,
                            alt_text: result.image_alt_text
                          },
                          base_fare_display: result.base_fare_display,
                          fare: {
                            value: result.base_fare.value,
                            currency: Currency$FrontendWebsite.fromString(result.base_fare.currency)
                          },
                          discount: result.discount.status ? ({
                                value: result.discount.value,
                                max_discount: {
                                  amount: result.discount.max_discount.amount,
                                  currency: result.discount.max_discount.currency
                                },
                                discounted_fare: result.new_base_fare_display
                              }) : undefined
                        };
                }), res.vehicles),
          request_id: res.callback_request_response.callback_request_id
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
