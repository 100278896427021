// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as CityRoutes$FrontendWebsite from "./CityRoutes.bs.js";
import * as CompactRoutes$FrontendWebsite from "./CompactRoutes.bs.js";
import * as TwoWheelerRoutes$FrontendWebsite from "./TwoWheelerRoutes.bs.js";
import * as AvailableVehiclesConfig$FrontendWebsite from "../vehicle/AvailableVehiclesConfig.bs.js";

function fromRouteString(route, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        switch (route) {
          case "canter-3tonne" :
              return /* RoutingVehicle */{
                      _0: {
                        TAG: /* UAE */0,
                        _0: /* Canter3Tonne */1
                      }
                    };
          case "pickup-1tonne" :
              return /* RoutingVehicle */{
                      _0: {
                        TAG: /* UAE */0,
                        _0: /* Pickup1Tonne */2
                      }
                    };
          default:
            return ;
        }
    case /* BD */1 :
        if (route === "1-ton-7feet") {
          return /* RoutingVehicle */{
                  _0: {
                    TAG: /* BD */1,
                    _0: /* Pickup1Tonne */0
                  }
                };
        } else {
          return ;
        }
    case /* TR */2 :
        switch (route) {
          case "minivan" :
              return /* RoutingVehicle */{
                      _0: {
                        TAG: /* TR */2,
                        _0: /* Minivan */2
                      }
                    };
          case "pickup" :
              return /* RoutingVehicle */{
                      _0: {
                        TAG: /* TR */2,
                        _0: /* Pickup */0
                      }
                    };
          default:
            return ;
        }
    
  }
}

function getVehicleFromRoutes(vehicle_route) {
  return vehicle_route._0;
}

function toRouteString(city, vehicle, country) {
  var route_city_path = CityRoutes$FrontendWebsite.toRouteString(city, country);
  switch (vehicle.TAG | 0) {
    case /* UAE */0 :
        switch (vehicle._0) {
          case /* TwoWheeler */0 :
              return TwoWheelerRoutes$FrontendWebsite.toRouteString(city);
          case /* Canter3Tonne */1 :
              return route_city_path + "/canter-3tonne";
          case /* Pickup1Tonne */2 :
              return route_city_path + "/pickup-1tonne";
          case /* Courier */3 :
              return "courier/" + city;
          case /* Compact */4 :
              return CompactRoutes$FrontendWebsite.toRouteString(city);
          
        }
    case /* BD */1 :
        if (vehicle._0) {
          return TwoWheelerRoutes$FrontendWebsite.toRouteString(city);
        } else {
          return route_city_path + "/1-ton-7feet";
        }
    case /* TR */2 :
        switch (vehicle._0) {
          case /* Pickup */0 :
              return route_city_path + "/pickup";
          case /* TwoWheeler */1 :
              return TwoWheelerRoutes$FrontendWebsite.toRouteString(city);
          case /* Minivan */2 :
              return route_city_path + "/minivan";
          
        }
    
  }
}

function getAvailableVehicles(city) {
  var match = $$String.lowercase_ascii(city);
  switch (match) {
    case "dhaka" :
        return AvailableVehiclesConfig$FrontendWebsite.vehicles_available_dhaka;
    case "dubai" :
        return AvailableVehiclesConfig$FrontendWebsite.vehicles_available_dubai;
    case "istanbul" :
        return AvailableVehiclesConfig$FrontendWebsite.vehicles_available_istanbul;
    case "sharjah" :
        return AvailableVehiclesConfig$FrontendWebsite.vehicles_available_sharjah;
    default:
      return /* [] */0;
  }
}

function getSlugFromVehicleTypeAndCity(vehicleType, city) {
  switch (vehicleType.TAG | 0) {
    case /* UAE */0 :
        switch (vehicleType._0) {
          case /* TwoWheeler */0 :
              switch (city) {
                case "dubai" :
                    return "bike-parcel-delivery-dubai";
                case "sharjah" :
                    return "bike-parcel-delivery-sharjah";
                default:
                  return "bike-parcel-delivery-dubai";
              }
          case /* Canter3Tonne */1 :
              return "canter-3tonne";
          case /* Pickup1Tonne */2 :
              return "pickup-1tonne";
          case /* Courier */3 :
              switch (city) {
                case "dubai" :
                    return "courier-dubai";
                case "sharjah" :
                    return "courier-sharjah";
                default:
                  throw {
                        RE_EXN_ID: "Match_failure",
                        _1: [
                          "VehicleRoutes.res",
                          75,
                          17
                        ],
                        Error: new Error()
                      };
              }
          case /* Compact */4 :
              switch (city) {
                case "dubai" :
                    return "compact-dubai";
                case "sharjah" :
                    return "compact-sharjah";
                default:
                  throw {
                        RE_EXN_ID: "Match_failure",
                        _1: [
                          "VehicleRoutes.res",
                          71,
                          17
                        ],
                        Error: new Error()
                      };
              }
          
        }
    case /* BD */1 :
        if (vehicleType._0) {
          return "bike-parcel-delivery-dhaka";
        } else {
          return "1-ton-7feet";
        }
    case /* TR */2 :
        switch (vehicleType._0) {
          case /* Pickup */0 :
              return "pickup";
          case /* TwoWheeler */1 :
              return "bike-parcel-delivery-istanbul";
          case /* Minivan */2 :
              return "minivan";
          
        }
    
  }
}

export {
  fromRouteString ,
  getVehicleFromRoutes ,
  toRouteString ,
  getAvailableVehicles ,
  getSlugFromVehicleTypeAndCity ,
  
}
/* No side effect */
