import * as Sentry from "@sentry/react";

var ignoreErrors = [
  /Cannot read property 'getReadModeExtract' of undefined/i,
  /Cannot read property 'getReadModeRender' of undefined/i,
  /Cannot read property 'getReadModeConfig' of undefined/i,
  /ibFindAllVideos is not defined/i,
];

export function init(initOptions) {
  Sentry.init({
    dsn: initOptions.dsn,
    environment: initOptions.env,
    tracesSampleRate: initOptions.tracesSampleRate,
    replaysSessionSampleRate: initOptions.replaysSessionSampleRate,
    replaysOnErrorSampleRate: initOptions.replaysOnErrorSampleRate,
    ignoreErrors,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}

export function captureException(str) {
  Sentry.captureException(new Error(str));
}

export function captureExceptionWithContext(str, obj) {
  Sentry.captureException(new Error(str), {
    contexts: obj,
  });
}

export function captureMessage(str) {
  Sentry.captureMessage(str);
}

export function captureMessageWithContext(str, obj) {
  Sentry.captureMessage(str, {
    contexts: obj,
  });
}

export function withProfiler(cmp) {
  return Sentry.withProfiler(cmp);
}

export function capturePromiseError(error) {
  Sentry.captureException(error);
}

export function capturePromiseErrorWithContext(error, obj) {
  Sentry.captureException(error, {
    contexts: obj,
  });
}

export function setTag(tag_key, tag_val) {
  Sentry.setTag(tag_key, tag_val);
}

export function setContext(context_str, context_obj) {
  Sentry.setContext(context_str, context_obj);
}
