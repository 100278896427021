// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AppContext$FrontendWebsite from "./AppContext.bs.js";

var make = AppContext$FrontendWebsite.context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

export {
  make ,
  makeProps ,
  
}
/* make Not a pure module */
