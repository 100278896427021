// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";

function dropdownItemsToEntity(dropdownItems) {
  return $$Array.map((function (item) {
                return {
                        order: item.order,
                        label: item.label,
                        value: item.value
                      };
              }), dropdownItems);
}

function dropdownLabelToEntity(dropdownLabel) {
  return {
          label: dropdownLabel.label
        };
}

function nameFieldToEntity(nameField) {
  return {
          label: nameField.label,
          placeholder: nameField.placeholder
        };
}

function mobileFieldToEntity(mobileField) {
  return {
          label: mobileField.label,
          placeholder: mobileField.placeholder,
          countryDialingCode: mobileField.countryDialingCode
        };
}

function pickAndDropFieldsToEntity(pickAndDropFields) {
  return {
          pickupLabel: pickAndDropFields.pickupLabel,
          pickupPlaceholder: pickAndDropFields.pickupPlaceholder,
          dropOffLabel: pickAndDropFields.dropOffLabel,
          dropOffPlaceholder: pickAndDropFields.dropOffPlaceholder
        };
}

function tripEstimateFormToEntity(tripEstimateForm) {
  return {
          formSubmitButtonLabel: tripEstimateForm.formSubmitButtonLabel,
          consentLabel: tripEstimateForm.consentLabel,
          requirementsDropdown: dropdownItemsToEntity(tripEstimateForm.requirementsDropdown),
          requirementField: {
            label: tripEstimateForm.requirementField.label
          },
          nameField: nameFieldToEntity(tripEstimateForm.nameField),
          mobileField: mobileFieldToEntity(tripEstimateForm.mobileField),
          pickAndDropFields: pickAndDropFieldsToEntity(tripEstimateForm.pickAndDropFields),
          citiesDropdown: dropdownItemsToEntity(tripEstimateForm.citiesDropdown),
          cityField: {
            label: tripEstimateForm.cityField.label
          }
        };
}

export {
  dropdownItemsToEntity ,
  dropdownLabelToEntity ,
  nameFieldToEntity ,
  mobileFieldToEntity ,
  pickAndDropFieldsToEntity ,
  tripEstimateFormToEntity ,
  
}
/* No side effect */
