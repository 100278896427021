// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GaPublisher$FrontendWebsite from "../publishers/GaPublisher.bs.js";
import * as CookieConsentHelper$FrontendWebsite from "../../../app/cookie-consent/utils/CookieConsentHelper.bs.js";

function publishEvent(category, action, label, param) {
  return GaPublisher$FrontendWebsite.publishGaEvent({
              category: category,
              action: action,
              label: label,
              value: undefined,
              nonInteraction: undefined
            });
}

function publishEventParameter(event_name, payload) {
  if (CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined).analytics) {
    return GaPublisher$FrontendWebsite.publishGaEventParameter(event_name, payload);
  }
  
}

function publishPageView(path, title) {
  if (CookieConsentHelper$FrontendWebsite.getCookieConsent(undefined).analytics) {
    return GaPublisher$FrontendWebsite.publishGaPageview({
                path: path,
                title: title
              });
  }
  
}

export {
  publishEvent ,
  publishEventParameter ,
  publishPageView ,
  
}
/* GaPublisher-FrontendWebsite Not a pure module */
