// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CookieConsent_TR$FrontendWebsite from "../../../../utils/languang_and_content/tr/CookieConsent_TR.bs.js";
import * as CookieCategoryCmp$FrontendWebsite from "./CookieCategoryCmp.bs.js";

function CookieCategoryList(Props) {
  var form = Props.form;
  var match = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content;
  switch (match.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "CookieCategoryList.res",
                3,
                56
              ],
              Error: new Error()
            };
    case /* TR */2 :
        Content = match._0 ? CookieConsent_TR$FrontendWebsite.TR : CookieConsent_TR$FrontendWebsite.English;
        break;
    
  }
  return React.createElement("div", {
              className: "cookie-categories-list"
            }, React.createElement(CookieCategoryCmp$FrontendWebsite.make, {
                  title: Content.neccessaryCategoryTitle,
                  description: Content.neccessaryCategoryDesc,
                  disabled: true,
                  checked: true,
                  on_change: (function (_event) {
                      
                    })
                }), React.createElement(CookieCategoryCmp$FrontendWebsite.make, {
                  title: Content.functionalCategoryTitle,
                  description: Content.functionalCategoryDesc,
                  checked: form.input.functional,
                  on_change: (function (_event) {
                      var value = !form.input.functional;
                      return Curry._2(form.updateFunctional, (function (input, param) {
                                    return {
                                            functional: value,
                                            analytics: input.analytics,
                                            marketing: input.marketing
                                          };
                                  }), value);
                    })
                }), React.createElement(CookieCategoryCmp$FrontendWebsite.make, {
                  title: Content.analyticsCategoryTitle,
                  description: Content.analyticsCategoryDesc,
                  checked: form.input.analytics,
                  on_change: (function (_event) {
                      var value = !form.input.analytics;
                      return Curry._2(form.updateAnalytics, (function (input, param) {
                                    return {
                                            functional: input.functional,
                                            analytics: value,
                                            marketing: input.marketing
                                          };
                                  }), value);
                    })
                }), React.createElement(CookieCategoryCmp$FrontendWebsite.make, {
                  title: Content.marketingCategoryTitle,
                  description: Content.marketingCategoryDesc,
                  checked: form.input.marketing,
                  on_change: (function (_event) {
                      var value = !form.input.marketing;
                      return Curry._2(form.updateMarketing, (function (input, param) {
                                    return {
                                            functional: input.functional,
                                            analytics: input.analytics,
                                            marketing: value
                                          };
                                  }), value);
                    })
                }));
}

var make = CookieCategoryList;

export {
  make ,
  
}
/* react Not a pure module */
