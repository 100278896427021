// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as FareEstimateRepoManager$FrontendWebsite from "../repo/FareEstimateRepoManager.bs.js";

function execute(onSuccess, onFaliure, country, language) {
  var RepoInstance = FareEstimateRepoManager$FrontendWebsite.getRepo(undefined);
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return Curry._2(RepoInstance.getFareEstimateCMSData, country, language);
              }), Curry.__1(onSuccess), Curry.__1(onFaliure));
}

var FetchFareEstimateCMS = {
  execute: execute
};

export {
  FetchFareEstimateCMS ,
  
}
/* FareEstimateRepoManager-FrontendWebsite Not a pure module */
