// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "./MediaApiModel.bs.js";

function cityAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var cityName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityName"), null));
      if (cityName.TAG === /* Ok */0) {
        var cityUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityUrl"), null));
        if (cityUrl.TAG === /* Ok */0) {
          var cityIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityIcon"), null));
          if (cityIcon.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      title: title._0,
                      description: description._0,
                      cityName: cityName._0,
                      cityUrl: cityUrl._0,
                      cityIcon: cityIcon._0
                    }
                  };
          }
          var e = cityIcon._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".cityIcon" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = cityUrl._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".cityUrl" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = cityName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".cityName" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function cityData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var attributes = cityAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "attributes"), null));
    if (attributes.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                attributes: attributes._0
              }
            };
    }
    var e = attributes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".attributes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function city_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = cityData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function availableCitiesData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = cityAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function availableCities_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(availableCitiesData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function serviceableCities_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var cities = availableCities_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cities"), null));
    if (cities.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                cities: cities._0
              }
            };
    }
    var e = cities._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".cities" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  cityAttributes_decode ,
  cityData_decode ,
  city_decode ,
  availableCitiesData_decode ,
  availableCities_decode ,
  serviceableCities_decode ,
  
}
/* No side effect */
