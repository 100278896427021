// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../utils/languang_and_content/ContentManager.bs.js";
import * as HeaderMobileCmp$FrontendWebsite from "./components/mobile/HeaderMobileCmp.bs.js";
import * as HeaderDesktopCmp$FrontendWebsite from "./components/desktop/HeaderDesktopCmp.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";

function Header(Props) {
  var cmsData = Props.cmsData;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  WebsiteConfig$FrontendWebsite.Desktop(Content.Home);
  var MobileNavContent = WebsiteConfig$FrontendWebsite.Mobile(Content.Home);
  return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
              desktop_view: React.createElement(HeaderDesktopCmp$FrontendWebsite.make, {
                    data: cmsData
                  }),
              mobile_view: React.createElement(HeaderMobileCmp$FrontendWebsite.make, {
                    porter_logo: cmsData.logoSection.logo,
                    primary_menu_items: cmsData.navSection.primaryNavLinks,
                    secondary_menu_items: cmsData.navSection.secondaryNavLinks,
                    partner_link: WebsiteConfig$FrontendWebsite.driveWithUsInfo(Content.Home.driverPartnerHeading),
                    menu_logo: MobileNavContent.hamburgerInfo,
                    menu_assets: MobileNavContent.menuAssets
                  })
            });
}

var make = Header;

export {
  make ,
  
}
/* react Not a pure module */
