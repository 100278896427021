// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as TrackLiveTripRepo$FrontendWebsite from "./TrackLiveTripRepo.bs.js";
import * as DummyTrackLiveTripRepo$FrontendWebsite from "./DummyTrackLiveTripRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return {
            submitForm: DummyTrackLiveTripRepo$FrontendWebsite.submitForm,
            fetchTrackLiveTripInfo: DummyTrackLiveTripRepo$FrontendWebsite.fetchTrackLiveTripInfo,
            fetchTrackLiveTripMarketingInfo: DummyTrackLiveTripRepo$FrontendWebsite.fetchTrackLiveTripMarketingInfo
          };
  } else {
    return TrackLiveTripRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
