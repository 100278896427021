// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FareEstimateResultPinLocationCmp(Props) {
  var address = Props.address;
  var title = Props.title;
  var class_name = Props.class_name;
  return React.createElement("div", {
              className: "location-details"
            }, React.createElement("div", {
                  className: "dot " + class_name
                }), React.createElement("div", {
                  className: "location"
                }, React.createElement("div", {
                      className: "title"
                    }, title), React.createElement("div", {
                      className: "address"
                    }, address)));
}

var make = FareEstimateResultPinLocationCmp;

export {
  make ,
  
}
/* react Not a pure module */
