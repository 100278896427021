// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as ExternalLink$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/external-link/ExternalLink.bs.js";
import * as GAConfig$FrontendWebsite from "../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../utils/library/ga/GAEvents.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as JsBindings$FrontendWebsite from "../../../utils/library/bindings/JsBindings.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../utils/context/events-manager/EventsHelper.bs.js";
import * as AssetsManager$FrontendWebsite from "../../../view/assets/AssetsManager.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";
import * as TrackLiveTripConfig$FrontendWebsite from "../TrackLiveTripConfig.bs.js";

var arrow = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("drop-down-arrow.svg");

var mobile = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("mobile_icon.svg");

function showSensitiveInfo(endTime) {
  if (endTime !== undefined) {
    return ((new Date().getTime() | 0) - endTime | 0) <= 3600;
  } else {
    return true;
  }
}

function getTargetElement(target) {
  return document.getElementsByClassName(target);
}

function addClassToElement(target, className) {
  if (document.getElementsByClassName(target).length !== 0) {
    return JsBindings$FrontendWebsite.addClass(Caml_array.get(document.getElementsByClassName(target), 0), className);
  }
  
}

function removeClassToElement(target, className) {
  if (document.getElementsByClassName(target).length !== 0) {
    return JsBindings$FrontendWebsite.removeClass(Caml_array.get(document.getElementsByClassName(target), 0), className);
  }
  
}

function TrackLiveTripHeaderTitleComponent(Props) {
  var title = Props.title;
  var isDetailVisible = Props.isDetailVisible;
  var onDetailClick = Props.onDetailClick;
  var barScrollDown = Props.barScrollDown;
  var onBarClick = Props.onBarClick;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(match.language);
  GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  var getName = function (name) {
    return React.createElement("div", {
                className: "track-live-trip-header__title-name"
              }, name);
  };
  var getTitleInfo = function (title) {
    var partner = title.partnerInfo;
    return React.createElement("div", {
                className: "track-live-trip-header__title-info-container"
              }, partner !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: "track-live-trip-header__title-info-container__title-row"
                        }, React.createElement("div", {
                              className: "track-live-trip-header__title-info-container__title-vehicle-type"
                            }, title.vehicle.vehicle_type), React.createElement("div", {
                              className: "track-live-trip-header__title-vehicle"
                            }, partner.vehicleNumber)), React.createElement("div", {
                          className: "track-live-trip-header__title-driver-info"
                        }, getName(partner.name))) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: "track-live-trip-header__title-info-container__title-vehicle-type"
                        }, title.vehicle.vehicle_type), React.createElement("div", {
                          className: "track-live-trip-header__title-driver-info"
                        }, getName(title.noPartner))));
  };
  var getstatusInfo = function (title) {
    var icon = title.tripStatusIcon;
    if (icon !== undefined) {
      return React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: "track-live-trip-header__title-status-info track-live-trip-header__title-status-background__" + icon
                    }, React.createElement("div", {
                          className: "track-live-trip-header__title-status-icon track-live-trip-header__title-status-icon__" + icon
                        }), React.createElement("div", {
                          className: "track-live-trip-header__title-status-background__" + icon
                        }, title.tripStatus)));
    } else {
      return null;
    }
  };
  var showMoreDetails = function (isDetailVisible, onDetailClick) {
    if (isDetailVisible) {
      return null;
    } else {
      return React.createElement("div", {
                  className: "track-live-trip-header__show-order-details-container",
                  onClick: (function (_event) {
                      return Curry._1(onDetailClick, undefined);
                    })
                }, React.createElement("div", undefined, Content.TrackOrder.show_order_details_text), React.createElement("div", {
                      className: "track-live-trip-header__show-order-details-container__indicator__show"
                    }, React.createElement("img", {
                          src: AssetsManager$FrontendWebsite.getTrackLiveTripsImage("drop-down-arrow.svg")
                        })));
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "bar",
                  onClick: (function (_event) {
                      if (barScrollDown) {
                        removeClassToElement("track-live-trip-header__container", "track-live-trip-header__container_down");
                        removeClassToElement("track-live-trip-map", "track-live-trip-map-down");
                        removeClassToElement("current-location-button", "current-location-button-down");
                      } else {
                        addClassToElement("track-live-trip-header__container", "track-live-trip-header__container_down");
                        addClassToElement("track-live-trip-map", "track-live-trip-map-down");
                        addClassToElement("current-location-button", "current-location-button-down");
                      }
                      return Curry._1(onBarClick, undefined);
                    })
                }), React.createElement("div", {
                  className: "track-live-trip-header__title-container "
                }, React.createElement("img", {
                      className: "track-live-trip-header__vehicle-icon",
                      src: title.vehicle.vehicle_url
                    }), getTitleInfo(title), getstatusInfo(title)), showMoreDetails(isDetailVisible, onDetailClick));
}

var make = TrackLiveTripHeaderTitleComponent;

export {
  arrow ,
  mobile ,
  showSensitiveInfo ,
  getTargetElement ,
  addClassToElement ,
  removeClassToElement ,
  make ,
  
}
/* arrow Not a pure module */
