// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function OfferValidCmp(Props) {
  var title = Props.title;
  return React.createElement("div", {
              className: "offer-valid"
            }, title);
}

var make = OfferValidCmp;

export {
  make ,
  
}
/* react Not a pure module */
