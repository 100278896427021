// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var KeywordsDesktopCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(KeywordsDesktopCmpLazyWrapper);

function KeywordsDesktopCmpLazyWrapper$1(Props) {
  var keywordsCMSData = Props.keywordsCMSData;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./KeywordsDesktopCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            keywordsCMSData: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              keywordsCMSData: keywordsCMSData
            });
}

var make = KeywordsDesktopCmpLazyWrapper$1;

export {
  KeywordsDesktopCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
