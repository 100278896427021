// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as LinkCmp$FrontendWebsite from "../../website/view/components/LinkCmp.bs.js";

function FooterListColumnCmp(Props) {
  var data = Props.data;
  var class_nameOpt = Props.class_name;
  var class_name = class_nameOpt !== undefined ? class_nameOpt : "";
  return React.createElement("div", {
              className: "footer-column-container " + class_name
            }, React.createElement("div", {
                  className: "list"
                }, $$Array.map((function (col) {
                        if (col.TAG === /* NoURL */0) {
                          return React.createElement("div", {
                                      className: "title"
                                    }, col._0);
                        }
                        var label = col._0;
                        return React.createElement(LinkCmp$FrontendWebsite.make, {
                                    href: col._1,
                                    className: "c-list-item",
                                    children: label,
                                    key: label
                                  });
                      }), data)));
}

var make = FooterListColumnCmp;

export {
  make ,
  
}
/* react Not a pure module */
