// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as FaqApiModel$FrontendWebsite from "../../website/cms/api-model/FaqApiModel.bs.js";
import * as MediaApiModel$FrontendWebsite from "../../website/cms/api-model/MediaApiModel.bs.js";

function point_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                description: description._0
              }
            };
    }
    var e = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function appDownload_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var googlePlayStoreUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "googlePlayStoreUrl"), null));
    if (googlePlayStoreUrl.TAG === /* Ok */0) {
      var googlePlayStoreBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "googlePlayStoreBanner"), null));
      if (googlePlayStoreBanner.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  googlePlayStoreUrl: googlePlayStoreUrl._0,
                  googlePlayStoreBanner: googlePlayStoreBanner._0
                }
              };
      }
      var e = googlePlayStoreBanner._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".googlePlayStoreBanner" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = googlePlayStoreUrl._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".googlePlayStoreUrl" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function section_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var image = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image"), null));
    if (image.TAG === /* Ok */0) {
      var points = Decco.arrayFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "points"), null));
      if (points.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  title: title._0,
                  image: image._0,
                  points: points._0
                }
              };
      }
      var e = points._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".points" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = image._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".image" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function heroSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var image = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "image"), null));
      if (image.TAG === /* Ok */0) {
        var appDownload = appDownload_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownload"), null));
        if (appDownload.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    description: description._0,
                    image: image._0,
                    appDownload: appDownload._0
                  }
                };
        }
        var e = appDownload._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".appDownload" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = image._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".image" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var heroSection = heroSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "heroSection"), null));
  if (heroSection.TAG === /* Ok */0) {
    var porterAdvantage = section_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "porterAdvantage"), null));
    if (porterAdvantage.TAG === /* Ok */0) {
      var lifeEasySection = section_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "lifeEasySection"), null));
      if (lifeEasySection.TAG === /* Ok */0) {
        var ownVehicleSection = section_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "ownVehicleSection"), null));
        if (ownVehicleSection.TAG === /* Ok */0) {
          var faqs = FaqApiModel$FrontendWebsite.faqs_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "faqs"), null));
          if (faqs.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      heroSection: heroSection._0,
                      porterAdvantage: porterAdvantage._0,
                      lifeEasySection: lifeEasySection._0,
                      ownVehicleSection: ownVehicleSection._0,
                      faqs: faqs._0
                    }
                  };
          }
          var e = faqs._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".faqs" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = ownVehicleSection._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".ownVehicleSection" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = lifeEasySection._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".lifeEasySection" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = porterAdvantage._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".porterAdvantage" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = heroSection._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".heroSection" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function data_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = data_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  point_decode ,
  appDownload_decode ,
  section_decode ,
  heroSection_decode ,
  attributes_decode ,
  data_decode ,
  t_decode ,
  
}
/* No side effect */
