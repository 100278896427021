// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "../../../../../../node_modules/rescript/lib/es6/caml_array.js";

function CarouselIndicatorsCmp(Props) {
  var currentSlide = Props.currentSlide;
  var totalSlides = Props.totalSlides;
  var setSlide = Props.setSlide;
  var buttons = Caml_array.make(totalSlides, "");
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "carousel-indicators"
                }, $$Array.mapi((function (index, item) {
                        return React.createElement("div", {
                                    key: "button-" + String(index),
                                    className: index === currentSlide ? "carousel-indicator-item active" : "carousel-indicator-item",
                                    onClick: (function (param) {
                                        return Curry._1(setSlide, index);
                                      })
                                  }, item);
                      }), buttons)));
}

var make = CarouselIndicatorsCmp;

export {
  make ,
  
}
/* react Not a pure module */
