// Generated by ReScript, PLEASE EDIT WITH CARE


var redirect = (function(url) {
    return window.location.href=url;
  });

var reloadPage = (function(url) {
    return location.reload();
  });

var isfbqInstatiated = (function() {
    return window.fbq !== undefined ? true: false
  });

var addClass = (function(elem, class_name) {
    return elem.classList.add(class_name)
  });

var removeClass = (function(elem, class_name) {
    return elem.classList.remove(class_name)
  });

var setParam = (function(url, key, value) {
    let u = new URL(url);
    u.searchParams.set(key, value)
    return u.toString();
  });

var scrollHeight = (function(ele) {
    return ele.scrollHeight
  });

var switchDisplay = (function (targetClass, position, isVisible) {
        const scopeClassArray = document.querySelectorAll(targetClass);
        scopeClassArray.forEach((targetClass, index)=> {
            if(index == position)
            isVisible ? targetClass.style.display = "block" : targetClass.style.display = "none";
        })
      });

var setChromeAutocompleteOff = (function (parentElQuery, childElQuery) {
        const scopeClassObjects = document.querySelectorAll(parentElQuery);
        scopeClassObjects.forEach((scopeClass)=> {
          let inputEl = scopeClass.querySelector(childElQuery);
            if(window.chrome){
              inputEl.setAttribute("onfocus", "this.setAttribute('autocomplete', 'chrome-off' );");
            }
            else{
              inputEl.setAttribute("name", "search");
            }

        })
      });

var setWebpackPublicPath = (function(path){
    return __webpack_public_path__ = path;
    });

var $$Document = {};

var $$Window = {};

var $$IntersectionObserver = {};

export {
  redirect ,
  reloadPage ,
  isfbqInstatiated ,
  addClass ,
  removeClass ,
  setParam ,
  scrollHeight ,
  switchDisplay ,
  setChromeAutocompleteOff ,
  setWebpackPublicPath ,
  $$Document ,
  $$Window ,
  $$IntersectionObserver ,
  
}
/* No side effect */
