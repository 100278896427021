// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var AboutUsMobileCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(AboutUsMobileCmpLazyWrapper);

function AboutUsMobileCmpLazyWrapper$1(Props) {
  var aboutUsCMSData = Props.aboutUsCMSData;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./AboutUsMobileCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            aboutUsCMSData: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              aboutUsCMSData: aboutUsCMSData
            });
}

var make = AboutUsMobileCmpLazyWrapper$1;

export {
  AboutUsMobileCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
