// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function execute(json) {
  return {
          redirect_path: Json_decode.field("redirect_path", Json_decode.string, json)
        };
}

export {
  execute ,
  
}
/* No side effect */
