// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_option from "../../node_modules/rescript/lib/es6/caml_option.js";
import * as CookieUtils$Catalyst from "../../node_modules/@porterin/catalyst/src/utils/CookieUtils.bs.js";

var configObj = (require("Config"));

var env = configObj.app_env;

var oms_url = configObj.oms_url;

var cms_url = configObj.cms_url;

var sentry_dsn = configObj.sentry_dsn;

var ga_tracking_id_uae = configObj.ga_tracking_id_uae;

var ga_tracking_id_bd = configObj.ga_tracking_id_bd;

var ga_tracking_id_tr = configObj.ga_tracking_id_tr;

var metapixel_id_uae = configObj.metapixel_id_uae;

var metapixel_id_bd = configObj.metapixel_id_bd;

var metapixel_id_tr = configObj.metapixel_id_tr;

var google_maps_api_key = configObj.google_maps_api_key;

var recaptcha_site_key = configObj.recaptcha_site_key;

function getOMSUrl(param) {
  if (env === "prod") {
    return "https://porter.ae";
  } else {
    return oms_url;
  }
}

function getFEUrl(param) {
  return "https://" + window.location.hostname;
}

function getHostName(param) {
  if (env === "development") {
    return "http://localhost:8090";
  } else {
    return "https://" + window.location.hostname;
  }
}

function getGoogleMapApiKey(param) {
  return google_maps_api_key;
}

function getGaTrackingId(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return ga_tracking_id_uae;
    case /* BD */1 :
        return ga_tracking_id_bd;
    case /* TR */2 :
        return ga_tracking_id_tr;
    
  }
}

function getGaTrackingName(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return "ga_4_website_uae";
    case /* BD */1 :
        return "ga_4_website_bd";
    case /* TR */2 :
        return "ga_4_website_tr";
    
  }
}

function getWebTrackingId(param) {
  var web_tracking_id = Js_json.decodeObject(CookieUtils$Catalyst.getCookie("delivery_ck"));
  if (web_tracking_id === undefined) {
    return ;
  }
  var value = Js_dict.get(Caml_option.valFromOption(web_tracking_id), "delivery_ck");
  if (value !== undefined) {
    return String(Caml_option.valFromOption(value));
  }
  
}

function getMetaPixelId(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return metapixel_id_uae;
    case /* BD */1 :
        return metapixel_id_bd;
    case /* TR */2 :
        return metapixel_id_tr;
    
  }
}

var libraries = ["places"];

var Env = {
  env: env,
  oms_url: oms_url,
  cms_url: cms_url,
  sentry_dsn: sentry_dsn,
  ga_tracking_id_uae: ga_tracking_id_uae,
  ga_tracking_id_bd: ga_tracking_id_bd,
  ga_tracking_id_tr: ga_tracking_id_tr,
  metapixel_id_uae: metapixel_id_uae,
  metapixel_id_bd: metapixel_id_bd,
  metapixel_id_tr: metapixel_id_tr,
  google_maps_api_key: google_maps_api_key,
  recaptcha_site_key: recaptcha_site_key,
  getOMSUrl: getOMSUrl,
  getFEUrl: getFEUrl,
  getHostName: getHostName,
  getGoogleMapApiKey: getGoogleMapApiKey,
  getGaTrackingId: getGaTrackingId,
  getGaTrackingName: getGaTrackingName,
  getWebTrackingId: getWebTrackingId,
  getMetaPixelId: getMetaPixelId,
  libraries: libraries,
  geoCodingPct: 0.00
};

export {
  configObj ,
  Env ,
  
}
/* configObj Not a pure module */
