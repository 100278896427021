// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ReactGoogleRecaptchaV3 from "react-google-recaptcha-v3";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../../utils/NetworkUtils.bs.js";
import * as LoadingFormComponent$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/LoadingFormComponent.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../website/view/components/CircularLoader.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../../website/view/components/ResponsiveLayout.bs.js";
import * as CouponDataMachine$FrontendWebsite from "../../data-machine/CouponDataMachine.bs.js";
import * as CouponStateMachine$FrontendWebsite from "../../state-machine/CouponStateMachine.bs.js";
import * as CampaignsRepoManager$FrontendWebsite from "../../repo/CampaignsRepoManager.bs.js";
import * as CouponCmpLazyWrapper$FrontendWebsite from "./desktop/CouponCmpLazyWrapper.bs.js";

import '../assets/styles/common.scss'
;

function CampiagnsPageCmp(Props) {
  var id = Props.id;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = React.useReducer(CouponStateMachine$FrontendWebsite.StateMachine.machine, /* Init */0);
  var dispatch = match[1];
  var state = match[0];
  var recaptcha_execute = ReactGoogleRecaptchaV3.useGoogleReCaptcha();
  var fetchCouponData = function (param) {
    var recaptcha_val = recaptcha_execute.executeRecaptcha("homepage");
    if (recaptcha_val !== undefined) {
      Caml_option.valFromOption(recaptcha_val).then(function (token) {
              CampaignsRepoManager$FrontendWebsite.getRepo(undefined);
              return Promise.resolve(CouponDataMachine$FrontendWebsite.FetchDiscountCoupon.execute(token, (function (data) {
                                console.log(data);
                                return Curry._1(dispatch, {
                                            TAG: /* FetchDiscountCoupon */0,
                                            _0: data
                                          });
                              }), (function (error) {
                                return Curry._1(dispatch, {
                                            TAG: /* FetchDiscountCouponFailed */1,
                                            _0: error
                                          });
                              }), country));
            }).catch(function (error) {
            NetworkUtils$FrontendWebsite.captureException("Home - ReCaptcha Failed - " + error.toString());
            return Promise.resolve(undefined);
          });
      return ;
    } else {
      console.log("submission failed");
      return NetworkUtils$FrontendWebsite.captureException("Home Desktop - ReCaptcha Init Failed");
    }
  };
  React.useEffect((function () {
          var match = recaptcha_execute.executeRecaptcha;
          if (match !== undefined) {
            fetchCouponData(undefined);
          }
          
        }), [recaptcha_execute]);
  if (typeof state === "number") {
    return React.createElement(LoadingFormComponent$Catalyst.make, {});
  }
  if (state.TAG !== /* DiscountOffers */0) {
    return null;
  }
  var data = state._0;
  return React.createElement(React.Fragment, undefined, React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                  desktop_view: React.createElement(React.Suspense, {
                        children: React.createElement(CouponCmpLazyWrapper$FrontendWebsite.make, {
                              className: "c-offers-container c-offers-container-desktop",
                              data: data,
                              id: id
                            }),
                        fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                      }),
                  mobile_view: React.createElement(React.Suspense, {
                        children: React.createElement(CouponCmpLazyWrapper$FrontendWebsite.make, {
                              className: "c-offers-container c-offers-container-mobile",
                              data: data,
                              id: id
                            }),
                        fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                      })
                }));
}

var make = CampiagnsPageCmp;

export {
  make ,
  
}
/*  Not a pure module */
