// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as MediaQuery$FrontendWebsite from "../../../../../utils/hooks/MediaQuery.bs.js";
import * as PolicyTitleCmp$FrontendWebsite from "./PolicyTitleCmp.bs.js";
import * as PolicyItemsContainerCmp$FrontendWebsite from "./PolicyItemsContainerCmp.bs.js";

function PolicyContainerCmp(Props) {
  var title = Props.title;
  var policyItems = Props.policyItems;
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "policy-desktop-container" : "policy-mobile-container"
            }, React.createElement(PolicyTitleCmp$FrontendWebsite.make, {
                  title: title
                }), React.createElement(PolicyItemsContainerCmp$FrontendWebsite.make, {
                  policyItems: policyItems
                }));
}

var make = PolicyContainerCmp;

export {
  make ,
  
}
/* react Not a pure module */
