// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var DriverPartnersDesktopCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(DriverPartnersDesktopCmpLazyWrapper);

function DriverPartnersDesktopCmpLazyWrapper$1(Props) {
  var cmsVm = Props.cmsVm;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./DriverPartnersDesktopCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            cmsVm: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              cmsVm: cmsVm
            });
}

var make = DriverPartnersDesktopCmpLazyWrapper$1;

export {
  DriverPartnersDesktopCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
