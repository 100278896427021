// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as CouponOffersCmp$FrontendWebsite from "../../../website/view/components/CouponOffersCmp.bs.js";

function VehicleOffersMobileCmp(Props) {
  var offersData = Props.offersData;
  return React.createElement("div", {
              className: "vehicle-mobile-offers-container"
            }, React.createElement(CouponOffersCmp$FrontendWebsite.make, {
                  coupons: offersData.coupons,
                  title: $$String.uppercase_ascii(offersData.title)
                }));
}

var make = VehicleOffersMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
