// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function DangerouslySetInnerHtml(Props) {
  var inner_html = Props.inner_html;
  return React.createElement("div", {
              dangerouslySetInnerHTML: {
                __html: inner_html
              }
            });
}

var make = DangerouslySetInnerHtml;

export {
  make ,
  
}
/* react Not a pure module */
