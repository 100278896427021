// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as FareVehicleMapper$FrontendWebsite from "../entity-mapper/FareVehicleMapper.bs.js";
import * as SendSmsStateMapper$FrontendWebsite from "../entity-mapper/SendSmsStateMapper.bs.js";
import * as GeoRegionBoundsMapper$FrontendWebsite from "../entity-mapper/GeoRegionBoundsMapper.bs.js";
import * as HomePageCMSEntityMapper$FrontendWebsite from "../entity-mapper/HomePageCMSEntityMapper.bs.js";
import * as FareEstimateResultStateMapper$FrontendWebsite from "../entity-mapper/FareEstimateResultStateMapper.bs.js";
import * as FareEstimateResultHashStateMapper$FrontendWebsite from "../entity-mapper/FareEstimateResultHashStateMapper.bs.js";

var execute = FareVehicleMapper$FrontendWebsite.execute;

var FareDetailsApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var FareDetailsResponseConverter = partial_arg(FareDetailsApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$1 = FareEstimateResultHashStateMapper$FrontendWebsite.execute;

var FareEstimateResultHashApiConfig = {
  execute: execute$1
};

var partial_arg$1 = ResponseHandler$Catalyst.ResponseConverter;

var FareEstimateResultHashApiResponseConverter = partial_arg$1(FareEstimateResultHashApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$2 = GeoRegionBoundsMapper$FrontendWebsite.execute;

var GeoRegionBoundsApiConfig = {
  execute: execute$2
};

var partial_arg$2 = ResponseHandler$Catalyst.ResponseConverter;

var GeoRegionBoundsResponseConverter = partial_arg$2(GeoRegionBoundsApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$3 = FareEstimateResultStateMapper$FrontendWebsite.execute;

var FareEstimateResultApiConfig = {
  execute: execute$3
};

var partial_arg$3 = ResponseHandler$Catalyst.ResponseConverter;

var FareEstimateResultApiResponseConverter = partial_arg$3(FareEstimateResultApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$4 = SendSmsStateMapper$FrontendWebsite.execute;

var SendSmsApiConfig = {
  execute: execute$4
};

var partial_arg$4 = ResponseHandler$Catalyst.ResponseConverter;

var SendSmsApiResponseConverter = partial_arg$4(SendSmsApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

var execute$5 = HomePageCMSEntityMapper$FrontendWebsite.execute;

var HomePageCMSConfig = {
  execute: execute$5
};

var partial_arg$5 = ResponseHandler$Catalyst.ResponseConverter;

var HomePageCMSResponseConverter = partial_arg$5(HomePageCMSConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  FareDetailsApiConfig ,
  FareDetailsResponseConverter ,
  FareEstimateResultHashApiConfig ,
  FareEstimateResultHashApiResponseConverter ,
  GeoRegionBoundsApiConfig ,
  GeoRegionBoundsResponseConverter ,
  FareEstimateResultApiConfig ,
  FareEstimateResultApiResponseConverter ,
  SendSmsApiConfig ,
  SendSmsApiResponseConverter ,
  HomePageCMSConfig ,
  HomePageCMSResponseConverter ,
  
}
/* FareDetailsResponseConverter Not a pure module */
