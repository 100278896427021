// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../../routing/WebsiteConfig.bs.js";

function NavigationMenuCmp(Props) {
  var menu_list = Props.menu_list;
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "navigation-menu-container" : "navigation-menu-container navigation-mobile"
            }, $$Array.of_list(List.mapi((function (index, menu) {
                        if (index !== (List.length(menu_list) - 1 | 0)) {
                          return React.createElement("a", {
                                      key: String(index) + menu.name,
                                      className: "active-menu-wrapper",
                                      href: WebsiteTypes$FrontendWebsite.urltoString(menu.path)
                                    }, React.createElement("div", {
                                          className: "active-menu"
                                        }, menu.name), React.createElement("div", {
                                          className: "arrow",
                                          style: {
                                            backgroundImage: "url(" + (WebsiteConfig$FrontendWebsite.uiAssets + ")")
                                          }
                                        }));
                        } else {
                          return React.createElement("div", {
                                      key: String(index) + menu.name,
                                      className: "inactive-menu"
                                    }, menu.name);
                        }
                      }), menu_list)));
}

var make = NavigationMenuCmp;

export {
  make ,
  
}
/* react Not a pure module */
