// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PolicyTitleCmp(Props) {
  var title = Props.title;
  return React.createElement("div", {
              className: "terms-of-service-header-wrapper"
            }, React.createElement("h1", {
                  className: "terms-of-service-header-title"
                }, title));
}

var make = PolicyTitleCmp;

export {
  make ,
  
}
/* react Not a pure module */
