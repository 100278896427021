// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "./MediaApiModel.bs.js";

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var googlePlayStoreBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "googlePlayStoreBanner"), null));
    if (googlePlayStoreBanner.TAG === /* Ok */0) {
      var appStoreBanner = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appStoreBanner"), null));
      if (appStoreBanner.TAG === /* Ok */0) {
        var appStoreUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appStoreUrl"), null));
        if (appStoreUrl.TAG === /* Ok */0) {
          var googlePlayStoreUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "googlePlayStoreUrl"), null));
          if (googlePlayStoreUrl.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      title: title._0,
                      googlePlayStoreBanner: googlePlayStoreBanner._0,
                      appStoreBanner: appStoreBanner._0,
                      appStoreUrl: appStoreUrl._0,
                      googlePlayStoreUrl: googlePlayStoreUrl._0
                    }
                  };
          }
          var e = googlePlayStoreUrl._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".googlePlayStoreUrl" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = appStoreUrl._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".appStoreUrl" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = appStoreBanner._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".appStoreBanner" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = googlePlayStoreBanner._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".googlePlayStoreBanner" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

export {
  t_decode ,
  
}
/* No side effect */
