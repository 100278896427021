// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";

function fromString(requirement) {
  var match = requirement.toLowerCase();
  switch (match) {
    case "business" :
        return /* Business */1;
    case "personal" :
        return /* Personal */0;
    default:
      return ErrorUtils$Catalyst.raiseError("FareEstimateResultRequirement.re", "Invalid Requirement", "");
  }
}

function toString(requirement) {
  if (requirement) {
    return "business";
  } else {
    return "personal";
  }
}

function toDependencyString(requirement) {
  if (requirement) {
    return "business";
  } else {
    return "personal";
  }
}

export {
  fromString ,
  toString ,
  toDependencyString ,
  
}
/* No side effect */
