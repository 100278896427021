// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as BrowserUtils$FrontendWebsite from "../../../utils/BrowserUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "neccesary",
                Decco.boolToJson(v.neccesary)
              ],
              [
                "functional",
                Decco.boolToJson(v.functional)
              ],
              [
                "marketing",
                Decco.boolToJson(v.marketing)
              ],
              [
                "analytics",
                Decco.boolToJson(v.analytics)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var neccesary = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "neccesary"), null));
  if (neccesary.TAG === /* Ok */0) {
    var functional = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "functional"), null));
    if (functional.TAG === /* Ok */0) {
      var marketing = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "marketing"), null));
      if (marketing.TAG === /* Ok */0) {
        var analytics = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "analytics"), null));
        if (analytics.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    neccesary: neccesary._0,
                    functional: functional._0,
                    marketing: marketing._0,
                    analytics: analytics._0
                  }
                };
        }
        var e = analytics._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".analytics" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = marketing._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".marketing" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = functional._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".functional" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = neccesary._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".neccesary" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function execute(json) {
  return DeccoUtils$Catalyst.resolveResult(t_decode(json), "CookieConsentHelper.res");
}

var Decoder = {
  execute: execute
};

var make = t_encode;

var Encoder = {
  make: make
};

function getDefaultCookieConsent(neccesary) {
  var match = CountryConfig$FrontendWebsite.getCountryConfig(undefined);
  switch (match.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        return {
                neccesary: true,
                functional: true,
                marketing: true,
                analytics: true
              };
    case /* TR */2 :
        return {
                neccesary: neccesary,
                functional: false,
                marketing: false,
                analytics: false
              };
    
  }
}

function getCookieConsent(param) {
  if (!BrowserUtils$FrontendWebsite.isLocalStorageAvailable(undefined)) {
    return getDefaultCookieConsent(true);
  }
  var data = localStorage.getItem("cookie_consent");
  if (data === null) {
    return getDefaultCookieConsent(false);
  }
  var json = JSON.parse(data);
  var decodedData = DeccoUtils$Catalyst.resolveResult(t_decode(json), "CookieConsentHelper.res");
  return {
          neccesary: decodedData.neccesary,
          functional: decodedData.functional,
          marketing: decodedData.marketing,
          analytics: decodedData.analytics
        };
}

function setCookieConsent(functional, marketing, analytics) {
  if (!BrowserUtils$FrontendWebsite.isLocalStorageAvailable(undefined)) {
    return ;
  }
  var setValue = {
    neccesary: true,
    functional: functional,
    marketing: marketing,
    analytics: analytics
  };
  var stringifiedData = JSON.stringify(t_encode(setValue));
  localStorage.setItem("cookie_consent", stringifiedData);
  
}

function acceptAll(param) {
  return setCookieConsent(true, true, true);
}

export {
  t_encode ,
  t_decode ,
  Decoder ,
  Encoder ,
  getDefaultCookieConsent ,
  getCookieConsent ,
  setCookieConsent ,
  acceptAll ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
