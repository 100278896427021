// Generated by ReScript, PLEASE EDIT WITH CARE


var uae_content_desc = "The Terms and Conditions will be governed as per the signed Services Agreement between the Service Provider and Porter.";

export {
  uae_content_desc ,
  
}
/* No side effect */
