// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CouponOffersCmp$FrontendWebsite from "../../../website/view/components/CouponOffersCmp.bs.js";

function VehicleOffersDesktopCmp(Props) {
  var offersData = Props.offersData;
  return React.createElement("div", {
              className: "vehicle-desktop-porter-offers-container"
            }, React.createElement(CouponOffersCmp$FrontendWebsite.make, {
                  coupons: offersData.coupons,
                  title: offersData.title
                }));
}

var make = VehicleOffersDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
