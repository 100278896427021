// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DangerouslySetInnerHtml$FrontendWebsite from "../../dangerously-set-inner-html/DangerouslySetInnerHtml.bs.js";

function ParagraphUnOrderedViewCmp(Props) {
  var paragraph = Props.paragraph;
  var children = Props.children;
  return React.createElement("ul", {
              className: "list-wrapper"
            }, React.createElement("li", undefined, React.createElement("div", {
                      className: paragraph.is_bold ? "paragraph-text paragraph-bold" : "paragraph-text"
                    }, paragraph.show_html === /* Text */0 ? paragraph.text : React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                            inner_html: paragraph.text
                          })), children));
}

var make = ParagraphUnOrderedViewCmp;

export {
  make ,
  
}
/* react Not a pure module */
