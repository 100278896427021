// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as FareEstimateCMSApiModel$FrontendWebsite from "../api-model/FareEstimateCMSApiModel.bs.js";

function decodeEditSection(data) {
  return {
          pickupLabel: data.pickupLabel,
          dropLabel: data.dropLabel,
          submitButtonLabel: data.submitButtonLabel,
          selectCityLabel: data.selectCityLabel,
          title: data.title,
          editButtonLabel: data.editButtonLabel,
          selectCityError: data.selectCityError,
          pickUpError: data.pickUpError,
          dropError: data.dropError,
          closeIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.closeIcon)
        };
}

function decodVehicleSection(data) {
  return {
          title: data.title,
          subtitle: data.subtitle,
          offLabel: data.offLabel,
          uptoLabel: data.uptoLabel,
          offerMessage: data.offerMessage,
          capacityLabel: data.capacityLabel,
          sizeLabel: data.sizeLabel
        };
}

function decodephoneCarousel(data) {
  return {
          phoneSkin: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.phoneSkin),
          phoneCarouselBanners: MediaEntityMapper$FrontendWebsite.bannersToEntity(data.phoneCarouselBanners)
        };
}

function decodeAppDownloadSection(data) {
  return {
          title: data.title,
          pointOne: data.pointOne,
          point2: data.point2,
          point3: data.point3,
          phoneCarousel: decodephoneCarousel(data.phoneCarousel),
          appStoreImage: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.appStoreImage),
          playStoreImage: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.playStoreImage)
        };
}

function decodeQrSection(data) {
  return {
          title: data.title,
          desription: data.desription,
          callToActionPrefix: Belt_Option.getWithDefault(data.callToActionPrefix, ""),
          callToActionLinkText: Belt_Option.getWithDefault(data.callToActionLinkText, ""),
          smsLinkDescription: Belt_Option.getWithDefault(data.smsLinkDescription, ""),
          qrImage: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.qrImage)
        };
}

function decodeFooter(data) {
  return {
          title: data.title,
          subtitle: data.subtitle,
          buttonLabel: data.buttonLabel
        };
}

function apiModelToEntity(json) {
  var data = json.data.attributes;
  return {
          editSection: decodeEditSection(data.editSection),
          vehicleSection: decodVehicleSection(data.vehicleSection),
          appDownloadSection: decodeAppDownloadSection(data.appDownloadSection),
          qrSection: decodeQrSection(data.qrSection),
          footer: decodeFooter(data.footer)
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(FareEstimateCMSApiModel$FrontendWebsite.t_decode(json), "FareEstimateCMSEntityMapper.res"));
}

export {
  decodeEditSection ,
  decodVehicleSection ,
  decodephoneCarousel ,
  decodeAppDownloadSection ,
  decodeQrSection ,
  decodeFooter ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
