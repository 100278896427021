// Generated by ReScript, PLEASE EDIT WITH CARE


var default_error_msg = "Something went wrong!";

var redirect_desc = "Data Not Found, Please redirect to home.";

var redirect_label = "Redirect to home";

export {
  default_error_msg ,
  redirect_desc ,
  redirect_label ,
  
}
/* No side effect */
