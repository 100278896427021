// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as MediaQuery$FrontendWebsite from "../../../../../utils/hooks/MediaQuery.bs.js";
import * as ParagraphModifiedCmp$FrontendWebsite from "../../../../../view/common/paragraph/paragraphModified/ParagraphModifiedCmp.bs.js";
import * as PartnersTermOfServiceVMMapper$FrontendWebsite from "../../view-model/PartnersTermOfServiceVMMapper.bs.js";

function PartnersTermsOfServiceInfoCmp_UAE(Props) {
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "terms-of-service-desktop-info-container" : "terms-of-service-mobile-info-container"
            }, React.createElement(ParagraphModifiedCmp$FrontendWebsite.make, {
                  paragraphs: PartnersTermOfServiceVMMapper$FrontendWebsite.get_uae_content(undefined)
                }));
}

var make = PartnersTermsOfServiceInfoCmp_UAE;

export {
  make ,
  
}
/* react Not a pure module */
