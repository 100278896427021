// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as HomeConfig$FrontendWebsite from "../../app/home/HomeConfig.bs.js";
import * as VehicleType$FrontendWebsite from "../../app/vehicle/VehicleType.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../app/website/types/WebsiteTypes.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../app/routing/WebsiteConfig.bs.js";

function encodeToUrlTripEstimateFormInfo(form_info) {
  return $$Array.fold_left((function (dict, param) {
                dict[param[0]] = window.encodeURI(param[1]);
                return dict;
              }), {}, [
              [
                "geo_region_id",
                form_info.geo_region
              ],
              [
                "from_address_landmark",
                form_info.pickup_address.landmark
              ],
              [
                "to_address_landmark",
                form_info.drop_address.landmark
              ],
              [
                "from_address_lat",
                form_info.pickup_address.location.lat.toString()
              ],
              [
                "from_address_long",
                form_info.pickup_address.location.lng.toString()
              ],
              [
                "to_address_lat",
                form_info.drop_address.location.lat.toString()
              ],
              [
                "to_address_long",
                form_info.drop_address.location.lng.toString()
              ],
              [
                "customer_name",
                form_info.name
              ],
              [
                "customer_mobile",
                form_info.mobile
              ],
              [
                "user_type",
                "/"
              ],
              [
                "brand",
                "porter"
              ],
              [
                "frequency",
                form_info.requirement
              ]
            ]);
}

function Helper(Content) {
  var getVehicleImage = function (name) {
    var match = VehicleType$FrontendWebsite.toString(name);
    var tmp = match === "2 Wheeler" ? HomeConfig$FrontendWebsite.bike : HomeConfig$FrontendWebsite.canter;
    return WebsiteTypes$FrontendWebsite.createAssetPath(tmp);
  };
  var getSelectComponentItems = function (typeList, conv) {
    return List.map((function (variant) {
                  var match = Curry._1(conv, variant);
                  var value = match[0];
                  return {
                          key: value.toLowerCase(),
                          value: value.toLowerCase(),
                          text: match[1].toUpperCase()
                        };
                }), typeList);
  };
  var getISDCode = WebsiteConfig$FrontendWebsite.isd_code;
  var getAutoCompRestrictions = function (country) {
    switch (country.TAG | 0) {
      case /* UAE */0 :
          return ["ae"];
      case /* BD */1 :
          return ["bd"];
      case /* TR */2 :
          return ["tr"];
      
    }
  };
  var getInputClass = function (base_class_name, input) {
    if (Belt_Option.mapWithDefault(input, false, Belt_Result.isError)) {
      return base_class_name + " modal-form__error-box";
    } else {
      return base_class_name;
    }
  };
  var getFadeAnimationClasses = function (param) {
    return [
            " fade-in-animation",
            " fade-out-animation"
          ];
  };
  return {
          getVehicleImage: getVehicleImage,
          getSelectComponentItems: getSelectComponentItems,
          getISDCode: getISDCode,
          getAutoCompRestrictions: getAutoCompRestrictions,
          getInputClass: getInputClass,
          getFadeAnimationClasses: getFadeAnimationClasses
        };
}

export {
  encodeToUrlTripEstimateFormInfo ,
  Helper ,
  
}
/* HomeConfig-FrontendWebsite Not a pure module */
