// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MediaApiModel$FrontendWebsite from "./MediaApiModel.bs.js";

function linkLabels_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
  if (label.TAG === /* Ok */0) {
    var url = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
    if (url.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                label: label._0,
                url: url._0
              }
            };
    }
    var e = url._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".url" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = label._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".label" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function partnerSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var subTititle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subTititle"), null));
    if (subTititle.TAG === /* Ok */0) {
      var tagLine = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "tagLine"), null));
      if (tagLine.TAG === /* Ok */0) {
        var icon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "icon"), null));
        if (icon.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    subTititle: subTititle._0,
                    tagLine: tagLine._0,
                    icon: icon._0
                  }
                };
        }
        var e = icon._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".icon" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = tagLine._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".tagLine" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = subTititle._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".subTititle" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function companyInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var pinIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "pinIcon"), null));
  if (pinIcon.TAG === /* Ok */0) {
    var companyName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyName"), null));
    if (companyName.TAG === /* Ok */0) {
      var trnNumber = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "trnNumber"), null));
      if (trnNumber.TAG === /* Ok */0) {
        var tncLinkLabel = linkLabels_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "tncLinkLabel"), null));
        if (tncLinkLabel.TAG === /* Ok */0) {
          var refundLinkLabel = linkLabels_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "refundLinkLabel"), null));
          if (refundLinkLabel.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      pinIcon: pinIcon._0,
                      companyName: companyName._0,
                      trnNumber: trnNumber._0,
                      tncLinkLabel: tncLinkLabel._0,
                      refundLinkLabel: refundLinkLabel._0
                    }
                  };
          }
          var e = refundLinkLabel._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".refundLinkLabel" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = tncLinkLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".tncLinkLabel" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = trnNumber._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".trnNumber" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = companyName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".companyName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = pinIcon._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".pinIcon" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function languageSelector_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var icon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "icon"), null));
  if (icon.TAG === /* Ok */0) {
    var englishLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "englishLabel"), null));
    if (englishLabel.TAG === /* Ok */0) {
      var banglaLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banglaLabel"), null));
      if (banglaLabel.TAG === /* Ok */0) {
        var englishPrefix = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "englishPrefix"), null));
        if (englishPrefix.TAG === /* Ok */0) {
          var banglaPrefix = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banglaPrefix"), null));
          if (banglaPrefix.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      icon: icon._0,
                      englishLabel: englishLabel._0,
                      banglaLabel: banglaLabel._0,
                      englishPrefix: englishPrefix._0,
                      banglaPrefix: banglaPrefix._0
                    }
                  };
          }
          var e = banglaPrefix._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".banglaPrefix" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = englishPrefix._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".englishPrefix" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = banglaLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".banglaLabel" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = englishLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".englishLabel" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = icon._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".icon" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function logoSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var logo = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "logo"), null));
    if (logo.TAG === /* Ok */0) {
      var partnerSection = partnerSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "partnerSection"), null));
      if (partnerSection.TAG === /* Ok */0) {
        var languageSelector = Decco.optionFromJson(languageSelector_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "languageSelector"), null));
        if (languageSelector.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    title: title._0,
                    logo: logo._0,
                    partnerSection: partnerSection._0,
                    languageSelector: languageSelector._0
                  }
                };
        }
        var e = languageSelector._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".languageSelector" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = partnerSection._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".partnerSection" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = logo._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".logo" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function navSection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var primaryNavLinks = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "primaryNavLinks"), null));
  if (primaryNavLinks.TAG === /* Ok */0) {
    var secondaryNavLinks = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "secondaryNavLinks"), null));
    if (secondaryNavLinks.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                primaryNavLinks: primaryNavLinks._0,
                secondaryNavLinks: secondaryNavLinks._0
              }
            };
    }
    var e = secondaryNavLinks._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".secondaryNavLinks" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = primaryNavLinks._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".primaryNavLinks" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function footer_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var logo = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "logo"), null));
  if (logo.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var appStoreIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appStoreIcon"), null));
      if (appStoreIcon.TAG === /* Ok */0) {
        var playStoreIcon = MediaApiModel$FrontendWebsite.banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "playStoreIcon"), null));
        if (playStoreIcon.TAG === /* Ok */0) {
          var cityLinkLabel = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityLinkLabel"), null));
          if (cityLinkLabel.TAG === /* Ok */0) {
            var cityLinksTitle = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cityLinksTitle"), null));
            if (cityLinksTitle.TAG === /* Ok */0) {
              var companyLinksLabel = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyLinksLabel"), null));
              if (companyLinksLabel.TAG === /* Ok */0) {
                var serviceLinksLabel = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "serviceLinksLabel"), null));
                if (serviceLinksLabel.TAG === /* Ok */0) {
                  var supportLinksLabel = Decco.arrayFromJson(linkLabels_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportLinksLabel"), null));
                  if (supportLinksLabel.TAG === /* Ok */0) {
                    var socialMediaIcons = MediaApiModel$FrontendWebsite.optionalBanner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "socialMediaIcons"), null));
                    if (socialMediaIcons.TAG === /* Ok */0) {
                      var instagramURL = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "instagramURL"), null));
                      if (instagramURL.TAG === /* Ok */0) {
                        var facebookURL = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "facebookURL"), null));
                        if (facebookURL.TAG === /* Ok */0) {
                          var linkdinURL = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "linkdinURL"), null));
                          if (linkdinURL.TAG === /* Ok */0) {
                            var twitterURL = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "twitterURL"), null));
                            if (twitterURL.TAG === /* Ok */0) {
                              var youtubeURL = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "youtubeURL"), null));
                              if (youtubeURL.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          logo: logo._0,
                                          title: title._0,
                                          appStoreIcon: appStoreIcon._0,
                                          playStoreIcon: playStoreIcon._0,
                                          cityLinkLabel: cityLinkLabel._0,
                                          cityLinksTitle: cityLinksTitle._0,
                                          companyLinksLabel: companyLinksLabel._0,
                                          serviceLinksLabel: serviceLinksLabel._0,
                                          supportLinksLabel: supportLinksLabel._0,
                                          socialMediaIcons: socialMediaIcons._0,
                                          instagramURL: instagramURL._0,
                                          facebookURL: facebookURL._0,
                                          linkdinURL: linkdinURL._0,
                                          twitterURL: twitterURL._0,
                                          youtubeURL: youtubeURL._0
                                        }
                                      };
                              }
                              var e = youtubeURL._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".youtubeURL" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = twitterURL._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".twitterURL" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = linkdinURL._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".linkdinURL" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = facebookURL._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".facebookURL" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = instagramURL._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".instagramURL" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = socialMediaIcons._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".socialMediaIcons" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = supportLinksLabel._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".supportLinksLabel" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = serviceLinksLabel._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".serviceLinksLabel" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = companyLinksLabel._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".companyLinksLabel" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = cityLinksTitle._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".cityLinksTitle" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = cityLinkLabel._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".cityLinkLabel" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = playStoreIcon._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".playStoreIcon" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = appStoreIcon._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".appStoreIcon" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = logo._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".logo" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var companyInfo = Decco.optionFromJson(companyInfo_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyInfo"), null));
  if (companyInfo.TAG === /* Ok */0) {
    var logoSection = logoSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "logoSection"), null));
    if (logoSection.TAG === /* Ok */0) {
      var navSection = navSection_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "navSection"), null));
      if (navSection.TAG === /* Ok */0) {
        var footer = footer_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "footer"), null));
        if (footer.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    companyInfo: companyInfo._0,
                    logoSection: logoSection._0,
                    navSection: navSection._0,
                    footer: footer._0
                  }
                };
        }
        var e = footer._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".footer" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = navSection._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".navSection" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = logoSection._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".logoSection" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = companyInfo._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".companyInfo" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function data_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = data_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  linkLabels_decode ,
  partnerSection_decode ,
  companyInfo_decode ,
  languageSelector_decode ,
  logoSection_decode ,
  navSection_decode ,
  footer_decode ,
  attributes_decode ,
  data_decode ,
  t_decode ,
  
}
/* No side effect */
