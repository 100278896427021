// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as CityConfig$FrontendWebsite from "../CityConfig.bs.js";
import * as VehicleConfig$FrontendWebsite from "../../vehicle/VehicleConfig.bs.js";
import * as VehicleRoutes$FrontendWebsite from "../../routing/VehicleRoutes.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

function get_local_data(spot_url) {
  return ({
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      "name": "Porter",
      "description": "Transport goods, local parcel & courier delivery! Solve all your logistic problems with Porter. Choose from canter - 3tonne, pickup - 1tonne, 2W.",
      "url": `https://www.porter.ae${spot_url}`,
      "logo": "https://porter-website-meta-content.s3.ap-southeast-1.amazonaws.com/local-business-data/logo.svg",
      "priceRange": "AED 25 to AED 232",
      "telephone": "97144242323",
      "image": "https://porter-website-meta-content.s3.ap-southeast-1.amazonaws.com/local-business-data/logo.svg",
      "geo": {
         "@type": "GeoCoordinates",
         "latitude": 24.78925790,
         "longitude": 54.46621364,
       },
       "contactPoint": [
        {"@type": "ContactPoint", "telephone": "97144242323", "contactType": "Customer Service"},
      ],
      "address": {
         "@type": "PostalAddress",
         "streetAddress": "Unit No: 1607, 1 Lake Plaza, Plot No: JLT-PH2-T2A, Jumeirah Lakes Towers, Dhaka, BD",
         "addressLocality": "Jumeirah Lakes Towers",
         "addressRegion": "Dhaka",
         "postalCode": "000000",
         "addressCountry": "AE",
       },
      "sameAs": [
        "https://www.facebook.com/portertrucks/",
        "https://www.instagram.com/portertrucks/",
        "https://www.linkedin.com/company/theporter-in/",
        "https://twitter.com/porter_trucks",
        "https://www.youtube.com/channel/UC6eokjbxNMN2WQNgyZElNeA/feed",
      ],
    });
}

var title = "Book Reliable On-demand Delivery Services in Dhaka";

var meta_description = "Get budget-friendly on-demand pickup truck and bike delivery services from Porter and say hello to a delivery service in Dhaka that you can trust.";

function getCityHeadTags(param) {
  return {
          title: title,
          meta_description: meta_description,
          link: /* [] */0,
          local_data: {
            hd: get_local_data("/rent-mini-trucks-in-" + CityConfig$FrontendWebsite.BD.dhaka),
            tl: /* [] */0
          }
        };
}

function getVehicleHeadTags(vehicle) {
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(match.language);
  var VehicleContent = Curry._1(Content.Vehicle.getVehicleData, /* Dhaka */2);
  var match$1 = VehicleConfig$FrontendWebsite.getTitleDesc(vehicle, VehicleContent);
  return {
          title: match$1[0],
          meta_description: match$1[1],
          link: /* [] */0,
          local_data: {
            hd: get_local_data(VehicleRoutes$FrontendWebsite.toRouteString(CityConfig$FrontendWebsite.BD.dhaka, vehicle, country)),
            tl: /* [] */0
          }
        };
}

var MetaTags = {
  title: title,
  meta_description: meta_description,
  getCityHeadTags: getCityHeadTags,
  getVehicleHeadTags: getVehicleHeadTags
};

export {
  get_local_data ,
  MetaTags ,
  
}
/* AppContext-FrontendWebsite Not a pure module */
