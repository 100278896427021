// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as TermsOfServiceRepo$FrontendWebsite from "./TermsOfServiceRepo.bs.js";
import * as DummyTermsOfServiceRepo$FrontendWebsite from "./DummyTermsOfServiceRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyTermsOfServiceRepo$FrontendWebsite;
  } else {
    return TermsOfServiceRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
