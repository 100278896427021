// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ReactUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as LoadingViewComponent$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/LoadingViewComponent.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as TrackLiveTripMapCmp$FrontendWebsite from "../map/TrackLiveTripMapCmp.bs.js";
import * as TrackLiveTripOrderDetails$FrontendWebsite from "../header/TrackLiveTripOrderDetails.bs.js";
import * as TrackLiveTripWrapperHelper$FrontendWebsite from "./TrackLiveTripWrapperHelper.bs.js";

((require('../style/mobile.scss')));

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetOrderInfo */0 :
        return {
                orderInfo: {
                  TAG: /* Live */1,
                  _0: action._0
                },
                marketingInfo: state.marketingInfo,
                showError: state.showError,
                autoReloadEnabled: true
              };
    case /* SetOrderInfoError */1 :
        return {
                orderInfo: {
                  TAG: /* Error */2,
                  _0: action._0
                },
                marketingInfo: state.marketingInfo,
                showError: state.showError,
                autoReloadEnabled: state.autoReloadEnabled
              };
    case /* SetMarketingInfo */2 :
        return {
                orderInfo: state.orderInfo,
                marketingInfo: {
                  TAG: /* Live */1,
                  _0: action._0
                },
                showError: state.showError,
                autoReloadEnabled: state.autoReloadEnabled
              };
    case /* SetMarketingInfoError */3 :
        return {
                orderInfo: state.orderInfo,
                marketingInfo: {
                  TAG: /* Error */2,
                  _0: action._0
                },
                showError: state.showError,
                autoReloadEnabled: state.autoReloadEnabled
              };
    case /* SetShowError */4 :
        return {
                orderInfo: state.orderInfo,
                marketingInfo: state.marketingInfo,
                showError: action._0,
                autoReloadEnabled: state.autoReloadEnabled
              };
    case /* SetAutoReload */5 :
        return {
                orderInfo: state.orderInfo,
                marketingInfo: state.marketingInfo,
                showError: state.showError,
                autoReloadEnabled: action._0
              };
    
  }
}

function TrackLiveTripWrapperComponent(Props) {
  var booking_id = Props.booking_id;
  var customer_uuid = Props.customer_uuid;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var language = match.language;
  var match$1 = React.useReducer(reducer, {
        orderInfo: /* Init */0,
        marketingInfo: /* Init */0,
        showError: false,
        autoReloadEnabled: true
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState(function () {
        return true;
      });
  var setScrollToAppSection = match$2[1];
  React.useEffect((function () {
          TrackLiveTripWrapperHelper$FrontendWebsite.getOrderInfo(dispatch, booking_id, customer_uuid, country, language);
          
        }), []);
  React.useEffect((function () {
          var info = state.orderInfo;
          if (typeof info !== "number" && info.TAG === /* Live */1) {
            var match = info._0.status;
            if (match === 8 || match === 7) {
              Curry._1(dispatch, {
                    TAG: /* SetAutoReload */5,
                    _0: false
                  });
            }
            
          }
          
        }), [state.orderInfo]);
  ReactUtils$Catalyst.useInterval((function (param) {
          if (document.visibilityState === "visible") {
            Curry._1(dispatch, {
                  TAG: /* SetAutoReload */5,
                  _0: true
                });
          } else {
            Curry._1(dispatch, {
                  TAG: /* SetAutoReload */5,
                  _0: false
                });
          }
          if (state.autoReloadEnabled && document.visibilityState === "visible") {
            return TrackLiveTripWrapperHelper$FrontendWebsite.getOrderInfo(dispatch, booking_id, customer_uuid, country, language);
          }
          
        }), WebsiteConfig$FrontendWebsite.partnerLocationRefreshInterval);
  var scrollHandler = function ($$event) {
    var scrolledHeight = $$event.target.scrollTop;
    var pageHeight = $$event.target.scrollHeight;
    var viewedHeight = window.innerHeight + scrolledHeight | 0;
    if (viewedHeight > (pageHeight - 110 | 0)) {
      return Curry._1(setScrollToAppSection, (function (param) {
                    return false;
                  }));
    } else {
      return Curry._1(setScrollToAppSection, (function (param) {
                    return true;
                  }));
    }
  };
  var match$3 = state.orderInfo;
  var match$4 = state.marketingInfo;
  var exit = 0;
  if (typeof match$3 === "number") {
    exit = 2;
  } else {
    switch (match$3.TAG | 0) {
      case /* Loading */0 :
          exit = 2;
          break;
      case /* Live */1 :
          var order_detail = match$3._0;
          if (typeof match$4 === "number") {
            return React.createElement("div", {
                        className: "track-live-trip-wrapper",
                        id: "track-live-trip-container",
                        onScroll: scrollHandler
                      }, null, React.createElement("div", {
                            className: "track-live-trip-map track-live-trip-map-newflow"
                          }, React.createElement(TrackLiveTripMapCmp$FrontendWebsite.make, {
                                order_detail: order_detail
                              })), React.createElement("div", {
                            className: "scrollable-container"
                          }, React.createElement(TrackLiveTripOrderDetails$FrontendWebsite.make, {
                                order_detail: order_detail
                              })));
          }
          switch (match$4.TAG | 0) {
            case /* Live */1 :
                return React.createElement("div", {
                            className: "track-live-trip-wrapper",
                            id: "track-live-trip-container",
                            onScroll: scrollHandler
                          }, null, React.createElement("div", {
                                className: "track-live-trip-map track-live-trip-map-newflow"
                              }, React.createElement(TrackLiveTripMapCmp$FrontendWebsite.make, {
                                    order_detail: order_detail
                                  })), React.createElement("div", {
                                className: "scrollable-container"
                              }, React.createElement(TrackLiveTripOrderDetails$FrontendWebsite.make, {
                                    order_detail: order_detail
                                  })));
            case /* Loading */0 :
            case /* Error */2 :
                exit = 2;
                break;
            
          }
          break;
      case /* Error */2 :
          return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                      error: match$3._0
                    });
      
    }
  }
  if (exit === 2 && typeof match$4 !== "number") {
    switch (match$4.TAG | 0) {
      case /* Loading */0 :
          return React.createElement(LoadingViewComponent$Catalyst.make, {});
      case /* Live */1 :
          break;
      case /* Error */2 :
          return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                      error: match$4._0
                    });
      
    }
  }
  if (typeof match$3 === "number") {
    return null;
  } else {
    return React.createElement(LoadingViewComponent$Catalyst.make, {});
  }
}

var make = TrackLiveTripWrapperComponent;

var $$default = TrackLiveTripWrapperComponent;

export {
  reducer ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
