// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as NavBarCmp$FrontendWebsite from "../NavBarCmp.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CompanyLogo$FrontendWebsite from "../../../website/view/components/CompanyLogo.bs.js";
import * as BDCopyrightCmp$FrontendWebsite from "../BDCopyrightCmp.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as PartnerLeadCmp$FrontendWebsite from "../PartnerLeadCmp.bs.js";
import * as LanguageSelectorCmp$FrontendWebsite from "../../../../utils/languang_and_content/LanguageSelectorCmp.bs.js";

function HeaderDesktopCmp(Props) {
  var data = Props.data;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  React.useEffect(function () {
        
      });
  var tmp;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp = React.createElement(BDCopyrightCmp$FrontendWebsite.make, {});
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp = null;
        break;
    
  }
  var tmp$1;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$1 = null;
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp$1 = React.createElement(LanguageSelectorCmp$FrontendWebsite.make, {});
        break;
    
  }
  return React.createElement("div", {
              className: "desktop-header"
            }, tmp, React.createElement("div", {
                  className: "header-content-wrapper"
                }, React.createElement("div", {
                      className: "career-header-container"
                    }, React.createElement("div", {
                          className: "logo-container"
                        }, React.createElement(CompanyLogo$FrontendWebsite.make, {
                              data: data.logoSection.logo
                            }), React.createElement("div", {
                              className: "heading"
                            }, Content.Home.heading)), React.createElement("div", {
                          className: "right-container"
                        }, React.createElement(PartnerLeadCmp$FrontendWebsite.make, {
                              data: data.logoSection.partnerSection
                            }), tmp$1)), React.createElement("div", {
                      className: "c-navbar"
                    }, React.createElement("div", {
                          className: "navbar-content"
                        }, React.createElement(NavBarCmp$FrontendWebsite.make, {
                              primary_navbar_links: data.navSection.primaryNavLinks,
                              secondary_navbar_links: data.navSection.secondaryNavLinks,
                              is_desktop: true
                            })))));
}

var make = HeaderDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
