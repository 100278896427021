// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Api from "@react-google-maps/api";

function OverlayComponentMod(Props) {
  var lib_nameOpt = Props.lib_name;
  var config = Props.config;
  var children = Props.children;
  var lib_name = lib_nameOpt !== undefined ? lib_nameOpt : /* GoogleMap */0;
  if (lib_name) {
    return null;
  } else {
    return React.createElement(Api.OverlayView, {
                position: config.position,
                mapPaneName: Belt_Option.getWithDefault(config.mapPaneName, "markerLayer"),
                bounds: config.bounds,
                getPixelPositionOffset: config.getPixelPositionOffset,
                children: children
              });
  }
}

var make = OverlayComponentMod;

export {
  make ,
  
}
/* react Not a pure module */
