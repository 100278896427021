// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";

var vehicle_helper_img = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("vehicle_helper.svg");

var use_cases_img = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("use_cases.png");

var current_location_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("current_location.png");

var android_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("playstore_black_icon.svg");

var ios_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("appstore_black_icon.svg");

var cross_icon = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("cross_icon.svg");

var referral_banner = AssetsManager$FrontendWebsite.getTrackLiveTripsImage("referral_banner.png");

var available_vehicle_title = "We are here to help take care of your logistic needs";

var available_vehicle_desc = "With our wide range of mini trucks and 2 wheeler options.";

var marketing_vehicle_title = "Porter offers a wide range of vehicles for all your needs!";

var marketing_goods_title = "Send anything, anywhere within the city at the cheapest cost.";

var service_category_heading = "Send Anything";

var service_category_desc = "Anytime, anywhere within the city at the cheapest cost";

var nature_of_goods_tiffin_title = "Tiffin";

var nature_of_goods_tiffin_desc = "Forgot Ma's prized tupperware in office? Don't worry Porter 2W is here. Book now!";

var nature_of_goods_tiffin_img = use_cases_img;

var nature_of_goods_kirana_store_title = "Kirana Store ";

var nature_of_goods_kirana_store_desc = "Spend your weekends peacefully! Our 2 wheeler service can help you send items across cities from your Kirana shop";

var nature_of_goods_kirana_store_img = use_cases_img;

var nature_of_goods_furniture_title = "Furniture";

var nature_of_goods_furniture_desc = "Carry yourself, not the extra baggage. Book Ace with Helper to help you move your furniture.";

var nature_of_goods_furniture_img = use_cases_img;

var nature_of_goods_home_decor_title = "Home Decor";

var nature_of_goods_home_decor_desc = "Don't carry the weight of goods on your shoulders. Move any goods upto 500kg with porter 3W";

var nature_of_goods_home_decor_img = use_cases_img;

var nature_of_goods_fruits_vegetables_title = "Fruits & Vegetables";

var nature_of_goods_fruits_vegetables_desc = "Don't let the traffic slow your business down. Book Porter 3W and race yourself towards victory ";

var nature_of_goods_fruits_vegetables_img = use_cases_img;

var nature_of_goods_commercial_equipments_title = "Commercial Equipments";

var nature_of_goods_commercial_equipments_desc = "We understand your requirements. Big or small, sturdy or fragile, move goods upto 1250kg with Porter Pickup 8FT";

var nature_of_goods_commercial_equipments_img = use_cases_img;

var nature_of_goods_chemicals_title = "Chemicals";

var nature_of_goods_chemicals_desc = "Safe & Sound. All chemicals can now be moved safely & easily with Porter 407. Book now.";

var nature_of_goods_chemicals_img = use_cases_img;

var nature_of_goods_parcel_title = "Parcel";

var nature_of_goods_parcel_desc = "Want to deliver loads of parcels to your clients? Use our Eecho service at attractive pricing. Book now.";

var nature_of_goods_parcel_img = use_cases_img;

var download_app_text = "Download the Porter Mobile app Now!";

var android_link = "https://porter.ae/android-app";

var ios_link = "https://porter.ae/iphone-app";

var download_offer_text_prefix = "Get upto ₹200 off";

var download_offer_text_prefix_newview = "Get ₹200 off";

var download_offer_text_suffix = "on first 2 trips";

var download_button_label = "Download Porter";

var greet_text_title = "Congratulations! 🎉";

var greet_text_desc = "Get ₹200 off on your first 2 rides*";

var offer_text = "For all your logistics needs";

var offer_banner_alt_text = "banner offer image";

var download_app_label = "Download Porter";

var validity_text = "*Valid only for new users";

var offer_modal_opened_label = "Offer Modal Opened";

var modal_cross_clicked_label = "Modal Opened/Cross-Clicked<Modal Closed>";

var modal_download_button_clicked_label = "Modal Opened/Download-Button-Clicked<Navigate>";

var service_banner_click_action = "Service-Banner-Clicked<Navigate>";

var call_support_text = "Call Support";

var marketing_video_link = "https://www.youtube.com/embed/4WRH5f7iTNc";

var arrow_symbol = "⌄";

var marketin_video_title = "Porter delivers anything, anywhere, anytime. Watch to know more!";

var google_map_padding = {
  top: 50,
  left: 50,
  bottom: 50,
  right: 50
};

var ga_analytics_before_time_text = " Before 1 hour";

var ga_analytics_after_time_text = " After 1 hour";

var ga_analytics_more_details_visible_text = "More details visible";

var ga_analytics_more_details_not_visible_text = "More details not visible";

export {
  vehicle_helper_img ,
  use_cases_img ,
  current_location_icon ,
  android_icon ,
  ios_icon ,
  cross_icon ,
  referral_banner ,
  available_vehicle_title ,
  available_vehicle_desc ,
  marketing_vehicle_title ,
  marketing_goods_title ,
  service_category_heading ,
  service_category_desc ,
  nature_of_goods_tiffin_title ,
  nature_of_goods_tiffin_desc ,
  nature_of_goods_tiffin_img ,
  nature_of_goods_kirana_store_title ,
  nature_of_goods_kirana_store_desc ,
  nature_of_goods_kirana_store_img ,
  nature_of_goods_furniture_title ,
  nature_of_goods_furniture_desc ,
  nature_of_goods_furniture_img ,
  nature_of_goods_home_decor_title ,
  nature_of_goods_home_decor_desc ,
  nature_of_goods_home_decor_img ,
  nature_of_goods_fruits_vegetables_title ,
  nature_of_goods_fruits_vegetables_desc ,
  nature_of_goods_fruits_vegetables_img ,
  nature_of_goods_commercial_equipments_title ,
  nature_of_goods_commercial_equipments_desc ,
  nature_of_goods_commercial_equipments_img ,
  nature_of_goods_chemicals_title ,
  nature_of_goods_chemicals_desc ,
  nature_of_goods_chemicals_img ,
  nature_of_goods_parcel_title ,
  nature_of_goods_parcel_desc ,
  nature_of_goods_parcel_img ,
  download_app_text ,
  android_link ,
  ios_link ,
  download_offer_text_prefix ,
  download_offer_text_prefix_newview ,
  download_offer_text_suffix ,
  download_button_label ,
  greet_text_title ,
  greet_text_desc ,
  offer_text ,
  offer_banner_alt_text ,
  download_app_label ,
  validity_text ,
  offer_modal_opened_label ,
  modal_cross_clicked_label ,
  modal_download_button_clicked_label ,
  service_banner_click_action ,
  call_support_text ,
  marketing_video_link ,
  arrow_symbol ,
  marketin_video_title ,
  google_map_padding ,
  ga_analytics_before_time_text ,
  ga_analytics_after_time_text ,
  ga_analytics_more_details_visible_text ,
  ga_analytics_more_details_not_visible_text ,
  
}
/* vehicle_helper_img Not a pure module */
