// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_format from "../../../node_modules/rescript/lib/es6/caml_format.js";
import * as CityConfig$FrontendWebsite from "../../app/city/CityConfig.bs.js";
import * as CityHelper$FrontendWebsite from "../../app/city/CityHelper.bs.js";
import * as CookieUtils$FrontendWebsite from "../CookieUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../app/CountryConfig.bs.js";

var country = CountryConfig$FrontendWebsite.getCountryConfig(undefined);

var cities;

switch (country.TAG | 0) {
  case /* UAE */0 :
      cities = {
        hd: CityConfig$FrontendWebsite.UAE.dubai,
        tl: {
          hd: CityConfig$FrontendWebsite.UAE.sharjah,
          tl: /* [] */0
        }
      };
      break;
  case /* BD */1 :
      cities = {
        hd: CityConfig$FrontendWebsite.BD.dhaka,
        tl: /* [] */0
      };
      break;
  case /* TR */2 :
      cities = {
        hd: CityConfig$FrontendWebsite.TR.istanbul,
        tl: /* [] */0
      };
      break;
  
}

function useCity(param) {
  var getCookieValue = function (param) {
    var cookieValue = CookieUtils$FrontendWebsite.getCookie("porter_home_geo_region_id");
    if (cookieValue.length !== 0) {
      return CityHelper$FrontendWebsite.geoRegionIdToCity(Caml_format.caml_int_of_string(cookieValue), country);
    }
    
  };
  var setCityCookie = function (city) {
    if (city !== undefined) {
      return CookieUtils$FrontendWebsite.setCookie("porter_home_geo_region_id=" + String(CityHelper$FrontendWebsite.cityToGeoRegionId(city, country)));
    }
    
  };
  var match = React.useState(function () {
        return getCookieValue(undefined);
      });
  var setCityState = match[1];
  var city = match[0];
  React.useEffect((function () {
          setCityCookie(city);
          
        }), [city]);
  var setCity = function (city) {
    return Curry._1(setCityState, (function (param) {
                  return city;
                }));
  };
  var refreshCity = function (param) {
    var city = getCookieValue(undefined);
    return Curry._1(setCityState, (function (param) {
                  return city;
                }));
  };
  return [
          city,
          setCity,
          refreshCity
        ];
}

export {
  country ,
  cities ,
  useCity ,
  
}
/* country Not a pure module */
