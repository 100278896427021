// Generated by ReScript, PLEASE EDIT WITH CARE


var default_thumbnail_image_link = "https://s3-ap-southeast-1.amazonaws.com/porter-website-banners/prod/cashback_offer_images/cashback_images/default_web_banner.png";

var default_coupon_code = "DUMMY10";

export {
  default_thumbnail_image_link ,
  default_coupon_code ,
  
}
/* No side effect */
