// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as Decco from "../../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";

function address_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var short_name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "short_name"), null));
  if (short_name.TAG === /* Ok */0) {
    var long_name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "long_name"), null));
    if (long_name.TAG === /* Ok */0) {
      var types = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "types"), null));
      if (types.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  short_name: short_name._0,
                  long_name: long_name._0,
                  types: types._0
                }
              };
      }
      var e = types._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".types" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = long_name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".long_name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = short_name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".short_name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function location_decode(json) {
  var decoder = (function(obj){
          return {
            lat: obj.lat(),
            lng: obj.lng()
          }

        });
  try {
    return {
            TAG: /* Ok */0,
            _0: decoder(json)
          };
  }
  catch (exn){
    return {
            TAG: /* Error */1,
            _0: {
              path: "GeocodeApiMapper.location_decode",
              message: "Invalide Lat,Lng",
              value: json
            }
          };
  }
}

function geometry_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var $$location = location_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "location"), null));
  if ($$location.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              location: $$location._0
            }
          };
  }
  var e = $$location._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".location" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function place_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var types = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "types"), null));
  if (types.TAG === /* Ok */0) {
    var formatted_address = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "formatted_address"), null));
    if (formatted_address.TAG === /* Ok */0) {
      var place_id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "place_id"), null));
      if (place_id.TAG === /* Ok */0) {
        var geometry = geometry_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "geometry"), null));
        if (geometry.TAG === /* Ok */0) {
          var address_components = Decco.listFromJson(address_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "address_components"), null));
          if (address_components.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      types: types._0,
                      formatted_address: formatted_address._0,
                      place_id: place_id._0,
                      geometry: geometry._0,
                      address_components: address_components._0
                    }
                  };
          }
          var e = address_components._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".address_components" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = geometry._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".geometry" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = place_id._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".place_id" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = formatted_address._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".formatted_address" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = types._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".types" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var results = Decco.listFromJson(place_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "results"), null));
  if (results.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              results: results._0
            }
          };
  }
  var e = results._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".results" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function execute(json) {
  var obj = DeccoUtils$Catalyst.resolveResult(response_decode(json), "GeocodeApiMapper.re");
  var place = List.hd(obj.results);
  return {
          types: place.types,
          formatted_address: place.formatted_address,
          place_id: place.place_id,
          geometry: {
            location: {
              lat: place.geometry.location.lat,
              lng: place.geometry.location.lng
            }
          },
          address_components: List.map((function (add) {
                  return {
                          short_name: add.short_name,
                          long_name: add.long_name,
                          address_types: add.types
                        };
                }), place.address_components)
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
