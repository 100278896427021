// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as List from "../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as ReactHelmet from "react-helmet";
import * as AppContext$FrontendWebsite from "../utils/context/app-contexts/AppContext.bs.js";
import * as RoutingHelper$FrontendWebsite from "./routing/RoutingHelper.bs.js";

function HelmetWrapperCmp(Props) {
  var routeOpt = Props.route;
  var children = Props.children;
  var route = routeOpt !== undefined ? routeOpt : /* DefaultScreen */0;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var helmet_info = RoutingHelper$FrontendWebsite.getHelmetTags(route, country);
  var renderLinkContent = function (links) {
    return $$Array.of_list(List.map((function (li) {
                      return React.createElement("link", {
                                  key: li,
                                  href: li,
                                  rel: "preload",
                                  type: "image"
                                });
                    }), links));
  };
  var renderLocalBusinessDataContent = function (scripts) {
    return $$Array.of_list(List.mapi((function (index, data) {
                      return React.createElement("script", {
                                  key: String(index),
                                  id: "data",
                                  type: "application/ld+json"
                                }, Json.stringify(data));
                    }), scripts));
  };
  React.createElement("link", {
        href: "https://porter.ae/",
        hrefLang: "en-ae",
        rel: "alternate"
      });
  React.createElement("link", {
        href: "https://porter.in/",
        hrefLang: "en-in",
        rel: "alternate"
      });
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = "Porter.ae";
        break;
    case /* BD */1 :
        tmp = "Porter.com.bd";
        break;
    case /* TR */2 :
        tmp = "ThePorter.com.tr";
        break;
    
  }
  var tmp$1;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$1 = "https://porter.ae";
        break;
    case /* BD */1 :
        tmp$1 = "https://porter.com.bd";
        break;
    case /* TR */2 :
        tmp$1 = "https://theporter.com.tr";
        break;
    
  }
  var tmp$2;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$2 = "Hire Pickup Truck | Movers & Delivery Service in UAE ";
        break;
    case /* BD */1 :
        tmp$2 = "Hire Pickup Truck | Movers & Delivery Service in Bangladesh";
        break;
    case /* TR */2 :
        tmp$2 = "Hire Pickup Truck | Movers & Delivery Service in Turkiye";
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: null
                }, React.createElement("title", undefined, helmet_info.title), React.createElement("meta", {
                      content: helmet_info.meta_description,
                      name: "description"
                    }), React.createElement("meta", {
                      content: tmp,
                      property: "og:site_name"
                    }), React.createElement("meta", {
                      content: tmp$1,
                      property: "og:url"
                    }), React.createElement("meta", {
                      content: tmp$2,
                      property: "og:title"
                    }), renderLinkContent(helmet_info.link), renderLocalBusinessDataContent(helmet_info.local_data)), children);
}

var make = HelmetWrapperCmp;

export {
  make ,
  
}
/* react Not a pure module */
