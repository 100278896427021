// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as GAEvents$FrontendWebsite from "../../library/ga/GAEvents.bs.js";
import * as EventsConfig$FrontendWebsite from "./EventsConfig.bs.js";
import * as EventsHelper$FrontendWebsite from "./EventsHelper.bs.js";

function userDurationEventHandler(timeout, page_name, event_action, event_label, value, language) {
  setTimeout((function (param) {
          return GAEvents$FrontendWebsite.publishEventParameter(event_action, {
                      event_category: page_name,
                      event_label: event_label,
                      event_value: value,
                      nonInteraction: true,
                      language_selected: language
                    });
        }), timeout);
  
}

function setDurationEvents(route, action, language) {
  List.map((function (timeout) {
          return userDurationEventHandler(timeout, EventsHelper$FrontendWebsite.routeToEventCategory(route), action, "Spend time " + (String(timeout / 1000 | 0) + " sec"), timeout / 1000 | 0, language);
        }), {
        hd: EventsConfig$FrontendWebsite.duration_10secs,
        tl: {
          hd: EventsConfig$FrontendWebsite.duration_30secs,
          tl: {
            hd: EventsConfig$FrontendWebsite.duration_60secs,
            tl: {
              hd: EventsConfig$FrontendWebsite.duration_120secs,
              tl: {
                hd: EventsConfig$FrontendWebsite.duration_180secs,
                tl: {
                  hd: EventsConfig$FrontendWebsite.duration_300secs,
                  tl: {
                    hd: EventsConfig$FrontendWebsite.duration_600secs,
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      });
  
}

export {
  userDurationEventHandler ,
  setDurationEvents ,
  
}
/* GAEvents-FrontendWebsite Not a pure module */
