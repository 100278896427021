// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as FareEstimateResultConfig$FrontendWebsite from "./FareEstimateResultConfig.bs.js";

function FareEstimateResultRedirectCmp(Props) {
  return React.createElement("div", {
              className: "fare-estimate-result-redirect-container"
            }, React.createElement("div", {
                  className: "redirect-desc"
                }, FareEstimateResultConfig$FrontendWebsite.redirect_desc), React.createElement("div", {
                  className: "redirect-button",
                  onClick: (function (param) {
                      return RescriptReactRouter.push("/");
                    })
                }, FareEstimateResultConfig$FrontendWebsite.redirect_label));
}

var make = FareEstimateResultRedirectCmp;

export {
  make ,
  
}
/* react Not a pure module */
