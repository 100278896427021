// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

function make_props(map_elem_id, center, zoomOpt, zoom_controlOpt, hybridOpt, mapContainerClassName, mapTypeId, onBoundsChanged, clickableIconsOpt, onClick, onLoad, onTilesLoaded, optionsOpt, ref, onDragEnd, param) {
  var zoom = zoomOpt !== undefined ? Caml_option.valFromOption(zoomOpt) : 11;
  var zoom_control = zoom_controlOpt !== undefined ? Caml_option.valFromOption(zoom_controlOpt) : true;
  var hybrid = hybridOpt !== undefined ? Caml_option.valFromOption(hybridOpt) : false;
  var clickableIcons = clickableIconsOpt !== undefined ? Caml_option.valFromOption(clickableIconsOpt) : false;
  var options = optionsOpt !== undefined ? Caml_option.valFromOption(optionsOpt) : Caml_option.some(Json_encode.object_({
              hd: [
                "gestureHandling",
                "greedy"
              ],
              tl: {
                hd: [
                  "maxZoom",
                  18
                ],
                tl: {
                  hd: [
                    "minZoom",
                    4
                  ],
                  tl: {
                    hd: [
                      "streetViewControl",
                      false
                    ],
                    tl: {
                      hd: [
                        "mapTypeControl",
                        false
                      ],
                      tl: {
                        hd: [
                          "zoomControl",
                          false
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }));
  return {
          map_elem_id: map_elem_id,
          center: center,
          zoom: zoom,
          zoom_control: zoom_control,
          hybrid: hybrid,
          mapContainerClassName: mapContainerClassName,
          mapTypeId: mapTypeId,
          onBoundsChanged: onBoundsChanged,
          clickableIcons: clickableIcons,
          onClick: onClick,
          onLoad: onLoad,
          onTilesLoaded: onTilesLoaded,
          options: options,
          ref: ref,
          onDragEnd: onDragEnd
        };
}

export {
  make_props ,
  
}
/* No side effect */
