// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as LinkCmp$FrontendWebsite from "../../../website/view/components/LinkCmp.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../../website/types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../../utils/helper/ContentHelper.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as BackgroundImageCmp$FrontendWebsite from "../../../website/view/components/BackgroundImageCmp.bs.js";
import * as TrackLiveTripModalLauncher$FrontendWebsite from "../TrackLiveTripModalLauncher.bs.js";

function getLinkTag(label, url, menu_assets, class_name) {
  var assetsInfo = ContentHelper$FrontendWebsite.getContent(menu_assets);
  return React.createElement("a", {
              key: label,
              className: class_name,
              href: url
            }, React.createElement("div", {
                  className: "menu"
                }, label), React.createElement("div", {
                  className: WebsiteTypes$FrontendWebsite.assetPathToString(assetsInfo.class_name),
                  style: {
                    backgroundImage: "url(" + WebsiteTypes$FrontendWebsite.assetPathToString(assetsInfo.src_path) + ")"
                  }
                }));
}

function getMenuContainer(menu_items, class_name, menu_assets) {
  return React.createElement("div", {
              className: class_name + "-container"
            }, $$Array.map((function (linkLable) {
                    if (linkLable.TAG === /* NoURL */0) {
                      return null;
                    } else {
                      return getLinkTag(linkLable._0, linkLable._1, menu_assets, class_name);
                    }
                  }), menu_items));
}

function MenuMobileCmp(Props) {
  var primary_menu_items = Props.primary_menu_items;
  var secondary_menu_items = Props.secondary_menu_items;
  var porter_logo = Props.porter_logo;
  var menu_logo = Props.menu_logo;
  var menu_assets = Props.menu_assets;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(match.language);
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var hamburgerInfo = ContentHelper$FrontendWebsite.getContent(menu_logo);
  var match$1 = React.useState(function () {
        return false;
      });
  var setMenu = match$1[1];
  var menuOpen = match$1[0];
  var tmp;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp = getLinkTag(Content.Home.refund_policy_link_text, "/refund-policy", menu_assets, "secondary");
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp = null;
        break;
    
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "hamburger-container",
                  onClick: (function (_event) {
                      return Curry._1(setMenu, (function (param) {
                                    return !menuOpen;
                                  }));
                    })
                }, React.createElement(BackgroundImageCmp$FrontendWebsite.make, {
                      background_img: WebsiteTypes$FrontendWebsite.assetPathToString(hamburgerInfo.src_path),
                      class_name: "hamburger " + Belt_Option.mapWithDefault(hamburgerInfo.class_name, "", (function (x) {
                              return x;
                            })),
                      height: "2.4rem",
                      width: "2.4rem"
                    })), React.createElement("div", {
                  className: menuOpen ? "mobile-menu-container-wrapper mobile-menu-container-wrapper-active" : "mobile-menu-container-wrapper",
                  onClick: (function (_event) {
                      return Curry._1(setMenu, (function (param) {
                                    return !menuOpen;
                                  }));
                    })
                }, React.createElement("div", {
                      className: menuOpen ? "mobile-menu-container-active" : "mobile-menu-container-inactive"
                    }, React.createElement(LinkCmp$FrontendWebsite.make, {
                          href: "/",
                          className: "menu-header",
                          children: React.createElement(BackgroundImageCmp$FrontendWebsite.make, {
                                background_img: porter_logo.url,
                                class_name: "mobile-company-logo ",
                                height: "1.7rem",
                                width: "11rem"
                              })
                        }), React.createElement("div", {
                          className: "content"
                        }, getMenuContainer(primary_menu_items, "primary", menu_assets), React.createElement(TrackLiveTripModalLauncher$FrontendWebsite.make, {
                              isDesktopView: false,
                              menu_assets: menu_assets
                            }), getMenuContainer(secondary_menu_items, "secondary", menu_assets), React.createElement("div", {
                              className: "secondary-container"
                            }, getLinkTag(Content.Home.about_us_link_text, "/about-us", menu_assets, "secondary"), getLinkTag(Content.Home.terms_of_service_link_text, Content.Home.terms_of_service_url, menu_assets, "secondary"), getLinkTag(Content.Home.privacy_policy_link_text, "/privacy-policy", menu_assets, "secondary"), tmp)))), React.createElement("div", {
                  className: menuOpen ? "dummy-mobile-wrapper dummy-mobile-wrapper-active" : "dummy-mobile-wrapper"
                }));
}

var make = MenuMobileCmp;

export {
  getLinkTag ,
  getMenuContainer ,
  make ,
  
}
/* react Not a pure module */
