// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/MediaEntityMapper.bs.js";
import * as VehicleCMSApiModel$FrontendWebsite from "../api-model/VehicleCMSApiModel.bs.js";
import * as AppDownloadEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/AppDownloadEntityMapper.bs.js";
import * as ServiceableCitiesEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/ServiceableCitiesEntityMapper.bs.js";

function vehicleContentToEntity(contentDetails) {
  return $$Array.map((function (contentDetail) {
                return {
                        title: contentDetail.title,
                        description: contentDetail.description
                      };
              }), contentDetails);
}

function serviceDetailsRowToEntity(serviceDetailsRows) {
  return $$Array.map((function (serviceDetailsRow) {
                return {
                        order: serviceDetailsRow.order,
                        label: serviceDetailsRow.label,
                        value: serviceDetailsRow.value
                      };
              }), serviceDetailsRows);
}

function vehicleInfoToEntity(vehicleInfo) {
  return {
          title: vehicleInfo.title,
          description: vehicleInfo.description,
          serviceDetailsRow: serviceDetailsRowToEntity(vehicleInfo.serviceDetailsRow),
          vehicleContent: vehicleContentToEntity(vehicleInfo.vehicleContent)
        };
}

function otherVehicleAttributesToEntity(otherVehicleAttributes) {
  return {
          title: otherVehicleAttributes.title,
          description: otherVehicleAttributes.description,
          vehicleName: otherVehicleAttributes.vehicleName,
          city: ServiceableCitiesEntityMapper$FrontendWebsite.cityToEntity(otherVehicleAttributes.city),
          url: otherVehicleAttributes.url,
          vehicleType: otherVehicleAttributes.vehicleType,
          vehicleBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(otherVehicleAttributes.vehicleBanner)
        };
}

function vehicleDataToEntity(vehicleData) {
  return $$Array.map((function (data) {
                return {
                        attributes: otherVehicleAttributesToEntity(data.attributes)
                      };
              }), vehicleData);
}

function vehiclesToEntity(vehicles) {
  return {
          data: vehicleDataToEntity(vehicles.data)
        };
}

function otherVehiclesToEntity(otherVehicles) {
  return {
          title: otherVehicles.title,
          vehicles: {
            data: vehicleDataToEntity(otherVehicles.vehicles.data)
          }
        };
}

function porterOffersToEntity(porterOffers) {
  return {
          title: porterOffers.title,
          coupons: List.map((function (coupon) {
                  return {
                          couponInfo: coupon.couponInfo,
                          couponCode: coupon.couponCode,
                          couponValidityText: coupon.couponValidityText,
                          detailsLink: coupon.detailsLink,
                          bannerImg: MediaEntityMapper$FrontendWebsite.bannerToEntity(coupon.bannerImg)
                        };
                }), porterOffers.coupons)
        };
}

function vehicleAttributesToEntity(vehicleAttributes) {
  return {
          title: vehicleAttributes.title,
          slug: vehicleAttributes.slug,
          description: vehicleAttributes.description,
          vehicleName: vehicleAttributes.vehicleName,
          vehicleBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(vehicleAttributes.vehicleBanner),
          city: ServiceableCitiesEntityMapper$FrontendWebsite.cityToEntity(vehicleAttributes.city),
          vehicleInfo: vehicleInfoToEntity(vehicleAttributes.vehicleInfo),
          otherVehicles: otherVehiclesToEntity(vehicleAttributes.otherVehicles),
          serviceableCities: ServiceableCitiesEntityMapper$FrontendWebsite.serviceableCitiesToEntity(vehicleAttributes.serviceableCities),
          porterOffers: porterOffersToEntity(vehicleAttributes.porterOffers),
          estimateButtonLabel: vehicleAttributes.estimateButtonLabel,
          url: vehicleAttributes.url,
          vehicleType: vehicleAttributes.vehicleType,
          appDownload: AppDownloadEntityMapper$FrontendWebsite.appDownloadToEntity(vehicleAttributes.appDownload)
        };
}

function dataToEntity(data) {
  return $$Array.map((function (vehicleData) {
                return {
                        id: vehicleData.id,
                        attributes: vehicleAttributesToEntity(vehicleData.attributes)
                      };
              }), data);
}

function apiModelToEntity(model) {
  return {
          data: dataToEntity(model.data)
        };
}

function execute(json) {
  var model = DeccoUtils$Catalyst.resolveResult(VehicleCMSApiModel$FrontendWebsite.t_decode(json), "VehiclePageCMSEntityMapper.res");
  return {
          data: dataToEntity(model.data)
        };
}

export {
  vehicleContentToEntity ,
  serviceDetailsRowToEntity ,
  vehicleInfoToEntity ,
  otherVehicleAttributesToEntity ,
  vehicleDataToEntity ,
  vehiclesToEntity ,
  otherVehiclesToEntity ,
  porterOffersToEntity ,
  vehicleAttributesToEntity ,
  dataToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
