// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as CityConfig$FrontendWebsite from "../CityConfig.bs.js";
import * as VehicleConfig$FrontendWebsite from "../../vehicle/VehicleConfig.bs.js";
import * as VehicleRoutes$FrontendWebsite from "../../routing/VehicleRoutes.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

function get_local_data(spot_url) {
  return {
      "@context": "",
      "@type": "",
      "name": "",
      "description": "",
      "url": "",
      "logo": "",
      "priceRange": "",
      "telephone": "",
      "image": "",
      "geo": {
         "@type": "",
         "latitude": 0,
         "longitude": 0,
       },
       "contactPoint": [
        {"@type": "", "telephone": "", "contactType": ""},
      ],
      "address": {
         "@type": "",
         "streetAddress": "",
         "addressLocality": "",
         "addressRegion": "",
         "postalCode": "",
         "addressCountry": "",
       },
      "sameAs": [],
    };
}

var title = "Book Reliable On-demand Delivery Services in Istanbul";

var meta_description = "Get budget-friendly on-demand pickup truck and bike delivery services from Porter and say hello to a delivery service in Istanbul that you can trust.";

function getCityHeadTags(param) {
  return {
          title: title,
          meta_description: meta_description,
          link: /* [] */0,
          local_data: {
            hd: get_local_data("/rent-mini-trucks-in-" + CityConfig$FrontendWebsite.TR.istanbul),
            tl: /* [] */0
          }
        };
}

function getVehicleHeadTags(vehicle) {
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var Content = ContentManager$FrontendWebsite.getContent(match.language);
  var VehicleContent = Curry._1(Content.Vehicle.getVehicleData, /* Dhaka */2);
  var match$1 = VehicleConfig$FrontendWebsite.getTitleDesc(vehicle, VehicleContent);
  return {
          title: match$1[0],
          meta_description: match$1[1],
          link: /* [] */0,
          local_data: {
            hd: get_local_data(VehicleRoutes$FrontendWebsite.toRouteString(CityConfig$FrontendWebsite.TR.istanbul, vehicle, country)),
            tl: /* [] */0
          }
        };
}

var MetaTags = {
  title: title,
  meta_description: meta_description,
  getCityHeadTags: getCityHeadTags,
  getVehicleHeadTags: getVehicleHeadTags
};

export {
  get_local_data ,
  MetaTags ,
  
}
/* AppContext-FrontendWebsite Not a pure module */
