// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as NavBarLinkCmp$FrontendWebsite from "./NavBarLinkCmp.bs.js";
import * as TrackLiveTripModalLauncher$FrontendWebsite from "./TrackLiveTripModalLauncher.bs.js";

function NavBarCmp(Props) {
  var primary_navbar_links = Props.primary_navbar_links;
  var secondary_navbar_links = Props.secondary_navbar_links;
  var container_classOpt = Props.container_class;
  var is_desktop = Props.is_desktop;
  var container_class = container_classOpt !== undefined ? container_classOpt : "nav-container";
  return React.createElement("div", {
              className: container_class
            }, React.createElement("div", {
                  className: "primary-links"
                }, React.createElement(NavBarLinkCmp$FrontendWebsite.make, {
                      navbar_links: primary_navbar_links
                    })), React.createElement("div", {
                  className: "secondary-links"
                }, React.createElement(NavBarLinkCmp$FrontendWebsite.make, {
                      navbar_links: secondary_navbar_links,
                      caps: false
                    }), is_desktop ? React.createElement(TrackLiveTripModalLauncher$FrontendWebsite.make, {
                        isDesktopView: is_desktop,
                        menu_assets: undefined
                      }) : null));
}

var make = NavBarCmp;

export {
  make ,
  
}
/* react Not a pure module */
