// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var CouponCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(CouponCmpLazyWrapper);

function CouponCmpLazyWrapper$1(Props) {
  var className = Props.className;
  var data = Props.data;
  var id = Props.id;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./CouponCmp.bs.js");
        }), (function (prim0, prim1, prim2, prim3, prim4) {
          var tmp = {
            className: prim0,
            data: prim1,
            id: prim2
          };
          if (prim3 !== undefined) {
            tmp.key = prim3;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              className: className,
              data: data,
              id: id
            });
}

var make = CouponCmpLazyWrapper$1;

export {
  CouponCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
