// Generated by ReScript, PLEASE EDIT WITH CARE


function city_label(city) {
  return city;
}

var English = {
  home_label: "Home",
  city_label: city_label
};

export {
  English ,
  
}
/* No side effect */
