// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactModal from "react-modal";
import * as Icon$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/icons/Icon.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";
import * as CookieConsent_TR$FrontendWebsite from "../../../../utils/languang_and_content/tr/CookieConsent_TR.bs.js";
import * as CookieManagementCmp$FrontendWebsite from "./CookieManagementCmp.bs.js";

function CookieManagementModal(Props) {
  var setConsentStatus = Props.setConsentStatus;
  var close_modal = Props.close_modal;
  var isDesktop = MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
  var match = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content;
  switch (match.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "CookieManagementModal.res",
                4,
                56
              ],
              Error: new Error()
            };
    case /* TR */2 :
        Content = match._0 ? CookieConsent_TR$FrontendWebsite.TR : CookieConsent_TR$FrontendWebsite.English;
        break;
    
  }
  var closeModal = function (param) {
    Curry._1(setConsentStatus, (function (param) {
            return false;
          }));
    return Curry._1(close_modal, undefined);
  };
  return React.createElement(ReactModal, {
              isOpen: true,
              onRequestClose: closeModal,
              className: "modal " + (
                isDesktop ? "desktop-cookie-management-modal" : "mobile-cookie-management-modal"
              ),
              shouldCloseOnOverlayClick: true,
              children: React.createElement("div", {
                    className: "modal-container"
                  }, React.createElement("div", {
                        className: "modal-titlebar"
                      }, React.createElement("div", {
                            className: "modal-titlebar__title"
                          }, React.createElement("b", undefined, Content.consentManagementTitle)), React.createElement("div", {
                            className: "modal-titlebar__close-btn",
                            onClick: (function (param) {
                                Curry._1(setConsentStatus, (function (param) {
                                        return false;
                                      }));
                                return Curry._1(close_modal, undefined);
                              })
                          }, React.createElement(Icon$Catalyst.CloseIcon.make, {}))), React.createElement(CookieManagementCmp$FrontendWebsite.make, {
                        close_modal: close_modal,
                        desc: Content.consentManagementDesc,
                        link_text: Content.consentManagementLinkText,
                        acceept_text: Content.acceptAllBtn,
                        confirm_text: Content.cnfCookiePreferenceBtn
                      }))
            });
}

var make = CookieManagementModal;

export {
  make ,
  
}
/* react Not a pure module */
