// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../node_modules/rescript/lib/es6/list.js";

function getCountryData(param) {
  return {
          currency: /* TRY */2,
          timeZone: "Europe/Istanbul",
          geoRegions: /* [] */0
        };
}

var prodHostname = {
  hd: "int-website.prod.theporter.com.tr",
  tl: {
    hd: "frontend-website.prod.theporter.com.tr",
    tl: {
      hd: "theporter.com.tr",
      tl: {
        hd: "oms.theporter.com.tr",
        tl: /* [] */0
      }
    }
  }
};

var stagingHostname = {
  hd: "frontend-website.staging.theporter.com.tr",
  tl: {
    hd: "oms-staging.theporter.com.tr",
    tl: {
      hd: "int-website.staging.theporter.com.tr",
      tl: /* [] */0
    }
  }
};

function getHostnames(param) {
  return List.concat({
              hd: prodHostname,
              tl: {
                hd: stagingHostname,
                tl: /* [] */0
              }
            });
}

var TR = {
  getCountryData: getCountryData,
  prodHostname: prodHostname,
  stagingHostname: stagingHostname,
  getHostnames: getHostnames
};

function getCountryData$1(param) {
  return {
          currency: /* BDT */1,
          timeZone: "Asia/Dhaka",
          geoRegions: /* [] */0
        };
}

var prodHostname$1 = {
  hd: "int-website.prod.porter.com.bd",
  tl: {
    hd: "porter.com.bd",
    tl: {
      hd: "frontend-website.porter.com.bd",
      tl: {
        hd: "oms.porter.com.bd",
        tl: /* [] */0
      }
    }
  }
};

var stagingHostname$1 = {
  hd: "frontend-website.staging.porter.com.bd",
  tl: {
    hd: "int-website.staging.porter.com.bd",
    tl: {
      hd: "oms-staging.porter.com.bd",
      tl: /* [] */0
    }
  }
};

function getHostnames$1(param) {
  return List.concat({
              hd: prodHostname$1,
              tl: {
                hd: stagingHostname$1,
                tl: /* [] */0
              }
            });
}

var BD = {
  getCountryData: getCountryData$1,
  prodHostname: prodHostname$1,
  stagingHostname: stagingHostname$1,
  getHostnames: getHostnames$1
};

function getCountryData$2(param) {
  return {
          currency: /* Dollar */0,
          timeZone: "Asia/Dubai",
          geoRegions: /* [] */0
        };
}

var prodHostname$2 = {
  hd: "porter.ae",
  tl: {
    hd: "int-website.prod.porter.ae",
    tl: {
      hd: "frontend-website.prod.porter.ae",
      tl: {
        hd: "oms.porter.ae",
        tl: /* [] */0
      }
    }
  }
};

var stagingHostname$2 = {
  hd: "frontend-website.staging.porter.ae",
  tl: {
    hd: "int-website.staging.porter.ae",
    tl: {
      hd: "oms-server.staging.porter.ae",
      tl: /* [] */0
    }
  }
};

function getHostnames$2(param) {
  return List.concat({
              hd: prodHostname$2,
              tl: {
                hd: stagingHostname$2,
                tl: /* [] */0
              }
            });
}

var UAE = {
  getCountryData: getCountryData$2,
  prodHostname: prodHostname$2,
  stagingHostname: stagingHostname$2,
  getHostnames: getHostnames$2
};

function countryToString(country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        return "uae";
    case /* BD */1 :
        return "bd";
    case /* TR */2 :
        return "tr";
    
  }
}

function getConfig(infra_stack) {
  switch (infra_stack) {
    case /* UAE */0 :
        return {
                TAG: /* UAE */0,
                _0: {
                  currency: /* Dollar */0,
                  timeZone: "Asia/Dubai",
                  geoRegions: /* [] */0
                }
              };
    case /* BD */1 :
        return {
                TAG: /* BD */1,
                _0: {
                  currency: /* BDT */1,
                  timeZone: "Asia/Dhaka",
                  geoRegions: /* [] */0
                }
              };
    case /* TR */2 :
        return {
                TAG: /* TR */2,
                _0: {
                  currency: /* TRY */2,
                  timeZone: "Europe/Istanbul",
                  geoRegions: /* [] */0
                }
              };
    
  }
}

function getCountryConfig(param) {
  var value = window.location.hostname.replace(/(www\.)?/g, "");
  return getConfig(List.mem(value, getHostnames(undefined)) ? /* TR */2 : (
                List.mem(value, getHostnames$1(undefined)) ? /* BD */1 : (List.mem(value, getHostnames$2(undefined)), /* UAE */0)
              ));
}

var defaultCountry = /* UAE */0;

export {
  defaultCountry ,
  TR ,
  BD ,
  UAE ,
  countryToString ,
  getConfig ,
  getCountryConfig ,
  
}
/* No side effect */
