// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AppDownloadApiModel$FrontendWebsite from "../../website/cms/api-model/AppDownloadApiModel.bs.js";

function keywordsAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
  if (slug.TAG === /* Ok */0) {
    var keyword = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keyword"), null));
    if (keyword.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                slug: slug._0,
                keyword: keyword._0
              }
            };
    }
    var e = keyword._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".keyword" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = slug._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".slug" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function keywordsData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = keywordsAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function keywords_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.listFromJson(keywordsData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function keywordsList_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var keywords = keywords_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keywords"), null));
    if (keywords.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                title: title._0,
                keywords: keywords._0
              }
            };
    }
    var e = keywords._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".keywords" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function bannerAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
  if (name.TAG === /* Ok */0) {
    var alternativeText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alternativeText"), null));
    if (alternativeText.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        var ext = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "ext"), null));
        if (ext.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    name: name._0,
                    alternativeText: alternativeText._0,
                    url: url._0,
                    ext: ext._0
                  }
                };
        }
        var e = ext._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".ext" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = alternativeText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".alternativeText" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function bannerData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = bannerAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function banner_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = bannerData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var banner = banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner"), null));
      if (banner.TAG === /* Ok */0) {
        var keywordsList = keywordsList_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "keywordsList"), null));
        if (keywordsList.TAG === /* Ok */0) {
          var appDownload = AppDownloadApiModel$FrontendWebsite.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "appDownload"), null));
          if (appDownload.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      title: title._0,
                      description: description._0,
                      banner: banner._0,
                      keywordsList: keywordsList._0,
                      appDownload: appDownload._0
                    }
                  };
          }
          var e = appDownload._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".appDownload" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = keywordsList._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".keywordsList" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = banner._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".banner" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function servicesCMSData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "attributes"), null));
  if (attributes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              attributes: attributes._0
            }
          };
  }
  var e = attributes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".attributes" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = servicesCMSData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  keywordsAttributes_decode ,
  keywordsData_decode ,
  keywords_decode ,
  keywordsList_decode ,
  bannerAttributes_decode ,
  bannerData_decode ,
  banner_decode ,
  attributes_decode ,
  servicesCMSData_decode ,
  t_decode ,
  
}
/* No side effect */
