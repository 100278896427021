// Generated by ReScript, PLEASE EDIT WITH CARE


var dummyFareEstimateResultResponse = {
      "vehicles":[
        {
          "display_name": "3 Wheeler",
          "vehicle_type": "3 Wheeler",
          "image_url": "https://oms-staging-8.porter.ae//assets/website_revamp/large_vehicles/3_wheeler-022fc2284832545607afeea8be6e567e0d2e99ff2e4e035e4f66374b22fa92db.png",
          "image_alt_text": "3 Wheeler in Bangalore",
          "capacity": {
            "value": 1000,
            "unit": "kg"
          },
          "capacity_display": "1000 kg",
          "size": {
            "length": {
              "value": 7.2,
              "unit": "ft"
            },
            "breadth": {
              "value": 4.9,
              "unit": "ft"
            },
            "height": {
              "value": 4.9,
              "unit": "ft"
            },
            "type": "goods_vehicle"
          },
          "size_display": "7.2 ft x 4.9 ft x 4.9 ft",
          "base_fare": {
            "value": "300",
            "currency": "rupee"
          },
          "base_fare_display": "₹300",
          "discount": {
					  "status": true,
					  "value": "15",
            "max_discount": {
              "amount": "20",
              "currency": "AED"
            },
          },
				  "new_base_fare_display": "₹200"
        },
        {
          "display_name": "3 Wheeler",
          "vehicle_type": "3 Wheeler",
          "image_url": "https://oms-staging-8.porter.ae//assets/website_revamp/large_vehicles/3_wheeler-022fc2284832545607afeea8be6e567e0d2e99ff2e4e035e4f66374b22fa92db.png",
          "image_alt_text": "3 Wheeler in Bangalore",
          "capacity": {
            "value": 1000,
            "unit": "kg"
          },
          "capacity_display": "1000 kg",
          "size": {
            "length": {
              "value": 7.2,
              "unit": "ft"
            },
            "breadth": {
              "value": 4.9,
              "unit": "ft"
            },
            "height": {
              "value": 4.9,
              "unit": "ft"
            },
            "type": "goods_vehicle"
          },
          "size_display": "7.2 ft x 4.9 ft x 4.9 ft",
          "base_fare": {
            "value": "300",
            "currency": "rupee"
          },
          "base_fare_display": "₹300",
          "discount": {
					  "status": true,
					  "value": "15",
            "max_discount": {
              "amount": "20",
              "currency": "AED"
            },
        },
				  "new_base_fare_display": "₹200"
        },
        {
          "display_name": "3 Wheeler",
          "vehicle_type": "3 Wheeler",
          "image_url": "https://oms-staging-8.porter.ae//assets/website_revamp/large_vehicles/3_wheeler-022fc2284832545607afeea8be6e567e0d2e99ff2e4e035e4f66374b22fa92db.png",
          "image_alt_text": "3 Wheeler in Bangalore",
          "capacity": {
            "value": 1000,
            "unit": "kg"
          },
          "capacity_display": "1000 kg",
          "size": {
            "length": {
              "value": 7.2,
              "unit": "ft"
            },
            "breadth": {
              "value": 4.9,
              "unit": "ft"
            },
            "height": {
              "value": 4.9,
              "unit": "ft"
            },
            "type": "goods_vehicle"
          },
          "size_display": "7.2 ft x 4.9 ft x 4.9 ft",
          "base_fare": {
            "value": "300",
            "currency": "rupee"
          },
          "base_fare_display": "₹300",
          "discount": {
					  "status": true,
					  "value": "15",
            "max_discount": {
              "amount": "20",
              "currency": "AED"
            },
          },
				  "new_base_fare_display": "₹200"
        },
      ],
      "callback_request_response": {
        "callback_request_id": "REQ528769646"
      }
    };

export {
  dummyFareEstimateResultResponse ,
  
}
/* No side effect */
