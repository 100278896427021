// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as VehicleType$FrontendWebsite from "../VehicleType.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as VehicleApiModel$FrontendWebsite from "../api-model/VehicleApiModel.bs.js";

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(VehicleApiModel$FrontendWebsite.t_decode(json), "VehicleEntityMapper.res");
  var country = CountryConfig$FrontendWebsite.getCountryConfig(undefined);
  var offers = res.offers;
  return {
          display_name: res.selected_vehicle.display_name,
          vehicle_type: VehicleType$FrontendWebsite.fromString(res.selected_vehicle.vehicle_type, country),
          capacity: res.selected_vehicle.capacity_display,
          base_fare: res.selected_vehicle.base_fare_display,
          size: res.selected_vehicle.size_display,
          img: {
            path: res.selected_vehicle.image_url,
            alt_text: res.selected_vehicle.image_alt_text
          },
          title_desc: res.selected_vehicle.description,
          title_desc_2: res.selected_vehicle.description_2,
          title_desc_3: res.selected_vehicle.description_3,
          fare_heading: res.selected_vehicle.snippet_details.heading_text,
          fare_heading_desc: res.selected_vehicle.snippet_details.content,
          fare_heading_desc_2: res.selected_vehicle.snippet_details.content_2,
          other_vehicles: List.map((function (res) {
                  return {
                          display_name: res.display_name,
                          vehicle_type: VehicleType$FrontendWebsite.fromString(res.vehicle_type, country),
                          img: {
                            path: res.image_url,
                            alt_text: res.image_alt_text
                          },
                          desc: res.description
                        };
                }), res.remaining_vehicles),
          porter_offers: offers !== undefined ? List.map((function (res) {
                    return {
                            banner_img: {
                              path: res.banner_img.path,
                              alt_text: res.banner_img.alt_text
                            },
                            coupon_info: res.coupon_info,
                            coupon_code: res.coupon_code,
                            coupon_validity_text: res.coupon_validity_text,
                            details_link: res.details_link
                          };
                  }), offers) : undefined
        };
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
