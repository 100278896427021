// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as NativeCarouselHelper$FrontendWebsite from "./NativeCarouselHelper.bs.js";
import * as CarouselIndicatorsCmp$FrontendWebsite from "./CarouselIndicatorsCmp.bs.js";

import './Carousel.scss'
;

function NativeCarouselCmp(Props) {
  var class_nameOpt = Props.class_name;
  var children = Props.children;
  var show_indicatorsOpt = Props.show_indicators;
  var delayOpt = Props.delay;
  var autoplayOpt = Props.autoplay;
  var is_mobileOpt = Props.is_mobile;
  var class_name = class_nameOpt !== undefined ? class_nameOpt : "";
  var show_indicators = show_indicatorsOpt !== undefined ? show_indicatorsOpt : true;
  var delay = delayOpt !== undefined ? delayOpt : 4000;
  var autoplay = autoplayOpt !== undefined ? autoplayOpt : true;
  var is_mobile = is_mobileOpt !== undefined ? is_mobileOpt : false;
  var match = React.useState(function () {
        return 0;
      });
  var setCurrentSlide = match[1];
  var currentSlide = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setTouchstartX = match$1[1];
  var touchstartX = match$1[0];
  var childrenCount = React.Children.count(children);
  var startTimer = NativeCarouselHelper$FrontendWebsite.returnStartSetTimeoutId(autoplay, setCurrentSlide, childrenCount, currentSlide, is_mobile, delay);
  var setSlide = function (current) {
    if (is_mobile) {
      NativeCarouselHelper$FrontendWebsite.scrollToSlide(current - 1 | 0, childrenCount);
    }
    return Curry._1(setCurrentSlide, (function (param) {
                  return current;
                }));
  };
  React.useEffect(function () {
        return (function (param) {
                  return NativeCarouselHelper$FrontendWebsite.stopTimer(autoplay, startTimer);
                });
      });
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "carousel " + class_name,
                  id: "carousel",
                  onMouseEnter: (function (param) {
                      return NativeCarouselHelper$FrontendWebsite.stopTimer(autoplay, startTimer);
                    }),
                  onMouseLeave: (function (param) {
                      return Curry._1(setCurrentSlide, (function (prev) {
                                    return prev;
                                  }));
                    }),
                  onTouchEnd: (function ($$event) {
                      var touchendX = NativeCarouselHelper$FrontendWebsite.getTouchCoordinate($$event);
                      return NativeCarouselHelper$FrontendWebsite.autoScroll(touchstartX, touchendX, setCurrentSlide, childrenCount);
                    }),
                  onTouchStart: (function ($$event) {
                      NativeCarouselHelper$FrontendWebsite.stopTimer(autoplay, startTimer);
                      return Curry._1(setTouchstartX, NativeCarouselHelper$FrontendWebsite.getTouchCoordinate($$event));
                    })
                }, NativeCarouselHelper$FrontendWebsite.childrenWrapper(children, is_mobile, currentSlide)), React.createElement("div", {
                  className: "carousel-indicators-wrapper"
                }, show_indicators ? React.createElement(CarouselIndicatorsCmp$FrontendWebsite.make, {
                        currentSlide: currentSlide,
                        totalSlides: childrenCount,
                        setSlide: setSlide
                      }) : null));
}

var make = NativeCarouselCmp;

export {
  make ,
  
}
/*  Not a pure module */
