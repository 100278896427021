// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as SupportCMSEntityMapper$FrontendWebsite from "../entity-mapper/SupportCMSEntityMapper.bs.js";

var execute = SupportCMSEntityMapper$FrontendWebsite.execute;

var SupportCMSApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var SupportCMSApiResponseConverter = partial_arg(SupportCMSApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  SupportCMSApiConfig ,
  SupportCMSApiResponseConverter ,
  
}
/* SupportCMSApiResponseConverter Not a pure module */
