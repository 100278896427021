// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";

function getVehicleTypeFromString(vehicle) {
  var match = $$String.lowercase_ascii($$String.trim(vehicle));
  switch (match) {
    case "2 wheeler" :
    case "2wheeler" :
        return /* TwoWheeler */0;
    default:
      return /* FourWheeler */1;
  }
}

export {
  getVehicleTypeFromString ,
  
}
/* No side effect */
