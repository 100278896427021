// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function defaultValue_toggleModal(_modal) {
  
}

var defaultValue = {
  modal: undefined,
  toggleModal: defaultValue_toggleModal
};

var context = React.createContext(defaultValue);

function useModalContext(param) {
  return React.useContext(context);
}

export {
  defaultValue ,
  context ,
  useModalContext ,
  
}
/* context Not a pure module */
