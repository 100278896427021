// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MediaEntityMapper$FrontendWebsite from "./MediaEntityMapper.bs.js";

function appDownloadToEntity(appDownload) {
  return {
          title: appDownload.title,
          googlePlayStoreBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(appDownload.googlePlayStoreBanner),
          appStoreBanner: MediaEntityMapper$FrontendWebsite.bannerToEntity(appDownload.appStoreBanner),
          appStoreUrl: appDownload.appStoreUrl,
          googlePlayStoreUrl: appDownload.googlePlayStoreUrl
        };
}

export {
  appDownloadToEntity ,
  
}
/* No side effect */
