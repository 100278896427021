// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as LinkCmp$FrontendWebsite from "../../view/components/LinkCmp.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../../utils/helper/ContentHelper.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../../routing/WebsiteConfig.bs.js";

((require('../../../website/view/styles/404.scss')));

function Cmp404(Props) {
  var linkInfo = ContentHelper$FrontendWebsite.getContent(WebsiteConfig$FrontendWebsite.link404);
  return React.createElement("div", {
              className: "container-404"
            }, React.createElement("div", {
                  className: "title"
                }, WebsiteConfig$FrontendWebsite.info404.title), React.createElement("div", {
                  className: "instruction"
                }, Belt_Option.mapWithDefault(WebsiteConfig$FrontendWebsite.info404.description, "", (function (x) {
                        return x;
                      }))), React.createElement(LinkCmp$FrontendWebsite.make, {
                  href: WebsiteTypes$FrontendWebsite.urltoString(linkInfo.target),
                  className: "redirect-button",
                  children: WebsiteTypes$FrontendWebsite.titleToString(linkInfo.text)
                }));
}

var make = Cmp404;

export {
  make ,
  
}
/*  Not a pure module */
