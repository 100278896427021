// Generated by ReScript, PLEASE EDIT WITH CARE


var English_status = {
  open_order_status: "Searching for a driver",
  allocated_order_status: "Searching for a driver",
  accepted_order_status: "Driver is on his way to pickup",
  live_order_status: "Driver is on the way to drop",
  loading_order_status: "Awaiting update\xe2\x80\xa6",
  unloading_order_status: "Awaiting update\xe2\x80\xa6",
  ended_order_status: "Driver has delivered the goods",
  completed_order_status: "Driver has delivered the goods",
  cancelled_order_status: "Trip is cancelled",
  rescheduled_order_status: "Trip is cancelled"
};

var English = {
  track_order_heading: "Track Order",
  booking_mobile_number_heading: "Booking mobile Number",
  track_order_button_title: "Track Order",
  allocated_driver_text: "Driver yet to be assigned",
  cancelled_driver_text: "No Driver assigned",
  status: English_status,
  show_order_details_text: "SHOW ORDER DETAILS",
  hide_order_details_text: "HIDE ORDER DETAILS",
  order_placed_by_text: "Order Placed By",
  order_details_text: "Order Details",
  order_id_text: "Order ID : ",
  contact_support_text: "Contact Support",
  experiencing_issues_text: "Still experiencing issues?"
};

export {
  English ,
  
}
/* No side effect */
