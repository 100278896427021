// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as CopyToClipboard$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/hooks/copy-to-clipboard/CopyToClipboard.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";

function CouponCardCmp(Props) {
  var banner_img = Props.banner_img;
  var validity = Props.validity;
  var link = Props.link;
  var coupon_code = Props.coupon_code;
  var coupon_info = Props.coupon_info;
  var match = CopyToClipboard$Catalyst.useCopyToClipboard(Belt_Option.getWithDefault(coupon_code, ""), React.createElement("div", undefined, Belt_Option.getWithDefault(coupon_code, "")));
  var setCopied = match[2];
  return React.createElement("div", {
              className: "coupon-card-container"
            }, React.createElement("div", {
                  className: "banner-wrapper"
                }, React.createElement("img", {
                      className: "img",
                      alt: banner_img.alt_text,
                      src: WebsiteTypes$FrontendWebsite.urltoString(banner_img.path)
                    })), React.createElement("div", {
                  className: "content"
                }, React.createElement("div", {
                      className: "info"
                    }, coupon_info), coupon_code !== undefined ? React.createElement("div", {
                        className: "coupon-wrapper"
                      }, React.createElement("div", {
                            className: "code",
                            onClick: (function (param) {
                                setTimeout((function (param) {
                                        return Curry._1(setCopied, (function (param) {
                                                      return false;
                                                    }));
                                      }), 2000);
                                
                              })
                          }, match[0]), React.createElement("div", {
                            className: "instruction"
                          }, "Click on the code to copy it")) : React.createElement("div", {
                        className: "coupon-wrapper"
                      }), React.createElement("ul", {
                      className: "validity"
                    }, React.createElement("li", undefined, validity)), React.createElement("a", {
                      className: "link-wrapper",
                      href: link
                    }, React.createElement("div", {
                          className: "link"
                        }, "View Details"), React.createElement("div", {
                          className: "arrow"
                        }, "❯")), match[1] ? React.createElement("div", {
                        className: "copied-dialog"
                      }, "Coupon Code Copied !") : null));
}

var make = CouponCardCmp;

export {
  make ,
  
}
/* react Not a pure module */
