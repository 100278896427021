// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CircularLoader$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../website/view/components/CircularLoader.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../../website/view/components/ResponsiveLayout.bs.js";
import * as SupportCMSViewMapper$FrontendWebsite from "../view-model/SupportCMSViewMapper.bs.js";
import * as SupportCMSRepoManager$FrontendWebsite from "../../repo/SupportCMSRepoManager.bs.js";
import * as SupportMobileCmpLazyWrapper$FrontendWebsite from "./mobile/SupportMobileCmpLazyWrapper.bs.js";
import * as SupportDesktopCmpLazyWrapper$FrontendWebsite from "./desktop/SupportDesktopCmpLazyWrapper.bs.js";

function SupportCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var RepoInstance = SupportCMSRepoManager$FrontendWebsite.getRepo(undefined);
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setCMSData = match$1[1];
  var cmsData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return Curry._2(RepoInstance.fetchSupportCMSData, countryConfig, language);
                }), (function (data) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  if (typeof cmsData === "number") {
    return React.createElement(CircularLoader$Catalyst.make, {});
  }
  switch (cmsData.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(CircularLoader$Catalyst.make, {});
    case /* Live */1 :
        var cmsVM = SupportCMSViewMapper$FrontendWebsite.cmsVM(cmsData._0);
        return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                    desktop_view: React.createElement(React.Suspense, {
                          children: React.createElement(SupportDesktopCmpLazyWrapper$FrontendWebsite.make, {
                                data: cmsVM
                              }),
                          fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                        }),
                    mobile_view: React.createElement(React.Suspense, {
                          children: React.createElement(SupportMobileCmpLazyWrapper$FrontendWebsite.make, {
                                data: cmsVM
                              }),
                          fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                        })
                  });
    case /* Error */2 :
        return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                    error: cmsData._0
                  });
    
  }
}

var make = SupportCmp;

export {
  make ,
  
}
/* react Not a pure module */
