// Generated by ReScript, PLEASE EDIT WITH CARE


function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetFareEstimateFormData */0 :
        return {
                fareEstimateFormData: action._0,
                fareEstimateResult: state.fareEstimateResult,
                showEditForm: state.showEditForm,
                cityBounds: state.cityBounds,
                showFormError: state.showFormError
              };
    case /* SetFareEstimateResult */1 :
        return {
                fareEstimateFormData: state.fareEstimateFormData,
                fareEstimateResult: {
                  TAG: /* Live */1,
                  _0: action._0
                },
                showEditForm: state.showEditForm,
                cityBounds: state.cityBounds,
                showFormError: state.showFormError
              };
    case /* SetCityBounds */3 :
        return {
                fareEstimateFormData: state.fareEstimateFormData,
                fareEstimateResult: state.fareEstimateResult,
                showEditForm: state.showEditForm,
                cityBounds: {
                  TAG: /* Live */1,
                  _0: action._0
                },
                showFormError: state.showFormError
              };
    case /* SetEditForm */2 :
    case /* SetFormError */4 :
        break;
    
  }
  return {
          fareEstimateFormData: state.fareEstimateFormData,
          fareEstimateResult: state.fareEstimateResult,
          showEditForm: action._0,
          cityBounds: state.cityBounds,
          showFormError: state.showFormError
        };
}

export {
  reducer ,
  
}
/* No side effect */
