// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../routing/RoutesConfig.bs.js";
import * as ServicesCMSApiModel$FrontendWebsite from "../api-model/ServicesCMSApiModel.bs.js";
import * as AppDownloadEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/AppDownloadEntityMapper.bs.js";

function decodeKeywordsList(keywords_data) {
  return {
          title: keywords_data.title,
          linksList: List.map((function (keyword) {
                  return {
                          text: keyword.attributes.keyword,
                          url: RoutesConfig$FrontendWebsite.serviceUrl + "/" + keyword.attributes.slug
                        };
                }), keywords_data.keywords.data)
        };
}

function decodeBanner(banner) {
  return {
          title: banner.data.attributes.name,
          ext: banner.data.attributes.ext,
          url: banner.data.attributes.url,
          alt_text: Belt_Option.getWithDefault(banner.data.attributes.alternativeText, "")
        };
}

function apiModelToEntity(model) {
  return {
          titleHeading: model.data.attributes.title,
          banner: decodeBanner(model.data.attributes.banner),
          description: model.data.attributes.description,
          keywords: decodeKeywordsList(model.data.attributes.keywordsList),
          appDownload: AppDownloadEntityMapper$FrontendWebsite.appDownloadToEntity(model.data.attributes.appDownload)
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(ServicesCMSApiModel$FrontendWebsite.t_decode(json), "ServicesEntityMapper.res"));
}

export {
  decodeKeywordsList ,
  decodeBanner ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
