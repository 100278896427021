// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionTypeUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/OptionTypeUtils.bs.js";
import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";
import * as ParagraphViewManager$FrontendWebsite from "./ParagraphViewManager.bs.js";

function paragraphRec(par, indexVal, param) {
  return $$Array.mapi((function (index, paragraph) {
                var tmp;
                if (paragraph.childlist.length !== 0) {
                  var match = paragraph.show_dot;
                  tmp = paragraphRec(paragraph.childlist, Caml_option.some(match !== 1 ? "N/A" : String(index + 1 | 0)), undefined);
                } else {
                  tmp = null;
                }
                var tmp$1;
                if (indexVal !== undefined) {
                  var indexVal$1 = Caml_option.valFromOption(indexVal);
                  var exit = 0;
                  if (indexVal$1 === "N/A") {
                    tmp$1 = String(index + 1 | 0);
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    tmp$1 = OptionTypeUtils$Catalyst.getStringFrmOptionalType(indexVal$1) + ("." + String(index + 1 | 0));
                  }
                  
                } else {
                  tmp$1 = String(index + 1 | 0);
                }
                return React.createElement("div", {
                            key: paragraph.text + String(index)
                          }, React.createElement(ParagraphViewManager$FrontendWebsite.make, {
                                paragraph: paragraph,
                                children: React.createElement("div", {
                                      className: "paragraph-text-list"
                                    }, tmp),
                                index: tmp$1
                              }));
              }), par);
}

function ParagraphModifiedCmp(Props) {
  var paragraphs = Props.paragraphs;
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "common-paragraph-container" : "common-paragraph-container mobile-paragraph-container"
            }, paragraphRec(paragraphs, undefined, undefined));
}

var make = ParagraphModifiedCmp;

export {
  paragraphRec ,
  make ,
  
}
/* react Not a pure module */
