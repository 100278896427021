// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";

function make_props(position, clickableOpt, visibleOpt, zIndexOpt, iconOpt, opacityOpt, ref, param) {
  var clickable = clickableOpt !== undefined ? clickableOpt : false;
  var visible = visibleOpt !== undefined ? visibleOpt : true;
  var zIndex = zIndexOpt !== undefined ? Caml_option.valFromOption(zIndexOpt) : undefined;
  var icon = iconOpt !== undefined ? iconOpt : "";
  var opacity = opacityOpt !== undefined ? Caml_option.valFromOption(opacityOpt) : 1.0;
  return {
          position: position,
          clickable: clickable,
          visible: visible,
          zIndex: zIndex,
          icon: icon,
          opacity: opacity,
          ref: ref
        };
}

export {
  make_props ,
  
}
/* No side effect */
