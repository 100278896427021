// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../node_modules/rescript/lib/es6/list.js";
import * as $$String from "../../node_modules/rescript/lib/es6/string.js";
import * as Caml_obj from "../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Caml_int32 from "../../node_modules/rescript/lib/es6/caml_int32.js";
import * as Caml_format from "../../node_modules/rescript/lib/es6/caml_format.js";
import * as Caml_string from "../../node_modules/rescript/lib/es6/caml_string.js";
import * as RescriptReactRouter from "../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as JsBindings$FrontendWebsite from "./library/bindings/JsBindings.bs.js";

function getPct(provided_value, total_value) {
  return Caml_int32.div(Math.imul(100, provided_value), total_value);
}

function getScrollHeight(id) {
  return JsBindings$FrontendWebsite.scrollHeight(document.getElementById(id));
}

function isIDMatched(id, upper, lower) {
  if (id === "") {
    return false;
  }
  var lastNum = Caml_format.caml_int_of_string("0x" + $$String.make(1, Caml_string.get(id, id.length - 1 | 0)));
  if (lastNum / 16.0 < upper) {
    return lastNum / 16.0 >= lower;
  } else {
    return false;
  }
}

function optimizeRoute(routeUrl) {
  return {
          path: List.filter(function (item) {
                  return item.length !== 0;
                })(routeUrl.path),
          hash: routeUrl.hash,
          search: routeUrl.search
        };
}

function routeRedirection(from_path, to_path) {
  var routeUrl = optimizeRoute(RescriptReactRouter.useUrl(undefined, undefined));
  if (Caml_obj.caml_equal(routeUrl.path, {
          hd: from_path,
          tl: /* [] */0
        })) {
    return RescriptReactRouter.push("/" + to_path + "?" + routeUrl.search + "#" + routeUrl.hash);
  }
  
}

export {
  getPct ,
  getScrollHeight ,
  isIDMatched ,
  optimizeRoute ,
  routeRedirection ,
  
}
/* RescriptReactRouter Not a pure module */
