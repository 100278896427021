// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as ModalContext$FrontendWebsite from "../../../view/modals/ModalContext.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../website/types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../utils/helper/ContentHelper.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

function TrackLiveTripModalLauncher(Props) {
  var isDesktopViewOpt = Props.isDesktopView;
  var menu_assets = Props.menu_assets;
  var isDesktopView = isDesktopViewOpt !== undefined ? isDesktopViewOpt : true;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var modalContext = ModalContext$FrontendWebsite.useModalContext(undefined);
  var getButtonView = function (param) {
    var btnText = Content.TrackOrder.track_order_heading;
    if (isDesktopView) {
      return React.createElement("b", undefined, btnText);
    }
    var assetsInfo = ContentHelper$FrontendWebsite.getContent(menu_assets);
    return React.createElement("div", {
                className: "primary"
              }, React.createElement("div", {
                    className: "menu"
                  }, btnText), React.createElement("div", {
                    className: WebsiteTypes$FrontendWebsite.assetPathToString(assetsInfo.class_name),
                    style: {
                      backgroundImage: "url(" + (WebsiteTypes$FrontendWebsite.assetPathToString(assetsInfo.src_path) + ")")
                    }
                  }));
  };
  return React.createElement("div", {
              className: isDesktopView ? "link nav-link" : "primary-container",
              style: {
                cursor: "pointer"
              },
              onClick: (function (param) {
                  return Curry._1(modalContext.toggleModal, /* TrackLiveTrip */0);
                })
            }, getButtonView(undefined));
}

var make = TrackLiveTripModalLauncher;

export {
  make ,
  
}
/* react Not a pure module */
