// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as H2TitleCmp$FrontendWebsite from "../../../website/view/components/H2TitleCmp.bs.js";
import * as VehicleCardCmp$FrontendWebsite from "../VehicleCardCmp.bs.js";

function OtherVehiclesMobileCmp(Props) {
  var otherVehiclesData = Props.otherVehiclesData;
  return React.createElement("div", {
              className: "mobile-vehicle-list-container"
            }, React.createElement(H2TitleCmp$FrontendWebsite.make, {
                  title: $$String.uppercase_ascii(otherVehiclesData.title)
                }), React.createElement("div", {
                  className: "vehicle-list-wrapper"
                }, $$Array.map((function (vehicle) {
                        return React.createElement(VehicleCardCmp$FrontendWebsite.make, {
                                    vehicle: vehicle,
                                    key: vehicle.vehicleName
                                  });
                      }), otherVehiclesData.vehicles)));
}

var make = OtherVehiclesMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
