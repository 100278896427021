// Generated by ReScript, PLEASE EDIT WITH CARE


var key = "app_link_sms_expiry";

var duration = 30.0;

var unit = /* Seconds */7;

export {
  key ,
  duration ,
  unit ,
  
}
/* No side effect */
