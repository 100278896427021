// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";

function fromRouteString(route, country) {
  switch (country.TAG | 0) {
    case /* UAE */0 :
        switch (route) {
          case "compact-dubai" :
              return {
                      TAG: /* VehiclePage */1,
                      _0: "dubai",
                      _1: {
                        TAG: /* UAE */0,
                        _0: /* Compact */4
                      }
                    };
          case "compact-sharjah" :
              return {
                      TAG: /* VehiclePage */1,
                      _0: "sharjah",
                      _1: {
                        TAG: /* UAE */0,
                        _0: /* Compact */4
                      }
                    };
          default:
            return ;
        }
    case /* BD */1 :
    case /* TR */2 :
        return ;
    
  }
}

function toRouteString(city) {
  var match = $$String.lowercase_ascii(city);
  switch (match) {
    case "dubai" :
        return "/compact-dubai";
    case "sharjah" :
        return "/compact-sharjah";
    default:
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "CompactRoutes.res",
              14,
              2
            ],
            Error: new Error()
          };
  }
}

export {
  fromRouteString ,
  toRouteString ,
  
}
/* No side effect */
