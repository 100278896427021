// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FooterFallBackCmp(Props) {
  return React.createElement("div", {
              className: "fallback__footer"
            }, React.createElement("div", {
                  className: "container"
                }, React.createElement("div", {
                      className: "loading-shimmer slide top"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    })), React.createElement("div", {
                  className: "container"
                }, React.createElement("div", {
                      className: "loading-shimmer slide top"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    })), React.createElement("div", {
                  className: "container"
                }, React.createElement("div", {
                      className: "loading-shimmer slide top"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    })), React.createElement("div", {
                  className: "container app"
                }, React.createElement("div", {
                      className: "loading-shimmer slide"
                    }), React.createElement("div", {
                      className: "loading-shimmer slide"
                    })));
}

var make = FooterFallBackCmp;

export {
  make ,
  
}
/* react Not a pure module */
