// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../routing/RoutesConfig.bs.js";
import * as KeywordsCMSApiModel$FrontendWebsite from "../api-model/KeywordsCMSApiModel.bs.js";
import * as AppDownloadEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/AppDownloadEntityMapper.bs.js";

function decodeFaqs(faqs) {
  return {
          title: faqs.title,
          faqsList: $$Array.map((function (faq) {
                  return {
                          order: faq.order,
                          question: faq.question,
                          answer: faq.answer
                        };
                }), faqs.questions)
        };
}

function decodeAvailableCategories(availableCategories) {
  return {
          title: availableCategories.title,
          categories: $$Array.map((function (category) {
                  return {
                          order: category.order,
                          name: category.title,
                          url: category.url,
                          icon: category.vehicleIcon.data.attributes.url
                        };
                }), availableCategories.categories)
        };
}

function decodeServiceBookingGuide(serviceBookingGuide) {
  return {
          title: serviceBookingGuide.title,
          description: serviceBookingGuide.description,
          bookingSteps: $$Array.map((function (bookingStep) {
                  return {
                          order: bookingStep.order,
                          step: bookingStep.stepLabel
                        };
                }), serviceBookingGuide.bookingSteps)
        };
}

function decodeKeywordsList(keywords_data) {
  return {
          title: keywords_data.title,
          linksList: $$Array.map((function (keyword) {
                  return {
                          text: keyword.attributes.keyword,
                          url: RoutesConfig$FrontendWebsite.serviceUrl + "/" + keyword.attributes.slug
                        };
                }), keywords_data.keywords.data)
        };
}

function decodeServiceFeatures(serviceFeatures) {
  return {
          title: serviceFeatures.title,
          featuresList: $$Array.map((function (feature) {
                  return {
                          order: feature.order,
                          title: feature.title,
                          subTitle: feature.subTitle,
                          icon: feature.featureIcon.data.attributes.url
                        };
                }), serviceFeatures.features)
        };
}

function decodeServiceInfo(serviceInfo) {
  return {
          title: serviceInfo.title,
          description: serviceInfo.description,
          tariffDetails: $$Array.map((function (rowDetails) {
                  return {
                          order: rowDetails.order,
                          label: rowDetails.label,
                          value: rowDetails.value
                        };
                }), serviceInfo.serviceDetailsRow)
        };
}

function decodeBanner(banner) {
  return {
          title: banner.data.attributes.name,
          ext: banner.data.attributes.ext,
          url: banner.data.attributes.url,
          alt_text: Belt_Option.getWithDefault(banner.data.attributes.alternativeText, "")
        };
}

function apiModelToEntity(model) {
  var data = Caml_array.get(model.data, 0);
  return {
          titleHeading: data.attributes.title,
          banner: decodeBanner(data.attributes.banner),
          description: data.attributes.description,
          tariff: decodeServiceInfo(data.attributes.serviceInfo),
          features: decodeServiceFeatures(data.attributes.serviceFeatures),
          keywords: decodeKeywordsList(data.attributes.keywordsList),
          bookingGuide: decodeServiceBookingGuide(data.attributes.serviceBookingGuide),
          availableCategories: decodeAvailableCategories(data.attributes.availableCategories),
          faqs: decodeFaqs(data.attributes.faqs),
          appDownload: AppDownloadEntityMapper$FrontendWebsite.appDownloadToEntity(data.attributes.appDownload)
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(KeywordsCMSApiModel$FrontendWebsite.t_decode(json), "KeywordsEntityMapper.res"));
}

export {
  decodeFaqs ,
  decodeAvailableCategories ,
  decodeServiceBookingGuide ,
  decodeKeywordsList ,
  decodeServiceFeatures ,
  decodeServiceInfo ,
  decodeBanner ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
