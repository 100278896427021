// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LinkCmp$FrontendWebsite from "./LinkCmp.bs.js";

function CompanyLogo(Props) {
  var container_classOpt = Props.container_class;
  var data = Props.data;
  var container_class = container_classOpt !== undefined ? container_classOpt : "";
  return React.createElement(LinkCmp$FrontendWebsite.make, {
              href: "/",
              className: container_class,
              children: React.createElement("img", {
                    src: data.url,
                    width: "120px"
                  })
            });
}

var make = CompanyLogo;

export {
  make ,
  
}
/* react Not a pure module */
