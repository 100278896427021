// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "../../../../../node_modules/re-formality/src/Formality.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as FormInput$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormInput.bs.js";
import * as FormHelper$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/FormHelper.bs.js";
import * as FormTextInput$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/generic/form/form-input/FormTextInput.bs.js";
import * as Formality__ReactUpdate from "../../../../../node_modules/re-formality/src/Formality__ReactUpdate.bs.js";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CommonHelper$FrontendWebsite from "../../../../utils/helper/CommonHelper.bs.js";
import * as FormValidationUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/FormValidationUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../../CountryConfig.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";
import * as TrackLiveTripFormUtils$FrontendWebsite from "../../../../utils/TrackLiveTripFormUtils.bs.js";

var validators_mobile = {
  strategy: /* OnSubmit */4,
  validate: (function (param) {
      var mobile = param.mobile;
      if (mobile.length === 0) {
        return {
                TAG: /* Error */1,
                _0: "Enter mobile Number"
              };
      }
      var _error = CommonHelper$FrontendWebsite.validateNumber(mobile, CountryConfig$FrontendWebsite.getCountryConfig(undefined));
      if (_error.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: _error._0
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Enter valid Mobile Number"
              };
      }
    })
};

var validators_crn = {
  strategy: /* OnSubmit */4,
  validate: (function (param) {
      var crn = param.crn;
      if (crn.length === 0) {
        return {
                TAG: /* Error */1,
                _0: "Enter CRN"
              };
      }
      var error = FormValidationUtils$Catalyst.validateCRN("CRN" + crn);
      if (error.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: error._0
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: error._0
              };
      }
    })
};

var validators = {
  mobile: validators_mobile,
  crn: validators_crn
};

function initialFieldsStatuses(_input) {
  return {
          mobile: /* Pristine */0,
          crn: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            mobile: /* Pristine */0,
            crn: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.mobile;
  var match_0 = match ? match._0 : Curry._1(validators.mobile.validate, input);
  var match$1 = fieldsStatuses.crn;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.crn.validate, input);
  var mobileResult = match_0;
  var mobileResult$1;
  if (mobileResult.TAG === /* Ok */0) {
    var crnResult = match_0$1;
    if (crnResult.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                crn: crnResult._0,
                mobile: mobileResult._0
              },
              fieldsStatuses: {
                mobile: /* Dirty */{
                  _0: mobileResult,
                  _1: /* Shown */0
                },
                crn: /* Dirty */{
                  _0: crnResult,
                  _1: /* Shown */0
                }
              },
              collectionsStatuses: undefined
            };
    }
    mobileResult$1 = mobileResult;
  } else {
    mobileResult$1 = mobileResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            mobile: /* Dirty */{
              _0: mobileResult$1,
              _1: /* Shown */0
            },
            crn: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurMobileField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.mobile, validators_mobile, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  mobile: status,
                                  crn: init.crn
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCrnField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.crn, validators_crn, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  mobile: init.mobile,
                                  crn: status
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */2 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */2,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */3,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */5);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */4);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */3 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */4 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */5 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateMobileField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.mobile, state.submissionStatus, validators_mobile, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            mobile: status,
                                            crn: init.crn
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCrnField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.crn, state.submissionStatus, validators_crn, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            mobile: init.mobile,
                                            crn: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */2 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                mobile: /* Pristine */0,
                                crn: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                mobile: /* Pristine */0,
                                crn: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */3 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */4 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateMobile: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateMobileField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateCrn: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateCrnField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurMobile: (function (param) {
              return Curry._1(dispatch, /* BlurMobileField */0);
            }),
          blurCrn: (function (param) {
              return Curry._1(dispatch, /* BlurCrnField */1);
            }),
          mobileResult: Formality.exposeFieldResult(state.fieldsStatuses.mobile),
          crnResult: Formality.exposeFieldResult(state.fieldsStatuses.crn),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.mobile || match.crn) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */2);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */3);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */4);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */4,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */5);
            })
        };
}

var TrackLiveTripForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function TrackLiveTripFormCmp(Props) {
  var get_track_order_info = Props.get_track_order_info;
  var error = Props.error;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var form = useForm({
        crn: "",
        mobile: ""
      }, (function (output, submissionCbs) {
          Curry._1(get_track_order_info, {
                crn: output.crn,
                mobile: output.mobile
              });
          return Curry._1(submissionCbs.notifyOnSuccess, undefined);
        }));
  return React.createElement("form", {
              className: "modal-form",
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  
                })
            }, React.createElement(FormTextInput$Catalyst.make, {
                  input_props: FormInput$Catalyst.make_props("CRN", (function ($$event) {
                          return Curry._2(form.updateCrn, (function (input, value) {
                                        return {
                                                crn: TrackLiveTripFormUtils$FrontendWebsite.crnConverter(value),
                                                mobile: input.mobile
                                              };
                                      }), FormHelper$Catalyst.TargetEventWrapper.value($$event));
                        }), undefined, undefined, "CRN12345678", undefined, undefined, undefined, form.crnResult, undefined, "modal-form__input " + (
                        Belt_Option.mapWithDefault(form.crnResult, false, Belt_Result.isError) ? " modal-form__error-box" : ""
                      ), undefined, undefined, {
                        className: "form-input-text ",
                        startAdornment: React.createElement("div", {
                              className: "text-input-adornment"
                            }, "CRN")
                      }, undefined, undefined),
                  textinput_props: {
                    value: form.input.crn
                  }
                }), React.createElement(FormTextInput$Catalyst.make, {
                  input_props: FormInput$Catalyst.make_props("Caller Number", (function ($$event) {
                          return Curry._2(form.updateMobile, (function (input, value) {
                                        return {
                                                crn: input.crn,
                                                mobile: TrackLiveTripFormUtils$FrontendWebsite.mobileConverter(value)
                                              };
                                      }), FormHelper$Catalyst.TargetEventWrapper.value($$event));
                        }), undefined, undefined, Content.TrackOrder.booking_mobile_number_heading, undefined, undefined, undefined, form.mobileResult, undefined, "modal-form__input " + (
                        Belt_Option.mapWithDefault(form.mobileResult, false, Belt_Result.isError) ? " modal-form__error-box" : ""
                      ), undefined, undefined, {
                        className: "form-input-text ",
                        startAdornment: React.createElement("div", {
                              className: "text-input-adornment"
                            }, WebsiteConfig$FrontendWebsite.isd_code(country) + " - ")
                      }, undefined, undefined),
                  textinput_props: {
                    value: form.input.mobile
                  }
                }), React.createElement("div", {
                  className: "modal-form__server-error"
                }, Belt_Option.mapWithDefault(error, null, (function (err) {
                        return err;
                      }))), React.createElement("button", {
                  className: "modal-form__button",
                  onClick: (function (param) {
                      return Curry._1(form.submit, undefined);
                    })
                }, Content.TrackOrder.track_order_button_title));
}

var make = TrackLiveTripFormCmp;

export {
  TrackLiveTripForm ,
  make ,
  
}
/* react Not a pure module */
