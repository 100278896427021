// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as LanguageConfig$FrontendWebsite from "../../../utils/languang_and_content/LanguageConfig.bs.js";
import * as TrackLiveTripRepoConfig$FrontendWebsite from "./TrackLiveTripRepoConfig.bs.js";

function submitForm(form, country, language) {
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + ("/website/track_live_order_new?booking_id=" + (form.crn + ("&mobile=" + form.mobile)));
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.PostApiResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function fetchTrackLiveTripInfo(booking_id, customer_uuid, country, language) {
  var requestUrl = $$String.concat("", {
        hd: Config$FrontendWebsite.Env.getOMSUrl(undefined),
        tl: {
          hd: "/api/public/orders/order_details?order_id=",
          tl: {
            hd: booking_id,
            tl: {
              hd: "&customer_uuid=",
              tl: {
                hd: customer_uuid,
                tl: /* [] */0
              }
            }
          }
        }
      });
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, 0, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.TrackLiveTripResponseConverter.execute, result);
                            });
                }), requestUrl);
}

function fetchTrackLiveTripMarketingInfo(booking_id, customer_uuid, country, language) {
  var requestUrl = $$String.concat("", {
        hd: Config$FrontendWebsite.Env.getOMSUrl(undefined),
        tl: {
          hd: "/api/public/orders/marketing_info?order_id=",
          tl: {
            hd: booking_id,
            tl: {
              hd: "&customer_uuid=",
              tl: {
                hd: customer_uuid,
                tl: /* [] */0
              }
            }
          }
        }
      });
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country),
                    language: LanguageConfig$FrontendWebsite.languageToString(language)
                  }, undefined, 0, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(TrackLiveTripRepoConfig$FrontendWebsite.TrackLiveTripPromoResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  submitForm ,
  fetchTrackLiveTripInfo ,
  fetchTrackLiveTripMarketingInfo ,
  
}
/* NetworkV2-Catalyst Not a pure module */
