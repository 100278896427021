// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as PrivacyPolicyV1Repo$FrontendWebsite from "./PrivacyPolicyV1Repo.bs.js";
import * as DummyPrivacyPolicyV1Repo$FrontendWebsite from "./DummyPrivacyPolicyV1Repo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return DummyPrivacyPolicyV1Repo$FrontendWebsite;
  } else {
    return PrivacyPolicyV1Repo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
