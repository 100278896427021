// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Row$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/components/custom/layout-components/Row.bs.js";

function DiscountFareCmp(Props) {
  var old_fare = Props.old_fare;
  var new_fare = Props.new_fare;
  return React.createElement(Row$Catalyst.make, {
              children: null
            }, React.createElement("div", {
                  className: "discount-fare"
                }, old_fare), React.createElement("div", {
                  className: "fare"
                }, new_fare));
}

var make = DiscountFareCmp;

export {
  make ,
  
}
/* react Not a pure module */
