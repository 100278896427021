// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as VehicleType$FrontendWebsite from "../../vehicle/VehicleType.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as FareVehicleApiModel$FrontendWebsite from "../api-model/FareVehicleApiModel.bs.js";
import * as CityGeoRegionApiModel$FrontendWebsite from "../api-model/CityGeoRegionApiModel.bs.js";

function apiModelToState(res) {
  var country = CountryConfig$FrontendWebsite.getCountryConfig(undefined);
  return List.map((function (result) {
                return {
                        id: result.id,
                        display_name: result.display_name,
                        vehicle_type: VehicleType$FrontendWebsite.fromString(result.vehicle_type, country),
                        capacity: result.capacity_display,
                        base_fare_text: result.base_fare_display,
                        size: result.size_display,
                        image_url: result.image_url,
                        image_alt_text: result.image_alt_text,
                        target_url: result.url,
                        free_time_distance_text: result.free_time_distance_text
                      };
              }), res.response);
}

function execute(json) {
  var res = DeccoUtils$Catalyst.resolveResult(CityGeoRegionApiModel$FrontendWebsite.t_decode(json), "FareVehicleMapper.res");
  var city_id = List.map((function (result) {
          return {
                  id: result.id
                };
        }), res.response);
  return List.filter(function (vehicle) {
                if (Belt_Option.isSome(vehicle.display_name)) {
                  var city_id$1 = List.hd(city_id).id;
                  return !(vehicle.id === 8 && city_id$1 === 1);
                } else {
                  return false;
                }
              })(apiModelToState(DeccoUtils$Catalyst.resolveResult(FareVehicleApiModel$FrontendWebsite.t_decode(json), "FareVehicleMapper.res")));
}

export {
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
