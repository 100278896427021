// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as DeccoUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as PolicyApiModel$FrontendWebsite from "../api-model/PolicyApiModel.bs.js";

function policyItemsToEntity(policyItems) {
  return $$Array.map((function (termsOfServiceItem) {
                return {
                        title: termsOfServiceItem.title,
                        description: termsOfServiceItem.description
                      };
              }), policyItems);
}

function attributesToEntity(attributes) {
  return {
          title: attributes.title,
          policyItems: policyItemsToEntity(attributes.policyItems)
        };
}

function apiModelToEntity(response) {
  return {
          data: {
            attributes: attributesToEntity(response.data.attributes)
          }
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(PolicyApiModel$FrontendWebsite.t_decode(json), "PolicyEntityMapper.res"));
}

export {
  policyItemsToEntity ,
  attributesToEntity ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
