// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

function ChipsCmp(Props) {
  var label = Props.label;
  var handler = Props.handler;
  var isSelected = Props.isSelected;
  var redirectTo = Props.redirectTo;
  return React.createElement("div", {
              className: isSelected ? "chip chip-selected" : "chip",
              onClick: (function (param) {
                  if (redirectTo !== undefined) {
                    return Curry._1(handler, redirectTo);
                  } else {
                    return Curry._1(handler, "");
                  }
                })
            }, label);
}

var make = ChipsCmp;

export {
  make ,
  
}
/* react Not a pure module */
