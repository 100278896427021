// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as MediaQuery$FrontendWebsite from "../../../../../utils/hooks/MediaQuery.bs.js";
import * as DangerouslySetInnerHtml$FrontendWebsite from "../../../../../view/common/dangerously-set-inner-html/DangerouslySetInnerHtml.bs.js";
import * as PartnersTermsOfServiceStore_BD$FrontendWebsite from "../../../repository/PartnersTermsOfServiceStore_BD.bs.js";
import * as PartnersTermsOfServiceStore_TR$FrontendWebsite from "../../../repository/PartnersTermsOfServiceStore_TR.bs.js";

function PartnersTermsOfServiceInfoCmp(Props) {
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  var match$1 = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content;
  switch (match$1.TAG | 0) {
    case /* UAE */0 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "PartnersTermsOfServiceInfoCmp.res",
                5,
                72
              ],
              Error: new Error()
            };
    case /* BD */1 :
        Content = match$1._0 ? PartnersTermsOfServiceStore_BD$FrontendWebsite.BD : PartnersTermsOfServiceStore_BD$FrontendWebsite.English;
        break;
    case /* TR */2 :
        Content = match$1._0 ? PartnersTermsOfServiceStore_TR$FrontendWebsite.TR : PartnersTermsOfServiceStore_TR$FrontendWebsite.English;
        break;
    
  }
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        tmp = null;
        break;
    case /* TR */2 :
        tmp = React.createElement("li", {
              className: "list-type-dot"
            }, Content.service_provider_heading_1);
        break;
    
  }
  var tmp$1;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-circle"
                    }, Content.service_provider_sub_heading_15), React.createElement("li", {
                      className: "list-type-circle"
                    }, Content.service_provider_sub_heading_16), React.createElement("li", {
                      className: "list-type-circle"
                    }, Content.service_provider_sub_heading_17)), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.service_provider_heading_8), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.service_provider_heading_9));
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp$1 = null;
        break;
    
  }
  var tmp$2;
  switch (country.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        tmp$2 = null;
        break;
    case /* TR */2 :
        tmp$2 = React.createElement("ul", undefined, React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_1), React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_2), React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_3), React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_4), React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_5));
        break;
    
  }
  var tmp$3;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$3 = null;
        break;
    case /* BD */1 :
        tmp$3 = React.createElement("ul", undefined, React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_1), React.createElement("li", {
                  className: "list-type-circle"
                }, Content.obligation_sub_heading_2));
        break;
    case /* TR */2 :
        tmp$3 = React.createElement(React.Fragment, undefined, React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_16), React.createElement("li", {
                  className: "list-type-dot"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.obligation_heading_17
                    })), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_18), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_19), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_20), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_21), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.obligation_heading_22));
        break;
    
  }
  var tmp$4;
  switch (country.TAG | 0) {
    case /* UAE */0 :
    case /* BD */1 :
        tmp$4 = null;
        break;
    case /* TR */2 :
        tmp$4 = React.createElement("li", {
              className: "list-type-dot"
            }, Content.property_rights_heading_22);
        break;
    
  }
  var tmp$5;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp$5 = React.createElement(React.Fragment, undefined, React.createElement("li", {
                  className: "list-type-dot"
                }, Content.abriation_heading_4), React.createElement("li", {
                  className: "list-type-dot"
                }, Content.abriation_heading_5));
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp$5 = null;
        break;
    
  }
  var tmp$6;
  switch (country.TAG | 0) {
    case /* BD */1 :
        tmp$6 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "9."), Content.juridication_title), React.createElement("div", {
                  className: "heading"
                }, Content.juridication_desc));
        break;
    case /* UAE */0 :
    case /* TR */2 :
        tmp$6 = null;
        break;
    
  }
  return React.createElement("div", {
              className: match[0] ? "terms-of-service-desktop-info-container" : "terms-of-service-mobile-info-container"
            }, React.createElement("div", {
                  className: "heading"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.header_title_1
                    })), React.createElement("div", {
                  className: "table"
                }, React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "left heading"
                        }, Content.loaction_title), React.createElement("div", {
                          className: "right heading"
                        }, Content.$$location)), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "left heading"
                        }, Content.first_party_title), React.createElement("div", {
                          className: "right"
                        }, React.createElement("div", {
                              className: "heading"
                            }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                                  inner_html: Content.first_party_heading_1
                                })), React.createElement("div", {
                              className: "heading"
                            }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                                  inner_html: Content.first_party_heading_2
                                })), React.createElement("div", {
                              className: "heading"
                            }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                                  inner_html: Content.first_party_heading_3
                                })))), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "heading left"
                        }, Content.secondary_party_title), React.createElement("div", {
                          className: "right"
                        }, React.createElement("div", {
                              className: "heading"
                            }, Content.secondary_party_heading_1), React.createElement("ul", undefined, React.createElement("li", {
                                  className: "heading"
                                }, Content.secondary_party_sub_heading_1), React.createElement("li", {
                                  className: "heading"
                                }, Content.secondary_party_sub_heading_2)), React.createElement("div", {
                              className: "heading"
                            }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                                  inner_html: Content.secondary_party_heading_2
                                })), React.createElement("div", {
                              className: "heading"
                            }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                                  inner_html: Content.secondary_party_heading_3
                                })))), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "heading left"
                        }, Content.recital_title), React.createElement("div", {
                          className: "right"
                        }, React.createElement("div", {
                              className: "heading"
                            }, Content.recital_heading_1), React.createElement("div", {
                              className: "heading"
                            }, Content.recital_heading_2), React.createElement("div", {
                              className: "heading"
                            }, Content.recital_heading_3))), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "heading left"
                        }, Content.service_provide_title), React.createElement("div", {
                          className: "heading right"
                        }, Content.service_provide_heading)), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "heading left"
                        }, Content.fee_title), React.createElement("div", {
                          className: "heading right"
                        }, Content.fee_heading_1)), React.createElement("div", {
                      className: "table-group"
                    }, React.createElement("div", {
                          className: "heading left"
                        }, Content.vehicle_title), React.createElement("div", {
                          className: "heading right"
                        }, Content.vehicle_headeing_1))), React.createElement("div", {
                  className: "heading"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.header_title_2
                    })), React.createElement("div", {
                  className: "heading"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.header_title_3
                    })), React.createElement("div", {
                  className: "heading"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.header_title_4
                    })), React.createElement("div", {
                  className: "heading"
                }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                      inner_html: Content.header_title_5
                    })), React.createElement("div", {
                  className: "title bold"
                }, Content.header_title_6), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "1."), Content.defination_title), React.createElement("div", {
                  className: "heading"
                }, Content.defination_desc), React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_1
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_2
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_3
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_4
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_5
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_6
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_7
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_8
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_9
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.defination_heading_10
                        }))), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "2."), Content.service_provider_title), React.createElement("ul", undefined, tmp, React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.service_provider_heading_2
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.service_provider_heading_3), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.service_provider_heading_4), React.createElement("ul", undefined, React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_1), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_2), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_3), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_4), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_5), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_6), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_7), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_8), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_9), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_10), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_11), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_12), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_13), React.createElement("li", {
                          className: "list-type-circle"
                        }, Content.service_provider_sub_heading_14)), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.service_provider_heading_5), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.service_provider_heading_6), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.service_provider_heading_7), tmp$1), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "3."), Content.taxes_title), React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_1), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_2), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_3), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_4), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_5), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_6), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_7), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.taxes_heading_8)), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "4."), Content.obligation_title), React.createElement("div", {
                  className: "heading"
                }, Content.obligation_desc), React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_1), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_2), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_3), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_4), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_5), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_6), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_7), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_8), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_9), tmp$2, React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_10), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_11), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_12), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.obligation_heading_13
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_14), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.obligation_heading_15), tmp$3), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "5."), Content.property_rights_title), React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_1), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_2), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_3), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_4), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_5), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_6), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_7), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_8), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_9), React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.property_rights_heading_10
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_11), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_12), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_13), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_14), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_15), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_16), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_17), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_18), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_19), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_20), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_21), tmp$4, React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.property_rights_heading_23), React.createElement("ul", undefined, React.createElement("li", {
                          className: "list-type-dot"
                        }, Content.property_rights_sub_heading_1), React.createElement("li", {
                          className: "list-type-dot"
                        }, Content.property_rights_sub_heading_2)), React.createElement("span", {
                      className: "bold"
                    }, Content.property_rights_sub_heading_3)), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "6."), Content.governing_law_title), React.createElement("div", {
                  className: "heading"
                }, Content.governing_law_desc), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "7."), Content.dispute_title), React.createElement("div", {
                  className: "heading"
                }, Content.dispute_desc), React.createElement("div", {
                  className: "title title-primary bold"
                }, React.createElement("span", {
                      className: "bold"
                    }, "8."), Content.abriation_title), React.createElement("ul", undefined, React.createElement("li", {
                      className: "list-type-dot"
                    }, React.createElement(DangerouslySetInnerHtml$FrontendWebsite.make, {
                          inner_html: Content.abriation_heading_1
                        })), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.abriation_heading_2), React.createElement("li", {
                      className: "list-type-dot"
                    }, Content.abriation_heading_3), tmp$5), tmp$6);
}

var make = PartnersTermsOfServiceInfoCmp;

export {
  make ,
  
}
/* react Not a pure module */
