// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as DeccoUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as MediaEntityMapper$FrontendWebsite from "./MediaEntityMapper.bs.js";
import * as HeaderFooterCMSApiModel$FrontendWebsite from "../api-model/HeaderFooterCMSApiModel.bs.js";

function decodeLinkLabels(data) {
  return {
          label: data.label,
          url: Belt_Option.getWithDefault(data.url, "")
        };
}

function decodedecodeLinkLabelsArray(data) {
  return $$Array.map(decodeLinkLabels, data);
}

function decodePartnerSection(data) {
  return {
          title: data.title,
          subTitle: data.subTititle,
          tagLine: data.tagLine,
          icon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.icon)
        };
}

function decodeCompanyInfo(data) {
  if (data !== undefined) {
    return {
            pinIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.pinIcon),
            companyName: data.companyName,
            trnNumber: data.trnNumber,
            tncLinkLabel: decodeLinkLabels(data.tncLinkLabel),
            refundLinkLabel: decodeLinkLabels(data.refundLinkLabel)
          };
  }
  
}

function decodeLanguageSelector(data) {
  if (data !== undefined) {
    return {
            icon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.icon),
            englishLabel: data.englishLabel,
            banglaLabel: data.banglaLabel,
            englishPrefix: data.banglaPrefix,
            banglaPrefix: data.banglaPrefix
          };
  }
  
}

function decodeLogoSection(data) {
  return {
          title: data.title,
          logo: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.logo),
          partnerSection: decodePartnerSection(data.partnerSection),
          languageSelector: decodeLanguageSelector(data.languageSelector)
        };
}

function decodeNavSection(data) {
  return {
          primaryNavLinks: $$Array.map(decodeLinkLabels, data.primaryNavLinks),
          secondaryNavLinks: $$Array.map(decodeLinkLabels, data.secondaryNavLinks)
        };
}

function decodeFooter(data) {
  return {
          logo: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.logo),
          title: data.title,
          appStoreIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.appStoreIcon),
          playStoreIcon: MediaEntityMapper$FrontendWebsite.bannerToEntity(data.playStoreIcon),
          cityLinkLabel: $$Array.map(decodeLinkLabels, data.cityLinkLabel),
          cityLinksTitle: data.cityLinksTitle,
          companyLinksLabel: $$Array.map(decodeLinkLabels, data.companyLinksLabel),
          serviceLinksLabel: $$Array.map(decodeLinkLabels, data.serviceLinksLabel),
          supportLinksLabel: $$Array.map(decodeLinkLabels, data.supportLinksLabel),
          socialMediaIcons: MediaEntityMapper$FrontendWebsite.optionalBannerToEntity(data.socialMediaIcons),
          instagramURL: data.instagramURL,
          facebookURL: data.facebookURL,
          linkdinURL: data.linkdinURL,
          twitterURL: data.twitterURL,
          youtubeURL: data.youtubeURL
        };
}

function apiModelToEntity(json) {
  var data = json.data.attributes;
  return {
          companyInfo: decodeCompanyInfo(data.companyInfo),
          logoSection: decodeLogoSection(data.logoSection),
          navSection: decodeNavSection(data.navSection),
          footer: decodeFooter(data.footer)
        };
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(HeaderFooterCMSApiModel$FrontendWebsite.t_decode(json), "HeaderFooterCMSEntityMapper.res"));
}

export {
  decodeLinkLabels ,
  decodedecodeLinkLabelsArray ,
  decodePartnerSection ,
  decodeCompanyInfo ,
  decodeLanguageSelector ,
  decodeLogoSection ,
  decodeNavSection ,
  decodeFooter ,
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
