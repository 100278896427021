// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DiscountFareCmp$FrontendWebsite from "../DiscountFareCmp.bs.js";

function showVehicleInfo(label, value) {
  return React.createElement("div", {
              className: "info"
            }, React.createElement("div", {
                  className: "prefix"
                }, label), React.createElement("div", {
                  className: "suffix"
                }, value));
}

function FareEstimateResultVehicleCardDesktopCmp(Props) {
  var vehicle = Props.vehicle;
  var vehicleSection = Props.vehicleSection;
  var discount = vehicle.discount;
  var discount$1 = vehicle.discount;
  return React.createElement("div", {
              className: "fare-estimate-result-desktop-vehicle-card-container"
            }, discount !== undefined ? React.createElement("div", {
                    className: "discount-container"
                  }, React.createElement("div", {
                        className: "value"
                      }, discount.value + "% " + vehicleSection.offLabel), React.createElement("div", {
                        className: "maximum-discount"
                      }, vehicleSection.uptoLabel + " " + discount.max_discount)) : null, React.createElement("div", {
                  className: "name"
                }, vehicle.display_name), discount$1 !== undefined ? React.createElement(DiscountFareCmp$FrontendWebsite.make, {
                    old_fare: vehicle.base_fare,
                    new_fare: discount$1.discounted_fare
                  }) : React.createElement("div", {
                    className: "fare"
                  }, vehicle.base_fare), React.createElement("div", {
                  className: "vehicle-icon-wrapper"
                }, React.createElement("img", {
                      className: "icon",
                      alt: vehicle.icon.alt_text,
                      src: vehicle.icon.path
                    })), React.createElement("div", undefined, showVehicleInfo(vehicleSection.capacityLabel, vehicle.capacity), showVehicleInfo(vehicleSection.sizeLabel, vehicle.size)));
}

var make = FareEstimateResultVehicleCardDesktopCmp;

export {
  showVehicleInfo ,
  make ,
  
}
/* react Not a pure module */
