// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CityCMSRepoConfig$FrontendWebsite from "./CityCMSRepoConfig.bs.js";
import * as CMSDataSourceConfig$FrontendWebsite from "../../../utils/cms/CMSDataSourceConfig.bs.js";

function fetchCityCMSData(country, language, city) {
  var requestUrl = CMSDataSourceConfig$FrontendWebsite.getCityRequestUrl(country, language, city);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, undefined, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(CityCMSRepoConfig$FrontendWebsite.CityCMSResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  fetchCityCMSData ,
  
}
/* NetworkV2-Catalyst Not a pure module */
