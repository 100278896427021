// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function bounds_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var max_lat = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "max_lat"), null));
  if (max_lat.TAG === /* Ok */0) {
    var max_lng = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "max_lng"), null));
    if (max_lng.TAG === /* Ok */0) {
      var min_lat = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "min_lat"), null));
      if (min_lat.TAG === /* Ok */0) {
        var min_lng = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "min_lng"), null));
        if (min_lng.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    max_lat: max_lat._0,
                    max_lng: max_lng._0,
                    min_lat: min_lat._0,
                    min_lng: min_lng._0
                  }
                };
        }
        var e = min_lng._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".min_lng" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = min_lat._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".min_lat" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = max_lng._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".max_lng" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = max_lat._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".max_lat" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var bounds = bounds_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "bounds"), null));
  if (bounds.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              bounds: bounds._0
            }
          };
  }
  var e = bounds._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".bounds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  bounds_decode ,
  t_decode ,
  
}
/* No side effect */
