// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as PolicyEntityMapper$FrontendWebsite from "../../website/cms/entity-mapper/PolicyEntityMapper.bs.js";

var execute = PolicyEntityMapper$FrontendWebsite.execute;

var TermsOfServiceDubaiBranchCMSApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var TermsOfServiceDubaiBranchCMSApiResponseConverter = partial_arg(TermsOfServiceDubaiBranchCMSApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  TermsOfServiceDubaiBranchCMSApiConfig ,
  TermsOfServiceDubaiBranchCMSApiResponseConverter ,
  
}
/* TermsOfServiceDubaiBranchCMSApiResponseConverter Not a pure module */
