// Generated by ReScript, PLEASE EDIT WITH CARE


function useDeviceLayoutDetector(param) {
  return window.matchMedia("(min-width: 600px)").matches;
}

export {
  useDeviceLayoutDetector ,
  
}
/* No side effect */
