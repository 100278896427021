// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as KeywordsRepoConfig$FrontendWebsite from "./KeywordsRepoConfig.bs.js";
import * as CMSDataSourceConfig$FrontendWebsite from "../../../utils/cms/CMSDataSourceConfig.bs.js";

function getKeywordsCMSData(country, language, keyword) {
  var requestUrl = CMSDataSourceConfig$FrontendWebsite.getKeywordsRequestUrl(country, language, keyword);
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, undefined, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(KeywordsRepoConfig$FrontendWebsite.KeywordsResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  getKeywordsCMSData ,
  
}
/* NetworkV2-Catalyst Not a pure module */
