// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ApiUtils$Catalyst from "../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CircularLoader$Catalyst from "../../../node_modules/@porterin/catalyst/src/components/generic/loaders/CircularLoader.bs.js";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as KeywordsRepo$FrontendWebsite from "./repo/KeywordsRepo.bs.js";
import * as CircularLoader$FrontendWebsite from "../website/view/components/CircularLoader.bs.js";
import * as ErrorDisplayComponent$Catalyst from "../../../node_modules/@porterin/catalyst/src/components/custom/error/ErrorDisplayComponent.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";
import * as KeywordsMobileCmpLazyWrapper$FrontendWebsite from "./view/component/mobile/KeywordsMobileCmpLazyWrapper.bs.js";
import * as KeywordsDesktopCmpLazyWrapper$FrontendWebsite from "./view/component/desktop/KeywordsDesktopCmpLazyWrapper.bs.js";

function KeywordPageCmp(Props) {
  var keyword = Props.keyword;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useState(function () {
        return /* Init */0;
      });
  var setKeywordsCMSData = match$1[1];
  var keywordsCMSData = match$1[0];
  React.useEffect((function () {
          ApiUtils$Catalyst.handleAPIRequest((function (param) {
                  return KeywordsRepo$FrontendWebsite.getKeywordsCMSData(countryConfig, language, keyword);
                }), (function (data) {
                  return Curry._1(setKeywordsCMSData, (function (param) {
                                return {
                                        TAG: /* Live */1,
                                        _0: data
                                      };
                              }));
                }), (function (error) {
                  return Curry._1(setKeywordsCMSData, (function (param) {
                                return {
                                        TAG: /* Error */2,
                                        _0: error
                                      };
                              }));
                }));
          
        }), []);
  if (typeof keywordsCMSData !== "number") {
    switch (keywordsCMSData.TAG | 0) {
      case /* Loading */0 :
          break;
      case /* Live */1 :
          var data = keywordsCMSData._0;
          return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                      desktop_view: React.createElement(React.Suspense, {
                            fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                            children: React.createElement(KeywordsDesktopCmpLazyWrapper$FrontendWebsite.make, {
                                  keywordsCMSData: data
                                })
                          }),
                      mobile_view: React.createElement(React.Suspense, {
                            fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                            children: React.createElement(KeywordsMobileCmpLazyWrapper$FrontendWebsite.make, {
                                  keywordsCMSData: data
                                })
                          })
                    });
      case /* Error */2 :
          return React.createElement(ErrorDisplayComponent$Catalyst.make, {
                      error: keywordsCMSData._0
                    });
      
    }
  }
  return React.createElement("div", {
              className: "loaderWrapper"
            }, React.createElement(CircularLoader$Catalyst.make, {}));
}

var make = KeywordPageCmp;

export {
  make ,
  
}
/* react Not a pure module */
