// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function getPosition(pos) {
  switch (pos) {
    case /* Left */0 :
        return "flex-start";
    case /* Center */1 :
        return "center";
    case /* Right */2 :
        return "flex-end";
    
  }
}

function Title(Props) {
  var title = Props.title;
  var class_name = Props.class_name;
  var positionOpt = Props.position;
  var position = positionOpt !== undefined ? positionOpt : /* Left */0;
  return React.createElement("div", {
              className: class_name,
              style: {
                alignItems: getPosition(position)
              }
            }, React.createElement("div", {
                  className: "title"
                }, title), React.createElement("div", {
                  className: "bar"
                }));
}

var make = Title;

export {
  getPosition ,
  make ,
  
}
/* react Not a pure module */
