// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../../../website/view/components/CircularLoader.bs.js";
import * as DriverPartnerDM$FrontendWebsite from "../../data-machine/DriverPartnerDM.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../../website/view/components/ResponsiveLayout.bs.js";
import * as DriverParnerCMSSM$FrontendWebsite from "../../state-machine/DriverParnerCMSSM.bs.js";
import * as DriverPartnerCMSVM$FrontendWebsite from "../../view-model/DriverPartnerCMSVM.bs.js";
import * as DriverPartnersMobileCmpLazyWrapper$FrontendWebsite from "./mobile/DriverPartnersMobileCmpLazyWrapper.bs.js";
import * as DriverPartnersDesktopCmpLazyWrapper$FrontendWebsite from "./desktop/DriverPartnersDesktopCmpLazyWrapper.bs.js";

function DriverPartnersCmp(Props) {
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useReducer(DriverParnerCMSSM$FrontendWebsite.StateMachine.machine, /* Init */0);
  var dispatch = match$1[1];
  var state = match$1[0];
  React.useEffect((function () {
          DriverPartnerDM$FrontendWebsite.FetchDriverPartnerCMS.execute((function (data) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchDriverPartnerCMSData */0,
                              _0: data
                            });
                }), (function (error) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchDriverPartnerCMSDataFailed */1,
                              _0: error
                            });
                }), countryConfig, language);
          
        }), []);
  if (typeof state === "number") {
    return React.createElement(CircularLoader$FrontendWebsite.make, {});
  }
  if (state.TAG !== /* Data */0) {
    return React.createElement("div", undefined, "Something Went wrong");
  }
  var cmsVm = DriverPartnerCMSVM$FrontendWebsite.getVm(state._0);
  return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
              desktop_view: React.createElement(React.Suspense, {
                    fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                    children: React.createElement(DriverPartnersDesktopCmpLazyWrapper$FrontendWebsite.make, {
                          cmsVm: cmsVm
                        })
                  }),
              mobile_view: React.createElement(React.Suspense, {
                    fallback: React.createElement(CircularLoader$FrontendWebsite.make, {}),
                    children: React.createElement(DriverPartnersMobileCmpLazyWrapper$FrontendWebsite.make, {
                          cmsVm: cmsVm
                        })
                  })
            });
}

var make = DriverPartnersCmp;

export {
  make ,
  
}
/* react Not a pure module */
