// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MetapixelJs from "./Metapixel.js";

function initMetapixelCfg(prim) {
  MetapixelJs.init(prim);
  
}

export {
  initMetapixelCfg ,
  
}
/* ./Metapixel.js Not a pure module */
