// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as AboutUsEntityMapper$FrontendWebsite from "../entity-mapper/AboutUsEntityMapper.bs.js";

var execute = AboutUsEntityMapper$FrontendWebsite.execute;

var AboutUsApiConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var AboutUsResponseConverter = partial_arg(AboutUsApiConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  AboutUsApiConfig ,
  AboutUsResponseConverter ,
  
}
/* AboutUsResponseConverter Not a pure module */
