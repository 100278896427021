// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkV2$Catalyst from "../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/NetworkV2.bs.js";
import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../utils/NetworkUtils.bs.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as CampaignsRepoConfig$FrontendWebsite from "./CampaignsRepoConfig.bs.js";

function fetchOffers(re_id, country) {
  var web_tracking_id = Config$FrontendWebsite.Env.getWebTrackingId(undefined);
  var requestUrl = Config$FrontendWebsite.Env.getOMSUrl(undefined) + ("/get_discount_campaigns_v2?re_id=" + re_id + "&website_tracking_id=" + Belt_Option.getWithDefault(web_tracking_id, "null"));
  return NetworkUtils$FrontendWebsite.catchPromiseError(NetworkV2$Catalyst.get(requestUrl, {
                    country: CountryConfig$FrontendWebsite.countryToString(country)
                  }, undefined, undefined, undefined, undefined).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(CampaignsRepoConfig$FrontendWebsite.DiscountCouponApiResponseConverter.execute, result);
                            });
                }), requestUrl);
}

export {
  fetchOffers ,
  
}
/* NetworkV2-Catalyst Not a pure module */
