// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as GAConfig$FrontendWebsite from "../../../../../utils/library/ga/GAConfig.bs.js";
import * as GAEvents$FrontendWebsite from "../../../../../utils/library/ga/GAEvents.bs.js";
import * as UtmUtils$FrontendWebsite from "../../../../../utils/UtmUtils.bs.js";
import * as AppContext$FrontendWebsite from "../../../../../utils/context/app-contexts/AppContext.bs.js";
import * as BrowserUtils$FrontendWebsite from "../../../../../utils/BrowserUtils.bs.js";
import * as EventsHelper$FrontendWebsite from "../../../../../utils/context/events-manager/EventsHelper.bs.js";
import * as FooterConfig$FrontendWebsite from "../../../../footer/FooterConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../../utils/languang_and_content/ContentManager.bs.js";
import * as FareEstimateResultBookingStepDesktopCmp$FrontendWebsite from "./FareEstimateResultBookingStepDesktopCmp.bs.js";

function FareEstimateResultAppsDesktopCmp(Props) {
  var data = Props.data;
  var match = UtmUtils$FrontendWebsite.getUtmString(undefined);
  var utm_campaign = match[1];
  var utm_source = match[0];
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  ContentManager$FrontendWebsite.getContent(language);
  var GAConfigActions = GAConfig$FrontendWebsite.getGAConfigActions(undefined);
  return React.createElement(FareEstimateResultBookingStepDesktopCmp$FrontendWebsite.make, {
              step: 1,
              heading: data.pointOne,
              children: React.createElement("div", {
                    className: "apps-wrapper"
                  }, React.createElement("a", {
                        href: FooterConfig$FrontendWebsite.androidLinkUrl
                      }, React.createElement("img", {
                            className: "icon",
                            src: data.playStoreImage.url,
                            onClick: (function (param) {
                                return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.googlePlayStoreButtonClicked, {
                                            event_category: GAConfigActions.category,
                                            event_label: EventsHelper$FrontendWebsite.getNumberedEventLabel({
                                                  hd: utm_source,
                                                  tl: {
                                                    hd: utm_campaign,
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                          });
                              })
                          })), React.createElement("a", {
                        href: BrowserUtils$FrontendWebsite.isMac(undefined) ? FooterConfig$FrontendWebsite.iosLinkUrl : FooterConfig$FrontendWebsite.iosWebpageLinkUrl
                      }, React.createElement("img", {
                            className: "icon",
                            src: data.appStoreImage.url,
                            onClick: (function (param) {
                                return GAEvents$FrontendWebsite.publishEventParameter(GAConfigActions.appStoreButtonClicked, {
                                            event_category: GAConfigActions.category,
                                            event_label: EventsHelper$FrontendWebsite.getNumberedEventLabel({
                                                  hd: utm_source,
                                                  tl: {
                                                    hd: utm_campaign,
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            language_selected: EventsHelper$FrontendWebsite.getEventLanguage(language)
                                          });
                              })
                          })))
            });
}

var make = FareEstimateResultAppsDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
