// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as H2TitleCmp$FrontendWebsite from "../../../website/view/components/H2TitleCmp.bs.js";
import * as ServiceableCitiesDesktopCmp$FrontendWebsite from "../../../website/view/components/desktop/ServiceableCitiesDesktopCmp.bs.js";

function VehicleServiceableCitiesDesktopCmp(Props) {
  var serviceableCitiesSectionData = Props.serviceableCitiesSectionData;
  return React.createElement("div", {
              className: "desktop-vehicle-cities-container"
            }, React.createElement(H2TitleCmp$FrontendWebsite.make, {
                  title: $$String.uppercase_ascii(serviceableCitiesSectionData.title),
                  class_name: "desktop-title-heading"
                }), React.createElement(ServiceableCitiesDesktopCmp$FrontendWebsite.make, {
                  serviceableCitiesSectionData: serviceableCitiesSectionData
                }));
}

var make = VehicleServiceableCitiesDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
