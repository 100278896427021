// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Home_UAE$FrontendWebsite from "./Home_UAE.bs.js";
import * as Vehicle_UAE$FrontendWebsite from "./Vehicle_UAE.bs.js";
import * as Navigation_UAE$FrontendWebsite from "./Navigation_UAE.bs.js";
import * as TrackOrder_UAE$FrontendWebsite from "./TrackOrder_UAE.bs.js";

var English = {
  Home: Home_UAE$FrontendWebsite.English,
  TrackOrder: TrackOrder_UAE$FrontendWebsite.English,
  Vehicle: Vehicle_UAE$FrontendWebsite.English,
  Navigation: Navigation_UAE$FrontendWebsite.English
};

export {
  English ,
  
}
/* Home_UAE-FrontendWebsite Not a pure module */
