// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as DeccoUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/DeccoUtils.bs.js";
import * as GeoRegionApiModel$FrontendWebsite from "../api-model/GeoRegionApiModel.bs.js";

function apiModelToEntity(res) {
  return List.map((function (data) {
                return {
                        id: data.id,
                        name: data.name
                      };
              }), res.geo_regions);
}

function execute(json) {
  return apiModelToEntity(DeccoUtils$Catalyst.resolveResult(GeoRegionApiModel$FrontendWebsite.t_decode(json), "GeoRegionEntityMapper.res"));
}

export {
  apiModelToEntity ,
  execute ,
  
}
/* DeccoUtils-Catalyst Not a pure module */
