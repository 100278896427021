// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../Config.bs.js";
import * as VehicleRepo$FrontendWebsite from "./VehicleRepo.bs.js";
import * as VehicleDummyRepo$FrontendWebsite from "./VehicleDummyRepo.bs.js";

function getRepo(param) {
  if (Config$FrontendWebsite.Env.env === "development") {
    return {
            getVehiclePageInfo: VehicleDummyRepo$FrontendWebsite.getVehiclePageInfo,
            getVehiclePageCMSData: VehicleDummyRepo$FrontendWebsite.getVehiclePageCMSData
          };
  } else {
    return VehicleRepo$FrontendWebsite;
  }
}

export {
  getRepo ,
  
}
/* Config-FrontendWebsite Not a pure module */
