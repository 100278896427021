// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as CountryConfig$FrontendWebsite from "../../CountryConfig.bs.js";
import * as GeoApiHandler$FrontendWebsite from "./GeoApiHandler.bs.js";

function getGeoRegionIds(country, setGeoRegions, language) {
  return GeoApiHandler$FrontendWebsite.FetchGeoRegion.execute(Curry.__1(setGeoRegions), (function (_error) {
                var tmp;
                switch (country.TAG | 0) {
                  case /* UAE */0 :
                      tmp = CountryConfig$FrontendWebsite.UAE.getCountryData(undefined).geoRegions;
                      break;
                  case /* BD */1 :
                      tmp = CountryConfig$FrontendWebsite.BD.getCountryData(undefined).geoRegions;
                      break;
                  case /* TR */2 :
                      tmp = CountryConfig$FrontendWebsite.TR.getCountryData(undefined).geoRegions;
                      break;
                  
                }
                return Curry._1(setGeoRegions, tmp);
              }), country, language);
}

export {
  getGeoRegionIds ,
  
}
/* GeoApiHandler-FrontendWebsite Not a pure module */
