// Generated by ReScript, PLEASE EDIT WITH CARE


function city_label(city) {
  return city;
}

var English = {
  home_label: "Home",
  city_label: city_label
};

function city_label$1(city) {
  if (city === "istanbul") {
    return "istanbul";
  } else {
    return "istanbul";
  }
}

var TR_home_label = "Home";

var TR = {
  home_label: TR_home_label,
  city_label: city_label$1
};

export {
  English ,
  TR ,
  
}
/* No side effect */
