// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import RemarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import * as MediaQuery$FrontendWebsite from "../../../../../utils/hooks/MediaQuery.bs.js";

function PolicyItemsContainerCmp(Props) {
  var policyItems = Props.policyItems;
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  return React.createElement("div", {
              className: match[0] ? "policy-desktop-info-container" : "policy-mobile-info-container"
            }, $$Array.map((function (policyItem) {
                    var title = policyItem.title;
                    return React.createElement("div", {
                                className: "pointer"
                              }, title !== undefined ? React.createElement("div", {
                                      className: "heading"
                                    }, title) : null, React.createElement(ReactMarkdown, {
                                    children: policyItem.description,
                                    remarkPlugins: [RemarkGfm]
                                  }));
                  }), policyItems));
}

var make = PolicyItemsContainerCmp;

export {
  make ,
  
}
/* react Not a pure module */
