// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Caml_format from "../../../node_modules/rescript/lib/es6/caml_format.js";
import * as CityRoutes$FrontendWebsite from "./CityRoutes.bs.js";
import * as VehicleRoutes$FrontendWebsite from "./VehicleRoutes.bs.js";
import * as WebsiteConfig$FrontendWebsite from "./WebsiteConfig.bs.js";
import * as TwoWheelerRoutes$FrontendWebsite from "./TwoWheelerRoutes.bs.js";
import * as CityConfigManager$FrontendWebsite from "../city/CityConfigManager.bs.js";
import * as FareEstimateResultRequirement$FrontendWebsite from "../fare-estimate-result/view/FareEstimateResultRequirement.bs.js";

function getCityRoutes(route_path) {
  var city_base_url = "rent-mini-trucks-in-";
  if (!route_path) {
    return ;
  }
  if (route_path.tl) {
    return ;
  }
  var route_data = route_path.hd;
  if (route_data.includes(city_base_url)) {
    return {
            TAG: /* CityPage */0,
            _0: route_data.replace(city_base_url, "")
          };
  }
  
}

function getVehiclesRoutes(route_path, country) {
  if (!route_path) {
    return ;
  }
  var match = route_path.tl;
  var city_route = route_path.hd;
  if (match) {
    if (match.tl) {
      return ;
    }
    var match$1 = CityRoutes$FrontendWebsite.fromRouteString(city_route, country);
    var match$2 = VehicleRoutes$FrontendWebsite.fromRouteString(match.hd, country);
    if (match$1 !== undefined) {
      if (match$2 !== undefined) {
        if (List.exists((function (v) {
                  return Caml_obj.caml_equal(v, VehicleRoutes$FrontendWebsite.getVehicleFromRoutes(match$2));
                }), VehicleRoutes$FrontendWebsite.getAvailableVehicles(CityRoutes$FrontendWebsite.getCityFromRoutes(match$1)))) {
          return {
                  TAG: /* VehiclePage */1,
                  _0: CityRoutes$FrontendWebsite.getCityFromRoutes(match$1),
                  _1: VehicleRoutes$FrontendWebsite.getVehicleFromRoutes(match$2)
                };
        } else {
          return ;
        }
      } else {
        return {
                TAG: /* CityPage */0,
                _0: CityRoutes$FrontendWebsite.getCityFromRoutes(match$1)
              };
      }
    } else {
      return ;
    }
  }
  var route_item = TwoWheelerRoutes$FrontendWebsite.fromRouteString(city_route, country);
  if (route_item === undefined) {
    return ;
  }
  if (typeof route_item === "number") {
    return ;
  }
  if (route_item.TAG !== /* VehiclePage */1) {
    return ;
  }
  var vehicle = route_item._1;
  var city = route_item._0;
  if (List.exists((function (v) {
            return Caml_obj.caml_equal(v, vehicle);
          }), VehicleRoutes$FrontendWebsite.getAvailableVehicles(city))) {
    return {
            TAG: /* VehiclePage */1,
            _0: city,
            _1: vehicle
          };
  }
  
}

function getPageRoutes(li) {
  return List.fold_left((function (acc, func) {
                if (acc !== undefined) {
                  return acc;
                } else {
                  return Curry._1(func, undefined);
                }
              }), undefined, li);
}

function getFareEstimateFormData(geo_region_id, from_address_landmark, to_address_landmark, from_address_lat, from_address_lng, to_address_lat, to_address_lng, customer_name, customer_mobile, frequency, fare_estimate_token, consent_for_callback) {
  if (geo_region_id === undefined) {
    return ;
  }
  if (from_address_landmark === undefined) {
    return ;
  }
  if (to_address_landmark === undefined) {
    return ;
  }
  if (from_address_lat === undefined) {
    return ;
  }
  if (from_address_lng === undefined) {
    return ;
  }
  if (to_address_lat === undefined) {
    return ;
  }
  if (to_address_lng === undefined) {
    return ;
  }
  if (customer_name === undefined) {
    return ;
  }
  if (customer_mobile === undefined) {
    return ;
  }
  if (frequency === undefined) {
    return ;
  }
  var tmp;
  if (consent_for_callback !== undefined) {
    switch (consent_for_callback) {
      case "false" :
          tmp = false;
          break;
      case "true" :
          tmp = true;
          break;
      default:
        tmp = undefined;
    }
  } else {
    tmp = undefined;
  }
  return {
          name: customer_name,
          mobile: customer_mobile,
          city: geo_region_id,
          requirement: FareEstimateResultRequirement$FrontendWebsite.fromString(window.decodeURIComponent(frequency)),
          pickup_address: {
            landmark: window.decodeURIComponent(from_address_landmark),
            location: {
              lat: Caml_format.caml_float_of_string(from_address_lat),
              lng: Caml_format.caml_float_of_string(from_address_lng)
            }
          },
          drop_address: {
            landmark: window.decodeURIComponent(to_address_landmark),
            location: {
              lat: Caml_format.caml_float_of_string(to_address_lat),
              lng: Caml_format.caml_float_of_string(to_address_lng)
            }
          },
          fare_estimate_token: fare_estimate_token,
          consent_for_callback: tmp
        };
}

function getHelmetTags(websiteRoute, country) {
  if (typeof websiteRoute === "number") {
    switch (websiteRoute) {
      case /* DefaultScreen */0 :
          return WebsiteConfig$FrontendWebsite.headTags_404;
      case /* Home */1 :
          return WebsiteConfig$FrontendWebsite.headTags_Home(country);
      case /* DriverPartners */2 :
          return WebsiteConfig$FrontendWebsite.headTags_DriverPartners;
      case /* AboutUs */3 :
          return WebsiteConfig$FrontendWebsite.headTags_AboutUs(country);
      case /* TermsOfService */4 :
          return WebsiteConfig$FrontendWebsite.headTags_TermsOfService;
      case /* TermsOfServiceDubaiBranch */5 :
          return WebsiteConfig$FrontendWebsite.headTags_termsOfServiceDubaiBranch;
      case /* PartnerTermsOfService */6 :
          return WebsiteConfig$FrontendWebsite.headTags_PartnerTermsOfService;
      case /* RefundPolicy */7 :
          return WebsiteConfig$FrontendWebsite.headTags_RefundPolicy;
      case /* CookiePolicy */8 :
          return WebsiteConfig$FrontendWebsite.headTags_CookiePolicy;
      case /* SpotFaq */9 :
          return WebsiteConfig$FrontendWebsite.headTags_spotFaq;
      case /* Support */10 :
          return WebsiteConfig$FrontendWebsite.headTags_support;
      case /* ServicesPage */11 :
          return WebsiteConfig$FrontendWebsite.headTags_services;
      case /* CourierPage */12 :
          return WebsiteConfig$FrontendWebsite.headTags_courier;
      
    }
  } else {
    switch (websiteRoute.TAG | 0) {
      case /* CityPage */0 :
          var CityConfig = CityConfigManager$FrontendWebsite.getConfig(websiteRoute._0, country);
          return Curry._1(CityConfig.MetaTags.getCityHeadTags, undefined);
      case /* VehiclePage */1 :
          var CityConfig$1 = CityConfigManager$FrontendWebsite.getConfig(websiteRoute._0, country);
          return Curry._1(CityConfig$1.MetaTags.getVehicleHeadTags, websiteRoute._1);
      case /* TrackLiveTrip */2 :
          return WebsiteConfig$FrontendWebsite.headTags_TrackLiveTrip(country);
      case /* PrivacyPolicy */3 :
          return WebsiteConfig$FrontendWebsite.headTags_PrivacyPolicy;
      case /* FareEstimateResult */4 :
          return WebsiteConfig$FrontendWebsite.headTags_fareEstimateResult(country);
      case /* OffersPage */5 :
          return WebsiteConfig$FrontendWebsite.headTags_offers;
      case /* KeywordPage */6 :
          return WebsiteConfig$FrontendWebsite.headTags_services;
      
    }
  }
}

export {
  getCityRoutes ,
  getVehiclesRoutes ,
  getPageRoutes ,
  getFareEstimateFormData ,
  getHelmetTags ,
  
}
/* WebsiteConfig-FrontendWebsite Not a pure module */
