// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as TrackLiveTripHeaderTitleVMMapper$FrontendWebsite from "./TrackLiveTripHeaderTitleVMMapper.bs.js";

function VM(Content) {
  var TrackLiveTripHeaderTitleVM = TrackLiveTripHeaderTitleVMMapper$FrontendWebsite.VM(Content);
  var getVM = function (orderDetail) {
    var address = orderDetail.dropLocation;
    var match = orderDetail.status;
    var customerInfo = orderDetail.customerInfo;
    var tmp;
    if (customerInfo !== undefined) {
      var mobile = customerInfo.number;
      var tmp$1;
      if (mobile !== undefined) {
        var match$1 = orderDetail.status;
        tmp$1 = match$1 !== 7 ? (
            match$1 >= 4 ? "" : mobile
          ) : (
            Curry._1(TrackLiveTripHeaderTitleVM.showSensitiveInfo, orderDetail) ? mobile : ""
          );
      } else {
        tmp$1 = "";
      }
      tmp = {
        name: Belt_Option.getWithDefault(customerInfo.name, ""),
        mobile: tmp$1
      };
    } else {
      tmp = {
        name: "",
        mobile: ""
      };
    }
    return {
            status: orderDetail.status,
            pickupAddress: orderDetail.pickupLocation.landmark,
            dropAddress: address !== undefined ? address.landmark : undefined,
            waypointAddresses: List.map((function (loc) {
                    return loc.landmark;
                  }), orderDetail.waypointLocations),
            crn: orderDetail.crn,
            hasWaypoints: orderDetail.hasWaypoints && !orderDetail.isRental,
            isRental: orderDetail.isRental,
            customerSupportContact: match !== 7 ? (
                match >= 4 ? "" : orderDetail.customerSupportContact
              ) : (
                Curry._1(TrackLiveTripHeaderTitleVM.showSensitiveInfo, orderDetail) ? orderDetail.customerSupportContact : ""
              ),
            customerInfo: tmp
          };
  };
  return {
          TrackLiveTripHeaderTitleVM: TrackLiveTripHeaderTitleVM,
          getVM: getVM
        };
}

export {
  VM ,
  
}
/* No side effect */
