// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as WebsiteCMSRepoManager$FrontendWebsite from "../repo/WebsiteCMSRepoManager.bs.js";

function execute(onSuccess, onFaliure, country, language) {
  var RepoInstance = WebsiteCMSRepoManager$FrontendWebsite.getRepo(undefined);
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return Curry._2(RepoInstance.getHeaderFooterCMSData, country, language);
              }), Curry.__1(onSuccess), Curry.__1(onFaliure));
}

var FetchHeaderFooterCMS = {
  execute: execute
};

export {
  FetchHeaderFooterCMS ,
  
}
/* WebsiteCMSRepoManager-FrontendWebsite Not a pure module */
