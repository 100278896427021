// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../utils/context/app-contexts/AppContext.bs.js";
import * as ContentManager$FrontendWebsite from "../../utils/languang_and_content/ContentManager.bs.js";
import * as FooterMobileCmp$FrontendWebsite from "./components/mobile/FooterMobileCmp.bs.js";
import * as FooterDesktopCmp$FrontendWebsite from "./components/desktop/FooterDesktopCmp.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../website/view/components/ResponsiveLayout.bs.js";

function Footer(Props) {
  var cmsData = Props.cmsData;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var companyCopyrightInfo_newEntityName = Content.Home.newCompanyCopyrightText;
  var companyCopyrightInfo_newEntityCIN = Content.Home.newCompanyCinNo;
  var companyCopyrightInfo_oldEntityName = Content.Home.companyCopyrightText;
  var companyCopyrightInfo_oldEntityCIN = Content.Home.companyCinNo;
  var companyCopyrightInfo = {
    newEntityName: companyCopyrightInfo_newEntityName,
    newEntityCIN: companyCopyrightInfo_newEntityCIN,
    oldEntityName: companyCopyrightInfo_oldEntityName,
    oldEntityCIN: companyCopyrightInfo_oldEntityCIN
  };
  return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
              desktop_view: React.createElement(FooterDesktopCmp$FrontendWebsite.make, {
                    data: cmsData,
                    companyCopyrightInfo: companyCopyrightInfo
                  }),
              mobile_view: React.createElement(FooterMobileCmp$FrontendWebsite.make, {
                    data: cmsData,
                    companyCopyrightInfo: companyCopyrightInfo
                  })
            });
}

var make = Footer;

export {
  make ,
  
}
/* react Not a pure module */
