// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactLazy$FrontendWebsite from "../../../../../utils/library/react-lazy/ReactLazy.bs.js";

var SupportDesktopCmpLazyWrapper = {};

var Lazy = ReactLazy$FrontendWebsite.MakeLazy(SupportDesktopCmpLazyWrapper);

function SupportDesktopCmpLazyWrapper$1(Props) {
  var data = Props.data;
  var LazyComp = Curry._2(Lazy.make, (function (param) {
          return import("./SupportDesktopCmp.bs.js");
        }), (function (prim0, prim1, prim2) {
          var tmp = {
            data: prim0
          };
          if (prim1 !== undefined) {
            tmp.key = prim1;
          }
          return tmp;
        }));
  return React.createElement(LazyComp.make, {
              data: data
            });
}

var make = SupportDesktopCmpLazyWrapper$1;

export {
  SupportDesktopCmpLazyWrapper ,
  Lazy ,
  make ,
  
}
/* Lazy Not a pure module */
