// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../website/types/WebsiteTypes.bs.js";
import * as ContentHelper$FrontendWebsite from "../../../utils/helper/ContentHelper.bs.js";
import * as WebsiteConfig$FrontendWebsite from "../../routing/WebsiteConfig.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";
import * as InternalLinkCmp$FrontendWebsite from "../../website/view/components/InternalLinkCmp.bs.js";
import * as WebsiteRouteConfig$FrontendWebsite from "../../routing/WebsiteRouteConfig.bs.js";

function NavBarLinkCmp(Props) {
  var navbar_links = Props.navbar_links;
  var capsOpt = Props.caps;
  var caps = capsOpt !== undefined ? capsOpt : true;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var match = React.useState(function () {
        return /* DefaultScreen */0;
      });
  var setWebsite = match[1];
  var website = match[0];
  var tabMapper = function (param) {
    var getTitle = function (data) {
      var t_ = ContentHelper$FrontendWebsite.getContent(data);
      return $$String.lowercase_ascii(WebsiteTypes$FrontendWebsite.titleToString(t_.text));
    };
    if (typeof website === "number") {
      switch (website) {
        case /* DefaultScreen */0 :
            return "404";
        case /* Home */1 :
            return getTitle(WebsiteConfig$FrontendWebsite.cityTempoInfo(Content.Home.navbar_title_1));
        case /* DriverPartners */2 :
            return getTitle(WebsiteConfig$FrontendWebsite.driverPartnersInfo(Content.Home.navbar_title_2));
        case /* TermsOfService */4 :
            return getTitle(WebsiteConfig$FrontendWebsite.termsOfServiceInfo(Content.Home.terms_of_service_link_text, Content.Home.terms_of_service_url));
        case /* RefundPolicy */7 :
            return getTitle(WebsiteConfig$FrontendWebsite.refundPolicyInfo(Content.Home.refund_policy_link_text));
        default:
          return "NA";
      }
    } else {
      switch (website.TAG | 0) {
        case /* CityPage */0 :
            return "City Page";
        case /* VehiclePage */1 :
            return "Vehicle Page";
        case /* PrivacyPolicy */3 :
            if (website._0 !== undefined) {
              return getTitle(WebsiteConfig$FrontendWebsite.privacyPolicyInfo(Content.Home.privacy_policy_link_text));
            } else {
              return "NA";
            }
        default:
          return "NA";
      }
    }
  };
  React.useEffect((function () {
          var initial_route = WebsiteRouteConfig$FrontendWebsite.getRoute(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined), country);
          if (initial_route !== undefined) {
            Curry._1(setWebsite, (function (param) {
                    return initial_route;
                  }));
          }
          var watcherID = RescriptReactRouter.watchUrl(function (url) {
                var route = WebsiteRouteConfig$FrontendWebsite.getRoute(url, country);
                if (route !== undefined) {
                  return Curry._1(setWebsite, (function (param) {
                                return route;
                              }));
                }
                
              });
          return (function (param) {
                    return RescriptReactRouter.unwatchUrl(watcherID);
                  });
        }), []);
  var get_active_class = function (texti) {
    var tab_text = $$String.lowercase_ascii(WebsiteTypes$FrontendWebsite.titleToString(texti));
    if (tab_text === tabMapper(undefined)) {
      return "nav-link__active";
    } else {
      return "";
    }
  };
  return $$Array.map((function (linkLable) {
                if (linkLable.TAG === /* NoURL */0) {
                  return null;
                }
                var url = linkLable._1;
                var label = linkLable._0;
                return React.createElement(InternalLinkCmp$FrontendWebsite.make, {
                            className: "nav-link " + get_active_class(label),
                            children: caps ? $$String.uppercase_ascii(label) : label,
                            on_click: (function (param) {
                                return RescriptReactRouter.push(url);
                              }),
                            key: label
                          });
              }), navbar_links);
}

var make = NavBarLinkCmp;

export {
  make ,
  
}
/* react Not a pure module */
