// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../../app/Config.bs.js";

var baseRouterUrl = Config$FrontendWebsite.Env.getFEUrl(undefined);

var personal = "personal";

var business = "business";

function getRequirementStrings(str) {
  switch (str) {
    case "Business" :
        return business;
    case "Personal" :
        return personal;
    default:
      return str;
  }
}

var Select = {
  personal: personal,
  business: business,
  getRequirementStrings: getRequirementStrings
};

var terms_of_service_url = baseRouterUrl + "/terms-of-service";

var English = {
  Select: Select,
  heading: "Largest Marketplace for Intracity Logistics",
  select_city_label: "Select City",
  driverPartnerHeading: "DRIVE WITH US!!",
  driverPartnerHeadingDesc: "turn kms into money",
  driverPartnerJoinUsTitle: "JOIN US",
  navbar_title_1: "City Tempo",
  navbar_title_2: "Driver Partners",
  form_heading_1: "City",
  form_heading_2: "Pick Up Address",
  form_heading_3: "DropOff Address",
  form_heading_4: "Mobile Number",
  form_heading_5: "Enter Mobile Number",
  form_heading_6: "Name(Optional)",
  form_heading_7: "Requirement",
  form_button_title: "Get Instant Quote Now",
  form_consent_box_title: "By checking this, you give consent to Porter to reach out to you via phone/WhatsApp to help with booking, etc",
  about_us_link_text: "About Us",
  privacy_policy_link_text: "Privacy Policy",
  terms_of_service_link_text: "Terms of Service",
  terms_of_service_url: terms_of_service_url,
  refund_policy_link_text: "",
  download_porter_button_label: "Download Porter",
  pickup_address_placeholder: "Enter PickUp Address",
  dropoff_address_placeholder: "Enter DropOff Address",
  get_estimate_title: "Get Trip Estimate",
  footer_title: "BOOK NOW",
  optional_placeholder: "Optional",
  companyCopyrightText: "2023 Porter Technologies DMCC.",
  companyCinNo: "CIN: DMCC192571",
  newCompanyCopyrightText: "2024 Porter Technologies DMCC Dubai Branch.",
  newCompanyCinNo: "CIN: 1297279"
};

export {
  baseRouterUrl ,
  English ,
  
}
/* baseRouterUrl Not a pure module */
