// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as MediaVMMapper$FrontendWebsite from "../../website/cms/view-model/MediaVMMapper.bs.js";
import * as AppDownloadVMMapper$FrontendWebsite from "../../website/cms/view-model/AppDownloadVMMapper.bs.js";
import * as ServiceableCitiesVMMapper$FrontendWebsite from "../../website/cms/view-model/ServiceableCitiesVMMapper.bs.js";

function getCityData(city) {
  return {
          cityName: city.data.attributes.cityName
        };
}

function getVehicleData(attributes) {
  return {
          title: attributes.title,
          slug: attributes.slug,
          description: attributes.description,
          vehicleName: attributes.vehicleName,
          estimateButtonLabel: attributes.estimateButtonLabel,
          url: attributes.url,
          vehicleType: attributes.vehicleType,
          vehicleBanner: MediaVMMapper$FrontendWebsite.getBanner(attributes.vehicleBanner.data)
        };
}

function getOtherVehicleData(otherVehiclesInfo) {
  return {
          title: otherVehiclesInfo.title,
          vehicles: $$Array.map((function (v) {
                  return {
                          title: v.attributes.title,
                          description: v.attributes.description,
                          vehicleName: v.attributes.vehicleName,
                          url: v.attributes.url,
                          vehicleType: v.attributes.vehicleType,
                          vehicleBanner: MediaVMMapper$FrontendWebsite.getBanner(v.attributes.vehicleBanner.data)
                        };
                }), otherVehiclesInfo.vehicles.data)
        };
}

function getCouponBanner(banner) {
  var alt = banner.data.attributes.alternativeText;
  return {
          path: banner.data.attributes.url,
          alt_text: alt !== undefined ? alt : ""
        };
}

function getPorterOfferData(porterOffers) {
  return {
          title: porterOffers.title,
          coupons: List.map((function (coupon) {
                  return {
                          banner_img: getCouponBanner(coupon.bannerImg),
                          coupon_info: coupon.couponInfo,
                          coupon_code: coupon.couponCode,
                          coupon_validity_text: coupon.couponValidityText,
                          details_link: ""
                        };
                }), porterOffers.coupons)
        };
}

function cmsVM(cmsData) {
  var attributes = Caml_array.get(cmsData.data, 0).attributes;
  return {
          vehicleData: getVehicleData(attributes),
          cityData: getCityData(attributes.city),
          vehicleServiceData: attributes.vehicleInfo,
          otherVehiclesData: getOtherVehicleData(attributes.otherVehicles),
          porterOffers: getPorterOfferData(attributes.porterOffers),
          serviceableCities: ServiceableCitiesVMMapper$FrontendWebsite.getServiceableCities(attributes.serviceableCities),
          appDownload: AppDownloadVMMapper$FrontendWebsite.getAppDownloadData(attributes.appDownload)
        };
}

export {
  getCityData ,
  getVehicleData ,
  getOtherVehicleData ,
  getCouponBanner ,
  getPorterOfferData ,
  cmsVM ,
  
}
/* No side effect */
