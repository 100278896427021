// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as CircularLoader$FrontendWebsite from "../../website/view/components/CircularLoader.bs.js";
import * as FareEstimateDM$FrontendWebsite from "../data-machine/FareEstimateDM.bs.js";
import * as ResponsiveLayout$FrontendWebsite from "../../website/view/components/ResponsiveLayout.bs.js";
import * as FareEstimateCMSSM$FrontendWebsite from "../state-machine/FareEstimateCMSSM.bs.js";
import * as FareEstimateCMSVM$FrontendWebsite from "../view-model/FareEstimateCMSVM.bs.js";
import * as FareEstimateResultMobileCmp$FrontendWebsite from "./components/mobile/FareEstimateResultMobileCmp.bs.js";
import * as FareEstimateResultDesktopCmp$FrontendWebsite from "./components/desktop/FareEstimateResultDesktopCmp.bs.js";

function FareEstimateResultCmp(Props) {
  var form_data = Props.form_data;
  var match = AppContext$FrontendWebsite.useAppContext(undefined);
  var countryConfig = match.countryConfig;
  var language = match.language;
  var match$1 = React.useReducer(FareEstimateCMSSM$FrontendWebsite.StateMachine.machine, /* Init */0);
  var dispatch = match$1[1];
  var state = match$1[0];
  React.useEffect((function () {
          FareEstimateDM$FrontendWebsite.FetchFareEstimateCMS.execute((function (data) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchFareEstimateCMSData */0,
                              _0: data
                            });
                }), (function (error) {
                  return Curry._1(dispatch, {
                              TAG: /* FetchFareEstimateCMSDataFailed */1,
                              _0: error
                            });
                }), countryConfig, language);
          
        }), []);
  if (typeof state === "number") {
    return React.createElement(CircularLoader$FrontendWebsite.make, {});
  }
  if (state.TAG === /* Data */0) {
    var cmsVm = FareEstimateCMSVM$FrontendWebsite.getVM(state._0);
    return React.createElement(ResponsiveLayout$FrontendWebsite.make, {
                desktop_view: React.createElement(React.Suspense, {
                      children: React.createElement(FareEstimateResultDesktopCmp$FrontendWebsite.make, {
                            form_data: form_data,
                            cmsVm: cmsVm
                          }),
                      fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                    }),
                mobile_view: React.createElement(React.Suspense, {
                      children: React.createElement(FareEstimateResultMobileCmp$FrontendWebsite.make, {
                            form_data: form_data,
                            cmsVm: cmsVm
                          }),
                      fallback: React.createElement(CircularLoader$FrontendWebsite.make, {})
                    })
              });
  }
  console.log(state._0);
  return React.createElement("div", undefined, "Something Went wrong");
}

var make = FareEstimateResultCmp;

export {
  make ,
  
}
/* react Not a pure module */
