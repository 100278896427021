// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../utils/context/app-contexts/AppContext.bs.js";
import * as RoutesConfig$FrontendWebsite from "../../routing/RoutesConfig.bs.js";
import * as AssetsManager$FrontendWebsite from "../../../view/assets/AssetsManager.bs.js";
import * as ContentManager$FrontendWebsite from "../../../utils/languang_and_content/ContentManager.bs.js";

function BDCopyrightCmp(Props) {
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  return React.createElement("div", {
              className: "bd-copyight-container"
            }, React.createElement("div", {
                  className: "bd-copyright"
                }, React.createElement("img", {
                      className: "icon",
                      src: AssetsManager$FrontendWebsite.getCommonImage("favicon.svg")
                    }), React.createElement("div", {
                      className: "info"
                    }, React.createElement("div", {
                          className: "name"
                        }, Content.Home.companyCopyrightText), React.createElement("div", {
                          className: "cin"
                        }, Content.Home.companyCinNo))), React.createElement("a", {
                  className: "link-text",
                  href: Content.Home.terms_of_service_url
                }, React.createElement("span", undefined, Content.Home.terms_of_service_link_text)), React.createElement("a", {
                  className: "link-text",
                  href: RoutesConfig$FrontendWebsite.refundPolicyUrl
                }, React.createElement("span", undefined, Content.Home.refund_policy_link_text)));
}

var make = BDCopyrightCmp;

export {
  make ,
  
}
/* react Not a pure module */
