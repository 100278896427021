// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FareEstimateResultBookingStepDesktopCmp(Props) {
  var step = Props.step;
  var heading = Props.heading;
  var children = Props.children;
  return React.createElement("div", {
              className: "fare-estimate-result-desktop-booking-step-container"
            }, React.createElement("div", {
                  className: "point"
                }, String(step) + " :"), React.createElement("div", {
                  className: "booking-info"
                }, React.createElement("div", {
                      className: "heading"
                    }, heading), children));
}

var make = FareEstimateResultBookingStepDesktopCmp;

export {
  make ,
  
}
/* react Not a pure module */
