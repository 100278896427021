// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function HeaderFallBackCmp(Props) {
  return React.createElement("div", {
              className: "fallback__header"
            }, React.createElement("div", {
                  className: "top "
                }, React.createElement("div", {
                      className: "loading-shimmer slide"
                    })), React.createElement("div", {
                  className: "bottom"
                }, React.createElement("div", {
                      className: "container"
                    }, React.createElement("div", {
                          className: "loading-shimmer slide"
                        }), React.createElement("div", {
                          className: "loading-shimmer slide"
                        })), React.createElement("div", {
                      className: "container"
                    }, React.createElement("div", {
                          className: "loading-shimmer slide"
                        }), React.createElement("div", {
                          className: "loading-shimmer slide"
                        }))));
}

var make = HeaderFallBackCmp;

export {
  make ,
  
}
/* react Not a pure module */
