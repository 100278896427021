// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MediaVMMapper$FrontendWebsite from "./MediaVMMapper.bs.js";

function getAppDownloadData(appDownload) {
  return {
          title: appDownload.title,
          googlePlayStoreBanner: MediaVMMapper$FrontendWebsite.getBanner(appDownload.googlePlayStoreBanner.data),
          appStoreBanner: MediaVMMapper$FrontendWebsite.getBanner(appDownload.appStoreBanner.data),
          googlePlayStoreUrl: appDownload.googlePlayStoreUrl,
          appStoreUrl: appDownload.appStoreUrl
        };
}

export {
  getAppDownloadData ,
  
}
/* No side effect */
