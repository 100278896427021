// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as NetworkUtils$FrontendWebsite from "../../../../utils/NetworkUtils.bs.js";
import * as GeoRegionRepoConfig$FrontendWebsite from "./GeoRegionRepoConfig.bs.js";

var dummyGeoRegions = {
    "geo_regions": [
      {
          "id": 2,
          "name": "Sharjah",
          "timezone": "Asia/Dubai"
      },
      {
          "id": 3,
          "name": "Dhaka",
          "timezone": "Asia/Dhaka"
      }
    ]
  };

function fetchGeoRegions(_country, _language) {
  return NetworkUtils$FrontendWebsite.catchPromiseError(new Promise((function (resolve, param) {
                      return resolve({
                                  TAG: /* Success */0,
                                  _0: dummyGeoRegions
                                });
                    })).then(function (result) {
                  return ApiUtils$Catalyst.resolveRepoResponse(function (param) {
                              return Curry._1(GeoRegionRepoConfig$FrontendWebsite.GeoRegionResponseConverter.execute, result);
                            });
                }), "fetchGeoRegions");
}

export {
  dummyGeoRegions ,
  fetchGeoRegions ,
  
}
/* NetworkUtils-FrontendWebsite Not a pure module */
