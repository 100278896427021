// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ErrorUtils.bs.js";

function fromString(status) {
  switch (status) {
    case "accepted" :
        return /* Accepted */2;
    case "allocated" :
        return /* Allocated */1;
    case "cancelled" :
        return /* Cancelled */8;
    case "completed" :
        return /* Completed */7;
    case "ended" :
        return /* Ended */6;
    case "live" :
        return /* Live */3;
    case "loading" :
        return /* Loading */4;
    case "open" :
        return /* Open */0;
    case "rescheduled" :
        return /* Rescheduled */9;
    case "unloading" :
        return /* Unloading */5;
    default:
      return ErrorUtils$Catalyst.raiseError("TripStatus.re", "Invalid order status", status);
  }
}

function toString(status) {
  switch (status) {
    case /* Open */0 :
        return "open";
    case /* Allocated */1 :
        return "allocated";
    case /* Accepted */2 :
        return "accepted";
    case /* Live */3 :
        return "live";
    case /* Loading */4 :
        return "loading";
    case /* Unloading */5 :
        return "unloading";
    case /* Ended */6 :
        return "ended";
    case /* Completed */7 :
        return "completed";
    case /* Cancelled */8 :
        return "cancelled";
    case /* Rescheduled */9 :
        return "rescheduled";
    
  }
}

export {
  fromString ,
  toString ,
  
}
/* No side effect */
