// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var status = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
  if (status.TAG === /* Ok */0) {
    var response = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "response"), null));
    if (response.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                status: status._0,
                response: response._0
              }
            };
    }
    var e = response._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".response" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = status._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".status" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  t_decode ,
  
}
/* No side effect */
