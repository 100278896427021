// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as H2TitleCmp$FrontendWebsite from "./H2TitleCmp.bs.js";
import * as MediaQuery$FrontendWebsite from "../../../../utils/hooks/MediaQuery.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../../types/WebsiteTypes.bs.js";
import * as CouponCardCmp$FrontendWebsite from "./CouponCardCmp.bs.js";
import * as ContentManager$FrontendWebsite from "../../../../utils/languang_and_content/ContentManager.bs.js";

function CouponOffersCmp(Props) {
  var coupons = Props.coupons;
  var title = Props.title;
  var language = AppContext$FrontendWebsite.useAppContext(undefined).language;
  var Content = ContentManager$FrontendWebsite.getContent(language);
  var match = React.useState(function () {
        return MediaQuery$FrontendWebsite.useDeviceLayoutDetector(undefined);
      });
  var isDesktop = match[0];
  var getEmptyCouponsCmp = function (param) {
    return React.createElement("div", {
                className: isDesktop ? "coupon-default-info" : "coupon-default-info coupon-mobile"
              }, Content.Vehicle.porter_offers_sub_title);
  };
  var tmp;
  if (coupons !== undefined) {
    var match$1 = List.length(coupons);
    tmp = React.createElement("div", {
          className: "coupons-wrapper"
        }, match$1 !== 0 ? $$Array.of_list(List.mapi((function (index, item) {
                      return React.createElement("div", {
                                  className: "coupon-card"
                                }, React.createElement(CouponCardCmp$FrontendWebsite.make, {
                                      banner_img: item.banner_img,
                                      validity: item.coupon_validity_text,
                                      link: WebsiteTypes$FrontendWebsite.urltoString(item.details_link),
                                      coupon_code: item.coupon_code,
                                      coupon_info: item.coupon_info,
                                      key: String(index)
                                    }));
                    }), coupons)) : getEmptyCouponsCmp(undefined));
  } else {
    tmp = getEmptyCouponsCmp(undefined);
  }
  return React.createElement("div", {
              className: "coupon-common-offers-container"
            }, React.createElement(H2TitleCmp$FrontendWebsite.make, {
                  title: title
                }), tmp);
}

var make = CouponOffersCmp;

export {
  make ,
  
}
/* react Not a pure module */
