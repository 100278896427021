// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../Config.bs.js";
import * as WebsiteTypes$FrontendWebsite from "../website/types/WebsiteTypes.bs.js";
import * as AssetsManager$FrontendWebsite from "../../view/assets/AssetsManager.bs.js";

var defaultBannerDestUrl = Config$FrontendWebsite.Env.getFEUrl(undefined) + "/app";

var phoneSkin = AssetsManager$FrontendWebsite.getHomeImage("phone_skin.png");

var arrowImg = AssetsManager$FrontendWebsite.getHomeImage("arrow-icons.svg");

var bike = AssetsManager$FrontendWebsite.getHomeImage("bike.jpg");

var canter = AssetsManager$FrontendWebsite.getHomeImage("canter.jpg");

var downArrow = {
  TAG: /* InfoIconWithStyle */3,
  _0: {
    src_path: WebsiteTypes$FrontendWebsite.createAssetPath(arrowImg),
    text: WebsiteTypes$FrontendWebsite.createTitle(""),
    class_name: WebsiteTypes$FrontendWebsite.createAssetPath("drop-arrow")
  }
};

var pixelImageSrc = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

var defaultAltText = "";

export {
  defaultBannerDestUrl ,
  pixelImageSrc ,
  phoneSkin ,
  arrowImg ,
  bike ,
  canter ,
  downArrow ,
  defaultAltText ,
  
}
/* defaultBannerDestUrl Not a pure module */
