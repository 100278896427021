// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ApiUtils$Catalyst from "../../../../node_modules/@porterin/catalyst/src/utils/ApiUtils.bs.js";
import * as CampaignsRepoManager$FrontendWebsite from "../repo/CampaignsRepoManager.bs.js";

function execute(token, onSuccess, onFaliure, country) {
  var RepoInstance = CampaignsRepoManager$FrontendWebsite.getRepo(undefined);
  return ApiUtils$Catalyst.handleAPIRequest((function (param) {
                return Curry._2(RepoInstance.fetchOffers, token, country);
              }), Curry.__1(onSuccess), Curry.__1(onFaliure));
}

var FetchDiscountCoupon = {
  execute: execute
};

export {
  FetchDiscountCoupon ,
  
}
/* CampaignsRepoManager-FrontendWebsite Not a pure module */
