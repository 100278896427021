// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ResponseHandler$Catalyst from "../../../../../node_modules/@porterin/catalyst/src/helper-modules/response-handler/ResponseHandler.bs.js";
import * as GeoRegionEntityMapper$FrontendWebsite from "../entity-mapper/GeoRegionEntityMapper.bs.js";

var execute = GeoRegionEntityMapper$FrontendWebsite.execute;

var GeoConfig = {
  execute: execute
};

var partial_arg = ResponseHandler$Catalyst.ResponseConverter;

var GeoRegionResponseConverter = partial_arg(GeoConfig, ResponseHandler$Catalyst.DefaultErrorConverter);

export {
  GeoConfig ,
  GeoRegionResponseConverter ,
  
}
/* GeoRegionResponseConverter Not a pure module */
