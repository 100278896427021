// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PicturesCmp(Props) {
  var class_name = Props.class_name;
  var img_src = Props.img_src;
  var webp_src = Props.webp_src;
  var img_typeOpt = Props.img_type;
  var alt_textOpt = Props.alt_text;
  var height = Props.height;
  var width = Props.width;
  var img_type = img_typeOpt !== undefined ? img_typeOpt : "image/png";
  var alt_text = alt_textOpt !== undefined ? alt_textOpt : "img";
  return React.createElement("picture", undefined, React.createElement("source", {
                  className: class_name,
                  srcSet: webp_src,
                  type: "image/webp"
                }), React.createElement("source", {
                  className: class_name,
                  srcSet: img_src,
                  type: img_type
                }), React.createElement("img", {
                  className: class_name,
                  style: {
                    height: height,
                    width: width
                  },
                  alt: alt_text,
                  src: img_src
                }));
}

var make = PicturesCmp;

export {
  make ,
  
}
/* react Not a pure module */
