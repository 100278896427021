// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext$FrontendWebsite from "../../../../utils/context/app-contexts/AppContext.bs.js";
import * as AppDownloadCmp$FrontendWebsite from "../../../website/view/components/AppDownloadCmp.bs.js";
import * as SocialMediaCmp$FrontendWebsite from "../../../website/view/components/SocialMediaCmp.bs.js";
import * as FooterListColumnCmp$FrontendWebsite from "../FooterListColumnCmp.bs.js";

function FooterMobileCmp(Props) {
  var data = Props.data;
  var companyCopyrightInfo = Props.companyCopyrightInfo;
  var country = AppContext$FrontendWebsite.useAppContext(undefined).countryConfig;
  var tmp;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp = React.createElement(SocialMediaCmp$FrontendWebsite.make, {
              data: data.socialMediaLinksLabel,
              target: "_blank"
            });
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp = null;
        break;
    
  }
  var tmp$1;
  switch (country.TAG | 0) {
    case /* UAE */0 :
        tmp$1 = React.createElement("div", {
              className: "company-item"
            }, React.createElement("div", {
                  className: ""
                }, companyCopyrightInfo.newEntityName), React.createElement("div", {
                  className: "vertical-seperator"
                }), React.createElement("div", {
                  className: ""
                }, companyCopyrightInfo.newEntityCIN));
        break;
    case /* BD */1 :
    case /* TR */2 :
        tmp$1 = null;
        break;
    
  }
  return React.createElement("div", {
              className: "mobile-footer-container",
              id: "mobile-footer-content"
            }, React.createElement("div", {
                  className: "footer-content-wrapper"
                }, React.createElement(AppDownloadCmp$FrontendWebsite.make, {
                      appLinks: data.appLinks
                    }), React.createElement("div", {
                      className: "footer-primary"
                    }, React.createElement(FooterListColumnCmp$FrontendWebsite.make, {
                          data: data.companyLinksLabel,
                          class_name: "companies-links"
                        }), React.createElement(FooterListColumnCmp$FrontendWebsite.make, {
                          data: data.supportLinksLabel,
                          class_name: "companies-links"
                        })), React.createElement(FooterListColumnCmp$FrontendWebsite.make, {
                      data: data.cityLinkLabel,
                      class_name: "cities-truck-links"
                    }), React.createElement("div", {
                      className: "footer-tertiary"
                    }, React.createElement("div", {
                          className: "social-media"
                        }, tmp), React.createElement("div", {
                          className: "company-info"
                        }, React.createElement("div", {
                              className: "company-item"
                            }, React.createElement("div", {
                                  className: ""
                                }, companyCopyrightInfo.oldEntityName), React.createElement("div", {
                                  className: "vertical-seperator"
                                }), React.createElement("div", {
                                  className: ""
                                }, companyCopyrightInfo.oldEntityCIN)), tmp$1))));
}

var make = FooterMobileCmp;

export {
  make ,
  
}
/* react Not a pure module */
