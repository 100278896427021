// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../node_modules/decco/src/Decco.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function bannerFormat_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
  if (url.TAG === /* Ok */0) {
    var width = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "width"), null));
    if (width.TAG === /* Ok */0) {
      var height = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "height"), null));
      if (height.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  url: url._0,
                  width: width._0,
                  height: height._0
                }
              };
      }
      var e = height._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".height" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = width._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".width" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = url._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".url" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bannerFormats_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var small = Decco.optionFromJson(bannerFormat_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "small"), null));
  if (small.TAG === /* Ok */0) {
    var medium = Decco.optionFromJson(bannerFormat_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "medium"), null));
    if (medium.TAG === /* Ok */0) {
      var large = Decco.optionFromJson(bannerFormat_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "large"), null));
      if (large.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  small: small._0,
                  medium: medium._0,
                  large: large._0
                }
              };
      }
      var e = large._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".large" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = medium._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".medium" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = small._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".small" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bannerAttributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
  if (name.TAG === /* Ok */0) {
    var alternativeText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alternativeText"), null));
    if (alternativeText.TAG === /* Ok */0) {
      var formats = bannerFormats_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "formats"), null));
      if (formats.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  name: name._0,
                  alternativeText: alternativeText._0,
                  formats: formats._0
                }
              };
      }
      var e = formats._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".formats" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = alternativeText._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".alternativeText" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bannerData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var attributes = bannerAttributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "attributes"), null));
    if (attributes.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                attributes: attributes._0
              }
            };
    }
    var e = attributes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".attributes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function banner_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = bannerData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function banners_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = Decco.arrayFromJson(bannerData_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function children_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "text"), null));
  if (text.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              text: text._0
            }
          };
  }
  var e = text._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".text" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function description_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var children = Decco.arrayFromJson(children_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "children"), null));
  if (children.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              children: children._0
            }
          };
  }
  var e = children._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".children" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function descriptionWithBanner_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var description = Decco.arrayFromJson(description_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
    if (description.TAG === /* Ok */0) {
      var banner = banner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner"), null));
      if (banner.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id._0,
                  description: description._0,
                  banner: banner._0
                }
              };
      }
      var e = banner._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".banner" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = description._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".description" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function stat_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var count = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "count"), null));
  if (count.TAG === /* Ok */0) {
    var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
    if (id.TAG === /* Ok */0) {
      var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
      if (label.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  count: count._0,
                  id: id._0,
                  label: label._0
                }
              };
      }
      var e = label._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".label" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = id._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".id" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = count._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".count" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function porterStats_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var teamCount = stat_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "teamCount"), null));
      if (teamCount.TAG === /* Ok */0) {
        var customers = stat_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "customers"), null));
        if (customers.TAG === /* Ok */0) {
          var yearFounded = stat_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "yearFounded"), null));
          if (yearFounded.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      id: id._0,
                      title: title._0,
                      teamCount: teamCount._0,
                      customers: customers._0,
                      yearFounded: yearFounded._0
                    }
                  };
          }
          var e = yearFounded._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".yearFounded" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = customers._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".customers" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = teamCount._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".teamCount" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function joinPorter_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
    if (title.TAG === /* Ok */0) {
      var subText = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "subText"), null));
      if (subText.TAG === /* Ok */0) {
        var openPositionsCtaLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "openPositionsCtaLabel"), null));
        if (openPositionsCtaLabel.TAG === /* Ok */0) {
          var openPositionsLink = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "openPositionsLink"), null));
          if (openPositionsLink.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      id: id._0,
                      title: title._0,
                      subText: subText._0,
                      openPositionsCtaLabel: openPositionsCtaLabel._0,
                      openPositionsLink: openPositionsLink._0
                    }
                  };
          }
          var e = openPositionsLink._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".openPositionsLink" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = openPositionsCtaLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".openPositionsCtaLabel" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = subText._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".subText" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = title._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".title" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function attributes_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
  if (title.TAG === /* Ok */0) {
    var createdAt = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "createdAt"), null));
    if (createdAt.TAG === /* Ok */0) {
      var updatedAt = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "updatedAt"), null));
      if (updatedAt.TAG === /* Ok */0) {
        var publishedAt = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "publishedAt"), null));
        if (publishedAt.TAG === /* Ok */0) {
          var overview = descriptionWithBanner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "overview"), null));
          if (overview.TAG === /* Ok */0) {
            var commitment = descriptionWithBanner_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "commitment"), null));
            if (commitment.TAG === /* Ok */0) {
              var porterStats = porterStats_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "porterStats"), null));
              if (porterStats.TAG === /* Ok */0) {
                var joinPorter = joinPorter_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "joinPorter"), null));
                if (joinPorter.TAG === /* Ok */0) {
                  var banner = banners_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "banner"), null));
                  if (banner.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              title: title._0,
                              createdAt: createdAt._0,
                              updatedAt: updatedAt._0,
                              publishedAt: publishedAt._0,
                              overview: overview._0,
                              commitment: commitment._0,
                              porterStats: porterStats._0,
                              joinPorter: joinPorter._0,
                              banner: banner._0
                            }
                          };
                  }
                  var e = banner._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".banner" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = joinPorter._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".joinPorter" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = porterStats._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".porterStats" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = commitment._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".commitment" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = overview._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".overview" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = publishedAt._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".publishedAt" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = updatedAt._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".updatedAt" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = createdAt._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".createdAt" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = title._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".title" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function aboutUsData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var attributes = attributes_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "attributes"), null));
    if (attributes.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                id: id._0,
                attributes: attributes._0
              }
            };
    }
    var e = attributes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".attributes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var data = aboutUsData_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "data"), null));
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              data: data._0
            }
          };
  }
  var e = data._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".data" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

export {
  bannerFormat_decode ,
  bannerFormats_decode ,
  bannerAttributes_decode ,
  bannerData_decode ,
  banner_decode ,
  banners_decode ,
  children_decode ,
  description_decode ,
  descriptionWithBanner_decode ,
  stat_decode ,
  porterStats_decode ,
  joinPorter_decode ,
  attributes_decode ,
  aboutUsData_decode ,
  t_decode ,
  
}
/* No side effect */
